import virgemFrente1g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-1g-frente.png";
import virgemFrente2g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-2g-frente.png";
import virgemFrente5g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-5g-frente.png";
import virgemFrente10g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-10g-frente.png";
import virgemFrente25g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-25g-frente.png";
import virgemFrente31g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-31g-frente.png";
import virgemFrente50g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-50g-frente.png";
import virgemVerso1g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-1g-verso.png";
import virgemVerso2g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-2g-verso.png";
import virgemVerso5g from "../../../assets/newImages/mockups/signos/virgem/virgem-ouro-5g-verso.png";

export const  virgemMockups = {
    nome: "Virgem",
    frente1g: virgemFrente1g,
  
    frente2g: virgemFrente2g,
  
    frente5g: virgemFrente5g, 
    frente10g: virgemFrente10g,
    frente25g:virgemFrente25g,
    frente31g: virgemFrente31g,
    frente50g: virgemFrente50g, 
  
    verso1g: virgemVerso1g,
    verso2g: virgemVerso2g,
  
    verso5g: virgemVerso5g,
    verso10g: virgemVerso5g,
    verso25g: virgemVerso5g,
    verso31g: virgemVerso5g,
    verso50g: virgemVerso5g,
  };