import esposaFrente1g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-1g-frente.png";
import esposaFrente2g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-2g-frente.png";
import esposaFrente5g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-5g-frente.png";
import esposaFrente10g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-10g-frente.png";
import esposaFrente25g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-25g-frente.png";
import esposaFrente31g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-31g-frente.png";
import esposaFrente50g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-50g-frente.png";
import esposaVerso1g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-1g-verso.png";
import esposaVerso2g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-2g-verso.png";
import esposaVerso5g from "../../../assets/newImages/mockups/familia/esposa/esposa-ouro-5g-verso.png";

export const  esposaMockups = {
    nome: "Esposa de Ouro",
    frente1g: esposaFrente1g,
  
    frente2g: esposaFrente2g,
  
    frente5g: esposaFrente5g, 
    frente10g: esposaFrente10g,
    frente25g:esposaFrente25g,
    frente31g: esposaFrente31g,
    frente50g: esposaFrente50g, 
  
    verso1g: esposaVerso1g,
    verso2g: esposaVerso2g,
  
    verso5g: esposaVerso5g,
    verso10g: esposaVerso5g,
    verso25g: esposaVerso5g,
    verso31g: esposaVerso5g,
    verso50g: esposaVerso5g,
  };