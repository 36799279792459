import frente1g from '../../../assets/newImages/barra-ouro-1g-frente.png';
import verso1g from '../../../assets/newImages/barra-ouro-1g-verso.png';
import especificacao1g from '../../../assets/images/especificacoesOuro/ouro-1g.png';

import frente2g from '../../../assets/newImages/barra-ouro-2g-frente.png';
import verso2g from '../../../assets/newImages/barra-ouro-2g-verso.png';
import especificacao2g from '../../../assets/images/especificacoesOuro/ouro-2g.png';

import frente5g from '../../../assets/newImages/barra-ouro-5g-frente.png';
import verso5g from '../../../assets/newImages/barra-ouro-5g-verso.png';
import especificacao5g from '../../../assets/images/especificacoesOuro/ouro-5g.png';

import frente10g from '../../../assets/newImages/barra-ouro-10g-frente.png';
import verso10g from '../../../assets/newImages/barra-ouro-10g-verso.png';
import especificacao10g from '../../../assets/images/especificacoesOuro/ouro-10g.png';

import frente25g from '../../../assets/newImages/barra-ouro-25g-frente.png';
import verso25g from '../../../assets/newImages/barra-ouro-25g-verso.png';
import especificacao25g from '../../../assets/images/especificacoesOuro/ouro-25g.jpg';

import frente31g from '../../../assets/newImages/barra-ouro-31g-frente.png';
import verso31g from '../../../assets/newImages/barra-ouro-31g-verso.png';
import especificacao31g from '../../../assets/images/especificacoesOuro/ouro-31g.png';

import frente50g from '../../../assets/newImages/barra-ouro-b50g-frente.png';
import verso50g from '../../../assets/newImages/barra-ouro-b50g-verso.png';
import especificacao50g from '../../../assets/images/especificacoesOuro/ouro-50g.png';

import frente100g from "../../../assets/newImages/mockups/investimento/MODELO SUPER 100g - frente.jpg";
import verso100g from "../../../assets/newImages/mockups/investimento/MODELO SUPER 100g - VERSO.jpg";
import especificacao100g from '../../../assets/images/especificacoesOuro/ouro-100g.png';

import frente250g from "../../../assets/newImages/mockups/investimento/MODELO HÍPER 250g - frente.jpg";
import verso250g from "../../../assets/newImages/mockups/investimento/MODELO HÍPER 250g - VERSO.jpg";

import especificacao250g from '../../../assets/images/especificacoesOuro/ouro-250g.png';

import frente500g from "../../../assets/newImages/mockups/investimento/ourominas_mychooice3.png";
import verso500g from "../../../assets/newImages/mockups/investimento/ourominas_mychooice4.png";
import especificacao500g from '../../../assets/images/especificacoesOuro/ouro-500g.png';

import frente1000g from "../../../assets/newImages/mockups/investimento/ourominas_mychooice8.png";
import verso1000g from "../../../assets/newImages/mockups/investimento/ourominas_mychooice7.png";
import especificacao1000g from '../../../assets/images/especificacoesOuro/ouro-1000g.png';


export const  investimentoMockups = {
    nome: "Investimento",
    frente100g: frente100g,
    frente250g: frente250g,
    frente500g: frente500g,
    frente1000g: frente1000g,

    verso100g: verso100g,
    verso250g: verso250g,
    verso500g: verso500g,
    verso1000g: verso1000g,

    especificacao100g: especificacao100g,
    especificacao250g: especificacao250g,
    especificacao500g: especificacao500g,
    especificacao1000g: especificacao1000g
  };


export const investimento = [
    { name: 'Blue', value: ['OURO BARRA (1g)'] },
    { name: 'Green', value: ['OURO BARRA (2g)'] },
    { name: 'Gold', value: ['OURO BARRA (5g)'] },
    { name: 'Platinum', value: ['OURO BARRA (10g)'] },
    { name: 'Black', value: ['OURO BARRA (25g)'] },
    { name: 'Onça Troy', value: ['OURO BARRA (31,104g)'] },
    { name: 'Infinity', value: ['OURO BARRA (50g)'] },
    { name: 'Super', value: ['OURO BARRA (100g)'] },
    { name: 'Hiper', value: ['OURO BARRA (250g)'] },
    { name: 'Mega', value: ['OURO BARRA (500g)'] },
    { name: 'Ultra', value: ['OURO BARRA (1000g)'] },
  ];


  export const investimentoAll = [
    { name: '1g-1kg', value: ['OURO BARRA (1g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (2g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (5g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (10g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (25g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (31,104g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (50g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (100g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (250g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (500g)'] },
    { name: '1g-1kg', value: ['OURO BARRA (1000g)'] },
  ];

  export const  investimentoAllMockups = {
    nome: "InvestimentoAll",
    frente1g: frente1g,

    frente2g: frente2g,

    frente5g: frente5g,
    frente10g: frente10g,
    frente25g:frente25g,
    frente31g: frente31g,
    frente50g: frente50g,

    frente100g: frente100g,
    frente250g: frente250g,
    frente500g: frente500g,
    frente1000g: frente1000g,

    verso1g: verso1g,
    verso2g: verso2g,

    verso5g: verso5g,
    verso10g: verso10g,
    verso25g: verso25g,
    verso31g: verso31g,
    verso50g: verso50g,
    verso100g: verso100g,
    verso250g: verso250g,
    verso500g: verso500g,
    verso1000g: verso1000g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
    especificacao100g: especificacao100g,
    especificacao250g: especificacao250g,
    especificacao500g: especificacao500g,
    especificacao1000g: especificacao1000g
  };


  export const  BlueMockups = {
    nome: "Blue",
    frente1g: frente1g,
    verso1g: verso1g,
    especificacao1g: especificacao1g,

  };

  export const  GreenMockups = {
    nome: "Green",
    frente2g: frente2g,
    verso2g: verso2g,
    especificacao2g: especificacao2g,

  };

  export const  GoldMockups = {
    nome: "Gold",
    frente5g: frente5g,
    verso5g: verso5g,
    especificacao5g: especificacao5g,
  };

  export const  PlatinumMockups = {
    nome: "Platinum",
    frente10g: frente10g,
    verso10g: verso10g,
    especificacao10g: especificacao10g,
  };

  export const  BlackMockups = {
    nome: "Black",
    frente25g: frente25g,
    verso25g: verso25g,
    especificacao25g: especificacao25g,
  };

  export const  oncaTroyMockups = {
    nome: "Onça Troy",
    frente31g: frente31g,
    verso31g: verso31g,
    especificacao31g: especificacao31g,
  };

  export const  infintyMockups = {
    nome: "Infinity",
    frente50g: frente50g,
    verso50g: verso50g,
    especificacao50g: especificacao50g,
  };

  export const  superMockups = {
    nome: "Super",
    frente100g: frente100g,
    verso100g: verso100g,
    especificacao100g: especificacao100g,
  };

  export const  hiperMockups = {
    nome: "Hiper",
    frente250g: frente250g,
    verso250g: verso250g,
    especificacao250g: especificacao250g,
  };

  export const  megaMockups = {
    nome: "Mega",
    frente500g: frente500g,
    verso500g: verso500g,
    especificacao500g: especificacao500g,
  };

  export const  ultraMockups = {
    nome: "Ultra",
    frente1000g: frente1000g,
    verso1000g: verso1000g,
    especificacao1000g: especificacao1000g
  };
