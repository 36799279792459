import lembreiVoceFrente1g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-1g-frente.png";
import lembreiVoceFrente2g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-2g-frente.png";
import lembreiVoceFrente5g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-5g-frente.png";
import lembreiVoceFrente10g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-10g-frente.png";
import lembreiVoceFrente25g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-25g-frente.png";
import lembreiVoceFrente31g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-31g-frente.png";
import lembreiVoceFrente50g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-50g-frente.png";
import lembreiVoceVerso1g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-1g-verso.png";
import lembreiVoceVerso2g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-2g-verso.png";
import lembreiVoceVerso5g from "../../../assets/newImages/mockups/sentimentos/lembrei-voce/lembrei-voce-5g-verso.png";

export const  lembreiVoceMockups = {
    nome: "Lembrei de Você",
    frente1g: lembreiVoceFrente1g,
  
    frente2g: lembreiVoceFrente2g,
  
    frente5g: lembreiVoceFrente5g, 
    frente10g: lembreiVoceFrente10g,
    frente25g:lembreiVoceFrente25g,
    frente31g: lembreiVoceFrente31g,
    frente50g: lembreiVoceFrente50g, 
  
    verso1g: lembreiVoceVerso1g,
    verso2g: lembreiVoceVerso2g,
  
    verso5g: lembreiVoceVerso5g,
    verso10g: lembreiVoceVerso5g,
    verso25g: lembreiVoceVerso5g,
    verso31g: lembreiVoceVerso5g,
    verso50g: lembreiVoceVerso5g,
  };