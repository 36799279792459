import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

const SubScetionTitle = styled.h4`
  text-align: left;
  margin: 50px 0 20px 0;
`;

function CyberSecurityPolicyPage() {
  return (
    <section>
      <Container style={{ marginTop: "90px" }}>
        <Row className="justify-content-center text-center section-intro">
          <Col>
            <h2 className="display-4" data-aos="fade-up" data-aos-delay="100">
              Política de segurança cibernética
            </h2>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col className="mb-30">
            <div className="section-text">
              <SubScetionTitle>1. OBJETIVO</SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                Este normativo estabelece a Política de Segurança Cibernética da
                OM DTVM, bem como os requisitos para a Contratação, Avaliação e
                Gestão de serviços de processamento e armazenamento de dados e
                de computação em nuvem visando total observância e adequação ao
                exigido na Resolução nº 4.658 do Banco Centra do Brasil. O
                principal objetivo desta Política é assegurar a proteção dos
                ativos de informação da Corretora contra ameaças, internas ou
                externas, reduzir a exposição a perdas ou danos decorrentes de
                falhas de cibersegurança e garantir que os recursos adequados
                estarão disponíveis, mantendo um processo de segurança efetivo
                de nossos negócios.
              </p>
              <p
                className="MsoNormal"
                style={{
                  textAlign: "justify",
                  verticalAlign: "baseline",
                  margin: "12.0pt 0cm 18.0pt 0cm",
                }}
              >
                &nbsp;
              </p>

              <SubScetionTitle>
                2. RAZÕES, AMEAÇAS E RISCOS CIBERNÉTICOS
              </SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                Existem diversas razões para que esses ataques sejam realizados
                por vários agentes (organizações criminosas, hackers
                individuais, terroristas, colaboradores, competidores etc.) como
                por exemplo:
              </p>
              <ul>
                <li style={{ textAlign: "left" }}>
                  &nbsp;Ganhos financeiros através de roubo, manipulação ou
                  adulteração de informações;
                </li>
                <li style={{ textAlign: "left" }}>
                  Obter vantagens competitivas e informações confidenciais de
                  Clientes ou Instituições concorrentes.
                </li>
                <li style={{ textAlign: "left" }}>
                  Fraudar, sabotar ou expor a Instituição invadida por motivos
                  de vingança, ideias políticas ou sociais.
                </li>
                <li style={{ textAlign: "left" }}>
                  Praticar o terror e disseminar pânico e caos.
                </li>
                <li style={{ textAlign: "left" }}>
                  Enfrentar desafios e/ou ter adoração por hackers famosos.
                </li>
              </ul>
              <p style={{ textAlign: "left" }}>
                Os invasores podem utilizar vários métodos para os ataques
                cibernéticos, destacam-se os mais comuns:
              </p>
              <br />
              <ul>
                <li style={{ textAlign: "left" }}>
                  <strong>Malware</strong>: softwares desenvolvidos para
                  corromper computadores e redes:
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Vírus</strong>: software que causa danos a máquina,
                  rede, softwares e banco de dados;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Cavalo de Troia</strong>: aparece dentro de outro
                  software e cria uma porta para a invasão do computador;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Spyware</strong>: software malicioso para coletar e
                  monitorar o uso de informações;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Ransomware</strong>: software malicioso que bloqueia o
                  acesso a sistemas e bases de dados, solicitando um resgate
                  para que o acesso seja reestabelecido.
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Engenharia</strong> <strong>social</strong>: métodos
                  de manipulação para obter informações confidenciais, como
                  senhas, dados pessoais e número de cartão de crédito:
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Pharming</strong>: direciona o usuário para um site
                  fraudulento, sem o seu conhecimento;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Phishing</strong>: links transmitidos por e-mails,
                  simulando ser uma pessoa ou empresa confiável que envia
                  comunicação eletrônica oficial para obter informações
                  confidenciais;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Vishing</strong>: simula ser uma pessoa ou empresa
                  confiável e, por meio de ligações telefônicas, tenta obter
                  informações confidenciais;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Smishing</strong>: simula ser uma pessoa ou empresa
                  confiável e, por meio de mensagens de texto, tenta obter
                  informações confidenciais;
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Acesso</strong> <strong>pessoal</strong>: pessoas
                  localizadas em lugares públicos como bares, cafés e
                  restaurantes que captam qualquer tipo de informação que possa
                  ser utilizada posteriormente para um ataque.
                </li>
                <li style={{ textAlign: "left" }}>
                  <strong>Ataques</strong> <strong>de</strong>{" "}
                  <strong>DDOS</strong> <strong>(Distributed denial of</strong>{" "}
                  <strong>services) e botnets</strong>: ataques visando negar ou
                  atrasar o acesso aos serviços ou sistemas da instituição; no
                  caso dos botnets, o ataque vem de um grande número de
                  computadores infectados utilizados para criar e mandar spam ou
                  vírus, ou inundar uma rede com mensagens resultando na negação
                  de serviços.
                </li>
              </ul>

              <SubScetionTitle>3. DIRETRIZES&nbsp;</SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                A Política de Segurança Cibernética da OM DTVM baseia-se nos
                seguintes princípios:
              </p>
              <ul>
                <li style={{ textAlign: "left" }}>
                  Assegurar a confidencialidade dos ativos de informação
                  (garantia de que o acesso à informação seja obtido somente por
                  pessoas autorizadas) observadas as regras de sigilo e
                  confidencialidade vigentes.
                </li>
                <li style={{ textAlign: "left" }}>
                  Assegurar a integridade (garantia de que a informação seja
                  mantida em seu estado original, visando protegê-la, na guarda
                  ou transmissão, contra alterações indevidas, intencionais ou
                  acidentais);
                </li>
                <li style={{ textAlign: "left" }}>
                  Assegurar a disponibilidade dos dados e sistemas de informação
                  utilizados na Corretora (garantia de que os usuários
                  autorizados obtenham acesso à informação e aos ativos
                  correspondentes sempre que necessário).
                </li>
              </ul>
              <p style={{ textAlign: "left" }}>
                Caberá a todos os Colaboradores conhecer e adotar as disposições
                desta política e deverão, ainda, proteger as informações contra
                acesso, modificação, destruição ou divulgação não-autorizados,
                assegurar que os recursos tecnológicos à sua disposição sejam
                utilizados apenas para as finalidades adequadas ao exercício de
                suas atividades.
              </p>
              <p style={{ textAlign: "left" }}>&nbsp;</p>

              <SubScetionTitle>
                4. CONTRATAÇÃO DE SERVIÇOS DE PROCESSAMENTO E ARMAZENAMENTO DE
                DADOS E DE COMPUTAÇÃO EM NUVEM
              </SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                Conforme a Resolução 4.658/2018 do Banco Central do Brasil, a OM
                DTVM ao realizar contratações de serviços relevantes de
                processamento e armazenamento de dados e de computação em nuvem,
                no país ou no exterior deverá adotar procedimentos visando
                certificar-se de que a empresa contratada atende as para a
                aderência às regras previstas na regulamentação em vigor.
              </p>

              <SubScetionTitle>5. MITIGAÇÃO DOS RISCOS</SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                A Corretora oferece aos Colaboradores uma completa estrutura
                tecnológica para o exercício das atividades, sendo
                responsabilidade de cada Colaborador manter e zelar pela
                integridade dessas ferramentas de trabalho, e por manter o
                controle sobre a segurança das informações armazenadas ou
                disponibilizadas nos equipamentos sob sua responsabilidade
                (Computador, notebook, acesso à internet, E-mail, etc.).
              </p>
            </div>
            <div className="section-text">
              <h4>&nbsp;</h4>

              <SubScetionTitle>
                6. PREVENÇÃO E PROTEÇÃO AO RISCO CIBERNÉTICO
              </SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                São realizados procedimentos para prevenção e proteção ao risco
                cibernético que são:
              </p>
              <ul>
                <li style={{ textAlign: "left" }}>Mitigação de Riscos</li>
                <li style={{ textAlign: "left" }}>Ações de Prevenção</li>
                <li style={{ textAlign: "left" }}>Tratamento de Incidentes</li>
                <li style={{ textAlign: "left" }}>Monitoramento de Testes</li>
              </ul>
              <p style={{ textAlign: "left" }}>&nbsp;</p>

              <SubScetionTitle>7. CONTINUIDADE DE NEGÓCIOS</SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                O Plano de Continuidade de Negócios (PCN) assegura à Organização
                a continuidade de seus negócios em caso de paralisação,
                decorrente de sinistro, de um ou mais processos considerados
                críticos. Incluem-se nesse processo, a continuidade de negócios
                relativos aos serviços contratados de nuvem e os testes
                previstos para os cenários de ataques cibernéticos.
              </p>
              <p
                className="MsoNormal"
                style={{
                  textAlign: "justify",
                  verticalAlign: "baseline",
                  margin: "12.0pt 0cm 18.0pt 0cm",
                }}
              >
                &nbsp;
              </p>

              <SubScetionTitle>8. RESPONSABILIDADE</SubScetionTitle>
              <p style={{ textAlign: "left" }}>
                A alta administração da OM DTVM se compromete com a melhoria
                contínua dos procedimentos e controles relacionados nesta
                política e com a execução do plano de ação e de respostas a
                incidentes.
              </p>
              <p
                className="MsoNormal"
                style={{
                  textAlign: "justify",
                  verticalAlign: "baseline",
                  margin: "12.0pt 0cm 18.0pt 0cm",
                }}
              >
                &nbsp;
              </p>
              <p style={{ textAlign: "left" }}>&nbsp;</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CyberSecurityPolicyPage;
