import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { getOperacaoByID } from '../../services/modules/cliente/operacaoService';
import { PageLoading } from '../../components/Loading/Loading';
import CountryFlags from '../../utils/countryFlags';
import { ReturnName } from '../../utils/coins';
import { toBRL } from '../../utils/regex';

export default function PedidoItem() {
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id) {
      setLoad(true);
      getOperacaoByID(parseInt(id))
        .then(setData)
        .catch((e) => console.log(e))
        .finally(() => setLoad(false));
    }
  }, [id]);
  return (
    <Container>
      {load && <PageLoading />}
      <h2>Detalhes do Pedido</h2>
      {!data && !load && <p>Não foi possível buscar o seu pedido.</p>}

      {data && (
        <>
          <CountryFlags cod={data.dadosOperacao.tipo} />
          <h5> {ReturnName(data.dadosOperacao.tipo)}</h5>
          {data.dadosOperacao.status === 'W' ? (
            <h6>Pedido em andamento</h6>
          ) : data.dadosOperacao.status === 'A' ? (
            <h6>Pedido concluído</h6>
          ) : data.dadosOperacao.status === 'N' ? (
            <h6>Pedido cancelado</h6>
          ) : null}
          <BoxTables>
            <div className="dadosPedido">
              <h1 className="titulo">Dados da Solicitação</h1>
              <hr />
              <div className="detalhesPedido">
                <p>Quantidade:</p>
                <span>
                  {data.dadosOperacao.tipo} {data.dadosOperacao.qtde}
                </span>
              </div>
              <div className="detalhesPedido">
                <p>Taxa Cambial:</p>
                <span>{toBRL(data.dadosOperacao.taxa, 6)}</span>
              </div>
              <div className="detalhesPedido">
                <p>Valor da Moeda Nacional (S/IOF):</p>
                <span>{toBRL(data.dadosOperacao.valorParcial)}</span>
              </div>

              <div className="detalhesPedido">
                <p>IOF:</p>
                <span>{toBRL(data.dadosOperacao.iof)}</span>
              </div>

              <div className="detalhesPedido">
                <p>Tarifa:</p>
                <span>{toBRL(data.dadosOperacao.remessa)}</span>
              </div>
              <div className="detalhesPedido">
                <p>
                  <b>Valor efetivo total (VET):</b>
                </p>
                <b>{toBRL(data.dadosOperacao.total)}</b>
              </div>
            </div>
            <div className="dadosPedidoCliente">
              <h1 className="titulo">Dados do Cliente</h1>
              <hr />
              <div className="detalhesPedido">
                <p>Nome/Razão:</p>
                <span>{data.dadosCliente.nome}</span>
              </div>
              <div className="detalhesPedido">
                <p>CPF/CNPJ:</p>
                <span>{data.dadosCliente.documento}</span>
              </div>
              <div className="detalhesPedido">
                <p>Endereço:</p>
                <span>
                  {data.dadosCliente.endereco},{' '}
                  {data.dadosCliente.numeroEndereco}{' '}
                  {data.dadosCliente.complemento}{' '}
                </span>
              </div>

              <div className="detalhesPedido">
                <p>Bairro:</p>
                <span>{data.dadosCliente.bairro}</span>
              </div>

              <div className="detalhesPedido">
                <p>Cidade/UF:</p>
                <span>{data.dadosCliente.cidade}</span>
              </div>
              <div className="detalhesPedido">
                <p>Contratado:</p>
                <span>{data.dadosContratado.nome}</span>
              </div>
              <div className="detalhesPedido">
                <p>Forma de Pagamento:</p>
                <span>{data.dadosOperacao.formaPagamento}</span>
              </div>
              <div className="detalhesPedido">
                <p>Observação:</p>
                <span>{data.dadosOperacao.observacao}</span>
              </div>
            </div>
          </BoxTables>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-top: 20px;
    width: 150px;
  }

  > h5 {
    color: rgb(0, 65, 51);
  }
  min-height: 600px;

  padding: 20px 0;
`;

const BoxTables = styled.div`
  display: flex;
  flex-direction: column;

  .dadosPedido {
    margin-left: 44px;
    margin-right: 44px;
  }

  .dadosPedidoCliente {
    margin-left: 44px;
    margin-right: 44px;
  }

  .titulo {
    font-size: 18px;
    color: var(--green);
    margin-top: 30px;
  }

  hr {
    height: 1.5px;
    background-color: var(--green);
    opacity: 1;
  }

  p {
    font-weight: 500;
  }

  .detalhesPedido {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      width: 50%;
      display: flex;
      justify-content: end;
    }

    @media (min-width: 500px) {
      span {
        width: 60%;
      }
    }

    @media (min-width: 600px) {
      span {
        width: 66%;
      }
    }

    @media (min-width: 1024px) {
      span {
        width: 60%;
        padding-bottom: 5px;
      }
    }

    @media (min-width: 1150px) {
      span {
        width: 65%;
      }
    }

    @media (min-width: 1500px) {
      span {
        width: 68%;
      }
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row-reverse;

    .dadosPedido {
      width: 50%;
    }

    .dadosPedidoCliente {
      width: 50%;
      margin-right: 0;
    }
  }
`;
