import React, { useEffect, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import {  getRecebaCotacoes } from "../../../../services/admin";
import {  toDateBR } from "../../../../utils/regex";
import { DisableAlert } from "../components/DisableAlert";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

export default function RecebaCotacoesADM() {
  const [data, setData] = useState();
  const [err, setErr] = useState();
  const [person, setPerson ] = useState();
  const header = [
    "ID",
    "NOME",
    "EMAIL",
    "TELEFONE",
    "PERIODO",
    "FORMA DE CONTATO",
    "COTAÇÃO",
    "DATA",
    "STATUS",
  ];
  useEffect(() => {
    getData();
  }, []);


  function getData(){
    getRecebaCotacoes()
    .then((resp) => {
      const filter = resp.filter((item) =>item.status === 1 )
      
      filter.sort((a,b) =>{
        return new Date(b.dataCadastro) - new Date(a.dataCadastro);
      })
      
      setData(filter);
    })
    .catch((resp) => setErr(resp.response.status));
  }

  function onDownload() {
    downloadExcel({
      fileName: "Users table",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body: data,
      },
    });
  }
 
  return (
    <section>
      <Container>
        <Title>Receba as Cotações</Title>
        {person &&(
          
          <DisableAlert item={person} reload={getData}/>

        )}
        {err && (
          <p className="text-center">
            Dados não disponíveis!
            <br />
            {err}
          </p>
        )}
        {data && (
          <>
          <div className="d-flex justify-content-end mb-2">
              {" "}
              <button
                className="btn btn-success text-align-center"
                onClick={() => onDownload()}
              >
                {" "}
                Exportar
              </button>{" "}
            </div>
         
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th className="text-center" scope="col">
                  Email
                </th>
                <th className="text-center" scope="col">
                  Telefone
                </th>
                <th className="text-center" scope="col">
                  Período
                </th>
                <th className="text-center" scope="col">
                  Cotação
                </th>
                <th className="text-center" scope="col">
                  Data
                </th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                return (
                  <tr key={i++}>
                    <td>{item?.nome}</td>
                    <td>{item?.email}</td>
                    <td>{item?.telefone}</td>
                    <td>{item?.periodo}</td>
                    <td>{item?.tipoCotacao}</td>
                    <td>{toDateBR(item?.dataCadastro)}</td>
                    <td><IconButton aria-label="delete" onClick={() => setPerson(item)} color="primary"><DeleteIcon /></IconButton></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </>
        )}
      </Container>
    </section>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 60px 100px;
`;
const Title = styled.h1`
  text-align: center;
  margin-bottom: 50px;
`;
