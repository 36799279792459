import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { loginAuth } from "../../../services/admin";
import load from "../../../assets/spinner.gif";
import styled from "styled-components";

export default function LoginADM() {
  const navigate = useNavigate();
  const [connect, setConnect] = useState(false);
  const [login, setLogin] = useState();
  const [senha, setSenha] = useState();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  function send() {
    setLoading(true);
    setErr("");
    if (!login || !senha) {
      setErr("Preencha o login e senha!");
    } else {
      let data = { username:login, password:senha };
      loginAuth(data, connect)
        .then((resp) => {
          if(resp === 'ADMIN' || resp === 'PRICING_COINS'){
            navigate("/admin/cotacoes/remessas-internacionais");
          }
          else if(resp === 'MKT'){
            navigate("/admin/home/receba-cotacoes")
          }else if(resp === 'PRICING_STORE'){
            navigate("/admin/cotacoes/lojas/ouro")
          }
        })
        .catch((err) => {
          if (err.request.status === 404) {
            setErr("Usuário ou senha inválido!");
          } else {
            setErr("Tente novamente mais tarde!");
          }
        })
        .finally(() => setLoading(false));
    }
  }
  return (
    <div className="row v-center d-flex justify-content-center m-0 shadow">
      <div className="d-flex justify-content-center mt-5">
        <button className="btn" onClick={() => navigate("/")}>
          <img src={logo} width="100px" alt="logo ourominas"></img>
        </button>
      </div>
      <div className="col-md-4 border border-5 rounded p-5">
        <h2 className="p-0 m-0 mb-3 text-center">Login</h2>
        <div className="mb-3">
          <label className="form-label">Login</label>
          <input
            type="text"
            className="form-control"
            id="login"
            placeholder="Login"
            onChange={(e) => setLogin(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Senha</label>
          <input
            type="password"
            className="form-control"
            id="senha"
            placeholder="**********"
            onChange={(e) => setSenha(e.target.value)}
          />
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            value={connect}
            id="check"
            onChange={(e) => setConnect(e.target.value)}
          />
          <label className="form-check-label">Manter Conectado</label>
        </div>
        <div className="my-2 text-center">
          <span className="text-danger text-center">{err}</span>
        </div>

        <div className="d-flex justify-content-center">
          <button className="w-100 btn btn-success" onClick={() => send()}>
            Entrar
          </button>
        </div>
        {loading && (
          <div className="d-flex justify-content-center">
            <Loading src={load} alt="gif de load" />
          </div>
        )}
      </div>
    </div>
  );
}
const Loading = styled.img`
  width: 20px;
  height: 20px;
  text-align: center;
  margin-top: 15px;
`;
