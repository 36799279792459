import micoFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-1g-frente.png";
import micoFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-2g-frente.png";
import micoFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-5g-frente.png";
import micoFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-10g-frente.png";
import micoFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-25g-frente.png";
import micoFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-31g-frente.png";
import micoFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-50g-frente.png";
import micoVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-1g-verso.png";
import micoVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-2g-verso.png";
import micoVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/mico/mico-ouro-5g-verso.png";

export const  micoMockups = {
    nome: "Mico Leão Dourado",
    frente1g: micoFrente1g,
  
    frente2g: micoFrente2g,
  
    frente5g: micoFrente5g,
  
    frente10g: micoFrente10g,
  
    frente25g: micoFrente25g,
  
    frente31g: micoFrente31g,
  
    frente50g: micoFrente50g,
  
    verso1g: micoVerso1g,
    verso2g: micoVerso2g,
  
    verso5g: micoVerso5g,
  };

 