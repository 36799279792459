import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import pix from '../../../assets/icons/pix.png';
import codigoBarra from '../../../assets/icons/codigo-de-barras.png';
import cartaoCredito from '../../../assets/icons/cartaoCredito.png';
import ted from '../../../assets/icons/bank-transfer.png';
import transferencia from '../../../assets/icons/transferencia.png';
import doc from '../../../assets/icons/doc.png';

import PagamentoPix from './pagamentoPix';
import PagamentoBoleto from './pagamentoBoleto';
import PagamentoCartao from './pagamentoCartao';
import PagamentoTed from './pagamentoTed';
import PagamentoTransferencia from './pagamentoTransferencia';
import PagamentoDoc from './pagamentoDoc';
import { useParams } from 'react-router-dom';
import { getOperacaoByCPF } from '../../../services/modules/cliente/operacaoService';
import { PageLoading } from '../../../components/Loading/Loading';
import { AlertNotify } from '../../../components/alerts/AlertNotify';
import { PedidoResumo } from '../../components/pedidoResumo';

const styleImg = {
  width: '50px',
  margin: '0 25px',
};

const opcoesPagamento = [
  { id: 'pix', nome: 'PIX', icon: pix, component: <PagamentoPix /> },
  //{ id: 'codigoBarra', nome: 'Boleto Bancário', icon: codigoBarra, component: <PagamentoBoleto /> },
  { id: 'ted', nome: 'TED', icon: ted, component: <PagamentoTed /> },
  //{ id: 'transferencia', nome: 'Transferência Bancária', icon: transferencia, component: <PagamentoTransferencia /> },
  //{ id: 'doc', nome: 'DOC', icon: doc, component: <PagamentoDoc /> },
  //{ id: 'cartaoCredito', nome: 'Cartão de Crédito', icon: cartaoCredito, component: <PagamentoCartao /> },
];

const renderizaPagamentoComponent = (formaPagamento) => {
  const opcaoSelecionada = opcoesPagamento.find((opcao) => opcao.id === formaPagamento);
  return opcaoSelecionada ? opcaoSelecionada.component : null;
};

export default function Pagamento() {
  const [openDetails, setOpenDetails] = useState('');
  const [arquivo,setArquivo] = useState(null);
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState(null);

  const handleFormaPagamentoChange = (forma) => {
    setOpenDetails((prevOpenDetails) =>
      prevOpenDetails === forma ? '' : forma
    );
  };

  useEffect(() => {
    setLoad(true);
    getOperacaoByCPF('W')
    .then((resp) => {
      const filter = resp.dados.find(item => item.Ope_ID === parseInt(id));
      if(filter){
        setData(filter)
      }else AlertNotify({message: 'Não foi possível encontrar a sua operação!', type: 'danger'})
    })
    .catch((e) => e)
    .finally(() => setLoad(false));

  }, [id])

  return (
    <MenuPrincipal>
      {load && <PageLoading/>}
      <MenuPagamento>
        <TituloPrincipal>
          <h1>Opções de pagamento</h1>
          <p>Selecione a forma de pagamento de sua compra abaixo</p>
        </TituloPrincipal>
        {data &&
        <BoxPedido>
        <PedidoResumo item={data} button={false}/>

        </BoxPedido>
}
        {opcoesPagamento.map((opcao) => (
          <Details
            key={opcao.id}
            className={`p-2 border ${openDetails === opcao.id ? 'open' : ''}`}
          >
            <Summary onClick={() => handleFormaPagamentoChange(opcao.id)}>
              <StyledCheckedCircle
                name={opcao.id}
                checked={openDetails === opcao.id}
              />
              <img src={opcao.icon} alt="" style={styleImg} />
              {opcao.nome}
              <Selecionado checked={openDetails === opcao.id} />
            </Summary>
            {openDetails === opcao.id && renderizaPagamentoComponent(opcao.id)}
          </Details>
        ))}
      </MenuPagamento>

    </MenuPrincipal>
  );
}

const MenuPrincipal = styled.article`
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  min-height: 350px;
  padding-bottom: 50px;
`;

const MenuPagamento = styled.section`
  width: 90%;
  margin: 0 auto;

  @media (min-width: 1000px) {
    width: 80%;
  }

  @media (min-width: 1800px) {
    width: 60%;
  }
`;

const TituloPrincipal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin: 30px 0 10px;
    color: var(--green);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
  }

  p {
    margin: 0 0 30px;
    color: var(--light);
    font-weight: 500;
    font-size: 16px;
  }
`;

const Details = styled.details`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  width: 100%;
`;

const Summary = styled.summary`
  cursor: pointer;
  padding: 10px;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid var(--green);
    font-weight: 500;
    margin-right: 10px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const Selecionado = styled.div`
  background-color: var(--green);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: ${(props) => (props.checked ? 'block' : 'none')};
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledCheckedCircle = styled(StyledRadio)`
  &:checked + ${Summary}::before {
    display: none;
  }

  &:checked + ${Summary} ${Selecionado} {
    display: block;
  }
`;


const BoxPedido = styled.div`
display: flex;
justify-content: center;

  > div {
   margin-top: 0;
   margin-bottom: 40px;
   }
`