export function ReturnName(cod) {
  switch (cod) {
    case "COP":
      return "PESO COLOMBIANO";

    case "DKK":
      return "COROA DINAMARQUESA";

    case "ZAR":
      return "RAND SUL-AFRICANO";

    case "CAD":
      return "DÓLAR CANADENSE";

    case "ILS":
      return "SHEKEL ISRAELENSE";

    case "RUB":
      return "RUBLO RUSSO";

    case "XAUUSD":
      return "OURO / ONÇA TROY 31.104";

    case "CHF":
      return "FRANCO SUIÇO";

    case "CNY":
      return "IUAN CHINÊS";

    case "GBP":
      return "LIBRA ESTERLINA";

    case "AED":
      return "DIRHAM DOS EMIRADOS ÁRABES";

    case "CLP":
      return "PESO CHILENO";

    case "AUD":
      return "DÓLAR AUSTRALIANO";

    case "ARS":
      return "PESO ARGENTINO";

    case "EUR":
      return "EURO";

    case "NZD":
      return "DÓLAR NEOZELANDÊS";

    case "MXN":
      return "PESO MEXICANO";

    case "SEK":
      return "COROA SUECA";

    case "JPY":
      return "IENE JAPONÊS";

    case "PEN":
      return "SOL PERUANO";

    case "OZ1D":
      return "OURO / BM&F (250g)";

    case "UYU":
      return "PESO URUGUAIO";

    case "EUSCOM":
      return "DÓLAR AMERICANO";
    case "USD":
      return "DÓLAR AMERICANO";

    case "NOK":
      return "COROA NORUEGUESA";

    case "MNG":
        return "MONEYGRAM";

    default:
      return null;
  }
}

export function ReturnNameGold(cod) {
  switch (cod) {
    case "OURO BARRA (1g)":
      return "1 grama";
    case "OURO BARRA (2g)":
      return "2 gramas";
    case "OURO BARRA (5g)":
      return "5 gramas";
    case "OURO BARRA (7g)":
      return "7 gramas";
    case "OURO BARRA (10g)":
      return "10 gramas";
    case "OURO BARRA (25g)":
      return "25 gramas";
    case "OURO BARRA (31,104g)":
      return "31,104 gramas / Onça Troy";
    case "OURO BARRA (50g)":
      return "50 gramas";
    case "OURO BARRA (100g)":
      return "100 gramas";
    case "OURO BARRA (250g)":
      return "250 gramas";
    case "OURO BARRA (500g)":
      return "500 gramas";
    case "OURO BARRA (1000g)":
      return "1000 gramas / 1kg";

    default:
      return null;
  }
}

export function ReturnGrammage(index) {
  switch (index) {
    case 0:
      return "1";
    case 1:
      return "2";
    case 2:
      return "5";
    case 3:
      return "7";
    case 4:
      return "10";
    case 5:
      return "25";
    case 6:
      return "31,104";
    case 7:
      return "50";
    case 8:
      return "100";
    case 9:
      return "250";
    case 10:
      return "500";
    case 11:
      return "1000";

      default:
        return null;
  }
}

export function ReturnGrammageGold(cod) {
  switch (cod) {
    case "OURO BARRA (1g)":
      return "1g";
    case "OURO BARRA (2g)":
      return "2g";
    case "OURO BARRA (5g)":
      return "5g";
    case "OURO BARRA (7g)":
      return "7g";
    case "OURO BARRA (10g)":
      return "10g";
    case "OURO BARRA (25g)":
      return "25g";
    case "OURO BARRA (31,104g)":
      return "31,104g";
    case "OURO BARRA (50g)":
      return "50g";
    case "OURO BARRA (100g)":
      return "100g";
    case "OURO BARRA (250g)":
      return "250g";
    case "OURO BARRA (500g)":
      return "500g";
    case "OURO BARRA (1000g)":
      return "1000g";

      default:
        return null;
  }
}

export function ReturnNamePage(name) {
  switch (name) {
    case "existentes":
      return "Barra de Ouro";
    case "1g-2g":
      return "Barra de Ouro";
    case "5g-10kg":
      return "Barra de Ouro";
    case "100g-1kg":
      return "Barra de Ouro";
    case "piramide-ouro":
      return "Pirâmide de Ouro";
    case "plante-arvore":
      return "Plante Árvore";
    case "amiga":
      return "Amiga de Ouro";
    case "amigo":
      return "Amigo de Ouro";
    case "moedas-estrangeiras":
      return "Moedas Estrangeiras";
    case "cartao-pre-pago":
      return "Cartão Pré-pago";
    case "pai":
      return "Pai de Ouro";
    default:
      break;
  }
}
