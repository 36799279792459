import sociaFrente1g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-1g-frente.png";
import sociaFrente2g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-2g-frente.png";
import sociaFrente5g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-5g-frente.png";
import sociaFrente10g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-10g-frente.png";
import sociaFrente25g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-25g-frente.png";
import sociaFrente31g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-31g-frente.png";
import sociaFrente50g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-50g-frente.png";
import sociaVerso1g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-1g-verso.png";
import sociaVerso2g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-2g-verso.png";
import sociaVerso5g from "../../../assets/newImages/mockups/empresa-ouro/socia/socia-ouro-5g-verso.png";

export const  sociaMockups = {
    nome: "Sócia",
    frente1g: sociaFrente1g,
  
    frente2g: sociaFrente2g,
  
    frente5g: sociaFrente5g, 
    frente10g: sociaFrente10g,
    frente25g:sociaFrente25g,
    frente31g: sociaFrente31g,
    frente50g: sociaFrente50g, 
  
    verso1g: sociaVerso1g,
    verso2g: sociaVerso2g,
  
    verso5g: sociaVerso5g
  };