import React from "react";
import "../../App.css";
import Section4 from "../../components/section4";
import Section3 from "../../components/section3";
import Banner from "../../components/banner/banner";
import Popup from "../../components/popup/Popup";
export default function Home() {
  return (
    <div>
      <Popup />
      <Banner />
      <Section3 />
      <Section4 />
    </div>
  );
}
