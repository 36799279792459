import cunhadaFrente1g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-1g-frente.png";
import cunhadaFrente2g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-2g-frente.png";
import cunhadaFrente5g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-5g-frente.png";
import cunhadaFrente10g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-10g-frente.png";
import cunhadaFrente25g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-25g-frente.png";
import cunhadaFrente31g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-31g-frente.png";
import cunhadaFrente50g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-50g-frente.png";
import cunhadaVerso1g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-1g-verso.png";
import cunhadaVerso2g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-2g-verso.png";
import cunhadaVerso5g from "../../../assets/newImages/mockups/familia/cunhada/cunhada-ouro-5g-verso.png";

export const  cunhadaMockups = {
    nome: "Cunhada de Ouro",
    frente1g: cunhadaFrente1g,
  
    frente2g: cunhadaFrente2g,
  
    frente5g: cunhadaFrente5g, 
    frente10g: cunhadaFrente10g,
    frente25g:cunhadaFrente25g,
    frente31g: cunhadaFrente31g,
    frente50g: cunhadaFrente50g, 
  
    verso1g: cunhadaVerso1g,
    verso2g: cunhadaVerso2g,
  
    verso5g: cunhadaVerso5g,
    verso10g: cunhadaVerso5g,
    verso25g: cunhadaVerso5g,
    verso31g: cunhadaVerso5g,
    verso50g: cunhadaVerso5g,
  };