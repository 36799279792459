import tucanoFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-1g-frente.png";
import tucanoFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-2g-frente.png";
import tucanoFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-5g-frente.png";
import tucanoFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-10g-frente.png";
import tucanoFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-25g-frente.png";
import tucanoFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-31g-frente.png";
import tucanoFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-50g-frente.png";
import tucanoVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-1g-verso.png";
import tucanoVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-2g-verso.png";
import tucanoVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/tucano/tucano-ouro-5g-verso.png";

export const  tucanoMockups = {
    nome: "Tucano",
    frente1g: tucanoFrente1g,
  
    frente2g: tucanoFrente2g,
  
    frente5g: tucanoFrente5g,
  
    frente10g: tucanoFrente10g,
  
    frente25g: tucanoFrente25g,
  
    frente31g: tucanoFrente31g,
  
    frente50g: tucanoFrente50g,
  
    verso1g: tucanoVerso1g,
    verso2g: tucanoVerso2g,
  
    verso5g: tucanoVerso5g,
  };

 