import parceiraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-1g-frente.png";
import parceiraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-2g-frente.png";
import parceiraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-5g-frente.png";
import parceiraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-10g-frente.png";
import parceiraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-25g-frente.png";
import parceiraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-31g-frente.png";
import parceiraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-50g-frente.png";
import parceiraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-1g-verso.png";
import parceiraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-2g-verso.png";
import parceiraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/parceira/parceira-ouro-5g-verso.png";

export const  parceiraMockups = {
    nome: "Parceira",
    frente1g: parceiraFrente1g,
  
    frente2g: parceiraFrente2g,
  
    frente5g: parceiraFrente5g, 
    frente10g: parceiraFrente10g,
    frente25g:parceiraFrente25g,
    frente31g: parceiraFrente31g,
    frente50g: parceiraFrente50g, 
  
    verso1g: parceiraVerso1g,
    verso2g: parceiraVerso2g,
  
    verso5g: parceiraVerso5g
  };