import capadociaFrente1g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-1g-frente.png";
import capadociaFrente2g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-2g-frente.png";
import capadociaFrente5g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-5g-frente.png";
import capadociaFrente10g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-10g-frente.png";
import capadociaFrente25g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-25g-frente.png";
import capadociaFrente31g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-31g-frente.png";
import capadociaFrente50g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-50g-frente.png";
import capadociaVerso1g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-1g-verso.png";
import capadociaVerso2g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-2g-verso.png";
import capadociaVerso5g from "../../../assets/newImages/mockups/mundos/capadocia/capadocia-ouro-5g-verso.png";

export const  capadociaMockups = {
    nome: "Capadócia",
    frente1g: capadociaFrente1g,
  
    frente2g: capadociaFrente2g,
  
    frente5g: capadociaFrente5g,
  
    frente10g: capadociaFrente10g,
  
    frente25g: capadociaFrente25g,
  
    frente31g: capadociaFrente31g,
  
    frente50g: capadociaFrente50g,
  
    verso1g: capadociaVerso1g,
    verso2g: capadociaVerso2g,
  
    verso5g: capadociaVerso5g,
    verso10g: capadociaVerso5g,
    verso25g: capadociaVerso5g,
    verso31g: capadociaVerso5g,
    verso50g: capadociaVerso5g,
  };

 