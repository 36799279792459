import React from 'react';
import '../../pages.css';
import img from '../../../assets/newImages/cartao-ouro.png';
import linha from '../../../assets/linha.png';
import TableGold from '../../../components/gold/tableGold';
import { PoliticaEntrega } from '../../../components/text/politcaEntrega';

export default function BarraLaminas() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">BARRA DE OURO</p>
            <p>
              O ouro resiste ao teste do tempo, repetidas vezes. É imune à
              inflação, riscos financeiros e bancários. Ele brilha em tempos
              incertos e é uma das maneiras mais seguras e inteligentes de
              investir e construir a riqueza de uma pessoa ao longo do tempo.
            </p>
            <p>
              <ul>
                <li>
                  O ouro é usado como protetor de riqueza há mais de 6.000 anos
                  e sempre reterá seu valor.
                </li>
                <li>
                  O ouro tem sido usado como moeda há milhares de anos e foi
                  universalmente aceito por todas as civilizações.
                </li>
                <li>
                  Os preços do ouro não são ditados pelos governos ou bancos
                  centrais.
                </li>
                <li>
                  O ouro em forma física pertence ao comprador, ao contrário dos
                  ativos digitais, não possui risco de contraparte.
                </li>
                <li>
                  O ouro demonstrou que, apesar da volatilidade, mantém seu
                  poder de compra constante ao longo do tempo.
                </li>
              </ul>
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5">
          Seja você um investidor especialista ou apenas começando, a Ourominas
          (OM) oferece acesso fácil a um mercado global, não importa se está
          comprando um grama ou uma tonelada, a empresa sempre oferece as
          melhores ofertas disponíveis no mercado de ouro e conta com uma equipe
          formada por profissionais graduados e mestres na área financeira para
          auxiliar o cliente da melhor maneira possível.
        </p>
        <p>
          O material vendido pela OM é adquirido no mercado Balcão e no mercado
          primário, extraído de área de produção mineral localizada no Brasil,
          oriundo de Permissões de Lavras Garimpeiras (PLG) autorizadas e
          conferidas pela Agência Nacional de Mineração (ANM) e pela Americas
          Gold Manufactures Association (AMAGOLD), certificação de qualidade que
          é dada às empresas que passam por uma rigorosa análise, averiguando
          inclusive sua forma de produção para adequação nos critérios mais
          rígidos de qualidade.
        </p>

        <p>
          Todo ouro comercializado na OM possui teor de pureza 999, são lacrados
          individualmente com selos holográficos tridimensionais que garantem a
          segurança e a qualidade do produto, acompanha Nota Fiscal Eletrônica
          (NF-e), Garantia de Recompra e Certificado de Origem do Metal.
        </p>

        <p className="mt-4">
          <b>Nossas Barras</b>
        </p>
        <TableGold />

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE INVESTIR EM OURO?</summary>
            <p className="mt-4">
              Ao longo da história, o ouro sempre desempenhou um papel
              fundamental no poder e na economia global. Antes mesmo da criação
              das moedas como meio de troca, civilizações antigas já atribuíam
              um valor especial ao metal dourado, associando-o ao Sol, enquanto
              a prata era vinculada à Lua. Essa ligação mística reforçou a
              crença no poder e na raridade desses metais.
            </p>
            <p>
              De <strong>1875 até meados do século XX</strong>, o ouro foi a
              base do sistema monetário global, servindo como referência
              econômica internacional no chamado <strong>“padrão-ouro”</strong>.
              Nesse modelo, os países eram obrigados a manter parte
              significativa de suas reservas internacionais em ouro,
              influenciando diretamente suas políticas de comércio exterior.
            </p>
            <p>
              Mesmo após a consolidação do dólar como moeda de referência global
              no final do século XX, o ouro manteve seu prestígio. Em{' '}
              <strong>2018</strong>, por exemplo, os{' '}
              <strong>
                Bancos Centrais ao redor do mundo compraram mais ouro do que em
                qualquer outro ano desde 1971
              </strong>
              , reforçando sua relevância como ativo seguro e reserva de valor.
            </p>
            <p>
              Investir em ouro significa apostar em um ativo sólido, atemporal e
              resistente a crises econômicas, sendo uma proteção contra a
              inflação e instabilidades financeiras.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE INVESTIR EM OURO COM A OUROMINAS?</summary>
            <p className="my-4">
              <p>
                Investir em ouro com a Ourominas (OM) significa contar com
                segurança, transparência e condições diferenciadas.
              </p>
              <p>
                <strong>Veja os principais motivos para escolher a OM:</strong>
              </p>
              <p>1. Melhores Cotações </p>
              <p>
                A OM negocia ouro seguindo as cotações do mercado interno e
                internacional, garantindo que o investidor sempre compre pelo
                menor preço possível, sem cobranças de taxas de confecção, taxas
                de corretagem e taxas administrativas. Tanto pequenos quanto
                grandes investidores encontram condições justas e competitivas.
              </p>
              <p>2. Transparência nas Cotações</p>
              <p>
                O preço do ouro é o mesmo para todos os clientes,
                independentemente do volume adquirido. Diferente de outras
                empresas, a OM não penaliza o investidor que compra pequenas
                quantidades.
              </p>
              <p>3. Sem Taxas Ocultas </p>
              <p>
                Ao contrário dos fundos de investimento, onde há taxas de
                administração, performance e entrada / saída, na OM o investidor
                pode comprar e vender ouro sem custos adicionais.
              </p>
              <p>
                Flexibilidade para comprar diariamente, semanalmente ou
                mensalmente, de acordo com sua estratégia.
              </p>
              <p>4. Mais Liquidez e Facilidade que a Bolsa </p>
              <p>
                Comprar ouro na OM é mais rápido e acessível, o cliente recebe
                as barras de ouro física, isso garante maior liquidez e
                agilidade para o investidor.
              </p>
              <p>5. Experiência e Credibilidade </p>
              <p>
                Com mais de 40 anos no mercado, a OM se destaca pela experiência
                e profissionalismo de sua equipe.
              </p>
              <p>
                A empresa é autorizada pelo Banco Central e credenciada na
                Comissão de Valores Mobiliários (CVM) para operar com ouro,
                garantindo total segurança para seus clientes.
              </p>
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>INVESTIR EM OURO VALE A PENA?</summary>
            <p className="my-4">
              <ol>
                <li>
                  É a única moeda que não está sob o controle de qualquer
                  governo e, portanto, não sofre ingerência governamental.
                </li>
                <li>
                  Os excessos de liquidez e de dinheiro na economia cria
                  inflação ou inflação potencial, desvalorizando ou criando
                  potencial para a desvalorização das moedas que circulam em
                  excesso.
                </li>
                <li>
                  O ouro é um valor seguro sempre, enquanto ações de empresas e
                  outras modalidades de investimentos sofrem bastante em
                  cenários de crise.
                </li>
                <li>
                  A descoberta do ouro como investimento em economias emergentes
                  vem aumentando.
                </li>
                <li>
                  O ouro é um investimento de altíssima liquidez. Existem muito
                  poucos outros investimentos que podem reverter-se em dinheiro
                  de maneira tão rápida como o ouro: sem penalizações, sem
                  espera de tempo, sem condições adicionais.
                </li>
                <li>
                  O ouro adquirido de instituições financeiras no Brasil é
                  definido na Constituição como ativo financeiro, estando
                  sujeito à tributação incidente sobre a renda variável.
                </li>
              </ol>
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE TANTA GENTE INVESTE EM OURO?</summary>
            <p className="mt-4">
              O ouro costuma reluzir ainda mais aos olhos dos investidores em
              momentos de crise e pressão inflacionária.
            </p>
            <p>
              Consolidado no imaginário popular como símbolo de luxo e
              ostentação de reis, o metal dourado resistiu a guerras e
              catástrofes naturais porque é menos suscetível à especulação dos
              bancos e do mercado.
            </p>
            <p>
              Pode ser vendido em qualquer momento e em qualquer lugar do
              planeta, por isso é chamado de “dinheiro real”. Na prática, o ouro
              preserva patrimônio e compensa o risco de outras aplicações
              financeiras tradicionais.
            </p>
            <p>
              A lógica do ouro é a seguinte: quanto mais a bolsa cai e o dólar
              se desvaloriza, mais a cotação do ouro aumenta, por pura lei de
              oferta e demanda.
            </p>
            <p>
              O que acontece nessa situação é que simplesmente os investidores
              retiram seu capital da bolsa de valores e do dólar durante as
              crises para alocar na segurança do metal dourado.
            </p>
            <p>
              Assim, se você perceber tendência de queda do dólar e perda de
              fôlego nos gráficos da bolsa (sinais de crise econômica), é
              praticamente certo que virá uma valorização do ouro em seguida.
            </p>
            <p>
              É preciso lembrar que o ouro, diferentemente de um papel-moeda, é
              de prospecção limitada. E, mais do que isso, um mineral já
              bastante explorado — há séculos — no Brasil e em outras nações
              colonizadas.
            </p>
            <p className="mb-4">
              Dessa forma, com a escassez das jazidas mundiais, o metal amarelo
              ainda circulante no mercado tende a se valorizar ainda mais.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO COMPRAR OURO?</summary>
            <strong>Cadastre-se na OM </strong>
            <p>
              • Escolha entre o <strong>Cadastro Simplificado</strong> (para
              operações de pequeno valor) ou o{' '}
              <strong>Cadastro Completo</strong> (para valores maiores e
              operações recorrentes).
            </p>
            <strong>Entre em Contato </strong>
            <p className="mb-4">
              • Realize suas compras por meio dos canais oficiais de atendimento
              da OM presencial ou online.
            </p>
            <strong>Confirme a Operação </strong>
            <p className="mb-4">
              • Após a negociação, a operação deve ser liquidada dentro do prazo
              combinado. Caso contrário, será cancelada automaticamente.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O OURO POSSUI GARANTIA?</summary>
            <p className="my-4">
              Todo ouro comercializado na OM possui pureza de 999, acompanha
              nota fiscal de negociação e tem recompra garantida. Para sua
              segurança, mantenha sempre as notas fiscais junto ao metal
              adquirido.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>CADASTRO.</summary>
            <p className="mt-4">
              A OM, como instituição financeira, segue rigorosos procedimentos
              cadastrais para atender às normas de Prevenção à Lavagem de
              Dinheiro e Financiamento ao Terrorismo (PLD/FT). Além disso, a
              empresa protege as informações de seus clientes, garantindo sigilo
              absoluto e só as compartilhando mediante determinação do Banco
              Central do Brasil.
            </p>
            <p className="mb-4">
              <strong>Tipos de Cadastro:</strong>
              <ol>
                <br />
                <li>
                  <strong>Cadastro Simplificado:</strong> Consiste no
                  preenchimento da Ficha Cadastral com dados básicos e
                  apresentação de cópias simples dos seguintes documentos: RG,
                  CPF e Comprovante de Endereço.
                </li>
                <li>
                  <strong>Cadastro Completo:</strong> Procedimento padrão da
                  instituição, inclui o preenchimento da Ficha Cadastral e a
                  entrega de cópias simples de RG, CPF e Comprovante de
                  Endereço, além de documentação complementar necessária para
                  definição do limite operacional. Esse limite é estabelecido
                  para fins de acompanhamento interno da empresa.
                </li>
              </ol>
            </p>
          </details>
          <PoliticaEntrega />
          <details className="mb-2 pb-2 border-bottom">
            <summary>HORÁRIO DE NEGOCIAÇÃO.</summary>
            <p className="my-4">
              Nosso horário de negociação: Segunda à Sexta, das 8:30 às 18:00h
              (Horário de Brasília). Temos horários diferenciados em alguns
              pontos de atendimento.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>TRIBUTAÇÃO DO OURO.</summary>
            <p className="mt-4">
              O investimento em Ouro Ativo Financeiro é classificado como
              aplicação em Renda Variável, regulamentado pela{' '}
              <strong>Lei nº 11.033, de 21 de dezembro de 2004</strong>, e pela{' '}
              <strong>
                Instrução Normativa RFB nº 1.022, de 5 de abril de 2010
              </strong>
              .
            </p>
            <p>
              <strong>Tributação sobre Ganhos de Capital:</strong>
            </p>
            <p>
              • Há <strong>isenção de Imposto de Renda</strong> sobre ganhos de
              capital em aplicações de renda variável, desde que o valor total
              das alienações mensais desses ativos não ultrapasse{' '}
              <strong>R$ 20.000,00</strong> em cada mercado (ações e Ouro Ativo
              Financeiro são considerados separadamente dentro do mesmo mês).{' '}
            </p>
            <p>
              • Caso haja isenção, os ganhos devem ser informados na declaração
              de Imposto de Renda no campo{' '}
              <strong>"Rendimentos Isentos e Não Tributáveis"</strong>.
            </p>

            <p>
              <strong>
                Tributação para Valores Superiores a R$ 20.000,00:
              </strong>
            </p>
            <p>
              • Se o total das alienações mensais de ativos de renda variável
              superar <strong>R$ 20.000,00</strong>, aplica-se uma alíquota de
              <strong> 15% sobre os ganhos de capital obtidos</strong> . O
              recolhimento deve ser feito até o último dia útil do mês seguinte
              via <strong>Carnê-Leão</strong>.
            </p>
            <p>
              • Para operações de
              <strong> Day-trade </strong>(compra e venda no mesmo dia), a
              alíquota é de <strong>20% sobre o ganho</strong> .
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>DECLARAÇÃO DE OURO NO IRPF.</summary>
            <p className="mt-4">
              O ouro deve ser declarado no{' '}
              <strong>Imposto de Renda Pessoa Física (IRPF)</strong>, mas a
              forma de declaração depende do tipo de ouro adquirido.
            </p>
            <ol>
              <br />
              <li>
                <strong>Ouro como Ativo Financeiro:</strong> Se você investiu em{' '}
                <strong>Ouro Ativo Financeiro</strong>, que é negociado em
                bolsas de valores ou instituições financeiras, ele é considerado
                renda variável e deve ser declarado da seguinte forma:
                <ul>
                  <p>
                    • <strong>Na ficha "Bens e Direitos"</strong> → Código{' '}
                    <strong>04 – Aplicações e Investimentos</strong>
                  </p>
                  <li style={{ marginLeft: '50px' }}>
                    Informe o saldo em ouro que você possuía em 31/12 do
                    ano-base.
                  </li>
                  <li style={{ marginLeft: '50px' }}>
                    {' '}
                    Utilize a cotação de aquisição, não a cotação atual.
                  </li>
                  <p className="mt-4">
                    • <strong>Ganhos de capital</strong> (lucro na venda acima
                    de R$ 20.000,00 no mês) devem ser tributados e informados na
                    ficha <strong>"Renda Variável"</strong>.
                  </p>
                </ul>
              </li>
              <li>
                <strong>Ouro Físico (Joias, Barras ou Moedas):</strong>Se você
                comprou ouro em barras, moedas ou joias para fins de patrimônio,
                ele deve ser declarado como <strong>bem pessoal</strong>, da
                seguinte maneira:
                <ul>
                  <p>
                    • <strong>Na ficha "Bens e Direitos"</strong> → Código{' '}
                    <strong>11 – Ouro, Joias e Objetos de Valor</strong>
                  </p>
                  <li>
                    Descreva a quantidade adquirida, tipo (barra, moeda, etc.) e
                    o local de custódia.
                  </li>
                  <li> Informe o valor pago na aquisição.</li>
                  <li>
                    O valor deve permanecer o mesmo até a venda (não há correção
                    pelo preço de mercado).
                  </li>
                </ul>
              </li>
            </ol>
          </details>
        </section>
      </section>
    </article>
  );
}
