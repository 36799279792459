import boiFrente5g from "../../../assets/newImages/mockups/boi/boi-ouro-5g-frente.png";
import boiVerso5g from "../../../assets/newImages/mockups/boi/boi-ouro-5g-verso.png";

import especificacao5g from '../../../assets/images/especificacoesOuro/ouro-5g.png';

export const  boiMockups = {
    nome: "Boi de Ouro",
    frente5g: boiFrente5g,
    verso5g: boiVerso5g,
    especificacao5g: especificacao5g
  };

   export const boi = [
    {
      name: "Boi de Ouro",
      value: [
        "OURO BARRA (5g)",
      ],
    }]