import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { apiTravel } from "../travel";
import { getClienteCPF, getClienteCodigo } from "../../../utils/localStorage";
import {
  OperacaoVendaPost,
  StatusOperacao,
  dataFilterOperacao,
  mountOperacaoVenda,
} from "../../../models/modules/Cliente/Operacao";
import { getClienteByCodigo } from "./clienteService";
import { db } from "../../dexie/stores";

export async function getOperacaoByCPF(status: StatusOperacao): Promise<any> {
  const cpf = getClienteCPF();
  const payload = dataFilterOperacao(cpf, status);
  try {
    const resp = await apiTravel.post(
      "extrato-operacoes/obter-qry-paginacao/EXTRATOOPERACOES_QRYCONSULTA/100/1",
      payload
    );
    if (resp.data && resp.data.data) {
      return resp.data.data;
    } else {
      return Promise.reject("No data found");
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function getOperacaoByID(id: number): Promise<any> {
  return apiTravel.get(`/extrato-operacoes/obter-comprovante/Venda/${id}`).then((resp) =>  resp.data.data);
}

function sendOperacao(data: OperacaoVendaPost): Promise<any>{
  return apiTravel.post("/compra-venda/inserir", data).then((resp) =>  resp.data.data).catch(e => {
    AlertNotify({message: 'Ocorreu um erro ao enviar a operação!', type:'warning'})
    return Promise.reject(e);
  });
}

export async function getQrCodeByID(id: string): Promise<any> {
  return apiTravel.get(`compra-venda/obter-qrcode-pix/${id}`).then((resp) =>  resp.data.data).catch(e => {
    AlertNotify({message: 'Ocorreu um erro ao enviar a operação!', type:'warning'})
    return Promise.reject(e);
  });
}

export async function postOperacao(tarifa: number, tipoEntrega: string, observacao: string): Promise<OperacaoVendaPost> {
  try {
    const operacao = await createOperacao(tarifa, tipoEntrega, observacao);
    if (operacao) {
      return await sendOperacao(operacao).then(resp => resp);
    } else {
      return Promise.reject(new Error("Operação não criada."));
    }
  } catch (error) {
    AlertNotify({ message: `Ocorreu um erro ao processar a operação. ${error.message}`, type: 'danger' });
    return Promise.reject(new Error("Operação não criada."));
  }
}


export async function createOperacao(tarifa: number, tipoEntrega: string, observacao: string) {
  const codigo = getClienteCodigo();

  // Verifica se endereco e codigo estão disponíveis
  if (!codigo) {
    AlertNotify({ message: `Ocorreu um erro ao processar a operação.`, type: 'danger' });
    return null;
  }

  let data = [];
  try {
    data = await db.getCarrinho();
  } catch (error) {
    AlertNotify({ message: `Ocorreu um erro ao processar a operação. Erro ao buscar dados do carrinho!`, type: 'danger' });
    return null;
  }

  // Verifica se o carrinho possui dados
  if (data.length === 0) {
    AlertNotify({ message: `Ocorreu um erro ao processar a operação. Carrinho vazio!`, type: 'danger' });
    return null;
  }

  const moeda = data[0];

  try {
    const cliente = await getClienteByCodigo(codigo);

    // Verifica se o cliente foi encontrado
    if (!cliente) {
      AlertNotify({ message: `Ocorreu um erro ao processar a operação. Erro ao buscar informações do cliente!`, type: 'danger' });
      return null;
    }

    // Monta a operação de venda
    return mountOperacaoVenda({
      codigoCliente: codigo,
      documentoRecurso: cliente.cpfCnpj,
      nomeRecurso: cliente.nome,
      codigoMoeda: moeda.codIso,
      liberado: moeda.liberado,
      liberadoBR: moeda.liberadoBR,
      quantidade: moeda.quantidade,
      taxaIOF: moeda.calculoIOFVenda,
      toleranciaMax: moeda.toleranciaMax,
      toleranciaMin: moeda.toleranciaMin,
      total: moeda.calculoIOFVenda * moeda.quantidade,
      tarifa,
      tipoEntrega,
      observacao
    });

  } catch (error) {
    AlertNotify({ message: `Ocorreu um erro ao processar a operação. Erro ao buscar informações do cliente!`, type: 'danger' });
    return null;
  }
}