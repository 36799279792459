import React, { useEffect,  useState } from "react";
import checkIcon from "../../../../assets/check.png";
import editIcon from "../../../../assets/edit.png";
import load from "../../../../assets/spinner.gif";
import "../admin.css";
import {  getOuro } from "../../../../services/api";
import styled from "styled-components";
import { maskDecimal } from "../../../../utils/regex";
import { putOuro } from "../../../../services/cotacoes";

export default function OuroAtivoADM() {
  const [clickEdit, setClickEdit] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [final, setFinal] = useState(false);
  const [coinSCP, setCoinSCP] = useState("");
  const [coinSVD, setCoinSVD] = useState("");

  useEffect(() =>{
    setInterval(() =>{
      getMoedas();

    }, 100000)
  },[])
  useEffect(() => {
    getMoedas();
  }, []);
  async function getMoedas() {
    await getOuro()
      .then(setData)
      .catch((err) => setError(err.response.status))
      .then(() => setFinal(true));
  }
  function edit(cod) {
    if (clickEdit === "") {
      document.getElementById("scp" + cod).disabled = false;
      document.getElementById("svd" + cod).disabled = false;
      document.getElementById("btn" + cod).classList.remove("d-none");
      setClickEdit(cod);
    } else if (clickEdit === cod) {
      document.getElementById("scp" + cod).disabled = true;
      document.getElementById("svd" + cod).disabled = true;
      document.getElementById("btn" + cod).classList.add("d-none");
      setClickEdit("");
    }
  }

  function salvar(moeda) {
    let newArr = moeda;

    if (coinSCP) {
      newArr.scp = maskDecimal(coinSCP);
    }
    if (coinSVD) {
      newArr.svd = maskDecimal(coinSVD);
    }
    putOuro(newArr)
      .then(() => {
        getMoedas();
      })
      .catch((err) => setError(err.response.status));
    setCoinSVD("");
    setCoinSCP("");
    document.getElementById("scp" + moeda.codIso).disabled = true;
    document.getElementById("svd" + moeda.codIso).disabled = true;
    document.getElementById("btn" + moeda.codIso).classList.add("d-none");
    setClickEdit("");
  }

  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="adminTable">
        <h1 className="text-center">Ouro Ativo Financeiro</h1>
        <div className="d-flex justify-content-center my-2"></div>

        <LineLoading>
          {!final && <Loading src={load} />}
          {error && <Error>Dados não disponíveis no momento. </Error>}
        </LineLoading>
        <table className="table table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">Ouro</th>
              <th className="text-center" scope="col" id="">
                Compra
              </th>
              <th className="text-center" scope="col" id="">
                Compra <br />
                Sugerida %
              </th>
              <th className="text-center" scope="col">
                Venda
              </th>
              <th className="text-center" scope="col">
                Venda <br />
                Sugerida %
              </th>
              <th className="text-center" scope="col">
                Var %
              </th>
              <th className="text-center" scope="col">
                Compra
                <br /> Sugerida %
              </th>

              <th className="text-center" scope="col">
                Venda <br />
                Sugerida %
              </th>
              <th className="text-center" scope="col">
                Editar
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((moeda, index) => (
              <tr key={index++}>
                <td className="text-capitalize fs-6">{moeda.codIso}</td>
                <td className="text-center">
                  {parseFloat(moeda.ocp).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}{" "}
                </td>
                <td className="text-capitalize fs-6 text-center">
                  {parseFloat(moeda.calculoCompra).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}
                </td>
                <td className="text-center">
                  {parseFloat(moeda.ovd).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}{" "}
                </td>
                <td className="text-capitalize fs-6 text-center">
                  {parseFloat(moeda.calculoVenda).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}
                </td>
                <td className="text-center">{moeda.var}</td>
                <td className="text-center">
                  <input
                    className="form-control inputEdit  margin-auto"
                    id={"scp" + moeda.codIso}
                    placeholder="moeda"
                    defaultValue={moeda.scp}
                    onChange={(e) => setCoinSCP(e.target.value)}
                    disabled
                  />
                </td>
                <td className="text-center">
                  <input
                    className="form-control inputEdit  margin-auto"
                    id={"svd" + moeda.codIso}
                    placeholder="moeda"
                    defaultValue={moeda.svd}
                    onChange={(e) => setCoinSVD((e.target.value))}
                    disabled
                  />
                </td>

                <td className="text-center">
                  <button className="btn" onClick={() => edit(moeda.codIso)}>
                    <img src={editIcon} alt="" width="25px" />
                  </button>
                  <button
                    className="btn d-none"
                    id={"btn" + moeda.codIso}
                    onClick={() => salvar(moeda)}
                  >
                    <img src={checkIcon} alt="" width="25px" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
const Loading = styled.img`
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
`;

const LineLoading = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
const Error = styled.span`
  font-size: 10px;
`;
