import React from "react";
import styled from "styled-components";
import pedidos from "../../assets/icons/pedido.png";
import dadosPessoais from "../../assets/icons/dados-pessoais.png";
import seguranca from "../../assets/icons/seguranca.png";
import endereco from "../../assets/icons/endereco.png";
import { Card } from "../../components/section/cards/cards";

export default function MinhaConta() {
  const itemsDescription = [
    {
      productName: "Meus Pedidos",
      src: pedidos,
      href: "meus-pedidos",
      width: 50,
    },
    {
      productName: "Dados Pessoais",
      src: dadosPessoais,
      href: "dados-pessoais",
      width: 56,
    },
    {
      productName: "Segurança",
      src: seguranca,
      href: "seguranca",
      width: 58,
    },
    {
      productName: "Endereço",
      src: endereco,
      href: "endereco",
      width: 50,
    },
  ];
  return (
    <SectionContainer>
      <SectionIntroContainer>
        <h2 className="display-4">
          Minha Conta
        </h2>
      </SectionIntroContainer>

      <ul className="feature-list feature-list-sm row wv-100">
        {itemsDescription.map(({ href, src, productName, width }) =>
          Card(href, src, productName, width)
        )}
      </ul>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-color: #f8f9fa;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 50px;

  h2 {
    text-transform: uppercase;
  }
`;
