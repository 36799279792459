export const familiaOuro = [
  {
    name: "Afilhada de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Afilhado de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Avô de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Avó de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Cunhada de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Cunhado de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Enteada de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Enteado de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Esposa de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Esposo de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Filha de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Filho de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Genro de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Irmã de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Irmão de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Madrasta de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Madrinha de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Mãe de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Neta de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Neto de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Nora de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Padrasto de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Padrinho de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Pai de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Prima de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Primo de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Sobrinha de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Sobrinho de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Sogra de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Sogro de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      //"OURO BARRA (50g)",
    ]
  },{
    name: "Tia de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      //"OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Tio de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }
    
    
  ];