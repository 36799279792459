import atrizFrente1g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-1g-frente.png";
import atrizFrente2g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-2g-frente.png";
import atrizFrente5g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-5g-frente.png";
import atrizFrente10g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-10g-frente.png";
import atrizFrente25g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-25g-frente.png";
import atrizFrente31g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-31g-frente.png";
import atrizFrente50g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-50g-frente.png";
import atrizVerso1g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-1g-verso.png";
import atrizVerso2g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-2g-verso.png";
import atrizVerso5g from "../../../assets/newImages/mockups/profissoes/atriz/atriz-ouro-5g-verso.png";

export const  atrizMockups = {
    nome: "Atriz de Ouro",
    frente1g: atrizFrente1g,
  
    frente2g: atrizFrente2g,
  
    frente5g: atrizFrente5g,
  
    frente10g: atrizFrente10g,
  
    frente25g: atrizFrente25g,
  
    frente31g: atrizFrente31g,
  
    frente50g: atrizFrente50g,
  
    verso1g: atrizVerso1g,
    verso2g: atrizVerso2g,
  
    verso5g: atrizVerso5g,
    verso10g: atrizVerso5g,
    verso25g: atrizVerso5g,
    verso31g: atrizVerso5g,
    verso50g: atrizVerso5g,
  };

 