import React from 'react';
import '../../pages.css';

export default function Fraudes() {
  return (
    <article className="art-s p-text">
      <br />
      <section className="row m-0 sectionInit">
        <div>
          <div className="d-flex flex-column sect">
            <div className="m-auto">
              <p className="fw-bolder">
                Fraude é coisa séria! Por isso, a Ourominas vem à público falar
                sobre{' '}
                <span className="text-decoration-underline">
                  conscientização!
                </span>
              </p>
            </div>
            <br />
            <p>
              Quando tomamos conhecimento sobre um determinado assunto que possa
              nos prejudicar financeiramente, passamos a mudar hábitos e
              atitudes diante desta realidade!!!
            </p>
            <br />
            <p>
              E não poderia ser diferente, quando o assunto é “fraude no mercado
              financeiro”!
            </p>
            <br />
            <p>
              Com a sociedade cada vez mais conectada e totalmente digital, os
              criminosos têm aproveitado o crescimento exponencial das operações
              digitais para aplicar golpes na população.
            </p>
            <br />
          </div>
        </div>
        <div>
          <p className="fw-bolder">Destacam-se entre os mais comuns:</p>
          <br />
          <ul>
            <li>
              <span className="fw-bolder">Engenharia social: </span>
              são os crimes que basicamente, usam a manipulação psicológica do
              usuário para que ele forneça informações confidenciais, como
              senhas e números de cartões para os criminosos, faça transações em
              favor das quadrilhas ou, na maioria das vezes, “laranjas” usados
              por estas organizações.
            </li>
            <li>
              <span className="fw-bolder">Golpe do Acesso Remoto: </span>
              também conhecido como Golpe da Mão Fantasma. Nesse tipo de fraude,
              o fraudador entra em contato com a vítima se passando por um falso
              funcionário de bancos ou instituições financeiras como a
              Ourominas. Eles usam várias abordagens para enganar o cliente,
              informando que a conta foi invadida, clonada ou que há
              movimentações suspeitas.
            </li>
            <li>
              Com uma interação que se “parece” real, o criminoso então propõe
              enviar um link para a instalação de um aplicativo que supostamente
              resolverá o problema. No entanto, se o cliente instalar o
              aplicativo, o fraudador terá acesso a todos os dados armazenados
              no celular.
            </li>
          </ul>
        </div>
        <div>
          <p className="fw-bolder">
            Para conscientizar nossos clientes sobre esses riscos e prevenir
            fraudes com cartões de débito internacional e contas em nossos
            aplicativos, a Ourominas adotou as seguintes ações:
          </p>
          <br />
          <ul>
            <li>
              <span className="fw-bolder">Campanhas de Conscientização: </span>
              As campanhas educativas de conscientização sobre fraudes na
              Ourominas, iniciaram com seus colaboradores e correspondentes, em
              canais de comunicações internos como o TEAMS e e-mails.
            </li>
          </ul>
        </div>
        <div>
          <p>
            Mas, com a intenção de não nos restringirmos à nossa estrutura, pois
            entendemos que nossos clientes, também precisam estar conscientes
            sobre os golpes mais comuns e como podem se manter seguros, passamos
            a fazer campanhas veiculadas em nossas redes sociais, sites e
            aplicativos móveis.
          </p>
          <br />
          <p>
            <span className="fw-bolder">Senha Pessoal: </span>
            Reforce que os aplicativos da Ourominas contenham segurança máxima,
            configurando o fator de dupla autenticação como o reconhecimento de
            face ou biométria em seu iOS ou Android, bem como, mantenha sempre
            ativadas as notificações por SMS de todas as operações, bem como
            alterações realizadas.
          </p>
          <br />
          <p>
            Faça uso do aplicativo da OurominasGlobal para que o seu cartão de
            débito não esteja habilitado com a opção “aproximação” e, habilite-o
            quando realmente for necessário.
          </p>
          <br />
          <p>
            <span className="fw-bolder">
              Não Instale Aplicativos por Telefone:{' '}
            </span>
            A Ourominas nunca liga para os seus clientes pedindo a instalação de
            aplicativos em seus celulares.
          </p>
          <br />
          <p>
            <span className="fw-bolder">Não Compartilhe Senhas: </span>É
            extremamente importante o não compartilhamento de senhas de seus
            aplicativos OurominasApp ou OurominasGlobal, bem como números de
            cartões ou fazer transferências por telefone.
          </p>
          <br />
          <p>
            A <strong>OM DTVM LTDA</strong> "Ourominas" possui canais exclusivos
            para atendimento, através do número&nbsp;
            <a
              className=""
              href={`https://api.whatsapp.com/send?phone=551120501646&type=phone_number&app_absent=0`}
              rel="noopene noreferrer"
              target="_blank"
            >
              11 2050-1646
            </a>
            &nbsp;(Chatbot) ou&nbsp;
            <a
              className=""
              href={`https://api.whatsapp.com/send?phone=5511970890387&type=phone_number&app_absent=0`}
              rel="noopene noreferrer"
              target="_blank"
            >
              +55 11 97089-0387
            </a>
            &nbsp;(Serviço de Atendimento ao Cliente (SAC) do OurominasApp).
          </p>
          <br />
          <p>
            A <strong>OM DTVM LTDA</strong> "Ourominas", orienta seus clientes a
            jamais deixarem de observar que os pagamentos de qualquer operação,
            seja balcão, lojas próprias, correspondentes ou aplicativo
            OurominasApp, são realizadas somente em suas contas jurídicas, tendo
            como único recebedor a própria{' '}
            <strong>
              OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA
            </strong>
            .
          </p>
          <br />
          <p>
            Nosso <strong>CNPJ</strong> será identificado, através do número{' '}
            <strong>11.495.073/0001-18</strong>.
          </p>
          <br />
          <p>
            A <strong>OM DTVM LTDA</strong> "Ourominas" não faz uso de conta de
            pessoa física e, caso seja constatada esta situação, não tenha
            dúvida de que se trata de tentativa de fraude.
          </p>
          <br />
          <p>
            Lembre-se de que a prevenção é fundamental. Com esforços conjuntos,
            podemos nos proteger e garantir a segurança no ambiente digital.
          </p>
          <br />
          <p>
            Caso tenha recebido qualquer comunicação suspeita sobre nossos
            produtos, seja ela por telefone, e-mail ou veiculas em sites e
            mídias sociais não oficiais, entre em contato conosco imediatamente.
          </p>

          <br />
          <p>
            Quer saber mais ou tomar conhecimento de outros golpes e aprender
            como evitá-los, acesse o site{' '}
            <a
              href="https://www.gov.br/fazenda/pt-br/composicao/orgaos/orgaos-colegiados/crsfn/acesso-a-informacao/educacao-financeira/copy_of_noticias/febraban-da-dicas-para-que-cliente-se-proteja-de-novo-golpe-bancario"
              target="_blank"
              rel="noreferrer"
            >
              gov.br
            </a>
          </p>
          <br />
          <br />
        </div>
        <p className="fw-bolder">Ourominas</p>
        <span>“Você pode Confiar”!</span>
      </section>
      <br />
    </article>
  );
}
