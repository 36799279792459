import React from 'react';
import './politicas.css';
import img from '../../../assets/newImages/políticaOurominasApp1.png';

export default function TermoOurominasApp() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              TERMO DE USO OUROMINAS APP
            </p>
            <p>
              Este Termo de Uso rege a utilização do aplicativo oferecido pela
              OM Distribuidora de Valores Ltda., (Ourominas), com o intuito de
              realizar transações seguras de compra e venda de ouro ativo
              financeiro por meio de dispositivos móveis. Ao utilizar este
              aplicativo, o usuário concorda com os termos e condições
              descritos.
            </p>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SOBRE O APLICATIVO
              </li>
              <li>
                <p>
                  O software é de titularidade de CUSTODIA LTDA., inscrita no
                  CNPJ/MF sob o nº 15.385.196/000157, com licença de uso e
                  exclusividade para OM DISTRIBUIDORA DE VALORES LTDA.
                  “Ourominas”, inscrita no CNPJ/MF sob o nº 11.495.073/0001-18.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DO USO DO APLICATIVO
              </li>
              <li>
                <p>
                  O aplicativo destina-se exclusivamente em realizar operações
                  de compra e venda de ouro ativo financeiro. O usuário concorda
                  em utilizar o aplicativo apenas para os fins descritos, em
                  conformidade com a legislação aplicável, e assume total
                  responsabilidade por suas ações.
                </p>
              </li>
              <li>
                <p>
                  O acesso ao aplicativo é pessoal e intransferível, sendo
                  protegido por uma senha criada pelo próprio usuário. Cabe ao
                  usuário manter a senha confidencial para evitar qualquer
                  acesso indevido às suas informações pessoais, bem como, ser
                  responsável por todas as atividades realizadas. A Ourominas
                  não irá solicitar, em qualquer hipótese, seja por e-mail,
                  telefone, WhatsApp, SMS ou qualquer outro aplicativo de
                  mensagem instantânea, sua senha pessoal. Se o usuário tomar
                  conhecimento do uso não autorizado da sua senha ao aplicativo,
                  ou de qualquer incidente de segurança, deverá entrar em
                  contato com a Ourominas imediatamente através dos canais de
                  atendimento:{' '}
                  <a href="mailto:app@ourominas.com">app@ourominas.com</a>, ou
                  através do nosso chatbot 11 2050-1646 (por mensagem ou
                  ligação).
                </p>
              </li>

              <li>
                <p>
                  Ao utilizar o aplicativo, o usuário concorda sob pena de
                  exclusão da plataforma, em seguir todas as leis e regulamentos
                  aplicáveis, fornecendo informações precisas e atualizadas
                  sobre suas operações e a utilizar o aplicativo somente para
                  fins legítimos previstos neste Termo de Uso.
                </p>
              </li>
              <li>
                <p>
                  O item “Política de Privacidade” prevista neste Termo de Uso
                  estabelece as condições em que a Ourominas realiza o
                  tratamento dos seus dados pessoais durante o oferecimento dos
                  serviços fornecidos pelo aplicativo, bem como informações
                  sobre os seus direitos enquanto titular desses dados.
                </p>
              </li>
              <li>
                <p>
                  Todas as informações enviadas e fornecidas pelo usuário serão
                  tratadas com sigilo, segurança e garantia da
                  confidencialidade, podendo ser utilizadas apenas e tão somente
                  para as finalidades descritas no presente Termo de Uso, sendo
                  autorizado, com a aceitação deste, o acesso dessas
                  informações.
                </p>
              </li>
              <li>
                <p>
                  O usuário poderá exercer seus direitos, tais como o de
                  exclusão e o de correção de dados incompletos, inexatos ou
                  desatualizados, para manter toda a informação fornecida à
                  Ourominas permanentemente atualizada, conforme descrito no
                  item “Política de Privacidade”.
                </p>
              </li>
              <li>
                <p>
                  O uso e cadastro do aplicativo é terminantemente proibido por
                  menores de 18 (dezoito) anos. A utilização, em caso de menores
                  de idade, deverá ser realizada pelo seu representante legal.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOWNLOAD e ATUALIZAÇÃO DO APLICATIVO
              </li>
              <li>
                <p>
                  O uso do aplicativo <strong>OurominasApp</strong> inicia-se
                  com o download para o seu dispositivo móvel, disponível
                  exclusivamente para pessoa física. Ao realizar o download,
                  serão solicitados dados, acompanhados de um documento de
                  identificação válido (CPF), comprovante de residência e selfie
                  do usuário portando tal documento, para análise e aprovação
                  cadastral.
                </p>
              </li>
              <li>
                <p>
                  O usuário autoriza, desde já, que o aplicativo consulte
                  informações públicas e privadas para o enriquecimento de dados
                  de seu cadastro, bem como ressalta-se que, poderão ser
                  solicitados outros documentos comprobatórios à critério da
                  Ourominas.
                </p>
              </li>
              <li>
                <p>
                  Após a análise, será enviado um e-mail de boas-vindas e o seu
                  acesso ao aplicativo para realizar compra e venda de ouro
                  ativo financeiro estará liberado.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DA DISPONIBILIDADE
              </li>
              <li>
                <p>
                  O aplicativo <strong>OurominasApp</strong> estará disponível
                  nas lojas Apple Store/Google Play e, caso o cliente não opte
                  por manter as atualizações automáticas, deverá verificar
                  constantemente se há disponibilidade de novas versões,
                  evitando erros e acesso às distribuições desatualizadas
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOS DIREITOS A PROPRIEDADE INTELECTUAL
              </li>
              <li>
                <p>
                  Os direitos de propriedade intelectual, que incluem, sem
                  limitações, a marca <strong>OurominasApp</strong> e quaisquer
                  outras marcas comerciais, direitos autorais ou outros
                  elementos utilizados ou incorporados na plataforma deste
                  aplicativo, são e permanecerão como propriedade exclusiva de
                  CUSTODIA LTDA., inscrita no CNPJ/MF sob o nº
                  15.385.196/0001-57 com licença de uso e exclusividade para OM
                  DISTRIBUIDORA DE VALORES LTDA. “Ourominas”.
                </p>
              </li>
              <li>
                <p>
                  É expressamente vedado, por meio deste Termo de Uso, copiar,
                  imitar ou utilizar os dados e/ou metadados da plataforma
                  OurominasApp sem o consentimento prévio e expresso por escrito
                  da OM Distribuidora de Valores Ltda., “Ourominas”. Isso
                  abrange todos os cabeçalhos de páginas, gráficos
                  personalizados, ícones, scripts da marca do serviço, marcas
                  comerciais e/ou elementos visuais associados a este produto.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOS PROCEDIMENTOS DE PAGAMENTO
              </li>
              <li>
                <p>
                  Na operação de compra ou venda de ouro ativo financeiro, o
                  recebimento ou entrega do seu contravalor em reais deve ser
                  realizado a partir de crédito ou de débito à conta de depósito
                  ou de pagamento do cliente (usuário) mantida em instituições
                  financeiras e demais instituições autorizadas a funcionar pelo
                  Banco Central do Brasil ou em instituições de pagamento que,
                  em virtude de sua adesão ao PIX, integrem o Sistema de
                  Pagamentos Brasileiro (SPB).
                </p>
              </li>
              <li>
                <p>
                  Os limites estipulados obedecem às normativas e
                  regulamentações do Banco Central do Brasil aplicadas à esta
                  Instituição Financeira.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DO ACESSO AO HISTÓRICO DE TRANSAÇÕES
              </li>
              <li>
                <p>
                  O usuário está ciente e concorda com o presente Termo de Uso e
                  concede autorização à Ourominas a analisar todo o histórico de
                  transações e quaisquer declarações realizadas no seu
                  aplicativo <strong>OurominasApp</strong>, procedimento este
                  necessário para garantir que as instruções foram seguidas e
                  executadas.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                INATIVIDADE DA CONTA
              </li>
              <li>
                <p>
                  Não havendo saldo na custódia no prazo de 12 (doze) meses, o
                  acesso ao aplicativo <strong>OurominasApp</strong> será
                  bloqueado até que seja solicitado o desbloqueio pelos canais
                  de atendimento do aplicativo.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DA RESPONSABILIDADE DO USUÁRIO
              </li>
              <li>
                <p>
                  O usuário do aplicativo OurominasApp está ciente de que é de
                  sua responsabilidade adotar medidas de segurança apropriadas
                  para a utilização do aplicativo e proteção de seus dados. O
                  usuário responderá por atos e omissões relativos às operações
                  no aplicativo, assim como pela falta de observância das
                  condições estabelecidas no presente Termo de Uso.
                </p>
              </li>
              <li>
                <p>
                  É de total responsabilidade do usuário manter a segurança e
                  controle de suas senhas, números de identificação pessoal ou
                  quaisquer outros códigos utilizados para acessar os serviços
                  deste aplicativo.
                </p>
              </li>
              <li>
                <p>
                  A Ourominas não se responsabiliza por eventuais perdas
                  decorrentes do mau uso do aplicativo. Orientamos que, em casos
                  comprovados de ataque por hackers, seja realizada
                  imediatamente a abertura de Boletim de Ocorrência Eletrônico
                  na Delegacia de Crimes Cibernéticos da sua região.
                </p>
              </li>
              <li>
                <p>
                  É de responsabilidade do usuário notificar imediatamente a
                  Ourominas caso suspeite de uma operação não autorizada, erro
                  em seu aplicativo, comprometimento de senha, perda, roubo ou
                  desativação do telefone móvel no qual o aplicativo{' '}
                  <strong>OurominasApp</strong> está instalado, ou caso
                  necessite de esclarecimentos adicionais sobre as operações.
                </p>
              </li>
              <li>
                <p>
                  O usuário também é responsável pela exatidão de todas as
                  operações em sua conta, uma vez realizadas, estas são
                  definitivas e irrevogáveis.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                LIMITAÇÃO E EXCLUDENTES DE RESPONSABILIDADE
              </li>
              <li>
                <p>
                  Por conta de questões técnicas e operacionais, a Ourominas não
                  pode garantir a disponibilidade, a infalibilidade e a
                  continuidade do funcionamento do aplicativo, nem garante a
                  utilidade do aplicativo para a realização de qualquer
                  atividade em concreto. Do mesmo modo, a Ourominas não será, em
                  hipótese alguma, responsável por quaisquer danos decorrentes
                  da interrupção do acesso ao aplicativo, ou por falhas de
                  funcionamento dele, nem se responsabiliza pela defraudação da
                  utilidade que os usuários possam ter atribuído ao aplicativo,
                  eventos fortuitos, nem por qualquer dificuldade de acesso.
                </p>
              </li>
              <li>
                <p>
                  A Ourominas utiliza as melhores práticas recomendadas de
                  mercado para manter seguros todos os dados inseridos no
                  aplicativo, no entanto, não garantem que terceiros não
                  autorizados não farão uso de meios ilícitos para obter
                  indevidamente tais informações.
                </p>
              </li>
              <li>
                <p>
                  Assim, a Ourominas se exime de responsabilidade pelos danos e
                  prejuízos de toda natureza que possam decorrer do conhecimento
                  que terceiros não autorizados possam ter quaisquer das
                  informações repassadas, em decorrência de falha exclusivamente
                  atribuível ao usuário ou a terceiros que fujam a qualquer
                  controle razoável da Ourominas.
                </p>
              </li>
              <li>
                <p>
                  O usuário concorda em defender, indenizar e manter indene a
                  Ourominas, seus diretores, empregados e parceiros, de e contra
                  quaisquer encargos, ações ou demandas, incluindo,
                  exemplificativamente, honorários advocatícios razoáveis,
                  resultantes: (i) da eventual utilização indevida do aplicativo
                  causado exclusivamente por sua conduta; (ii) da violação das
                  condições ora pactuadas; (iii) a violação de qualquer lei ou
                  dos direitos de terceiro.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DA SEGURANÇA CIBERNÉTICA
              </li>
              <li>
                <p>
                  <ul>
                    <li>
                      <strong>MANUTENÇÃO DE EQUIPAMENTOS:</strong>É de
                      responsabilidade do usuário manter o seu equipamento e/ou
                      dispositivos móveis com as últimas atualizações do Sistema
                      Operacional e com antivírus atualizado.
                    </li>
                    <li>
                      <strong>PROTEÇÃO DE DADOS DE ACESSO:</strong> Proteger as
                      senhas e acessos favoritados nos navegadores com segurança
                      e evitar vulnerabilidades através de phishing (e-mails
                      falsos infectados com códigos maliciosos).
                    </li>
                    <li>
                      <strong>USO LEGÍTIMO DO APLICATIVO:</strong> É vedado o
                      uso do aplicativo <strong>OurominasApp</strong> para
                      qualquer atividade ilícita.
                    </li>
                    <li>
                      <strong>MEDIDAS DE SEGURANÇA PROATIVAS:</strong> A
                      Ourominas poderá bloquear o acesso e uso dos serviços
                      <strong>OurominasApp</strong> e/ou operações relacionadas
                      à conta na identificação de atividades fora do padrão.
                    </li>
                    <li>
                      <strong>VALIDAÇÃO DE IDENTIDADE:</strong> A Ourominas pode
                      contatar o usuário diretamente ou por meio de terceiros
                      para validação de identidade.
                    </li>
                    <li>
                      <strong>TERMINAÇÃO DE ACESSO:</strong> A Ourominas se
                      reserva de direito de terminação, suspensão ou limitação
                      de acesso. A Ourominas pode encerrar, suspender ou limitar
                      o acesso ao aplicativo caso não seja possível obter ou
                      verificar informações necessárias em conformidade com a
                      legislação, incluindo regulamentações contra Lavagem de
                      Dinheiro e Financiamento ao Terrorismo – PLDFT.
                    </li>
                    <li>
                      <strong>MANUTENÇÃO DE REGISTROS:</strong> Armazenamento e
                      modificação de registros: A plataforma mantém uma cópia
                      dos registros de bens de propriedade do usuário,
                      modificáveis apenas mediante instruções válidas conforme
                      disposto no Termo de Uso.
                    </li>
                    <li>
                      <strong>CONTINUIDADE DE SERVIÇOS:</strong> A Ourominas não
                      garante acesso contínuo, ininterrupto ou segurança
                      constante, devido a possíveis interferências externas na
                      operação do website.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                GUARDA OU RETIRADA
              </li>
              <p>
                A Ourominas disponibiliza, no processo de finalização da
                operação da compra do ouro ativo financeiro, a possibilidade de:
              </p>
              <li>
                <p>
                  <ul>
                    <li>
                      Enviar a remessa para guarda do ouro ativo financeiro
                      junto à Custodia Ltda., inscrita no CNPJ/MF sob o nº
                      15.385.196/0001-57;
                    </li>
                    <li>
                      Realizar a solicitação de entrega do ouro ativo financeiro
                      na sede da Ourominas (Avenida Cidade Jardim, 400, 17º
                      andar, Jardim Paulistano, CEP 01454-901, São Paulo/SP);
                      quando o saldo for igual ou superior a 10g (dez gramas); e
                    </li>
                    <li>
                      Envio do ouro ativo financeiro pelos Correios com seguro
                      para o endereço cadastrado, quando a operação realizada,
                      for igual ou superior a 10g (dez gramas).
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>Valores e Condições:</p>
              </li>
              <li>
                <p>
                  <ul>
                    <li>
                      a) O cliente está ciente e de acordo que o ouro ativo
                      financeiro ora adquirido será remetido e ficará sob a
                      guarda da Custodia Ltda., inscrita no CNPJ/MF sob o nº
                      15.385.196/0001-57, até sua efetiva retirada e/ou entrega,
                      de acordo com o saldo mínimo de 10g;
                    </li>
                    <li>
                      O cliente está ciente e de acordo que a retirada e/ou
                      entrega do ouro ativo financeiro, ocorrerá apenas em
                      horário comercial (de segunda-feira a sexta-feira, exceto
                      finais de semana e feriados, das 9h00min às 18h00min),
                      desde que não esgotado o estoque diário e/ou na ausência
                      de operadores logísticos, podendo ser retirado e/ou
                      entregue imediatamente no primeiro dia útil posterior;
                    </li>
                    <li>
                      c) O cliente está ciente e de acordo que o valor do frete
                      será calculado de acordo com o endereço existente no
                      cadastro de sua conta no OurominasApp;
                    </li>
                    <li>
                      d) O cliente está ciente e de acordo que a cobrança sobre
                      ouro ativo financeiro remetido para guarda junto à
                      Custodia Ltda., inscrita no CNPJ/MF sob o nº
                      15.385.196/0001-57, será de 0,1% (um décimo por cento) ao
                      mês, tendo como base o peso (quantidade em gramas)
                      multiplicado pelo valor de mercado (grama do ouro ativo
                      financeiro) na data de vencimento da mensalidade;
                    </li>
                    <li>
                      e) O cliente que mantiver movimentação de compra ou venda
                      a cada 3 (três) meses será isento da tarifa de 0,1% (um
                      décimo por cento) ao mês, referente à guarda do saldo
                      existente;
                    </li>
                    <li>
                      f) O cliente está ciente e de acordo que a isenção da
                      tarifa referente à guarda junto à Custodia Ltda., inscrita
                      no CNPJ/MF sob o nº 15.385.196/0001-57, limita-se a 250g
                      (duzentos cinquenta gramas).
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                VENDA
              </li>
              <p>
                Quando o cliente desejar realizar a venda de parte ou seu saldo
                integral, através do OurominasApp, será realiza a transferência
                do valor desta venda, única e exclusivamente emconta corrente
                cadastrada e de sua titularidade, sendo vedada o uso de contas
                de terceiro.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                POLÍTICA DE PRIVACIDADE
              </li>
              <p>
                O uso do aplicativo está sujeito à Política de Privacidade,
                estando em total aderência à Lei Geral de Proteção de Dados
                (LGPD), Lei nº 13.709/2018.
              </p>
              <li>
                <p>
                  Esta política descreve a coleta, utilização e divulgação de
                  informações pessoais entre as empresas do Grupo. Ao utilizar o
                  aplicativo, o usuário concorda com o Termo de Uso e Política
                  de Privacidade.
                </p>
              </li>
              <li>
                <p>
                  O usuário tem a opção de não concordar com estes termos, o que
                  resultará imediatamente na impossibilidade de acessar ou
                  prosseguir com o cadastro. Em tal situação, é de livre escolha
                  do usuário procurar os canais de comunicação ou o nosso
                  Departamento de Compliance para ativação, reativação ou
                  bloqueio.
                </p>
              </li>
              <li>
                <p>
                  Conforme estabelecido na Lei Geral de Proteção de Dados, é
                  crucial o cumprimento do artigo 7º, § 5º, que define
                  "CONSENTIMENTO".
                </p>
              </li>
              <li>
                <p>
                  Este pode ser revogado a qualquer momento, mediante
                  manifestação expressa do titular, de forma gratuita e
                  simplificada. Entretanto, os tratamentos realizados com base
                  no consentimento prévio permanecerão válidos até a revogação,
                  salvo requerimento de eliminação, conforme estabelecido na
                  legislação.
                </p>
              </li>
              <li>
                <p>
                  Para assegurar o pleno exercício dos direitos de privacidade,
                  a OM DISTRIBUIDORA DE VALORES LTDA. Ourominas, disponibiliza
                  um canal direto ao nosso Encarregado de Proteção de Dados (DPO
                  - Data Protection Officer) através do e-mail
                  <a href="mailto:dpo@ourominas.com">dpo@ourominas.com</a>. Esse canal está disponível para prontamente
                  atender, esclarecer e permitir a revogação do uso dos dados
                  por parte do titular, reservando-nos o direito de manter
                  registros de operações de compra e venda de ouro ativo
                  financeiro por questões regulatórias, fiscais ou contábeis.
                </p>
              </li>
              <li>
                <p>
                  Visando total transparência, recomendamos a visita ao site
                  oficial da Lei Geral de Proteção de Dados (LGPD) disponível em
                  L13709{' '}
                  <a
                    rel="noreferrer"
                    className="text-decoration-none"
                    href={
                      'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.html'
                    }
                    target="_blank"
                  >
                    (planalto.gov.br)
                  </a>
                  , onde é possível obter informações mais detalhadas sobre a
                  legislação.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DADOS NECESSÁRIOS
              </li>
              <p>
                Coleta-se informações pessoais dos usuários em diferentes
                etapas, tais como no momento do registro no aplicativo, durante
                a realização das operações, ao entrar em contato com a Ourominas
                e ao participar de pesquisas e promoções.
              </p>
              <li>
                <p>As informações compreendem, mas não se limitam a:</p>
              </li>
              <li>
                <p>
                  <ul>
                    <li>
                      a) Informações de identificação pessoal: nome completo,
                      endereço, e-mail, número de telefone;
                    </li>
                    <li>
                      b) Detalhes das operações: detalhes sobre as operações de
                      compra e venda de ouro ativo financeiro;
                    </li>
                    <li>
                      c) Informações relacionadas ao dispositivo utilizado para
                      acessar o aplicativo;
                    </li>
                    <li>
                      d) Informações relacionadas aos detalhes de pagamento.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COOKIES
              </li>
              <p>
                Os cookies desempenham um papel fundamental ao permitir a
                personalização da experiência do usuário, armazenando
                informações relevantes sobre suas preferências.
              </p>
              <li>
                <p>
                  Os usuários têm a opção de desativar o uso de cookies em seus
                  navegadores. No entanto, é importante observar que essa ação
                  pode impactar a funcionalidade e o desempenho do aplicativo,
                  levando a uma possível redução na velocidade percebida durante
                  o uso.
                </p>
              </li>
              <li>
                <p>
                  Importante salientar que o uso de cookies é regido pela
                  Política de Privacidade, garantindo a conformidade com as
                  normativas vigentes, como a Lei Geral de Proteção de Dados
                  (LGPD).
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                MODIFICAÇÕES
              </li>
              <p>
                Reserva-se o direito de realizar modificações neste Termo de Uso
                a qualquer momento, seguindo nosso exclusivo de critério. Tais
                alterações entrarão em vigor imediatamente após sua publicação
                no aplicativo. Recomenda-se a verificação deste Termo de Uso
                periodicamente, a fim de manter-se informado sobre quaisquer
                mudanças realizadas.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                LEGISLAÇÃO E FORO APLICÁVEIS
              </li>
              <p>
                Esses Termos serão regidos, interpretados e executados de acordo
                com as leis da República Federativa do Brasil, independentemente
                dos conflitos dessas leis com leis de outros estados ou países,
                sendo o único competente o Foro Central Cível da Cidade de São
                Paulo, Estado de São Paulo, para dirimir qualquer dúvida
                decorrente deste instrumento, renunciado o usuário a competência
                de qualquer outro foro, por mais privilegiado que seja ou venha
                a ser.
              </p>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
