import piramideFrente50g from "../../../assets/newImages/mockups/piramide/piramide-frente-50g.png";
import piramideFrente100g from "../../../assets/newImages/mockups/piramide/piramide-frente-100g.png";
import piramideFrente250g from "../../../assets/newImages/mockups/piramide/piramide-frente-250g.png";
import piramideVerso50g from "../../../assets/newImages/mockups/piramide/piramide-verso-50g.png";
import piramideVerso100g from "../../../assets/newImages/mockups/piramide/piramide-verso-100g.png";
import piramideVerso250g from "../../../assets/newImages/mockups/piramide/piramide-verso-250g.png";
import especificacao50g from "../../../assets/images/especificacoesOuro/piramide-ouro-50g.png";
import especificacao100g from "../../../assets/images/especificacoesOuro/piramide-ouro-100g.png";
import especificacao250g from "../../../assets/images/especificacoesOuro/piramide-ouro-250g.png";

export const  piramideMockups = {
    nome: "Pirâmide de Ouro",    
  
    frente50g: piramideFrente50g,
    frente100g: piramideFrente100g,
    frente250g: piramideFrente250g,
    verso50g: piramideVerso50g,
    verso100g: piramideVerso100g,
    verso250g: piramideVerso250g,

    especificacao50g: especificacao50g,
    especificacao100g: especificacao100g,
    especificacao250g: especificacao250g,

  
  };


export const piramides = [
    {
      name: "Pirâmide de Ouro",
      value: [
        "OURO BARRA (50g)",
        "OURO BARRA (100g)",
        "OURO BARRA (250g)",
      ]
    }
    
    
  ];