import axios from "axios";
import { getToken, SetLoginStorage } from "../utils/localStorage";
import { env } from "./env";
import { setLogModel } from "../models/Logs";
const url = env.linkAPI;
const token = getToken();

let AuthStr = `Bearer ${token}`;

const api = axios.create({
  baseURL: url,
  headers: {
    Authorization: AuthStr,
  },
});

 function setToken(token) {
  AuthStr =  `Bearer ${token}`;
}


export function loginAuth(user) {
  return api
    .post("/userlogin", user)
    .then((respUser) => {
      return api.post("/login", {
        //Se o Login do usuário for OK --> realizar o login do usuário ADMIN para retornar token
        username: env.userLogin,
        password: env.userPassword
      }).then(async (resp) => {
        user.id = respUser.data.user.id;
        user.role = respUser.data.user.role;
        user.token = await resp.data.token;
         setToken(resp.data.token)
        await SetLoginStorage(user).then(()=>{
          const model = new setLogModel(user.id,`Login ADMIN - Usuário: ${user.username}`, "SUCCESS");

          postLogUser(model);
          return resp;
        });
        return respUser.data.user.role;
      }).catch(() => alert("Erro interno, tente novamente mais tarde!"))
    })
}

export function getAlerts() {
  return api.get("/alerta").then((resp) => resp.data);
}
export function getLancamentos() {
  return api.get("/lancamento").then((resp) => resp.data);
}
export function getRecebaCotacoes() {
  return api.get("/newsletter").then((resp) => resp.data);
}
export function getOferts() {
  return api.get("/oferta").then((resp) => resp.data);
}
export function getOfertOuro() {
  return api.get("/ofertaouro").then((resp) => resp.data);
}

export function putRecebaCotacoes(id) {
  return api.put(`/newsletter/id/${id}`).then((resp) => resp.data);
}

export function postLogUser(data) {
  return api
    .post("/log", data)
    .then(resp => {return resp.data})
    .catch((err) =>{
      console.log(err)

    })
}

export function getUsersPortal() {
  return api.get("/user").then((resp) => resp.data);
}

export function getLogUser(id) {
  return api
  .get(`/log/user/${id}`)
    .then((respUser) => respUser.data)
      .catch((err) =>{
        console.log(err)
      });
  }