import argentinaFrente1g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-1g-frente.png";
import argentinaFrente2g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-2g-frente.png";
import argentinaFrente5g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-5g-frente.png";

import argentinaVerso1g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-1g-verso.png";
import argentinaVerso2g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-2g-verso.png";
import argentinaVerso5g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-5g-verso.png";
import argentinaVerso10g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-10g-verso.png";
import argentinaVerso25g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-25g-verso.png";
import argentinaVerso31g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-31g-verso.png";
import argentinaVerso50g from "../../../assets/newImages/mockups/golden-cup/argentina/argentina-ouro-50g-verso.png";

export const  argentinaMockups = {
    nome: "Argentina",
    frente1g: argentinaFrente1g,
  
    frente2g: argentinaFrente2g,
  
    frente5g: argentinaFrente5g,  

    frente10g: argentinaFrente5g,
    frente25g:argentinaFrente5g,
    frente31g: argentinaFrente5g,
    frente50g: argentinaFrente5g,
  
    verso1g: argentinaVerso1g,
    verso2g: argentinaVerso2g,
  
    verso5g: argentinaVerso5g,

    verso10g: argentinaVerso10g,
    verso25g: argentinaVerso25g,
    verso31g: argentinaVerso31g,
    verso50g: argentinaVerso50g
  };
