import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { putRecebaCotacoes } from "../../../../services/admin";

export function DisableAlert({ item, reload }) {

    const [data, setData] = useState(null)

    useEffect(() => {
        setData(item)
    }, [item])

    const handleClose = () => {
        setData(null)
    }

    const disable = () => {
        putRecebaCotacoes(item.id)
            .then(() => {
                alert(`Alerta desabilitado para ${item.nome.split(' ', 1)}`);
                handleClose();
                reload();
                
            })
    }
    return (
        <>
            {data && (
                <Container>
                    <Text>
                        Desabilitar alerta para {data.nome.split(' ', 1)}?
                    </Text>

                    <BoxButtons>
                        <Button variant="contained" onClick={handleClose} color="error">Cancelar</Button>
                        <Button variant="contained" color="success" onClick={disable}>Desabilitar</Button>
                    </BoxButtons>
                </Container>
            )}

        </>
    )
}

const Container = styled.div`
width: 400px;
border-radius: 5px;
height: 130px;
background: #b8b8b8;
position: fixed;
top: 20%;
left: 50%; 
transform: translateX(-50%);
display:flex;
flex-direction: column;
padding: 20px 0;
`

const Text = styled.p`
text-align: center;
`

const BoxButtons = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-top: 10px;
`