import React from 'react';
import './politicas.css';
import img from '../../../assets/newImages/politicaSegurancaCibernetica1.png';

export default function PoliticaSegurancaCibernetica() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              POLÍTICA DE SEGURANÇA CIBERNÉTICA
            </p>
            <p>
              Política elaborada por Adriano Nascimento, revisada por Bruna
              Finarde e aprovada por Mauriciano Cavalcante (Diretor), destinado
              a todas as partes interessadas, ou seja, aos clientes e usuários
              dos produtos oferecidos pela <strong>OM DTVM</strong>, a
              comunidade interna (funcionários, colaboradores (pessoas
              contratadas fora do regime CLT que executam atividades em nome da{' '}
              <strong>OM DTVM</strong>), estagiários, jovens aprendizes,
              correspondentes cambiais e postos de atendimento) e, claro, para
              todos aqueles que querem conhecer o posicionamento da{' '}
              <strong>OM DTVM</strong> sobre o tema.
            </p>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO
              </li>
              <li className="list-unstyled">
                <p>
                  Este normativo estabelece a Política de Segurança Cibernética
                  da <strong>OM DTVM</strong>, bem como os requisitos para a
                  contratação, avaliação e gestão de serviços de processamento e
                  armazenamento de dados e de computação em nuvem, visando total
                  observância e adequação ao exigido na Resolução nº 4.893, de
                  26 de fevereiro de 2021.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                RESPONSABILIDADES, VALIDADE E DISPONIBILIZAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  Os avanços tecnológicos criam facilidades e possibilitam o uso
                  de novas ferramentas para a atuação das Instituições
                  Financeiras - IF, permitindo assim, agilidade na construção e
                  disponibilização de serviços e na ampliação dos meios de
                  comunicação.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Por outro lado, o aumento do uso de tais ferramentas
                  potencializa os riscos de ataques cibernéticos, ameaçando a
                  confidencialidade, a integridade e a disponibilidade dos dados
                  ou dos sistemas que utilizam redes de dados existentes em
                  cloud computing.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  As principais razões para que esses ataques sejam realizados
                  por vários agentes (organizações criminosas, hackers
                  individuais, terroristas, colaboradores, competidores etc.) se
                  dá por tentativas de ganhos financeiros através de roubo,
                  manipulação ou adulteração de informações; obtenção de
                  vantagens competitivas e informações confidenciais de clientes
                  ou instituições concorrentes para o acometimento de fraudes,
                  sabotagens ou exposição da Instituição invadida por motivos de
                  vingança, ideias políticas ou sociais (hackitivismo).
                </p>
                <p>
                  Os invasores podem utilizar vários métodos para os ataques
                  cibernéticos. Entre eles, destacam-se os mais comuns:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Malwares: softwares desenvolvidos para corromper
                      computadores e redes;
                    </li>
                    <li>
                      Vírus: softwares que causam danos aos sistemas
                      operacionais, redes, softwares e banco de dados;
                    </li>
                    <li>
                      Cavalo de Troia: aparecem dentro de outros softwares e
                      criam uma porta para invasão dos computadores;
                    </li>
                    <li>
                      Spyware: softwares maliciosos para coletar e monitorar o
                      uso de informações;
                    </li>
                    <li>
                      Ransomware: softwares maliciosos que bloqueiam o acesso a
                      dados, sistemas e bases, solicitando um resgate para que o
                      acesso seja reestabelecido (através de pagamentos por meio
                      de criptomoedas);
                    </li>
                    <li>
                      Engenharia social: métodos de manipulação para obter
                      informações confidenciais, como senhas, dados pessoais e
                      número de cartão de crédito;
                    </li>
                    <li>
                      Pharming: direciona o usuário para um site fraudulento,
                      sem o seu conhecimento;
                    </li>
                    <li>
                      Phishing: links transmitidos por e-mail, simulando ser uma
                      pessoa ou empresa confiável que envia comunicação
                      eletrônica oficial para obter informações confidenciais;
                    </li>
                    <li>
                      Vishing: simula ser uma pessoa ou empresa confiável e, por
                      meio de ligações telefônicas, tenta obter informações
                      confidenciais;
                    </li>
                    <li>
                      Smishing: simula ser uma pessoa ou empresa confiável e,
                      por meio de mensagens de texto, tenta obter informações
                      confidenciais;
                    </li>
                    <li>
                      Insiders Threats: são conhecidas como ameaças internas
                      realizadas por ex ou colaboradores, terceirizados, etc
                      através de ataques de dentro da própria corporação, pois
                      conhecem as práticas de segurança, dados e sistemas
                      computacionais.
                    </li>
                    <li>
                      Ataques de DDOS (Distributed denial of services) e
                      botnets: ataques visando negar ou atrasar o acesso aos
                      serviços ou sistemas da instituição; no caso dos botnets,
                      o ataque vem de um grande número de computadores
                      infectados utilizados para criar e mandar SPAM´s, vírus,
                      ou inundar uma rede com mensagens resultando na negação de
                      serviços.
                    </li>
                    <li>
                      Invasões (advanced persistent threats): ataques realizados
                      por invasores sofisticados, utilizando conhecimentos e
                      ferramentas para detector e explorer fragilidades
                      específicas em um ambiente tecnológico.
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                As ameaças cibernéticas podem variar de acordo com a natureza,
                vulnerabilidade e informações/bens de cada organização. As
                consequências para as instituições podem ser significativas em
                termos de risco de imagem, danos financeiros ou perda de
                vantagem concorrencial, além de riscos operacionais. Os
                possíveis impactos dependem também da rápida detecção e resposta
                após a identificação do ataque. Instituições grandes ou pequenas
                podem ser impactadas e por esse motivo, os ativos incorporados
                no espaço cibernético devem ser protegidos e preservados.
              </p>
              <p>Entre esses ativos cibernéticos estão:</p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>Softwares, como um programa de computador;</li>
                    <li>
                      Conectividades como acesso à internet, Banco Central,
                      Receita Federal, etc;
                    </li>
                    <li>
                      Informações sigilosas de clientes e da Distribuidora;
                    </li>
                    <li>
                      Componentes físicos, como servidores, estações de
                      trabalho, etc.
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                Com o aumento exponencial das ameaças cibernéticas nos últimos
                anos, tanto em volume quanto em sofisticação, reguladores de
                mercado, incluindo o Banco Central, através da Resolução nº
                4.893 já mencionada, têm voltado maior atenção para esse assunto
                com o objetivo de orientar as instituições em seus respectivos
                mercados e verificar se suas estruturas estão preparadas para
                identificar e mitigar riscos cibernéticos, assim como para se
                recuperar de possíveis incidentes.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ÁREA GESTORA DA POLÍTICA DE SEGURANÇA CIBERNÉTICA
              </li>
              <p>
                Diretor responsável pela Política de Segurança Cibernética. As
                atribuições deste diretor contemplam: a) responsável pela
                Política de Segurança Cibernética, atualização e constante
                monitoramento; b) responsável pela execução do Plano de Ação e
                resposta a incidentes.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DIRETRIZES
              </li>
              <li className="list-unstyled">
                <p>
                  A Política de Segurança Cibernética, que está implementada na{' '}
                  <strong>OM DTVM</strong>, baseia-se nos seguintes princípios:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Assegurar a confidencialidade dos ativos de informação
                      (garantia de que o acesso à informação seja obtido somente
                      por pessoas autorizadas), observadas as regras de sigilo e
                      confidencialidade vigentes.
                    </li>
                    <li>
                      Assegurar a integridade (garantia de que a informação seja
                      mantida em seu estado original, visando protegê-la, na
                      guarda ou transmissão, contra alterações indevidas,
                      intencionais ou acidentais);
                    </li>
                    <li>
                      Assegurar a disponibilidade dos dados e sistemas de
                      informação utilizados na Distribuidora (garantia de que os
                      usuários autorizados obtenham acesso à informação e aos
                      ativos correspondentes sempre que necessário).
                    </li>
                    <li>
                      A implementação desta Política considera as seguintes
                      compatibilidades da Distribuidora:
                    </li>
                    <li>
                      O porte, perfil de risco e o modelo de nossos negócios;
                    </li>
                    <li>
                      A natureza das operações e a complexidade dos produtos,
                      serviços, atividades e processos atuais;
                    </li>
                    <li>
                      A sensibilidade dos dados e das informações sob
                      responsabilidade da instituição.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Os ambientes, sistemas, computadores e redes da Distribuidora
                  poderão ser monitorados e gravados, com prévia informação,
                  conforme previsto nas leis brasileiras.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Caberá a todos os Colaboradores conhecer e adotar as
                  disposições desta política e deverão, ainda, proteger as
                  informações contra acesso, modificação, destruição ou
                  divulgação não autorizados, assegurar que os recursos
                  tecnológicos à sua disposição sejam utilizados apenas para as
                  finalidades adequadas ao exercício de suas atividades.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Conforme a Resolução nº 4.893/21, os serviços de computação em
                  nuvem abrangem a disponibilidade da <strong>OM DTVM</strong>,
                  sob demanda e de maneira virtual, de ao menos um dos seguintes
                  serviços:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Processamento de dados, armazenamento de dados,
                      infraestrutura de redes e outros recursos computacionais
                      que permitam a <strong>OM DTVM</strong> implantar ou
                      executar softwares, que podem incluir sistemas
                      operacionais e aplicativos internos ou adquiridos.
                    </li>
                    <li>
                      Implantação ou execução de aplicativos desenvolvidos ou
                      adquiridos pela <strong>OM DTVM</strong>, utilizando
                      recursos computacionais de seus prestadores de serviços;
                    </li>
                    <li>
                      Execução por meio de internet dos aplicativos implantados
                      ou desenvolvidos por prestadores de serviços da{' '}
                      <strong>OM DTVM</strong>, com utilização de recursos
                      computacionais do próprio prestador de serviços contratado
                      pela Distribuidora.
                    </li>
                    <li>
                      A <strong>OM DTVM</strong> é responsável pela Gestão dos
                      serviços contratados incluindo as seguintes atividades:
                    </li>
                    <li>
                      Análises de informações e de recursos adequados ao
                      monitoramento dos serviços;
                    </li>
                    <li>
                      Confiabilidade, integridade, disponibilidade, segurança e
                      sigilo em relação aos serviços contratados junto a
                      Prestadores de serviços; e
                    </li>
                    <li>
                      Cumprimento da legislação e da regulamentação vigente.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PLANO DE AÇÃO/RESPOSTAS A INCIDENTES
              </li>
              <li className="list-unstyled">
                <p>
                  Visando a implementação das práticas da Política de Segurança
                  Cibernética na <strong>OM DTVM</strong>, há a implementação um
                  Plano de Ação e de resposta a incidentes abrangendo o
                  seguinte:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      As ações a serem desenvolvidas para adequar a estrutura
                      organizacional aos princípios e diretrizes da Política de
                      Segurança Cibernética;
                    </li>
                    <li>
                      Os procedimentos, rotinas, controles e tecnologias a serem
                      utilizadas na prevenção e na resposta a incidentes;
                    </li>
                    <li>
                      Área responsável pelo registro e controle dos efeitos de
                      incidentes relevantes.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  O Plano de Ação e de resposta a Incidentes será aprovado pelo
                  Diretor responsável pela Política de Segurança Cibernética e
                  será revisado no mínimo anualmente.
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                RELATÓRIO SOBRE A IMPLEMENTAÇÃO DO PLANO DE AÇÃO
              </li>
              <p>
                Será emitido anualmente, com data base de 31 de dezembro,
                relatório sobre implementação do Plano de Ação e de Resposta a
                Incidentes.
              </p>
              <p>
                Esse relatório deve contemplar, no mínimo, as seguintes
                informações:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      A efetividade da implementação das ações relativas à
                      implementação da Política de Segurança Cibernética;
                    </li>
                    <li>
                      O resumo dos resultados obtidos na implementação das
                      rotinas, dos procedimentos, dos controles e tecnologias a
                      serem utilizados na prevenção em a resposta a incidentes.
                    </li>
                    <li>
                      Os incidentes relevantes relacionados com o ambiente
                      cibernético ocorridos no período;
                    </li>
                    <li>
                      Os resultados dos testes de continuidade de negócios,
                      considerando cenários de indisponibilidade ocasionada por
                      incidentes.
                    </li>
                    <li>
                      O relatório deve ser elaborado até 31 de março do ano
                      seguinte ao da data base, devendo ser aprovado pelo
                      Diretor responsável pela Segurança Cibernética.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONTRATAÇÃO DE SERVIÇOS DE PROCESSAMENTO
              </li>
              <p>
                Os prestadores e parceiros de serviços de processamento de dados
                e armazenamento em nuvem podem representar uma fonte
                significativa de riscos de cibersegurança. A computação em nuvem
                considerada como uma forma de contratação de serviço de
                terceiros e, assim como as demais contratações, envolve
                determinados riscos que são levados em conta pela distribuidora,
                demandando assim cuidados proporcionais a esta identificação de
                ameaças.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                EXIGÊNCIAS PARA A CONTRATAÇÃO DE SERVIÇOS
              </li>
              <p>
                A <strong>OM DTVM</strong> ao realizar contratações de serviços
                relevantes de processamento e armazenamento de dados e de
                computação em nuvem, no país ou no exterior deverá adotar
                procedimentos visando certificar-se de que a empresa contratada
                tende a seguintes exigências:
              </p>
              <ul>
                <li>
                  Adoção de práticas de Governança Corporativa e de Gestão
                  proporcionais a relevância dos serviços que estão sendo
                  contratados e aos riscos que estão expostos, como por exemplo:
                  a) se mantém Política de Segurança da Informação; b) Se possui
                  Plano de Continuidade Operacional; e c) se as mudanças ou
                  alterações de serviços ou sistemas são registradas e
                  autorizadas quando se tratam de ambientes de homologação e de
                  sua implantação em ambientes de produção (GMUD – Gestão de
                  Mudanças); d) se mantém Gestão de Incidentes.
                </li>
                <li>
                  Verificação da capacidade do potencial Prestador de Serviços
                  de forma a assegurar os seguintes requisitos: a) cumprimento
                  da legislação e da regulamentação em vigor; b) Permissão de
                  acesso da <strong>OM DTVM</strong> aos dados e as informações
                  a serem processadas ou armazenadas pelo Prestador de Serviços;
                  c) Confidencialidade, integridade, disponibilidade e
                  recuperação aos dados e das informações processadas ou
                  armazenadas pelo Prestador de Serviços; d) Aderência a
                  certificações que a <strong>OM DTVM</strong> possa exigir para
                  a prestação do serviço a ser contratado; e) Aderência a
                  certificações que a <strong>OM DTVM</strong> possa exigir para
                  a prestação do serviço a ser contratado; f) Aderência a
                  certificações que a <strong>OM DTVM</strong> possa exigir para
                  a prestação do serviço a ser contratado; g) Aderência a
                  certificações que a <strong>OM DTVM</strong> possa exigir para
                  a prestação do serviço a ser contratado;
                </li>
                <li>
                  Qualidade dos controles de acesso voltados à proteção dos
                  dados e das informações dos clientes da
                  <strong>OM DTVM</strong>.
                </li>
              </ul>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                AVALIAÇÃO DOS SERVIÇOS A SEREM CONTRATADOS
              </li>
              <p>
                A <strong>OM DTVM</strong> deve proceder a uma avaliação da
                relevância dos serviços prestados por empresas com
                possibilidades de serem contratadas considerando o seguinte:
              </p>

              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>Criticidade dos serviços a serem prestados;</li>
                    <li>
                      Sensibilidade dos dados e das informações processadas,
                      armazenadas e gerenciadas pela empresa contratada.
                    </li>
                    <li>
                      Verificação quanto a adoção, por parte do prestador de
                      serviços quanto a adoção de controles que mitiguem efeitos
                      de eventuais vulnerabilidades na liberação de novas
                      versões de aplicativos no caso de serem executados através
                      de internet.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMUNICAÇÕES AO BANCO CENTRAL
              </li>
              <p>
                A <strong>OM DTVM</strong> deverá informar ao Banco Central a
                contratação de serviços relevantes de processamento,
                armazenamento de dados e de computação em nuvem.
              </p>

              <p>
                Essa comunicação deve ser realizada até 10 dias após a
                contratação dos serviços e devem ficar à disposição do Banco
                Central do Brasil pelo prazo de cinco anos.
              </p>
              <p>A comunicação deve conter as seguintes informações: </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      a) Denominação da empresa contratada;
                    </li>
                    <li className="list-unstyled">
                      b) Os serviços relevantes contratados;
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                A indicações dos países e das regiões em cada país onde os
                serviços serão prestados e onde os dados poderão ser
                armazenados, processados e gerenciados, nos casos de contratação
                no exterior.
              </p>
              <p>
                As alterações contratuais que impliquem modificações nas
                informações contratuais devem ser comunicadas ao Banco Central
                do Brasil até 10 dias após a alteração contratual.
              </p>
              <p>
                A contratação de serviços relevantes de processamento,
                armazenamento de dados e de computação em nuvem prestados no
                exterior, a ser realizada, deve observar os seguintes
                requisitos:
              </p>

              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      a) A existência de convênio para troca de informações
                      entre o Banco Central do Brasil e as autoridades
                      supervisoras dos países onde os serviços poderão ser
                      prestados;
                    </li>
                    <li className="list-unstyled">
                      b) Assegurar que a prestação dos serviços não cause
                      prejuízos ao seu regular funcionamento nem embaraço à
                      atuação do Banco Central do Brasil;
                    </li>
                    <li className="list-unstyled">
                      c) Definir, previamente à contratação, os países e as
                      regiões em cada país onde os serviços poderão ser
                      prestados e os dados poderão ser armazenados, processados
                      e gerenciados; e
                    </li>
                    <li className="list-unstyled">
                      d) Prever alternativas para a continuidade dos negócios,
                      no caso de impossibilidade de manutenção ou extinção do
                      contrato de prestação de serviços.
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                No caso de inexistência de convênio citado nos itens anteriores
                a <strong>OM DTVM</strong> deverá solicitar autorização ao Banco
                Central do Brasil para:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      a) Contratação do serviço, no prazo mínimo de 60
                      (sessenta) dias antes da contratação, observando que a
                      solicitação de autorização deve conter as seguintes
                      informações:
                    </li>
                    
                          <div className="espaco">
                          <li>A denominação da empresa a ser contratada;</li>
                          <li>Os serviços relevantes a serem contratados; e</li>
                          <li>
                            A indicação dos países e das regiões em cada país
                            onde os serviços poderão ser prestados e os dados
                            poderão ser armazenados, processados e gerenciados.
                          </li>
                          </div>
                        
                    
                    <li className="list-unstyled">
                      b) As alterações contratuais que impliquem modificação das
                      informações sobre a denominação da empresa contratada,
                      serviços relevantes contratados, países ou regiões de cada
                      país onde os serviços são prestados e os dados
                      armazenados, processados e gerenciados, observando o prazo
                      mínimo de 60 (sessenta) dias antes da alteração
                      contratual.
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                A <strong>OM DTVM</strong> deve assegurar que a legislação e a
                regulamentação nos países e nas regiões em cada país onde os
                serviços poderão ser prestados não restringem nem impedem o
                acesso das instituições contratantes e do Banco Central do
                Brasil aos dados e às informações.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOS CONTRATOS
              </li>
              <p>
                Os contratos firmados entre a <strong>OM DTVM LTDA</strong>. e
                as empresas prestadoras de serviços relevantes de processamento,
                armazenamento de dados e computação em nuvem devem prever:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      a) A indicação dos países e da região, em cada país, onde
                      os serviços poderão ser prestados e os dados poderão ser
                      armazenados, processados e gerenciados;
                    </li>
                    <li className="list-unstyled">
                      b) A adoção de medidas de segurança para a transmissão e
                      armazenamento dos dados;
                    </li>
                    <li className="list-unstyled">
                      c) A manutenção, enquanto o contrato estiver vigente, da
                      segregação dos dados e dos controles de acesso para
                      proteção das informações dos clientes.
                    </li>
                    <li className="list-unstyled">
                      d) A obrigatoriedade, em caso de extinção do contrato, de:
                    </li>
                      <div className="espaco"><li>
                              Transferência dos dados ao novo prestador de
                              serviços ou a <strong>OM DTVM LTDA</strong>.;
                            </li>
                            <li>
                              Exclusão dos dados pela empresa contratada
                              substituída após a transferência dos dados e a
                              confirmação da integridade e da disponibilidade
                              dos dados recebidos.
                            </li></div>
                    <li className="list-unstyled">
                      e) O acesso da <strong>OM DTVM LTDA</strong>. a:
                    </li>
                      <div className="espaco">
                      <li>
                              Informações fornecidas pela empresa contratada
                              visando verificar o cumprimento dos itens
                              previstos nos itens a), b) e c) acima.
                            </li>
                            <li>
                              Informações relativas às certificações exigidas
                              pela Distribuidora e aos relatórios de auditoria
                              contratada;
                            </li>
                            <li>
                              Informações e recursos de Gestão adequados ao
                              monitoramento dos serviços prestados.
                            </li>
                      </div>
                    <li className="list-unstyled">
                      f) A obrigação da empresa contratada notificar a{' '}
                      <strong>OM DTVM LTDA</strong>. sobre a subcontratação de
                      serviços relevantes para a Distribuidora.
                    </li>
                    <li className="list-unstyled">
                      g) A permissão de acesso do Banco Central do Brasil às
                      seguintes informações:
                    </li>
                    <div className="espaco">
                    <li>
                              Contratos e acordos firmados para a prestação de
                              serviços
                            </li>
                            <li>
                              Documentação e informações referentes aos serviços
                              prestados
                            </li>
                            <li>Os dados armazenados;</li>
                            <li>As informações sobre processamento</li>
                            <li>
                              As cópias de segurança dos dados e das informações
                            </li>
                            <li>
                              Códigos de acesso aos dados e as informações
                            </li>
                    </div>
                    <li className="list-unstyled">
                      h) A adoção de medidas pela <strong>OM DTVM LTDA</strong>
                      ., em decorrência de determinação do Banco Central do
                      Brasil;
                    </li>
                    <li className="list-unstyled">
                      i) O contrato deve também prever, para o caso de
                      decretação de regime de resolução da Distribuidora pelo
                      Banco Central:
                    </li>
                    <div className="espaco">
                    <li>
                              A obrigação da empresa contratada para a prestação
                              de serviços conceder pleno e irrestrito acesso do
                              responsável pelo regime de resolução aos
                              contratos, aos acordos, a documentação e as
                              informações referentes aos serviços prestados, aos
                              dados armazenados e as informações sobre seus
                              processos, as cópias de segurança dos dados
                              armazenados e as informações sobre seus processos,
                              as cópias de segurança dos dados e das
                              informações, bem como aos códigos de acesso que
                              estejam em poder da empresa contratada.
                            </li>
                            <li>
                              A obrigação de notificação prévia do responsável
                              pelo regime de resolução sobre a intenção da
                              empresa contratada interromper a prestação de
                              serviços, com pelo menos 30 (trinta) dias de
                              antecedência da data prevista para a interrupção.
                            </li>
                    </div>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                MITIGAÇÃO DOS RISCOS
              </li>
              <p>
                Os contratos firmados entre a <strong>OM DTVM LTDA</strong>. e
                as empresas prestadoras de serviços relevantes de processamento,
                armazenamento de dados e computação em nuvem devem prever:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="list-unstyled">
                      a) A indicação dos países e da região, em cada país, onde
                      os serviços poderão ser prestados e os dados poderão ser
                      armazenados, processados e gerenciados;
                    </li>
                    <li className="list-unstyled">
                      b) A adoção de medidas de segurança para a transmissão e
                      armazenamento dos dados;
                    </li>
                    <li className="list-unstyled">
                      c) A manutenção, enquanto o contrato estiver vigente, da
                      segregação dos dados e dos controles de acesso para
                      proteção das informações dos clientes.
                    </li>
                    <li className="list-unstyled">
                      d) A obrigatoriedade, em caso de extinção do contrato, de:
                    </li>
                    <div className="espaco">
                    <li>
                              Transferência dos dados ao novo prestador de
                              serviços ou a <strong>OM DTVM LTDA</strong>.;
                            </li>
                            <li>
                              Exclusão dos dados pela empresa contratada
                              substituída após a transferência dos dados e a
                              confirmação da integridade e da disponibilidade
                              dos dados recebidos.
                            </li>
                    </div>
                    <li className="list-unstyled">
                      e) O acesso da <strong>OM DTVM LTDA</strong>. a:
                    </li>
                    <div className="espaco">
                            <li>
                              Informações fornecidas pela empresa contratada
                              visando verificar o cumprimento dos itens
                              previstos nos itens a), b) e c) acima.
                            </li>
                            <li>
                              Informações relativas às certificações exigidas
                              pela Distribuidora e aos relatórios de auditoria
                              contratada;
                            </li>
                            <li>
                              Informações e recursos de Gestão adequados ao
                              monitoramento dos serviços prestados.
                            </li>
                    </div>
                    <li className="list-unstyled">
                      f) A obrigação da empresa contratada notificar a{' '}
                      <strong>OM DTVM LTDA</strong>. sobre a subcontratação de
                      serviços relevantes para a Distribuidora.
                    </li>
                    <li className="list-unstyled">
                      g) A permissão de acesso do Banco Central do Brasil às
                      seguintes informações:
                      </li>
                      <div className="espaco">
                      <li>
                              Contratos e acordos firmados para a prestação de
                              serviços
                            </li>
                            <li>
                              Documentação e informações referentes aos serviços
                              prestados
                            </li>
                            <li>Os dados armazenados;</li>
                            <li>As informações sobre processamento</li>
                            <li>
                              As cópias de segurança dos dados e das informações
                            </li>
                            <li>
                              Códigos de acesso aos dados e as informações
                            </li>
                      </div>
                    <li className="list-unstyled">
                      h) A adoção de medidas pela <strong>OM DTVM LTDA</strong>
                      ., em decorrência de determinação do Banco Central do
                      Brasil;
                    </li>
                    <div className="espaco">
                      <li>
                            A obrigatoriedade da empresa contratada manter a{' '}
                            <strong>OM DTVM LTDA</strong>. permanentemente
                            informada sobre eventuais limitações que possam
                            afetar a prestação dos serviços ou o cumprimento da
                            legislação e regulamentação em vigor;
                          </li>
                          <li>
                            O contrato deve também prever, para o caso de
                            decretação de regime de resolução da Distribuidora
                            pelo Banco Central:
                          </li>
                          <li>
                            A obrigação da empresa contratada para a prestação
                            de serviços conceder pleno e irrestrito acesso do
                            responsável pelo regime de resolução aos contratos,
                            aos acordos, a documentação e as informações
                            referentes aos serviços prestados, aos dados
                            armazenados e as informações sobre seus processos,
                            as cópias de segurança dos dados e das informações,
                            bem como aos códigos de acesso que estejam em poder
                            da empresa contratada.
                          </li>
                          <li>
                            A obrigação de notificação prévia do responsável
                            pelo regime de resolução sobre a intenção da empresa
                            contratada interromper a prestação de serviços, com
                            pelo menos 30 (trinta) dias de antecedência da data
                            prevista para a interrupção, observando que:
                          </li>
                          <li>
                            A empresa contratada obriga-se a aceitar eventual
                            pedido de prazo adicional de 3 (trinta) dias para a
                            interrupção do serviço, feito pelo responsável pelo
                            regime de resolução.
                          </li>
                          <li>
                            A notificação prévia deve ocorrer também na situação
                            em que a interrupção for motivada por inadimplência
                            da Distribuidora.
                          </li>
                    </div>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                AÇÕES DE PREVENÇÃO
              </li>
              <p>
                Devem ser criados mecanismos de monitoramento de todas as ações
                de proteção implementadas para garantir o bom funcionamento e
                efetividade da segurança cibernética da Distribuidora através
                das seguintes ações:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Manter inventários atualizados de hardware e software, bem
                      como verificá-los com frequência para identificar
                      elementos estranhos à instituição. Por exemplo,
                      computadores não autorizados ou software não licenciado.
                    </li>
                    <li>
                      Manter os sistemas operacionais e softwares de aplicação
                      sempre atualizados, instalando as atualizações sempre que
                      forem disponibilizadas.
                    </li>
                    <li>
                      Monitorar diariamente as rotinas de backup, executando
                      testes regulares de restauração dos dados;
                    </li>
                    <li className="list-unstyled">
                      Realizar, periodicamente testes de invasão externa e
                      phishing.
                    </li>
                    <li className="list-unstyled">
                      Realizar análises de vulnerabilidade na estrutura
                      tecnológica, periodicamente ou sempre que houver mudança
                      significativa em tal estrutura.
                    </li>
                    <li className="list-unstyled">
                      Periodicamente testar o plano de resposta a incidentes,
                      simulando os cenários.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                TRATAMENTO DE INCIDENTES
              </li>
              <p>
                Incidentes são interrupções de sistema não planejadas que
                ocorrem de várias naturezas e que afetam os negócios da
                Distribuidora, como por exemplo:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>Queda de energia elétrica</li>
                    <li>Falha de um elemento de conexão</li>
                    <li>Servidor fora do ar</li>
                    <li>Ausência de conexão com internet</li>
                    <li>Sabotagem/terrorismo</li>
                    <li>Vazamento de dados/informações</li>
                    <li>Indisponibilidade de acesso a distribuidora</li>
                    <li> Indisponibilidade de recursos computacionais</li>
                    <li>Ataques DDOS</li>
                  </ul>
                </p>
              </li>
              <p>
                Qualquer funcionário que detectar um incidente deverá comunicar
                imediatamente as demais áreas sobre o fato para que ele seja
                levado ao conhecimento do Diretor responsável pela Política de
                Segurança Cibernética.
              </p>
              <li className="list-unstyled">
                <p>
                  <strong>AVALIAÇÃO INICIAL</strong>
                </p>
                <p>
                  Avaliar o incidente em conjunto com a Diretoria para verificar
                  se é provável a sua reincidência ou se é um sintoma de
                  problema crônico, para a tomada de providências e medidas
                  corretivas. Analisar motivos e consequências imediatas, bem
                  como a gravidade da situação.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <strong>INCIDENTE CARACTERIZADO</strong>
                </p>
                <p>
                  Caracterizado o incidente, devem ser tomadas as medidas
                  imediatas, tais como:
                </p>
                <p>
                  <ul>
                    <li>
                      Iniciar a redundância de TI, redirecionar as linhas de
                      telefone para os celulares, instruir o provedor de
                      telefonia a desviar linhas de dados/e-mail, entre outros.
                    </li>
                    <li>
                      O Diretor responsável pela Política de Segurança
                      Cibernética avaliará o impacto do incidente nos diversos
                      riscos envolvidos.
                    </li>
                    <li>
                      Conforme a relevância (sabotagem, terrorismo etc.) poderá
                      ser registrado um boletim de ocorrência ou queixa crime
                      para as devidas providências.
                    </li>
                    <li>
                      Conforme a relevância do incidente comunicar os clientes
                      que porventura tenham sido afetados.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <strong>RECUPERAÇÃO</strong>
                </p>
                <p>
                  Essa fase começa após o incidente ter sido contornado, já
                  tendo sido a contingência de TI acionada e terceiroschave
                  notificados. Quaisquer dados faltando ou corrompidos, ou
                  problemas identificados por colaboradores internos devem ser
                  comunicados à Diretoria.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <strong>RETOMADA</strong>
                </p>
                <p>
                  Tal fase refere-se ao período de transição do retorno ao modo
                  normal de operação e pode incluir a análise de projetos, como
                  voltar a operação normal, reconstrução de eventuais sistemas e
                  eventuais mudanças e medidas de prevenção.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMUNICAÇÕES AO BANCO CENTRAL
              </li>
              <p>
                A <strong>OM DTVM LTDA</strong>. deverá informar ao Banco
                Central do Brasil e a ANPD (Autoridade Nacional de Proteção de
                Dados), as ocorrências de incidentes relevantes e as
                interrupções dos serviços relevantes que configurem uma situação
                de crise;
              </p>

              <p>
                Essa comunicação deve ser acompanhada das informações sobre o
                incidente ocorrido bem como das informações sobre as
                providências tomadas para o reinício das atividades.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMPARTILHAMENTO DE INFORMAÇÕES
              </li>
              <p>
                Iniciativas para compartilhamento de informações sobre os
                incidentes relevantes ocorridos na <strong>OM DTVM LTDA</strong>
                . e/ou comunicados por terceiros/empresas que prestam serviços
                relevantes de processamento de dados a distribuidora, o controle
                será realizado em níveis de complexidade, abrangência e precisão
                compatíveis com os utilizados pela própria instituição.
              </p>

              <p>
                A <strong>OM DTVM LTDA</strong>., deverá contemplar a capacidade
                da instituição para prevenir, detectar e reduzir a
                vulnerabilidade a incidentes relacionados com o ambiente
                cibernético, reforçando assim, seus controles internos, que
                devem abranger no mínimo:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>Autenticação, a criptografia;</li>
                    <li>A prevenção de vazamento de informações;</li>
                    <li>
                      Realização periódica de testes e varreduras para detecção
                      de vulnerabilidades;
                    </li>
                    <li>Proteção contra softwares maliciosos;</li>
                    <li>Mecanismos de rastreabilidade;</li>
                    <li>
                      O registro, a análise da causa e do impacto, bem como o
                      controle dos efeitos de incidentes;
                    </li>
                    <li>
                      Controles de acesso e de segmentação da rede de
                      computadores; e
                    </li>
                    <li>
                      Manutenção de cópias de segurança dos dados e das
                      informações;
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                As informações de incidentes relevantes serão compartilhadas com
                as demais instituições mencionadas no Art. 1º da Resolução CMN
                4.893, de 26 de fevereiro de 2021 e estarão disponíveis para
                consulta do Banco Central do Brasil
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                MONITORAMENTO E TESTES
              </li>
              <p>
                O ambiente da TI da Distribuidora deve ser supervisionado e
                monitorado com o objetivo de verificar sua efetividade e
                detectar as ameaças em tempo hábil, reforçando os controles,
                caso necessário, e identificar possíveis anomalias no ambiente
                tecnológico, incluindo a presença de usuários, componentes ou
                dispositivos não autorizados.
              </p>

              <p>
                É possível a ocorrência de algum risco de segurança cibernética
                através de uma das seguintes situações descritas:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Invasão sistêmica que prejudique dados internos, incluindo
                      vírus ou ataque de hackers;
                    </li>
                    <li>
                      Comunicações falsas utilizando os dados coletados para ter
                      credibilidade e enganar vítimas;
                    </li>
                    <li>
                      Comprometimento de estações de trabalho decorrente de
                      cliques em link malicioso
                    </li>
                    <li>
                      Exposição do ambiente devido a uma brecha de segurança,
                      por diversos motivos como a instalação de software em
                      contrariedade com condições internamento estabelecidas.
                    </li>
                    <li>
                      Vazamento de informações durante tráfego de dados não
                      criptografados.
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                Semestralmente a Distribuidora deve providenciar a execução de
                testes de cibersegurança através da verificação dos seguintes
                itens:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Uso da capacidade instalada da rede e dos equipamentos;
                    </li>
                    <li>
                      Tempo de resposta no acesso à internet e aos sistemas
                      críticos da Distribuidora;
                    </li>
                    <li>
                      Períodos de indisponibilidade no acesso à internet e aos
                      sistemas críticos da distribuidora
                    </li>
                    <li>
                      Vulnerabilidades que possam causar incidentes (vírus,
                      trogans, furtos, acessos indevidos, etc)
                    </li>
                    <li>
                      Inspeção física nas máquinas de hardware, se mantido
                      servidor físico;,
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOCUMENTOS À DISPOSIÇÃO DO BANCO CENTRAL
              </li>
              <p>
                Os seguintes documentos devem ficar à disposição do Banco
                Central do Brasil pelo prazo de cinco anos:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>Política de Segurança Cibernética;</li>
                    <li>
                      Ata de reunião da diretoria da Distribuidora,
                      implementando a política de segurança cibernética
                    </li>
                    <li>
                      Documento relativo ao plano de ação e de resposta a
                      incidentes relativos à implementação da política de
                      segurança cibernética
                    </li>
                    <li>
                      Relatório anual sobre a implementação do plano de ação e
                      de resposta a incidente
                    </li>
                    <li>
                      Documento relativo ao plano de ação e de resposta a
                      incidentes relativos à implementação da Política de
                      Segurança Cibernética;
                    </li>
                    <li>
                      Relatório anual sobre a implementação do Plano de ação e
                      de resposta a incidente;
                    </li>
                    <li>
                      Documentação sobre os procedimentos relativos à
                      contratação de serviços relevantes de processamento e
                      armazenamento de dados e de computação em nuvem;
                    </li>
                    <li>
                      Documentação sobre os serviços relevantes de
                      processamento, armazenamento de dados e de computação em
                      nuvem prestados no exterior, caso isso ocorra
                    </li>
                    <li>
                      Contratos de prestação de serviços relevantes de
                      processamento, armazenamento de dados e computação na
                      nuvem;
                    </li>
                    <li>
                      Dados, registros e informações relativas aos mecanismos de
                      acompanhamento e de controle com vistas a assegurar a
                      implementação e a efetividade da política de segurança
                      cibernética, do plano de ação e de resposta a incidentes e
                      dos requisitos para contratação de serviços de
                      processamento e armazenamento de dados e de computação em
                      nuvem.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                REGULAMENTAÇÃO ASSOCIADA
              </li>
              <p>
                Resolução Bacen nº 4.893 de 26 de fevereiro de 2021 (revoga a
                Resolução Bacen nº 4.658 de 26 de abril de 2018 e a Resolução nº
                4.752 de 26 de setembro de 2019). Circular Bacen nº 3.909, de 16
                de agosto de 2018.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ABRANGÊNCIA, APROVAÇÃO, DIVULGAÇÃO E REVISÃO
              </li>
              <p>
                O conteúdo desta Política de Segurança Cibernética aplica-se a
                todos os funcionários e prestadores de serviços relevantes da OM
                DTVM LTDA., no âmbito de suas atividades, atribuições e
                responsabilidades.
              </p>
              <p>
                Está aprovada pela Diretoria a qual está comprometida com a
                melhoria contínua do disposto neste normativo.
              </p>
              <p>
                Está sendo publicada e comunicada para todos os funcionários,
                empresas contratadas de serviços de cibernética e clientes e
                partes externas relevantes, para o necessário cumprimento.
              </p>
              <p>
                Um resumo da Política de Segurança Cibernética será divulgado ao
                público através do site da distribuidora.
              </p>
              <p>
                É obrigação de todo funcionário ou colaborador conhecer e
                praticar às disposições desta Política e assegurar que, quando
                necessário, prestadores de serviços sejam informados sobre as
                regras estabelecidas.
              </p>
              <p>
                Será implementado um Programa de capacitação e de avaliação
                periódica de pessoal sobre as diretrizes desta Política.
              </p>
              <p>
                Esta Política, juntamente com o Plano de Ação e respostas a
                incidentes será revisada anualmente ou quando mudanças
                significativas ocorrerem, assegurando a sua contínua
                pertinência, adequação e eficácia.
              </p>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
