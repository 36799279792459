import noraFrente1g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-1g-frente.png";
import noraFrente2g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-2g-frente.png";
import noraFrente5g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-5g-frente.png";
import noraFrente10g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-10g-frente.png";
import noraFrente25g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-25g-frente.png";
import noraFrente31g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-31g-frente.png";
import noraFrente50g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-50g-frente.png";
import noraVerso1g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-1g-verso.png";
import noraVerso2g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-2g-verso.png";
import noraVerso5g from "../../../assets/newImages/mockups/familia/nora/nora-ouro-5g-verso.png";

export const  noraMockups = {
    nome: "Nora de Ouro",
    frente1g: noraFrente1g,
  
    frente2g: noraFrente2g,
  
    frente5g: noraFrente5g, 
    frente10g: noraFrente10g,
    frente25g:noraFrente25g,
    frente31g: noraFrente31g,
    frente50g: noraFrente50g, 
  
    verso1g: noraVerso1g,
    verso2g: noraVerso2g,
  
    verso5g: noraVerso5g,
    verso10g: noraVerso5g,
    verso25g: noraVerso5g,
    verso31g: noraVerso5g,
    verso50g: noraVerso5g,
  };