import enteadoFrente1g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-1g-frente.png";
import enteadoFrente2g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-2g-frente.png";
import enteadoFrente5g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-5g-frente.png";
import enteadoFrente10g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-10g-frente.png";
import enteadoFrente25g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-25g-frente.png";
import enteadoFrente31g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-31g-frente.png";
import enteadoFrente50g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-50g-frente.png";
import enteadoVerso1g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-1g-verso.png";
import enteadoVerso2g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-2g-verso.png";
import enteadoVerso5g from "../../../assets/newImages/mockups/familia/enteado/enteado-ouro-5g-verso.png";

export const  enteadoMockups = {
    nome: "Enteado de Ouro",
    frente1g: enteadoFrente1g,
  
    frente2g: enteadoFrente2g,
  
    frente5g: enteadoFrente5g, 
    frente10g: enteadoFrente10g,
    frente25g:enteadoFrente25g,
    frente31g: enteadoFrente31g,
    frente50g: enteadoFrente50g, 
  
    verso1g: enteadoVerso1g,
    verso2g: enteadoVerso2g,
  
    verso5g: enteadoVerso5g,
    verso10g: enteadoVerso5g,
    verso25g: enteadoVerso5g,
    verso31g: enteadoVerso5g,
    verso50g: enteadoVerso5g,
  };