import judaicaFrente1g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-1g-frente.png";
import judaicaFrente2g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-2g-frente.png";
import judaicaFrente5g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-5g-frente.png";
import judaicaFrente10g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-10g-frente.png";
import judaicaFrente25g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-25g-frente.png";
import judaicaFrente31g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-31g-frente.png";
import judaicaFrente50g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-50g-frente.png";
import judaicaVerso1g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-1g-verso.png";
import judaicaVerso2g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-2g-verso.png";
import judaicaVerso5g from "../../../assets/newImages/mockups/religiao/judaica/judaica-ouro-5g-verso.png";

export const  judaicaMockups = {
    nome: "Judaica",
    frente1g: judaicaFrente1g,
  
    frente2g: judaicaFrente2g,
  
    frente5g: judaicaFrente5g, 
    frente10g: judaicaFrente10g,
    frente25g:judaicaFrente25g,
    frente31g: judaicaFrente31g,
    frente50g: judaicaFrente50g, 
  
    verso1g: judaicaVerso1g,
    verso2g: judaicaVerso2g,
  
    verso5g: judaicaVerso5g,
    verso10g: judaicaVerso5g,
    verso25g: judaicaVerso5g,
    verso31g: judaicaVerso5g,
    verso50g: judaicaVerso5g,
  };