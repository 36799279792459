import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './tabTable.css';
import {
  BolsaNovaYork,
  MoedasEstrangeirasCartao,
  MoedasEstrangeirasEspecie,
  Moneygram,
  More,
  OuroAtivoFinanceiroCotacao,
  OuroAtivoFinanceiroSimulacao,
  RemessasInternacionais,
} from '../coinTable/coinTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function TabTable() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: '#F7F7F7',
        display: 'flex',
        border: '1px dotted grey',
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="BOLSA DE NOVA YORK" className="btnTab " {...a11yProps(0)} />
        <Tab
          label="OURO ATIVO FINANCEIRO COTAÇÃO"
          className="btnTab "
          {...a11yProps(1)}
        />
        <Tab
          label="OURO ATIVO FINANCEIRO SIMULAÇÃO"
          className="btnTab "
          {...a11yProps(2)}
        />
        <Tab
          label="MOEDAS ESTRANGEIRAS EM ESPÉCIE"
          className="btnTab"
          {...a11yProps(3)}
        />
        <Tab
          label="MOEDAS ESTRANGEIRAS CARTÃO PRÉ-PAGO"
          className="btnTab"
          {...a11yProps(4)}
        />
        <Tab
          label="REMESSAS INTERNACIONAIS"
          className="btnTab"
          {...a11yProps(5)}
        />
        <Tab label="MONEYGRAM" className="btnTab" {...a11yProps(6)} />
        {/* <Tab
          label="MORE PAYMENT"
          className="btnTab"
          {...a11yProps(7)}
        /> */}
      </Tabs>
      <TabPanel value={value} index={0} className="eachPanel">
        <BolsaNovaYork />
      </TabPanel>

      <TabPanel value={value} index={1} className="eachPanel">
        <OuroAtivoFinanceiroCotacao />
      </TabPanel>
      <TabPanel value={value} index={2} className="eachPanel">
        <OuroAtivoFinanceiroSimulacao />
      </TabPanel>
      <TabPanel value={value} index={3} className="eachPanel">
        <MoedasEstrangeirasEspecie />
      </TabPanel>
      <TabPanel value={value} index={4} className="eachPanel">
        <MoedasEstrangeirasCartao />
      </TabPanel>
      <TabPanel value={value} index={5} className="eachPanel">
        <RemessasInternacionais />
      </TabPanel>
      <TabPanel value={value} index={6} className="eachPanel">
        <Moneygram />
      </TabPanel>
      <TabPanel value={value} index={7} className="eachPanel">
        <More />
      </TabPanel>
    </Box>
  );
}
