import React from 'react';
import img from '../../../assets/newImages/piramide.png';
import '../../pages.css';
import linha from '../../../assets/linha.png';
import { TableGoldPiramide } from '../../../components/gold/tableGold';
import styled from 'styled-components';
import { PoliticaEntrega } from '../../../components/text/politcaEntrega';
export default function PiramideOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              PIRÂMIDE DE OURO
            </p>
            <p>
              A Ourominas (OM), de forma pioneira e exclusiva no Brasil, lançou
              um novo produto, uma barra de ouro no formato piramidal. O
              produto, que também pode servir como investimento em ouro, foi
              criado com o propósito de agregar valor, uma vez que prosperidade
              e concentração de boas energias são qualidades associadas a
              pirâmide de ouro.
            </p>
            <p>
              O produto que simboliza a natureza, e os seus quatro ângulos
              representam a verdade, a inteligência, o silêncio e a
              profundidade, rapidamente tornou-se um sucesso, o que nos instigou
              a criar mais de um tamanho de pirâmide de 50, 100 e 250 gramas de
              ouro puro 24k.
            </p>
            <p>
              A OM criou a pirâmide de ouro, pois alguns especialistas acreditam
              que elas podem ser muito úteis para a vida cotidiana, e que seu
              poder pode funcionar de forma fiel a esses itens abaixo:
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section>
          <BoxBorder>
            <p>
              <ol>
                <li>
                  <b>Catalisar energia: </b>as estruturas piramidais de ouro
                  podem funcionar como um catalisador de energia, gerada a
                  partir dos seus cinco pontos;
                </li>
                <li>
                  <b>Conservação alimentícia:</b>se manter a comida perto de uma
                  pirâmide de ouro ela pode se manter fresca por mais tempo;
                </li>
                <li>
                  <b>Conservação de lâminas:</b>se colocar facas, tesouras e
                  lâminas de barbear perto de uma pirâmide de ouro em alguns
                  dias elas poderão estar mais afiadas;
                </li>
                <li>
                  <b>Sono contínuo:</b> se colocar uma pirâmide de ouro debaixo
                  da cama pode ajudar a evitar a insônia e a ter sonhos
                  agradáveis e duráveis.
                </li>
                <li>
                  <b>Mais concentração e memória: </b>a energia da pirâmide de
                  ouro pode melhorar a concentração e a memória das pessoas, por
                  isso, estudar perto de uma pirâmide de ouro pode melhorar o
                  desempenho no estudo e no trabalho;
                </li>
                <li>
                  <b>Controle do tabagismo: </b> as energias das pirâmides de
                  ouro são tão poderosas que podem ajudar as pessoas a pararem
                  de fumar ou a diminuir o vício, para isso, o fumante deve
                  armazenar seus cigarros perto de uma pirâmide de ouro, uns
                  vinte minutos por dia e com o tempo, ele irá notar que seu
                  hábito começa a desaparecer;
                </li>
                <li>
                  <b>Equilibrar os chacras:</b>deite-se e coloque uma pirâmide
                  de ouro sobre um ponto ou cada um dos pontos chacras, os
                  centros energéticos do organismo durante vinte minutos, por
                  sete dias seguidos;
                </li>
                <li>
                  <b>Favorecer alguém distante:</b> deixe por alguns dias fotos
                  da pessoa distante perto de uma pirâmide de ouro, isso pode
                  atrair bons fluidos para a pessoa e melhorar sua saúde.
                </li>
              </ol>
            </p>
          </BoxBorder>
        </section>
        <p className="mt-4">
          <b>Nossas Pirâmides</b>
        </p>
        <TableGoldPiramide />

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE COMPRAR COM A OUROMINAS? </summary>
            <p className="mt-2">
              Investir em ouro com a Ourominas (OM) significa contar com
              segurança, transparência e condições diferenciadas.
            </p>
            <p>Veja os principais motivos para escolher a OM:</p>
            <ol className="mt-4">
              <li>
                <p> Melhores Cotações </p>A OM negocia ouro seguindo as cotações
                do mercado interno e internacional, garantindo que o investidor
                sempre compre pelo menor preço possível, sem cobranças de taxas
                de confecção, taxas de corretagem e taxas administrativas. Tanto
                pequenos quanto grandes investidores encontram condições justas
                e competitivas.
              </li>
              <li>
                <p className="mt-2"> Transparência nas Cotações</p>O preço do
                ouro é o mesmo para todos os clientes, independentemente do
                volume adquirido. Diferente de outras empresas, a OM não
                penaliza o investidor que compra pequenas quantidades.
              </li>
              <li>
                <p className="mt-2"> Sem Taxas Ocultas </p>
                Ao contrário dos fundos de investimento, onde há taxas de
                administração, performance e entrada / saída, na OM o investidor
                pode comprar e vender ouro sem custos adicionais.
                <p>
                  Flexibilidade para comprar diariamente, semanalmente ou
                  mensalmente, de acordo com sua estratégia.
                </p>
              </li>
              <li>
                <p className="mt-2"> Mais Liquidez e Facilidade que a Bolsa </p>
                Comprar ouro na OM é mais rápido e acessível, o cliente recebe
                as barras de ouro física, isso garante maior liquidez e
                agilidade para o investidor.
              </li>
              <li>
                <p className="mt-2"> Experiência e Credibilidade </p>
                Com mais de 40 anos no mercado, a OM se destaca pela experiência
                e profissionalismo de sua equipe.
                <p>
                  A empresa é autorizada pelo Banco Central e credenciada na
                  Comissão de Valores Mobiliários (CVM) para operar com ouro,
                  garantindo total segurança para seus clientes.
                </p>
              </li>
            </ol>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO COMPRAR A PIRÂMIDE? </summary>
            <strong>Cadastre-se na OM </strong>
            <p>
              • Escolha entre o <strong>Cadastro Simplificado</strong> (para
              operações de pequeno valor) ou o{' '}
              <strong>Cadastro Completo</strong> (para valores maiores e
              operações recorrentes).
            </p>
            <strong>Entre em Contato </strong>
            <p className="mb-4">
              • Realize suas compras por meio dos canais oficiais de atendimento
              da OM presencial ou online.
            </p>
            <strong>Confirme a Operação </strong>
            <p className="mb-4">
              • Após a negociação, a operação deve ser liquidada dentro do prazo
              combinado. Caso contrário, será cancelada automaticamente.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>A PIRÂMIDE POSSUI GARANTIA?</summary>
            <p>
              Todo ouro comercializado na OM possui pureza de 999, acompanha
              nota fiscal de negociação e tem recompra garantida. Para sua
              segurança, mantenha sempre as notas fiscais junto ao metal
              adquirido.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>CADASTRO.</summary>
            <p className="mt-4">
              A OM, como instituição financeira, segue rigorosos procedimentos
              cadastrais para atender às normas de Prevenção à Lavagem de
              Dinheiro e Financiamento ao Terrorismo (PLD/FT). Além disso, a
              empresa protege as informações de seus clientes, garantindo sigilo
              absoluto e só as compartilhando mediante determinação do Banco
              Central do Brasil.
            </p>
            <p>
              <strong>Tipos de Cadastro:</strong>
            </p>
            <ol>
              <li>
                <strong>Cadastro Simplificado: </strong>Consiste no
                preenchimento da Ficha Cadastral com dados básicos e
                apresentação de cópias simples dos seguintes documentos: RG, CPF
                e Comprovante de Endereço.
              </li>
              <li>
                <strong> Cadastro Completo: </strong>Procedimento padrão da
                instituição, inclui o preenchimento da Ficha Cadastral e a
                entrega de cópias simples de RG, CPF e Comprovante de Endereço,
                além de documentação complementar necessária para definição do
                limite operacional. Esse limite é estabelecido para fins de
                acompanhamento interno da empresa.
              </li>
            </ol>
          </details>

          <PoliticaEntrega />
        </section>
      </section>
    </article>
  );
}

const BoxBorder = styled.div`
  margin: 70px 0 30px 0px;
  border: solid 4px #c3c1c1;
  padding: 40px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;
