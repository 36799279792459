import jacareFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-1g-frente.png";
import jacareFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-2g-frente.png";
import jacareFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-5g-frente.png";
import jacareFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-10g-frente.png";
import jacareFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-25g-frente.png";
import jacareFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-31g-frente.png";
import jacareFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-50g-frente.png";
import jacareVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-1g-verso.png";
import jacareVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-2g-verso.png";
import jacareVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/jacare/jacare-ouro-5g-verso.png";

export const  jacareMockups = {
    nome: "Jacaré",
    frente1g: jacareFrente1g,
  
    frente2g: jacareFrente2g,
  
    frente5g: jacareFrente5g,
  
    frente10g: jacareFrente10g,
  
    frente25g: jacareFrente25g,
  
    frente31g: jacareFrente31g,
  
    frente50g: jacareFrente50g,
  
    verso1g: jacareVerso1g,
    verso2g: jacareVerso2g,
  
    verso5g: jacareVerso5g,
  };

 