import React, { useState, useEffect } from 'react';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import styled from 'styled-components';

const TEMPO_INICIAL = 30 * 60;

export default function Temporizador({ iniciarTemporizador, produtoFinalizado, carrinhoAtivo }) {
  const [tempoRestante, setTempoRestante] = useState(TEMPO_INICIAL);
  const [temporizadorAtivo, setTemporizadorAtivo] = useState(false);

  useEffect(() => {
    const storedTime = localStorage.getItem('tempoRestante');
    if (storedTime) {
      setTempoRestante(parseInt(storedTime, 10));
    }

    // Ativa o temporizador se estiver na página do carrinho
    if (carrinhoAtivo) {
      setTemporizadorAtivo(true);
    }

    // Se iniciarTemporizador é verdadeiro, ativa o temporizador
    if (iniciarTemporizador) {
      setTemporizadorAtivo(true);
    } else {
      setTempoRestante(TEMPO_INICIAL);
      setTemporizadorAtivo(false);
      localStorage.removeItem('tempoRestante'); // Limpa o tempo armazenado
    }
  }, [iniciarTemporizador, carrinhoAtivo]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (temporizadorAtivo) {
        setTempoRestante((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem('tempoRestante', newTime);
          return newTime <= 0 ? 0 : newTime;
        });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [temporizadorAtivo]);

  const formatarTempo = (tempo) => {
    const minutos = Math.floor(tempo / 60);
    const segundos = tempo % 60;
    return `00:${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
  };

  return (
    <Background>
      <p>Tempo de reserva do pedido:</p>
      <button>
        <AvTimerIcon />
        {formatarTempo(tempoRestante)}
      </button>
    </Background>
  );
}

const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  height: 45px;

  p {
    color: white;
    margin-bottom: 0;
  }

  button {
    font-size: 15px;
    color: var(--primary-yellow);
    border: 2px solid var(--primary-yellow);
    border-radius: 5px;
    margin-left: 20px;
    padding: 1px;
  }

  @media (min-width: 450px) {
    justify-content: flex-end;

    button {
      margin-right: 50px;
    }
  }
`;
