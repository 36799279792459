export const UserStorageModel = {
    id: "",
    username: "",
    role: "",
    token: "",
    date: ""
  };
  
  function setUserStorage(id, username, role, token, date) {
    this.id = id;
    this.username = username;
    this.role = role;
    this.token = token
   this.date = date;
  }

  
export { setUserStorage };