import React from 'react';
import '../../pages.css';
import img from '../../../assets/newImages/ouro-para-sua-empresa.png';
import linha from '../../../assets/linha.png';
import TableGold, { TableGoldLamina } from '../../../components/gold/tableGold';

export default function OuroSuaEmpresa() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              OURO PARA SUA EMPRESA
            </p>
            <p>
              A Ourominas (OM) tem como clientes fiéis e satisfeitos as mais
              respeitadas empresas no ramo joalheiro do Brasil.
            </p>
            <p>
              Além de atendimento personalizado com profissionais altamente
              qualificados, a OM oferece condições especiais para empresas de
              pequeno, médio e grande porte com os melhores preços do mercado
              para atender a demanda de cada empresa.
            </p>
            <p>
              Durante o processo cadastral, é analisado minuciosamente o perfil
              de cada cliente e a OM se responsabiliza por todo o processo de
              venda, desde a negociação até a entrega do produto, com total
              pontualidade e discrição.
            </p>
            <p>
              O ouro pode ser em forma de barra padrão ou em forma de lâmina,
              que é produzido através de uma barra de ouro que passa pelo
              processo através de uma máquina laminadora, de tal modo, são
              produzidas chapas timbradas que facilitam o trabalho de empresas,
              joalheiros, fabricantes de joias e ourives como matéria prima. As
              tiras de ouro 999 são muito versáteis e podem ser utilizadas em
              grande escala para a produção de modelos variados de joias. A
              espessura da camada de ouro pode variar, o padrão de lâminas da OM
              é de 2mm até 5mm, o que confere grande eficiência ao trabalho dos
              clientes.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-5">
          O material vendido pela OM é adquirido no mercado Balcão e no mercado
          primário, extraído de área de produção mineral localizada no Brasil,
          oriundo de Permissões de Lavras Garimpeiras (PLG) autorizadas e
          conferidas pela Agência Nacional de Mineração (ANM) e pela Americas
          Gold Manufactures Association (AMAGOLD), certificação de qualidade que
          é dada às empresas que passam por uma rigorosa análise, averiguando
          inclusive sua forma de produção para adequação nos critérios mais
          rígidos de qualidade.
        </p>
        <p>
          Todo ouro comercializado na OM possui teor de pureza 999, são lacrados
          individualmente com selos holográficos tridimensionais que garantem a
          segurança e a qualidade do produto, acompanha Nota Fiscal Eletrônica
          (NF-e), Garantia de Recompra e Certificado de Origem do Metal.
        </p>

        <p className="mt-4">
          <b>Nossas Barras</b>
        </p>
        <TableGold />

        <p className="mt-4">
          <b>Nossa Lâmina</b>
        </p>
        <TableGoldLamina />

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE COMPRAR COM A OUROMINAS? </summary>
            <ol className="mt-4">
              <li>
                A Ourominas se destaca por negociar o ouro com spreads baixos, o
                que significa que os clientes podem adquirir o metal precioso a
                preços mais competitivos. A empresa considera tanto os
                parâmetros de cotação do mercado interno quanto do
                internacional, garantindo que todos os clientes,
                independentemente do tamanho da empresa, tenham acesso a uma
                excelente cotação. Isso proporciona uma oportunidade valiosa
                para quem deseja investir ou fabricar suas joias em ouro, sempre
                buscando o menor preço disponível no mercado.
              </li>
              <li>
                As cotações na Ourominas são claras e transparentes, garantindo
                que os clientes não sejam penalizados com preços mais altos ao
                adquirirem pequenas quantidades de ouro, uma prática comum em
                outras DTVMs. Isso assegura que todos os clientes,
                independentemente do volume de compra, tenham acesso a condições
                justas e competitivas.
              </li>
              <li>
                A Ourominas é uma empresa com uma longa trajetória no mercado,
                acumulando mais de 40 anos de experiencia no setor. Nossa equipe
                é composta por profissionais altamente capacitados e eficientes,
                o que reforça seu compromisso com a qualidade e a excelência no
                atendimento aos clientes.
              </li>
              <li>
                A Ourominas é devidamente autorizada pelo Banco Central do
                Brasil e está credenciada na Comissão de Valores Mobiliários
                (CVM) para realizar operações/negociações com ouro, assim como
                todas as instituições financeiras do tipo DTVM(Distribuidora de
                Títulos e Valores Mobiliários). Isso garante que a empresa atue
                de acordo com as regulamentações do setor, proporcionando
                segurança e confiança aos seus clientes.
              </li>
              <li>
                A Ourominas, como instituição financeira, é obrigada a seguir
                procedimentos cadastrais rigorosos para prevenir a lavagem de
                dinheiro e ao financiamento ao terrorismo (PLD/FT). A empresa
                valoriza a privacidade de seus clientes, garantindo que suas
                informações sejam protegidas e não sejam compartilhadas com
                terceiros, exceto quando houver uma determinação do Banco
                Central do Brasil. Isso demonstra o compromisso da Ourominas com
                a segurança e a conformidade regulatória.
              </li>
            </ol>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO COMPRAR OURO PARA MINHA EMPRESA? </summary>
            <ol className="my-4">
              <li>
                Para a empresa realizar as compras, basta entrar em contato com
                a OM através de seus canais de atendimento para pessoa jurídica.
              </li>
              <li>
                A OM, como instituição financeira e com fins de prevenção contra
                a lavagem de dinheiro e financiamento ao terrorismo (PLD/FT),
                segue procedimentos cadastrais, protegendo as informações,
                guardando sigilo, salvo sob determinação dos órgãos públicos
                Federais, (Como por exemplo o Banco Central do Brasil).
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>ORIGEM DO METAL. </summary>
            <p className="mt-4">Esclarecemos, ainda, que:</p>
            <p>
              <ul>
                <li>
                  Cumprimos todas as exigências legais e regulamentares que
                  normatizam a nossa atividade;
                </li>
                <li>Recolhemos todos os impostos, taxas e encargos devidos;</li>
                <li>
                  Repudiamos a existência de qualquer prática de abusos nas
                  respectivas áreas de produção mineral, assim consideradas atos
                  de tortura, tratamento degradante, trabalho infantil, trabalho
                  forçado, etc.;
                </li>
                <li>
                  Não financiamos, apoiamos ou incentivamos qualquer espécie de
                  grupo armado público ou privado;
                </li>
                <li>
                  Não oferecemos ou prometemos vantagens indevidas que venham a
                  corromper a origem do material remetido;
                </li>
                <li>
                  Não praticamos ou participamos de qualquer ação que, direta ou
                  indiretamente se relacione às operações de lavagem de dinheiro
                  ou financiamento ao terrorismo;
                </li>
                <li>
                  Não contribuímos para o risco de conflitos armados envolvendo
                  governos, milícias, organizações criminosas terroristas;
                </li>
                <li>
                  Não contribuímos para o risco de abuso aos direitos humanos.
                </li>
              </ul>
            </p>
          </details>
          {/* <details className="mb-2 pb-2 border-bottom">
            <summary>CADASTRO PESSOA JURÍDICA.</summary>
            <p className="mt-4">
              A OM, como instituição financeira e com fins de prevenção contra a
              lavagem de dinheiro e financiamento ao terrorismo (PLD/FT) é
              obrigada a seguir certos procedimentos cadastrais. A empresa
              protege as informações de seus clientes e não repassa a terceiros,
              salvo sob determinação do Banco Central do Brasil.
            </p>
            <ol> */}
              {/* <li>
                Cadastro Simplificado: consiste no preenchimento da Ficha
                Cadastral com os dados básicos necessários.
              </li> */}
              {/* <li>
                Cadastro Completo: é o procedimento padrão de cadastro da
                instituição. Consiste no preenchimento da Ficha Cadastral e
                documentação complementar.
              </li>
            </ol>
          </details> */}

          <details className="mb-2 pb-2 border-bottom">
            <summary>HORÁRIO DE NEGOCIAÇÃO.</summary>
            <p className="my-4">
              Nosso horário de negociação: Segunda à Sexta, das 9:00 às 18:00h
              (Horário de Brasília), exceto feriados.
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
