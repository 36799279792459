import medicaFrente1g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-1g-frente.png";
import medicaFrente2g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-2g-frente.png";
import medicaFrente5g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-5g-frente.png";
import medicaFrente10g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-10g-frente.png";
import medicaFrente25g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-25g-frente.png";
import medicaFrente31g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-31g-frente.png";
import medicaFrente50g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-50g-frente.png";
import medicaVerso1g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-1g-verso.png";
import medicaVerso2g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-2g-verso.png";
import medicaVerso5g from "../../../assets/newImages/mockups/profissoes/medica/medica-ouro-5g-verso.png";

export const  medicaMockups = {
    nome: "Médica de Ouro",
    frente1g: medicaFrente1g,
  
    frente2g: medicaFrente2g,
  
    frente5g: medicaFrente5g,
  
    frente10g: medicaFrente10g,
  
    frente25g: medicaFrente25g,
  
    frente31g: medicaFrente31g,
  
    frente50g: medicaFrente50g,
  
    verso1g: medicaVerso1g,
    verso2g: medicaVerso2g,
  
    verso5g: medicaVerso5g,
    verso10g: medicaVerso5g,
    verso25g: medicaVerso5g,
    verso31g: medicaVerso5g,
    verso50g: medicaVerso5g,
  };

 