import esposoFrente1g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-1g-frente.png";
import esposoFrente2g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-2g-frente.png";
import esposoFrente5g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-5g-frente.png";
import esposoFrente10g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-10g-frente.png";
import esposoFrente25g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-25g-frente.png";
import esposoFrente31g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-31g-frente.png";
import esposoFrente50g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-50g-frente.png";
import esposoVerso1g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-1g-verso.png";
import esposoVerso2g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-2g-verso.png";
import esposoVerso5g from "../../../assets/newImages/mockups/familia/esposo/esposo-ouro-5g-verso.png";

export const  esposoMockups = {
    nome: "Esposo de Ouro",
    frente1g: esposoFrente1g,
  
    frente2g: esposoFrente2g,
  
    frente5g: esposoFrente5g, 
    frente10g: esposoFrente10g,
    frente25g:esposoFrente25g,
    frente31g: esposoFrente31g,
    frente50g: esposoFrente50g, 
  
    verso1g: esposoVerso1g,
    verso2g: esposoVerso2g,
  
    verso5g: esposoVerso5g,
    verso10g: esposoVerso5g,
    verso25g: esposoVerso5g,
    verso31g: esposoVerso5g,
    verso50g: esposoVerso5g,
  };