import React, { useEffect, useState } from "react";
import "../../../styles/ouroninas.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import "./ofert.css";
import { FormHelperText, InputLabel } from "@mui/material";
import { postOferta, postOfertaOuro } from "../../../services/api";
import {
  maskDDD,
  maskFloat,
  maskNumber,
  maskOnlyLetters,
  maskPhoneNotDDD,
  validEmail,
} from "../../../utils/regex";
import { OfertCoin, OfertOuro } from "../../../models/Ofert";
import { ReturnGrammage } from "../../../utils/coins";
import CountryFlags from "../../../utils/countryFlags";
import { TextSuccess } from "../../styled/text";

export default function Ofert({ ouro, moedas }) {
  let count = 0;
  const [checkOuro, setCheckOuro] = useState(false);
  const [checkCambio, setCheckCambio] = useState(false);
  const [moeda, setMoeda] = useState('');
  const [errMoeda, setErrMoeda] = useState();
  const [gramatura, setGramatura] = useState('');
  const [errGramatura, setErrGramatura] = useState();
  const [barras, setBarras] = useState('');
  const [errBarras, setErrBarras] = useState();
  const [quantidade, setQuantidade] = useState('');
  const [errQuantidade, setErrQuantidade] = useState();
  const [cotAtual, setCotAtual] = useState([0]);
  const [cotDesejada, setCotDesejada] = useState();
  const [errCotDesejada, setErrCotDesejada] = useState();
  const [fullName, setFullName] = useState("");
  const [errName, setErrName] = useState();
  const [email, setEmail] = useState("");
  const [errEmail, setErrEmail] = useState();
  const [ddd, setDdd] = useState("");
  const [telephone, setTelephone] = useState("");

  const [errTelephone, setErrTelephone] = useState();
  const [check, setCheck] = useState(false);
  const [errCheck, setErrCheck] = useState();
  const [dolar, setDolar] = useState([]);
  const [libra, setLibra] = useState([]);
  const [euro, setEuro] = useState([]);
  const [codIso, setCodIso] = useState();
  const [contato, setContato] = useState('');
  const [errContato, setErrContato] = useState();
  const [success, setSuccess] = useState();
  const ofertOuro = OfertOuro;
  const ofertCoin = OfertCoin;

  useEffect(() => {
    if(moedas && Object.keys(moedas).length > 0){
      filterMoedas(moedas);

    }
    async function filterMoedas(coin) {
      setDolar(() => coin.filter((moeda) => moeda.codIso.includes("EUSCOM")));
      setEuro(() => coin.filter((moeda) => moeda.codIso.includes("EUR")));
      setLibra(() => coin.filter((moeda) => moeda.codIso.includes("GBP")));
    }
  }, [moedas]);

  useEffect(() => {
    if (moeda === "ouro") {
      setQuantidade("");
    } else {
      setBarras("");
    }
  }, [moeda]);

  useEffect(() => {
    setCotAtual(ouro[gramatura]?.calculoVenda);
  }, [gramatura, ouro]);
  function validationInputs() {
    setSuccess("");
    setErrMoeda("");
    setErrGramatura("");
    setErrBarras("");
    setErrQuantidade("");
    setErrCotDesejada("");
    setErrName("");
    setErrEmail("");
    setErrCheck("");
    setErrContato("");
    setErrTelephone("");
    if (moeda === 0 || moeda === "" || moeda === undefined || moeda === null) {
      count++;
      setErrMoeda("Selecione a moeda!");
    }
    if (moeda === "ouro") {
      if (gramatura === "" || gramatura == null || gramatura === undefined) {
        count++;
        setErrGramatura("Selecione a gramatura!");
      }
      if (
        barras === 0 ||
        barras === "" ||
        barras == null ||
        barras === undefined
      ) {
        count++;
        setErrBarras("Selecione a quantidade de barras!");
      }
    } else {
      if (
        quantidade === "" ||
        quantidade === null ||
        quantidade === undefined
      ) {
        count++;
        setErrQuantidade("Selecione a quantidade da moeda!");
      }
    }
    if (
      contato === 0 ||
      contato === undefined ||
      contato === "" ||
      contato === null
    ) {
      count++;
      setErrContato("Selecione o contato");
    }
    if (fullName === "") {
      count++;
      setErrName("Digite o seu nome!");
    }
    if (contato === 1) {
      setTelephone("");
      setDdd("");
      if (email === "") {
        count++;
        setErrEmail("Digite o seu email!");
      } else if (!validEmail.test(email)) {
        count++;
        setErrEmail("Email inválido!");
      }
    } else if (contato === 2) {
      setEmail("");
      if (telephone === "" || ddd === "") {
        count++;
        setErrTelephone("Digite o Telefone");
      }
    }
    if (!cotDesejada) {
      count++;
      setErrCotDesejada("Digite a cotação!");
    } else if (parseFloat(cotDesejada) > parseFloat(cotAtual)) {
      count++;
      setErrCotDesejada("O valor deve ser menor que a cotação atual!");
    }
    if (!check) {
      count++;
      setErrCheck("Marque o aceite!");
    }
  }

  function returnQuantity(quant) {
    switch (quant) {
      case 1:
        return "Até 1.000,00 " + moeda;
      case 2:
        return "De 1.000,00 a 3.000,00 " + moeda;

      case 3:
        return "De 3.000,00 a 5.000,00 " + moeda;

      case 4:
        return "Acima de 5.000,00 " + moeda;
      default:
        break;
    }
  }
  function createPost() {
    validationInputs();
    if (count === 0) {
      if (moeda === "ouro") {
        ofertOuro.nome = fullName;
        ofertOuro.email = contato === 1 ? email : "";
        ofertOuro.telefone = contato === 2 ? ddd + telephone : "";
        ofertOuro.codIso = "XAUUSD"; //
        ofertOuro.valor = maskFloat(cotDesejada);
        ofertOuro.gramatura = ReturnGrammage(gramatura); //
        ofertOuro.quantidade = barras + " barra(s)"; //
        postOfertaOuro(ofertOuro).then(() => cleanForm());
      } else {
        ofertCoin.nome = fullName;
        ofertCoin.email = contato === 1 ? email : "";
        ofertCoin.telefone = contato === 2 ? ddd + telephone : "";
        ofertCoin.codIso = codIso === "EUSCOM" ? "USD" : codIso;
        ofertCoin.valor = maskFloat(cotDesejada);
        ofertCoin.quantidade = returnQuantity(quantidade);
        postOferta(ofertCoin).then(() => cleanForm());
      }
    }
  }
  const selectMoedas = (event) => {
    let moeda = event.target.value;
    setMoeda(moeda);
    if (moeda === "ouro") {
      setCheckCambio(false);
      setCheckOuro(true);
    } else {
      setCheckOuro(false);
      setCheckCambio(true);
    }
    switch (moeda) {
      case "ouro":
        setCotAtual(ouro[0]?.calculoGramaturaVenda);
        setCodIso(dolar[0].codIso);
        break;
      case "dólares":
        setCotAtual(dolar[0]?.calculoIOFVenda);
        setCodIso(dolar[0].codIso);
        break;
      case "libras":
        setCotAtual(libra[0]?.calculoIOFVenda);
        setCodIso(libra[0].codIso);
        break;
      case "euros":
        setCotAtual(euro[0]?.calculoIOFVenda);
        setCodIso(euro[0].codIso);
        break;
      default:
        break;
    }
  };

  const cleanForm = () => {
    setSuccess("Oferta enviada com sucesso!")
    setCotDesejada("");
    setFullName("");
    setEmail("");
    setDdd("");
    setTelephone("");
    setMoeda('')
    setGramatura('')
    setBarras('')
    setQuantidade('')
    setContato('')
  };
  return (
    <div className="ofert">
      <div className="Alert-cont-h p-0">
        <h3>FAÇA A SUA OFERTA</h3>
        <p className="m-0">
          Analisaremos a sua oferta, e se caso estiver dentro dos valores
          possíveis, entraremos em contato.
        </p>
      </div>

      <Box>
        <section className="sectOfert css-151dd8w mt-2">
          <div className="formaContato">
            <div className="eachOfert my-3">
              <FormControl className="w-100 bg-white">
                <InputLabel id="demo-simple-select-label">
                  Selecione a moeda 
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label="Selecione a moeda"
                  onChange={selectMoedas}
                  value={moeda}
                >
                  <MenuItem value="ouro"> <CountryFlags cod="OURO" />Ouro</MenuItem>
                  <MenuItem value="dólares">
                   <CountryFlags cod="EUSCOM"/> Dólar Americano (em espécie)
                  </MenuItem>
                  <MenuItem value="libras">
                  <CountryFlags cod="GBP"/> Libra Esterlina (em espécie)
                  </MenuItem>
                  <MenuItem value="euros">
                  <CountryFlags cod="EUR"/> Euro (em espécie)</MenuItem>
                </Select>
                <FormHelperText>{errMoeda}</FormHelperText>
              </FormControl>
            </div>
            {checkOuro && (
              <div className="d-flex divOuro">
                <div className="eachOfert my-3">
                  <FormControl className="w-100 bg-white">
                    <InputLabel id="demo-simple-select-label">
                      Selecione a Gramatura
                    </InputLabel>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      label="Selecione a Gramatura"
                      onChange={(e) => setGramatura(e.target.value)}
                      defaultValue={0}
                      value={gramatura}
                    >
                      <MenuItem value={0}>1g</MenuItem>
                      <MenuItem value={1}>2g</MenuItem>
                      <MenuItem value={2}>5g</MenuItem>
                      <MenuItem value={3}>7g</MenuItem>
                      <MenuItem value={4}>10g</MenuItem>
                      <MenuItem value={5}>25g</MenuItem>
                      <MenuItem value={6}>31g</MenuItem>
                      <MenuItem value={7}>50g</MenuItem>
                      <MenuItem value={8}>100g</MenuItem>
                      <MenuItem value={9}>250g</MenuItem>
                      <MenuItem value={10}>500g</MenuItem>
                      <MenuItem value={11}>1kg</MenuItem>
                    </Select>
                    <FormHelperText>{errGramatura}</FormHelperText>
                  </FormControl>
                </div>
                <div className="eachOfert my-3">
                  <FormControl className="w-100 bg-white">
                    <InputLabel id="demo-simple-select-label">
                      Quantidade de Barras
                    </InputLabel>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      label="Quantidade de Barras"
                      onChange={(e) => setBarras(e.target.value)}
                      value={barras}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                    <FormHelperText>{errBarras}</FormHelperText>
                  </FormControl>
                </div>
              </div>
            )}
            {checkCambio && (
              <div className="eachOfert my-3">
                <FormControl className="w-100 bg-white">
                  <InputLabel id="demo-simple-select-label">
                    Selecione a quantidade
                  </InputLabel>
                  <Select
                    inputProps={{ "aria-label": "Without label" }}
                    label="Selecione a Gramatura"
                    onChange={(e) => setQuantidade(e.target.value)}
                    value={quantidade}
                  >
                    <MenuItem value={1}>Até 1.000,00 {moeda}</MenuItem>
                    <MenuItem value={2}>
                      De 1.000,00 a 3.000,00 {moeda}
                    </MenuItem>
                    <MenuItem value={3}>
                      De 3.000,00 a 5.000,00 {moeda}
                    </MenuItem>
                    <MenuItem value={4}>Acima de 5.000,00 {moeda}</MenuItem>
                  </Select>
                  <FormHelperText>{errQuantidade}</FormHelperText>
                </FormControl>
              </div>
            )}
            <div className="divCot eachAlert my-3 ">
              <div className="d-flex">
                <TextField
                  className="bg-white me-2"
                  type="text"
                  placeholder="COTAÇÃO"
                  label="Cotação Atual"
                  value={parseFloat(cotAtual).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                  disabled
                />
                <TextField
                  className="cotDesejada bg-white col-6"
                  placeholder="COTAÇÃO"
                  label="Cotação desejada"
                  value={cotDesejada}
                  helperText={errCotDesejada}
                  onChange={(e) => setCotDesejada(maskNumber(e.target.value))}
                />
              </div>
            </div>
          </div>
          <div className="formaContato">
            <div className="eachAlert my-3 ">
              <FormControl className="col-12 bg-white formCotacao">
                <InputLabel id="demo-simple-select-label">
                  Forma de Contato
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={contato}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label="Forma de contato"
                  placeholder="Forma de Contato"
                  onChange={(e) => setContato(e.target.value)}
                >
                  <MenuItem value={1}>Email</MenuItem>
                  <MenuItem value={2}>Whatsapp</MenuItem> 
                </Select>
                <FormHelperText>{errContato}</FormHelperText>
              </FormControl>
            </div>
            <div className="eachOfert my-3">
              <TextField
                className="w-100 bg-white"
                placeholder="NOME"
                label="Nome"
                value={fullName}
                helperText={errName}
                onChange={(e) => setFullName(maskOnlyLetters(e.target.value))}
              />
            </div>
            {contato === 1 ? (
              <div className="eachOfert my-3">
                <TextField
                  className="w-100 bg-white"
                  placeholder="EMAIL"
                  label="Email"
                  value={email}
                  helperText={errEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            ) : contato === 2 ? (
              <div className="eachOfert my-3 d-flex">
                <div className="divDDD">
                  <TextField
                    className="ddd bg-white"
                    placeholder="DDD"
                    label="DDD"
                    value={ddd}
                    onChange={(e) => setDdd(maskDDD(e.target.value))}
                  />
                </div>
                <div className="ms-2 col-9 divCel">
                  <TextField
                    helperText={errTelephone}
                    className="bg-white w-100"
                    placeholder="Celular"
                    label="Celular"
                    value={telephone}
                    onChange={(e) =>
                      setTelephone(maskPhoneNotDDD(e.target.value))
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className=" divCheck col-12">
            <input
              className="form-check-input checkInput"
              type="checkbox"
              value=""
              id="check2"
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
            />
            <label className="form-check-label lblCheck">
            Autorizo que a OM entre em contato comigo caso a oferta seja aceita.
            </label>
          </div>
          <div className="col-12">
            <span className="text-danger fs-6" value={errCheck}>
              {errCheck}
            </span>
          </div>
          <div className=" py-2 divButton mb-2">
            <button
              onClick={createPost}
              type="submit"
              className="send-to btn-green-hover btnOfert"
            >
              FAZER OFERTA
            </button>
          </div>
           
          <div className="divSuccess">
            <TextSuccess>{success}</TextSuccess>
          </div>
        </section>
      </Box>
    </div>
  );
}
