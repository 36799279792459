import React from "react";
import img from "../../../assets/cartao-ouro-facil.png";
import linha from "../../../assets/linha.png";
export default function ModelosNegocio() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
            MODELO CORRESPONDENTE CAMBIAL
            </p>
            <p>
            Compra e venda de todos os produtos de câmbio da OM, o parceiro faz o atendimento ao cliente do início ao fim. Vantagem: aumenta o portfólio de produtos e obtém um maior retorno comparado ao modelo Indicador de Negócios.
            </p>
            <ul>
            <li>Investimento Inicial: R$ 50.000,00*;</li>
            <li>Taxa de Cadastro: R$ 3.000,00;</li>
            <li>Retorno do Investimento Esperado: 12 meses;</li>
            <li>Faturamento Médio Mensal Esperado: R$ 900.000,00;</li>
            <li>Spread Médio: de 2% a 3%.</li>
          </ul>
          <p>*Investimento inicial não estamos considerando a parte de infraestrutura da loja, apenas o estoque mínimo da loja.</p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <section className="sect my-5">
        <p className="fw-bold"> MODELO INDICADOR DE NEGÓCIOS COM CÂMBIO</p>
        <p>
          Através do sistema de câmbio da OM, o parceiro faz as indicações de
          nossas soluções em câmbio turismo e comercial. Vantagem: aumento da
          receita e fidelização do cliente.
        </p>
        <ul>
          <li>Investimento Inicial: Não possui;</li>
          <li>Taxa de Cadastro: Não possui;</li>
          <li>Receita Média Mensal: de R$ 500,00 à R$ 4.500,00.</li>
        </ul>

        <p className="fw-bold">MODELO AGÊNCIA INDICA</p>
        <p>
          Voltado para agências de viagem que não tem tempo para fazer a
          indicação em nosso sistema, mas tem cliente potencial. Vantagem:
          aumento da receita, não expende tempo com sistema e fideliza clientes.
        </p>
        <ul>
          <li>Investimento Inicial: Não possui;</li>
          <li>Taxa de Cadastro: Não possui;</li>
          <li>Receita Média Mensal: de R$ 200,00 à R$ 1.700,00.</li>
        </ul>

        <p className="fw-bold">MODELO AFILIADOS</p>
        <p>
          Parceria ideal para blogs, sites e influencers. Através de um link ou
          banner o cliente realiza a compra em nossa plataforma. Vantagem: o
          afiliado ganhará uma comissão pela venda concretizada.
        </p>
        <ul>
          <li>Investimento Inicial: Não possui;</li>
          <li>Taxa de Cadastro: Não possui;</li>
          <li>Receita Média Mensal: de R$ 200,00 à R$ 3.000,00.</li>
        </ul>

        <p className="fw-bold">MODELO INDICADOR DE NEGÓCIOS COM OURO</p>
        <p>
          Através do sistema de ouro da OM, o parceiro faz as indicações de
          clientes potenciais que desejam investir ou comprar ouro. Modelo
          destinado para indicação de clientes pessoa física e jurídica.
          Vantagem: aumento da receita e fidelização do cliente.
        </p>
        <ul>
          <li>Investimento Inicial: Não possui;</li>
          <li>Taxa de Cadastro: Não possui;</li>
          <li>Receita Média Mensal: de R$ 500,00 à R$ 4.500,00.</li>
        </ul>
        <p>
          *A OM não faz contrato de fornecimento de ouro com promessa mensal,
          grandes quantidades e preços abaixo do mercado.
        </p>
      </section>
      </section>
    </article>
  );
}
