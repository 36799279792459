import { araraAzulMockups, araraVermelhaMockups } from "./faunaBrasileira/arara";
import { cascavelMockups } from "./faunaBrasileira/cascavel";
import { corujaMockups } from "./faunaBrasileira/coruja";
import { jacareMockups } from "./faunaBrasileira/jacare";
import { micoMockups } from "./faunaBrasileira/mico";
import { oncaPintadaMockups } from "./faunaBrasileira/onca";
import { papagaioMockups } from "./faunaBrasileira/papagaio";
import { preguicaMockups } from "./faunaBrasileira/preguica";
import { tartarugaMockups } from "./faunaBrasileira/tartaruga";
import { tucanoMockups } from "./faunaBrasileira/tucano";
import { planteMockups } from "./planteArvore/planteArvore";
import { chinaMockups, chinchenMockups, coliseuMockups, cristoMockups, machuMockups, mahalMockups, petraMockups } from "./maravilhasMundo/maravilhasMundo";
import { belemMockups } from "./brasilTuristico/belem";
import { beloHorizonteMockups } from "./brasilTuristico/beloHorizonte";
import { brasiliaMockups } from "./brasilTuristico/brasilia";
import { curitibaMockups } from "./brasilTuristico/curitiba";
import { fortalezaMockups } from "./brasilTuristico/fortaleza";
import { goianiaMockups } from "./brasilTuristico/goiania";
import { manausMockups } from "./brasilTuristico/manaus";
import { portoMockups } from "./brasilTuristico/portoAlegre";
import { recifeMockups } from "./brasilTuristico/recife";
import { rioJaneiroMockups } from "./brasilTuristico/rioJaneiro";
import { salvadorMockups } from "./brasilTuristico/salvador";
import { SaoPauloMockups } from "./brasilTuristico/saoPaulo";
import { primaveraMockups } from "./estacoes-ano/primavera";
import { invernoMockups } from "./estacoes-ano/inverno";
import { outonoMockups } from "./estacoes-ano/outono";
import { veraoMockups } from "./estacoes-ano/verao";
import { boasFestasMockups } from "./empresa-ouro/boasFestas";
import { clienteMockups } from "./empresa-ouro/cliente";
import { colaboradorMockups } from "./empresa-ouro/colaborador";
import { colaboradoraMockups } from "./empresa-ouro/colaboradora";
import { consultorMockups } from "./empresa-ouro/consultor";
import { consultoraMockups } from "./empresa-ouro/consultora";
import { coordenadorMockups } from "./empresa-ouro/coordenador";
import { destaqueAnoMockups } from "./empresa-ouro/destaqueAno";
import { destaqueMesMockups } from "./empresa-ouro/destaqueMes";
import { diretorMockups } from "./empresa-ouro/diretor";
import { diretoraMockups } from "./empresa-ouro/diretora";
import { gerenteMockups } from "./empresa-ouro/gerente";
import { operadoraMockups } from "./empresa-ouro/operadora";
import { parceiraMockups } from "./empresa-ouro/parceira";
import { parceiroMockups } from "./empresa-ouro/parceiro";
import { secretariaMockups } from "./empresa-ouro/secretaria";
import { secretarioMockups } from "./empresa-ouro/secretario";
import { sociaMockups } from "./empresa-ouro/socia";
import { socioMockups } from "./empresa-ouro/socio";
import { vendedorMockups } from "./empresa-ouro/vendedor";
import { vendedoraMockups } from "./empresa-ouro/vendedora";
import { coordenadoraMockups } from "./empresa-ouro/coordenadora";
import { operadorMockups } from "./empresa-ouro/operador";
import { piramideMockups } from "./piramides/piramides";
import { maeMockups } from "./familia-ouro/mae";
import { madrastaMockups } from "./familia-ouro/madrasta";
import { madrinhaMockups } from "./familia-ouro/madrinha";
import { netaMockups } from "./familia-ouro/neta";
import { netoMockups } from "./familia-ouro/neto";
import { noraMockups } from "./familia-ouro/nora";
import { padrastoMockups } from "./familia-ouro/padrasto";
import { padrinhoMockups } from "./familia-ouro/padrinho";
import { paiMockups } from "./familia-ouro/pai";
import { sobrinhaMockups } from "./familia-ouro/sobrinha";
import { sobrinhoMockups } from "./familia-ouro/sobrinho";
import { sograMockups } from "./familia-ouro/sogra";
import { sogroMockups } from "./familia-ouro/sogro";
import { tiaMockups } from "./familia-ouro/tia";
import { tioMockups } from "./familia-ouro/tio";
import { afilhadaMockups } from "./familia-ouro/afilhada";
import { afilhadoMockups } from "./familia-ouro/afilhado";
import { avoMockups } from "./familia-ouro/avo";
import { avohMockups } from "./familia-ouro/avoh";
import { cunhadaMockups } from "./familia-ouro/cunhada";
import { cunhadoMockups } from "./familia-ouro/cunhado";
import { filhaMockups } from "./familia-ouro/filha";
import { filhoMockups } from "./familia-ouro/filho";
import { genroMockups } from "./familia-ouro/genro";
import { irmaMockups } from "./familia-ouro/irma";
import { irmaoMockups } from "./familia-ouro/irmao";
import { argentinaMockups } from "./goldenCup/argentina";
import { alemanhaMockups } from "./goldenCup/alemanha";
import { mexicoMockups } from "./goldenCup/mexico";
import { espanhaMockups } from "./goldenCup/espanha";
import { euaMockups } from "./goldenCup/eua";
import { francaMockups } from "./goldenCup/franca";
import { catarMockups } from "./goldenCup/catar";
import { primaMockups } from "./familia-ouro/prima";
import { primoMockups } from "./familia-ouro/primo";
import { esposaMockups } from "./familia-ouro/esposa";
import { esposoMockups } from "./familia-ouro/esposo";
import { safiraMockups } from "./pedrasPreciosas/safira";
import { esmeraldaMockups } from "./pedrasPreciosas/esmeralda";
import { diamanteMockups } from "./pedrasPreciosas/diamante";
import { ametistaMockups } from "./pedrasPreciosas/ametista";
import { rubiMockups } from "./pedrasPreciosas/rubi";
import { adventistaMockups } from "./religiao/adventista";
import { bahaiMockups } from "./religiao/bahai";
import { budistaMockups } from "./religiao/budista";
import { catolicaMockups } from "./religiao/catolica";
import { cristaMockups } from "./religiao/crista";
import { espiritaMockups } from "./religiao/espirita";
import { evangelicaMockups } from "./religiao/evangelica";
import { hinduMockups } from "./religiao/hindu";
import { islamismoMockups } from "./religiao/islamismo";
import { jeovaMockups } from "./religiao/jeova";
import { judaicaMockups } from "./religiao/judaica";
import { mormonMockups } from "./religiao/mormon";
import { atenasMockups } from "./mundo/atenas";
import { capadociaMockups } from "./mundo/capadocia";
import { croaciaMockups } from "./mundo/croacia";
import { dubaiMockups } from "./mundo/dubai";
import { londresMockups } from "./mundo/londres";
import { madriMockups } from "./mundo/madri";
import { newYorkMockups } from "./mundo/newYork";
import { parisMockups } from "./mundo/paris";
import { rioJaneiroMundoMockups } from "./mundo/rioJaneiro";
import { sydneyMockups } from "./mundo/sydney";
import { venezaMockups } from "./mundo/veneza";
import { cairoMockups } from "./mundo/cairo";
import { yamanashiMockups } from "./mundo/yamanshi";
import { sorteMockups } from "./sentimentos/boaSorte";
import { desculpaMockups } from "./sentimentos/desculpas";
import { lembreiVoceMockups } from "./sentimentos/lembreiVoce";
import { melhorasMockups } from "./sentimentos/melhoras";
import { ObrigadoMockups } from "./sentimentos/obrigado";
import { sshdMockups } from "./sentimentos/sshd";
import { novaDeliMockups } from "./mundo/novaDeli";
import { teamoMockups } from "./sentimentos/teAmo";
import { enteadaMockups } from "./familia-ouro/enteada";
import { enteadoMockups } from "./familia-ouro/enteado";
import { moscowMockups } from "./mundo/moscow";
import { aquarioMockups } from "./signos/aquario";
import { ariesMockups } from "./signos/aries";
import { cancerMockups } from "./signos/cancer";
import { capricornioMockups } from "./signos/capricornio";
import { escorpiaoMockups } from "./signos/escorpiao";
import { gemeosMockups } from "./signos/gemeos";
import { leaoMockups } from "./signos/leao";
import { libraMockups } from "./signos/libra";
import { peixesMockups } from "./signos/peixes";
import { sagitarioMockups } from "./signos/sagitario";
import { touroMockups } from "./signos/touro";
import { virgemMockups } from "./signos/virgem";
import { advogadaMockups } from "./profissoes/advogada";
import { advogadoMockups } from "./profissoes/advogado";
import { arquitetaMockups } from "./profissoes/arquiteta";
import { arquitetoMockups } from "./profissoes/arquiteto";
import { artistaMockups } from "./profissoes/artista";
import { atorMockups } from "./profissoes/ator";
import { atrizMockups } from "./profissoes/atriz";
import { dentistaMockups } from "./profissoes/dentista";
import { economistaMockups } from "./profissoes/economista";
import { enfermeiraMockups } from "./profissoes/enfermeira";
import { enfermeiroMockups } from "./profissoes/enfermeiro";
import { engenheiraMockups } from "./profissoes/engenheira";
import { engenheiroMockups } from "./profissoes/engenheiro";
import { farmaceuticaMockups } from "./profissoes/farmaceutica";
import { farmaceuticoMockups } from "./profissoes/farmaceutico";
import { fotografaMockups } from "./profissoes/fotografa";
import { fotografoMockups } from "./profissoes/fotografo";
import { medicaMockups } from "./profissoes/medica";
import { medicoMockups } from "./profissoes/medico";
import { nutricionistaMockups } from "./profissoes/nutricionista";
import { professorMockups } from "./profissoes/professor";
import { psicologaMockups } from "./profissoes/psicologa";
import { psicologoMockups } from "./profissoes/psicologo";
import { publicitariaMockups } from "./profissoes/publicitaria";
import { publicitarioMockups } from "./profissoes/publicitario";
import { empresariaMockups } from "./profissoes/empresaria";
import { professoraMockups } from "./profissoes/professora";
import { empresarioMockups } from "./profissoes/empresario";
import { boiMockups } from "./boi/boi";
import { BlackMockups, BlueMockups, GoldMockups, GreenMockups, PlatinumMockups, hiperMockups, infintyMockups, investimentoAllMockups, megaMockups, oncaTroyMockups, superMockups, ultraMockups } from "./investimento/investimento";
import { amigaMockups, amigoMockups, aniversarioMockups, babyMeninaMockups, babyMeninoMockups, bemvindaMockups, bemvindoMockups, brasilMockups, casamentoMockups, formandosMockups, homemMockups, loveWinsMockups, mazalMockups, mulherMockups, namoradaMockups, namoradoMockups, natalMockups, noivaMockups, noivadoMockups, noivoMockups } from "./datasComemorativas/datasComemorativas";


export const mockups = [
  amigaMockups,
  amigoMockups,
  aniversarioMockups,
  babyMeninaMockups,
  babyMeninoMockups,
  brasilMockups,
  bemvindaMockups,
  bemvindoMockups,
  casamentoMockups,
  formandosMockups,
  loveWinsMockups,
  mazalMockups,
  mulherMockups,
  homemMockups,
  namoradaMockups,
  namoradoMockups,
  natalMockups,
  noivadoMockups,
  noivoMockups,
  noivaMockups,
  araraAzulMockups,
  araraVermelhaMockups,
  cascavelMockups,
  corujaMockups,
  jacareMockups,
  micoMockups,
  oncaPintadaMockups,
  papagaioMockups,
  preguicaMockups,
  tartarugaMockups,
  tucanoMockups,
  planteMockups,
  chinchenMockups,
  cristoMockups,
  chinaMockups,
  coliseuMockups,
  mahalMockups,
  machuMockups,
  petraMockups,
  belemMockups,
  beloHorizonteMockups,
  brasiliaMockups,
  curitibaMockups,
  fortalezaMockups,
  goianiaMockups,
  manausMockups,
  portoMockups,
  recifeMockups,
  rioJaneiroMockups,
  salvadorMockups,
  SaoPauloMockups,
  primaveraMockups,
  invernoMockups,
  outonoMockups,
  veraoMockups,
  boasFestasMockups,
  clienteMockups,
  colaboradorMockups,
  colaboradoraMockups,
  consultorMockups,
  consultoraMockups,
  coordenadorMockups,
  destaqueAnoMockups,
  destaqueMesMockups,
  diretorMockups,
  diretoraMockups,
  gerenteMockups,
  operadoraMockups,
  parceiraMockups,
  parceiroMockups,
  secretariaMockups,
  secretarioMockups,
  sociaMockups,
  socioMockups,
  vendedorMockups,
  vendedoraMockups,
  coordenadoraMockups,
  operadorMockups,
  piramideMockups,
  maeMockups,
  afilhadaMockups,
  afilhadoMockups,
  avoMockups,
  avohMockups,
  cunhadaMockups,
  cunhadoMockups,
  filhaMockups,
  filhoMockups,
  genroMockups,
  irmaMockups,
  irmaoMockups,

  madrastaMockups,
  madrinhaMockups,
  netaMockups,
  netoMockups,
  noraMockups,
  padrastoMockups,
  padrinhoMockups,
  paiMockups,
  sobrinhaMockups,
  sobrinhoMockups,
  sograMockups,
  sogroMockups,
  tiaMockups,
  tioMockups,
  argentinaMockups,
  alemanhaMockups,
  brasilMockups,
  mexicoMockups,
  espanhaMockups,
  euaMockups,
  francaMockups,
  euaMockups,
  catarMockups,
  primaMockups,
  primoMockups,
  esposaMockups,
  esposoMockups,
  safiraMockups,
  esmeraldaMockups,
  diamanteMockups,
  ametistaMockups,
  rubiMockups,
  adventistaMockups,
  bahaiMockups,
  budistaMockups,
  catolicaMockups,
  cristaMockups,
  espiritaMockups,
  evangelicaMockups,
  hinduMockups,
  islamismoMockups,
  jeovaMockups,
  judaicaMockups,
  mormonMockups,
  atenasMockups,
  capadociaMockups,
  croaciaMockups,
  dubaiMockups,
  londresMockups,
  madriMockups,
  newYorkMockups,
  parisMockups,
  rioJaneiroMundoMockups,
  sydneyMockups,
  venezaMockups,
  yamanashiMockups,
  cairoMockups,
  sorteMockups,
  desculpaMockups,
  lembreiVoceMockups,
  melhorasMockups,
  ObrigadoMockups,
  sshdMockups,
  novaDeliMockups,
  teamoMockups,
  enteadaMockups,
  enteadoMockups,
  moscowMockups,
  aquarioMockups,
  ariesMockups,
  cancerMockups,
  capricornioMockups,
  escorpiaoMockups,
  gemeosMockups,
  leaoMockups,
  libraMockups,
  peixesMockups,
  sagitarioMockups,
  touroMockups,
  virgemMockups,
  advogadaMockups,
  advogadoMockups,
  arquitetaMockups,
  arquitetoMockups,
  artistaMockups,
  atorMockups,
  atrizMockups,
  dentistaMockups,
  economistaMockups,
  empresariaMockups,
  enfermeiraMockups,
  enfermeiroMockups,
  engenheiraMockups,
  engenheiroMockups,
  farmaceuticaMockups,
  farmaceuticoMockups,
  fotografaMockups,
  fotografoMockups,
  medicaMockups,
  medicoMockups,
  nutricionistaMockups,
  professorMockups,
  professoraMockups,
  psicologaMockups,
  psicologoMockups,
  publicitariaMockups,
  publicitarioMockups,
  empresarioMockups,
  boiMockups,
  investimentoAllMockups,
  BlueMockups,
  GreenMockups,
  GoldMockups,
  PlatinumMockups,
  BlackMockups,
  oncaTroyMockups,
  infintyMockups,
  superMockups,
  hiperMockups,
  megaMockups,
  ultraMockups
];
