import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/ouroninas.css";
import ssl from "../assets/ssl-min.png";
import safeBrowsing from "../assets/safe-browser.png";
import "../components/homepage/ofert/ofert.css";
import marca from "../assets/marca-comercial.png";
import {
  Abracam,
  Amagold,
  Anbima,
  BancoCentral,
  Cvm,
  Gptw,
  Ibf,
  Iso14001,
  Iso45001,
  Iso9001,
  PlanteArvore,
  Sgi,
  AnbimaCPA10,
  ABT1,
  ABT2,
  Awtra,
} from "./footer/selos/selos";
import FiquePorDentro from "./footer/fiquePorDentro/fiquePorDentro";
import styled from "styled-components";

export default function Footer() {
  return (
    <section className="footer-sec">
      <Container>
        <FiquePorDentro />
      </Container>
      <div className="divider"></div>
      <Container>
        <Row className="footer-row-l">
          <Col xs={12} sm={12} md={12} className="footer2 p-0">
            <p className="m-0 pFooter">
              Instituição Autorizada, Credenciada e Certificada
            </p>
            <div className="d-flex pb-2 flex-wrap divFooter2">
              {/* <Anab /> */}
              <BancoCentral />
              <Cvm />
              <Iso9001 />
              <Iso14001 />
              <Iso45001 />
              <Sgi />
              <PlanteArvore />
              <Ibf />
              <AnbimaCPA10 />
              <Anbima />
              <Gptw />
              <Amagold />
              <Abracam />
              <ABT1 />
              <ABT2 />
              <Awtra />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className="footer2 p-0 mb-4">
            <p className="m-0 pFooter mt-3 mb-4">Segurança</p>
            <div className="divF2">
              <img src={ssl} alt="Selo SSL" height="45px" />
              <img
                src={marca}
                alt="Selo de Marca Registrada"
                style={{ width: "38px", height: "45px" }}
              />
              <a  target="_blank" rel="noopener noreferrer" href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fourominas.com%2F&hl=pt-PT">

              <img src={safeBrowsing} alt="Selo do Google Safe Browsing" />
              </a>

            </div>
          </Col>
        </Row>
      </Container>
      <Container className="p-0">
        <Info>
        RAZÃO SOCIAL: OM DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.
           CNPJ:
          11.495.073/0001-18
        </Info>
      </Container>
    </section>
  );
}

const Info = styled.div`

font-family: "Poppins", sans-serif;
  padding: 0 !important;
  font-size: 10px;
  text-align: left;
`;
