import capricornioFrente1g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-1g-frente.png";
import capricornioFrente2g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-2g-frente.png";
import capricornioFrente5g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-5g-frente.png";
import capricornioFrente10g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-10g-frente.png";
import capricornioFrente25g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-25g-frente.png";
import capricornioFrente31g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-31g-frente.png";
import capricornioFrente50g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-50g-frente.png";
import capricornioVerso1g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-1g-verso.png";
import capricornioVerso2g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-2g-verso.png";
import capricornioVerso5g from "../../../assets/newImages/mockups/signos/capricornio/capricornio-ouro-5g-verso.png";

export const  capricornioMockups = {
    nome: "Capricórnio",
    frente1g: capricornioFrente1g,
  
    frente2g: capricornioFrente2g,
  
    frente5g: capricornioFrente5g, 
    frente10g: capricornioFrente10g,
    frente25g:capricornioFrente25g,
    frente31g: capricornioFrente31g,
    frente50g: capricornioFrente50g, 
  
    verso1g: capricornioVerso1g,
    verso2g: capricornioVerso2g,
  
    verso5g: capricornioVerso5g,
    verso10g: capricornioVerso5g,
    verso25g: capricornioVerso5g,
    verso31g: capricornioVerso5g,
    verso50g: capricornioVerso5g,
  };