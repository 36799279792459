import veraoFrente1g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-1g-frente.png";
import veraoFrente2g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-2g-frente.png";
import veraoFrente5g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-5g-frente.png";
import veraoVerso1g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-1g-verso.png";
import veraoVerso2g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-2g-verso.png";
import veraoVerso5g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-5g-verso.png";
import veraoVerso10g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-10g-verso.png";
import veraoVerso25g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-25g-verso.png";
import veraoVerso31g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-31g-verso.png";

import veraoVerso50g from "../../../assets/newImages/mockups/estacoes-ano/verao/verao-50g-verso.png";


import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g-verao.png";
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g-verao.png";
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g-verao.png";
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g-verao.png";
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g-verao.png";

export const  veraoMockups = {
    nome: "Verão",
    frente1g: veraoFrente1g,
  
    frente2g: veraoFrente2g,
  
    frente5g: veraoFrente5g,  
  
    verso1g: veraoVerso1g,
    verso2g: veraoVerso2g,
  
    verso5g: veraoVerso5g,
    verso10g: veraoVerso10g,
    verso25g: veraoVerso25g,
    verso31g: veraoVerso31g,
    verso50g: veraoVerso50g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g
  };

  export const verao = [
    {
      name: "Verão",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]