import enfermeiraFrente1g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-1g-frente.png";
import enfermeiraFrente2g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-2g-frente.png";
import enfermeiraFrente5g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-5g-frente.png";
import enfermeiraFrente10g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-10g-frente.png";
import enfermeiraFrente25g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-25g-frente.png";
import enfermeiraFrente31g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-31g-frente.png";
import enfermeiraFrente50g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-50g-frente.png";
import enfermeiraVerso1g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-1g-verso.png";
import enfermeiraVerso2g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-2g-verso.png";
import enfermeiraVerso5g from "../../../assets/newImages/mockups/profissoes/enfermeira/enfermeira-ouro-5g-verso.png";

export const  enfermeiraMockups = {
    nome: "Enfermeira de Ouro",
    frente1g: enfermeiraFrente1g,
  
    frente2g: enfermeiraFrente2g,
  
    frente5g: enfermeiraFrente5g,
  
    frente10g: enfermeiraFrente10g,
  
    frente25g: enfermeiraFrente25g,
  
    frente31g: enfermeiraFrente31g,
  
    frente50g: enfermeiraFrente50g,
  
    verso1g: enfermeiraVerso1g,
    verso2g: enfermeiraVerso2g,
  
    verso5g: enfermeiraVerso5g,
    verso10g: enfermeiraVerso5g,
    verso25g: enfermeiraVerso5g,
    verso31g: enfermeiraVerso5g,
    verso50g: enfermeiraVerso5g,
  };

 