import { Store } from "react-notifications-component";

export function AlertNotify(props: {message: string, type?: NOTIFICATION_TYPE}){
  try {
    Store.removeAllNotifications();
    return Store.addNotification({
        title: "Aviso!",
        message: props.message,
        type: props?.type || 'success',
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        dismiss: {
          duration: 20000,
          onScreen: true,
          showIcon: true
        }
      });
  } catch (error) {
    console.log(error)
  }

}
declare type NOTIFICATION_TYPE = 'success' | 'danger' | 'info' | 'default' | 'warning';

