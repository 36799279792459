import atorFrente1g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-1g-frente.png";
import atorFrente2g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-2g-frente.png";
import atorFrente5g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-5g-frente.png";
import atorFrente10g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-10g-frente.png";
import atorFrente25g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-25g-frente.png";
import atorFrente31g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-31g-frente.png";
import atorFrente50g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-50g-frente.png";
import atorVerso1g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-1g-verso.png";
import atorVerso2g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-2g-verso.png";
import atorVerso5g from "../../../assets/newImages/mockups/profissoes/ator/ator-ouro-5g-verso.png";

export const  atorMockups = {
    nome: "Ator de Ouro",
    frente1g: atorFrente1g,
  
    frente2g: atorFrente2g,
  
    frente5g: atorFrente5g,
  
    frente10g: atorFrente10g,
  
    frente25g: atorFrente25g,
  
    frente31g: atorFrente31g,
  
    frente50g: atorFrente50g,
  
    verso1g: atorVerso1g,
    verso2g: atorVerso2g,
  
    verso5g: atorVerso5g,
    verso10g: atorVerso5g,
    verso25g: atorVerso5g,
    verso31g: atorVerso5g,
    verso50g: atorVerso5g,
  };

 