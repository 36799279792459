import React from "react";
import "../../pages.css";
import img from "../../../assets/newImages/autorizado.png";
import linha from "../../../assets/linha.png";
import "./sejaAutorizado.css";

export default function SejaAutorizado() {
  return (
    <article className="art-s mb-5 p-text" id="autorizado">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              SEJA UM AUTORIZADO
            </p>
            <p>
              Faça parte da rede credenciada da Ourominas (OM) e represente a
              marca que é sinônimo de qualidade em todos os segmentos em que
              atua, um parceiro autorizado OM é respeitado nacionalmente.
            </p>
            <p>
              O objetivo é que os parceiros se sintam seguros para prestar um
              atendimento igual ao feito dentro da OM. Para isso, a meta da
              empresa é dar autonomia, mas sem deixar os parceiros sem um porto
              seguro. A ideia é que os parceiros sejam um ponto avançado da OM e
              tenham condições de realizar relacionamento, cuidar do suporte
              técnico, lidar com toda a documentação e efetuar o fechamento do
              melhor negócio para os clientes.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section className="mt-4 sect">
          <h4 className="mb-4">
            <b>Principais diferenciais da parceria:</b>
          </h4>

          <section className="  items">
            <p className="m-0">
              <b>1. Participação nos resultados das operações: </b>
            </p>
            <p className="m-0">
              Os parceiros acabam sendo sócios em cada transação, a divisão de
              lucro é atrativa e diferenciada.{" "}
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>2. Portfólio diverso:</b>
            </p>
            <p className="m-0">
              A OM possui um leque completo de produtos e serviços.
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>3. Atuação nacional:</b>
            </p>
            <p className="m-0">
              Os parceiros da OM têm liberdade para atender clientes de qualquer
              região do País.{" "}
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>4. Referência no setor:</b>
            </p>
            <p className="m-0">
              Fazer transações com moedas estrangeiras e ativo financeiro exige
              alto grau de conhecimento. Por isso, contar com uma equipe com
              anos de experiência no mercado de ouro e câmbio é fundamental para
              garantir agilidade e assertividade nas operações.
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>5. Qualificação e treinamentos:</b>
            </p>
            <p className="m-0">
              A OM oferece ferramentas para desenvolvimento dos parceiros. Além
              dos treinamentos, há palestras, conteúdos e frentes que estimulam
              que o parceiro garanta mais autonomia e retorno para as operações.{" "}
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>6. Núcleo de Especialistas:</b>
            </p>
            <p className="m-0">
              A OM oferece atendimento aos parceiros com profissionais de alto
              nível.{" "}
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>7. Logística Própria:</b>
            </p>
            <p className="m-0">
              Mesmo possuindo um custo mais elevado, a OM preza pela segurança e
              eficiência nas entregas.
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>8. Tecnologia Operacional:</b>
            </p>
            <p>Sistema e plataforma de negócio simples de operar.</p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>9. Representante Oficial:</b>
            </p>
            <p className="m-0">
              A OM é parceira e representante oficial de grandes marcas mundiais
              como a MoneyGram, More Payment, MySim Travel, GTA e a DHL.{" "}
            </p>
          </section>

          <section className="  items">
            <p className="m-0">
              <b>10. Marketing:</b>
            </p>
            <p className="m-0">
              Além do suporte do departamento, o parceiro tem acesso a um portal
              com todos os materiais necessários para divulgação on e off line,
              e o SharePoint, a Intranet da OM, com conteúdos institucionais
              importantes, como por exemplo os treinamentos.{" "}
            </p>
          </section>
        </section>
      </section>
    </article>
  );
}
