import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { ReturnName } from "../../utils/coins";
import CountryFlags from "../../utils/countryFlags";
import spin from "../../assets/spinner.gif";
import "./vitrine.css";
import logo from "../../assets/logo-large.png";
import {
  getCartaoPrePagoGuarulhosLojas,
  getMoedasEstrangeiraGuarulhos,
  getOuroLojaGuarulhos,
} from "../../services/cotacoes";
import { Carousel } from "react-bootstrap";

export default function VitrineGuarulhosTeste() {
  const [filteredCoins, setFilteredCoins] = useState([]);
  const [dolar, setDolar] = useState([]);
  const [moneygram, setMoneygram] = useState([]);
  const [gold, setGold] = useState();
  const [filteredCoinsCards, setFilteredCoinsCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCoins();
      await fetchGold();
      await fetchCoinCard();
    };

    // Chamada inicial das funções de busca
    setIsLoading(true);
    fetchData().finally(() => setIsLoading(false));

    // Inicia o intervalo depois da chamada inicial
    const intervalId = setInterval(fetchData, 60000);

    // Limpa o int ervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  function fetchCoins() {
    getMoedasEstrangeiraGuarulhos()
      .then((resp) => {
        const filter = resp.filter((coin) => {
          return !coin.codIso.includes("MNG");
        });
        const filterDolar = resp.filter((coin) => {
          return coin.codIso.includes("EUSCOM");
        });
        const filterMOneygram = resp.filter((coin) => {
          return coin.codIso.includes("MNG");
        });
        setFilteredCoins(filter);
        setDolar(filterDolar);
        setMoneygram(filterMOneygram);
      })
      .catch((e) => console.log(e));
  }

  function fetchGold() {
    getOuroLojaGuarulhos()
      .then((resp) => {
        let filter = resp.filter((item) =>
          item.codIso.includes("OURO BARRA (1g)")
        );
        setGold(filter[0]);
      })
      .catch((e) => console.log(e));
  }

  function fetchCoinCard() {
    getCartaoPrePagoGuarulhosLojas()
      .then((resp) => {
        let filter = resp.sort((a, b) => {
          return a["nomeMoeda"] < b["nomeMoeda"] ? -1 : 1;
        });
        setFilteredCoinsCards(filter);
      })
      .catch((e) => console.log(e));
  }

  return (
    <Container>
      <Carousel
        controls={true}
        indicators={true}
        className="carousel-vitrine"
        pause={false}
      >
        <Carousel.Item interval={10000}>
          <div>
            <BoxLogo>
              <img src={logo} alt="" />
            </BoxLogo>
            <Title>COTAÇÃO AGORA / QUOTE NOW</Title>
            {isLoading && (
              <div className="col-12 d-flex justify-content-center ">
                <Spin alt="imagem de load" src={spin} />
              </div>
            )}

            {dolar.length > 0 && (
              <Box>
                <table className="table table-hover table-bordered cTable mb-2">
                  <thead>
                    <tr>
                      <th scope="col">COMERCIAL / MERCHANT</th>
                      <th scope="col" className="th">
                        Compra / Buy
                      </th>
                      <th scope="col" className="th">
                        Venda / Sell
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dolar?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <CountryFlags cod={item?.codIso} />
                            {ReturnName(item?.codIso)} {" - "}
                            {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                          </td>
                          <td>
                            {" "}
                            {parseFloat(item?.ovd).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </td>
                          <td>
                            {parseFloat(item?.ocp).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            )}

            {filteredCoins.length > 0 && (
              <>
                <Box>
                  <table className="table table-hover table-bordered cTable mb-2">
                    <thead>
                      <tr>
                        <th scope="col">TURISMO / TOURISM</th>
                        <th scope="col" className="th">
                          Você Compra / You Buy
                        </th>
                        <th scope="col" className="th">
                          Você Vende / You Sell
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCoins?.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <CountryFlags cod={item?.codIso} />
                              {ReturnName(item?.codIso)} {" - "}
                              {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                            </td>
                            <td>
                              {" "}
                              {parseFloat(item?.calculoIOFVenda).toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                  minimumFractionDigits: 4,
                                  maximumFractionDigits: 4,
                                }
                              )}
                            </td>
                            <td>
                              {parseFloat(
                                item?.calculoIOFCompra
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
                <Legend>Cotações com IOF incluso.</Legend>
              </>
            )}

            {filteredCoinsCards.length > 0 && (
              <>
                <Box>
                  <table className="table table-hover table-bordered cTable mb-2">
                    <thead>
                      <tr>
                        <th scope="col">CARTÃO PRÉ PAGO / PREPAID CARD</th>
                        <th scope="col" className="th">
                          Você Carrega / You Carry
                        </th>
                        <th scope="col" className="th">
                          Você Vende / You Sell
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCoinsCards?.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <CountryFlags cod={item?.codIso} />
                              {ReturnName(item?.codIso)} {" - "}
                              {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                            </td>
                            <td>
                              {" "}
                              {parseFloat(item?.calculoIOFVenda).toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                  minimumFractionDigits: 4,
                                  maximumFractionDigits: 4,
                                }
                              )}
                            </td>
                            <td>
                              {parseFloat(
                                item?.calculoIOFCompra
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
                <Legend>Cotações com IOF incluso.</Legend>
              </>
            )}

            {moneygram.length > 0 && (
              <Box>
                <table className="table table-hover table-bordered cTable mb-2">
                  <thead>
                    <tr>
                      <th scope="col">MONEYGRAM</th>
                      <th scope="col" className="th">
                        Recebimento / Receivement
                      </th>
                      <th scope="col" className="th">
                        Envio / Sent
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {moneygram?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <CountryFlags cod="MONEYGRAM" />
                            MONEYGRAM
                          </td>
                          <td>
                            {" "}
                            {parseFloat(item?.calculoIOFCompra).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              }
                            )}
                          </td>
                          <td>
                            {parseFloat(item?.calculoIOFVenda).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              }
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <Legend>Cotações com IOF incluso.</Legend>
              </Box>
            )}

            {gold && (
              <Box>
                <table className="table table-hover table-bordered cTable">
                  <thead>
                    <tr>
                      <th scope="col">
                        Barra de Ouro - Gramatura / Gold Bar - Grammage
                      </th>
                      <th scope="col" className="th">
                        Você Compra / You Buy
                      </th>
                      <th scope="col" className="th">
                        Você Vende / You Sell
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <CountryFlags cod="OURO" />
                        Cotação da Grama do Ouro 999 Mercantil / Gold Bar
                        Quotation 999 Mercantil
                      </td>
                      <td>
                        {parseFloat(gold?.calculoVenda).toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          }
                        )}
                      </td>
                      <td>
                        {parseFloat(gold?.calculoCompra).toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          }
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            )}
          </div>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <BoxVideo>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=sgitVvfr1JQ&vq=hd1080&cc_load_policy=0"
              playing
              loop
              controls
              muted
              width="100vw"
              height="100vh"
            />
          </BoxVideo>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  width: 80vw;
  height: 80vh;
`;

const Title = styled.h2`
  color: #004032;
  text-align: center;
  margin-bottom: 20px;
`;

const Box = styled.div`
  background-color: #f7f7f7;
`;

const Spin = styled.img`
  width: 20px;
  margin: 5px 0 !important;
`;

const BoxLogo = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 10px 0;
  && > img {
    width: 140px;
  }
`;

const Legend = styled.p`
  text-align: start;
  color: #010101;
  font-size: 12px;
`;

const BoxVideo = styled.div`
  display: flex;
  justify-content: center;

`;
