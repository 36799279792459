import farmaceuticaFrente1g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-1g-frente.png";
import farmaceuticaFrente2g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-2g-frente.png";
import farmaceuticaFrente5g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-5g-frente.png";
import farmaceuticaFrente10g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-10g-frente.png";
import farmaceuticaFrente25g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-25g-frente.png";
import farmaceuticaFrente31g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-31g-frente.png";
import farmaceuticaFrente50g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-50g-frente.png";
import farmaceuticaVerso1g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-1g-verso.png";
import farmaceuticaVerso2g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-2g-verso.png";
import farmaceuticaVerso5g from "../../../assets/newImages/mockups/profissoes/farmaceutica/farmaceutica-ouro-5g-verso.png";

export const  farmaceuticaMockups = {
    nome: "Farmacêutica de Ouro",
    frente1g: farmaceuticaFrente1g,
  
    frente2g: farmaceuticaFrente2g,
  
    frente5g: farmaceuticaFrente5g,
  
    frente10g: farmaceuticaFrente10g,
  
    frente25g: farmaceuticaFrente25g,
  
    frente31g: farmaceuticaFrente31g,
  
    frente50g: farmaceuticaFrente50g,
  
    verso1g: farmaceuticaVerso1g,
    verso2g: farmaceuticaVerso2g,
  
    verso5g: farmaceuticaVerso5g,
    verso10g: farmaceuticaVerso5g,
    verso25g: farmaceuticaVerso5g,
    verso31g: farmaceuticaVerso5g,
    verso50g: farmaceuticaVerso5g,
  };

 