import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import GoldSimulator from "../../components/section/gold/simulator/simulator";
import { OuroContext } from "../../components/context/cotacoes/cotacoesService";
import GoldCartSimulator from "../../components/section/gold/simulator/simulatorCartao";

export default function PageSimulatorOuro() {
  const { productsOuro, addOuro, error } = useContext(OuroContext);
  const { name } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    setInterval(() => {
      addOuro();
    }, 100000);
  }, []);


  useEffect(() => {


  async function filter() {
    window.scrollTo(0, 0);
    if (productsOuro.length === 0) {
      addOuro();
      return;
    }
    switch (name) {
      case "1g-2g":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)")
          )
        );
        break;
      case "5g-10kg":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );
        break;
      case "100g-1kg":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (100g)") ||
              item.codIso.includes("OURO BARRA (250g)") ||
              item.codIso.includes("OURO BARRA (500g)") ||
              item.codIso.includes("OURO BARRA (1000g)")
          )
        );
        break;
      case "lamina-ouro":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (7g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );
        break;
      case "piramide-ouro":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (50g)") ||
              item.codIso.includes("OURO BARRA (100g)") ||
              item.codIso.includes("OURO BARRA (250g)")
          )
        );
        break;
      case "existentes":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (7g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)") ||
              item.codIso.includes("OURO BARRA (100g)") ||
              item.codIso.includes("OURO BARRA (250g)") ||
              item.codIso.includes("OURO BARRA (500g)")||
              item.codIso.includes("OURO BARRA (1000g)")
          )
        );
        break;
      case "plante-arvore":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );
        break;
      case "pai":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );
        break;
      case "namorados":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );
        break;

      case "1g-1kg":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)") ||
              item.codIso.includes("OURO BARRA (50g)") ||
              item.codIso.includes("OURO BARRA (100g)") ||
              item.codIso.includes("OURO BARRA (250g)") ||
              item.codIso.includes("OURO BARRA (500g)") ||
              item.codIso.includes("OURO BARRA (1000g)")
          )
        );
        break;
      case "natal":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );

        break;

        case "mulher":
        setData(
          productsOuro.filter(
            (item) => item.codIso.includes("OURO BARRA (1g)") ||
              item.codIso.includes("OURO BARRA (2g)") ||
              item.codIso.includes("OURO BARRA (5g)") ||
              item.codIso.includes("OURO BARRA (10g)") ||
              item.codIso.includes("OURO BARRA (25g)") ||
              item.codIso.includes("OURO BARRA (31,104g)") ||
              item.codIso.includes("OURO BARRA (50g)")
          )
        );

        break;

      default:
        break;
    }
  }

    filter();
  }, [productsOuro, name]);

  return (
    <Container>
      {/* <h1 className="display-4 mt-5 text-center">{title}</h1> */}
      {name === "existentes" ? (
        <GoldCartSimulator data={data} error={error} />
      ) : (
        <GoldSimulator data={data} error={error} />
      )}
    </Container>
  );
}
const Container = styled.section`
  width: 100%;
  border: 1px solid #e9ecef;
  background-color: #f8f9fa;

  @media (min-width: 320px) {
    padding: 0;
  }

  @media (min-width: 1060px) {
    padding: 40px 1vw;
  }
  @media (min-width: 1260px) {
    padding: 40px 5vw;
  }
  @media (min-width: 1700px) {
    padding: 40px 10vw;
  }
`;
