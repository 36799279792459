import React, { useState } from "react";
import "./cadastro.css";
import { useNavigate } from "react-router-dom";
import { isCPF, maskCPF } from "../../../utils/regex";
import { PageLoading } from "../../../components/Loading/Loading";
import { getClienteByCPF } from "../../../services/modules/cliente/clienteService";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import politica from "../../../assets/docs/politica-privacidade-lgpd.pdf"
export default function Cadastro() {
  const navigateTo = useNavigate();
  const [cpf, setCpf] = useState("");
  const [errCPF, setErrCPF] = useState("");
  const [load, setLoad] = useState(false);

  const navigate = (value) =>{
    window.scrollTo(0,0);
    navigateTo(value);
  }

  const handleCadastro = (e) => {
    e.preventDefault();
    validarCPF();
  };

  const validarCPF = () => {
    setErrCPF("");

    const cpfNumerico = cpf.replace(/\D/g, "");

    if (cpfNumerico.length !== 11) {
      setErrCPF("O CPF deve conter 11 dígitos!");
    }else if(!isCPF(cpf)){
      setErrCPF("CPF inválido!");
    } else {
      setLoad(true);
      getClienteByCPF(cpfNumerico)
        .then((resp) => {
          if(resp){
            AlertNotify({message: 'Cliente já cadastrado, faça login para continuar!',type: 'warning' })
          }else {
            navigate(`criar-conta/${cpfNumerico}`);
            window.scrollTo(0,0)
          }
        })
        .catch((e) => {
          if(e.response.request.status === 400){
            navigate(`criar-conta/${cpfNumerico}`);
          }
        })
        .finally(() => setLoad(false));
    }
  };

  return (
    <>
      <div className="cadastro">
        {load && <PageLoading />}
        <h2>Cadastre-se</h2>
        <p>Faça seu cadastro e facilite suas compras futuras</p>
        <form onSubmit={handleCadastro}>
          <p className="formulario">CPF</p>
          <input
            className="inputs"
            type="text"
            value={cpf}
            placeholder="CPF"
            onChange={(e) => setCpf(maskCPF(e.target.value))}
          />
          <span className="text-danger">{errCPF}</span>
          <div className="botaoLog">
            <button type="submit" className="botaoCadastro">
              Criar minha conta
            </button>
          </div>
        </form>
        <span className="politicaPrivacidade">
          Ao clicar em "Criar Conta", você concorda com a nossa
          <a className="politica" target="_blank" rel="noopener noreferrer" href={politica}>
           Política de
          Privacidade e Política de Segurança
          </a>
        </span>
      </div>
    </>
  );
}
