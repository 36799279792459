/** @format */

import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default function FormButton(props) {
  const { isRequesting, children, ...htmlProps } = props;

  return (
    <Button
      variant="success"
      type="submit"
      disabled={props.isRequesting}
      {...htmlProps}
    >
      {isRequesting && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ marginRight: "10px" }}
        />
      )}
      {children}
    </Button>
  );
}
