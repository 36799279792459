import { getLimiteOperacaoByMoeda } from '../modules/cliente/moedasService';

const { default: Dexie } = require('dexie');

class LocalDBDexie extends Dexie {
  constructor() {
    super('developDB');
    this.version(2).stores({
      carrinho:
        '++id, tipo, nome, quantidade , valor, toleranciaMin, toleranciaMax, liberado, liberadoBR, embalagemProtetora',
    });

    this.open();
    this.carrinho = this.table('carrinho');
  }

  async setCarrinho(item, cupom = null) {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        let cupomSalvo = localStorage.getItem('cupom');

        if (cupom) {
          console.log('Cupom recebido:', cupom);
          const cupomValido = await this.validarCupom(cupom);
          console.log('Cupom validado:', cupomValido);

          if (cupomValido) {
            item.valor -= item.valor * (cupomValido.desconto / 100);
            console.log('Desconto aplicado:', cupomValido.desconto);
            localStorage.setItem('cupom', JSON.stringify(cupomValido));
          } else {
            console.log('Cupom inválido, prosseguir sem desconto.');
          }
        } else if (cupomSalvo) {
          cupomSalvo = JSON.parse(cupomSalvo);
          item.valor -= item.valor * (cupomSalvo.desconto / 100);
        }

        await this.carrinho.clear();
        await this.carrinho.add(item);
      });
    } catch (error) {
      console.log('Erro ao salvar carrinho localmente: ', error);
    }
  }

  async setItem(item, id) {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        const itemExistente = await this.carrinho.where({ id: id }).first();

        if (itemExistente) {
          await this.carrinho
            .where({ id: id })
            .modify((value) => Object.assign(value, item));
        } else {
          await this.carrinho.add(item);
        }
      });
    } catch (error) {
      console.log('Erro ao salvar carrinho localmente: ', error);
    }
  }

  async deleteItemCarrinho(id) {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        await this.carrinho.delete(String(id));
      });
      // localStorage.removeItem('cupom');
    } catch (error) {
      console.log('Erro ao salvar carrinho localmente: ', error);
    }
  }

  async cleanCarrinho() {
    try {
      await this.transaction('rw', this.carrinho, async () => {
        await this.carrinho.clear();
      });

      // localStorage.removeItem('cupom');
    } catch (error) {
      console.log('Erro ao limpar carrinho localmente: ', error);
    }
  }

  async getCarrinho() {
    const data = await db.carrinho.toArray();

    if (!(data.length > 0)) return [];

    const cupomSalvo = localStorage.getItem('cupom');
    let cupomAplicado = null;

    if (cupomSalvo) {
      cupomAplicado = JSON.parse(cupomSalvo);
      console.log('Cupom recuperado do localStorage:', cupomAplicado);
    }

    for (const element of data) {
      if (cupomAplicado) {
        element.valor -= element.valor * (cupomAplicado.desconto / 100);
      }

      if (element?.codIso) {
        try {
          const moedaData = await getLimiteOperacaoByMoeda(element.codIso);
          element.toleranciaMin = moedaData.toleranciaMin;
          element.toleranciaMax = moedaData.toleranciaMax;
          element.liberado = moedaData.liberado;
          element.liberadoBR = moedaData.liberadoBR;
        } catch (error) {
          console.log('Moeda Não encontrada!');
        }
      }
    }

    return data;
  }

  async validarCupom(cupom) {
    try {
      const cupomValido = await verificarCupomDesconto(cupom);

      if (!cupomValido) {
        throw new Error('Cupom inválido ou expirado.');
      }

      return cupomValido;
    } catch (error) {
      console.log('Erro ao validar cupom: ', error);
      return null;
    }
  }
}

async function verificarCupomDesconto(cupom) {
  const cuponsValidos = {
    OMD10: {
      desconto: 10,
      descricao: 'Cupom de R$ 10,00 na compra de dólar em espécie.',
      condicao: 'Válido nas compras acima de R$6.000,00 na Central de Vendas.',
      valor: 6000,
      observacao: 'Válido para qualquer tipo de dólar (USD, CAD, AUD, etc.).',
      moedasPermitidas: [
        'Dólar Americano',
        'Dólar Canadense',
        'Dólar Australiano',
        'Dólar Neozelandês',
      ],
    },
    OMCPP: {
      desconto: 10,
      descricao: 'Cupom de R$ 10,00 na compra e recarga de Cartão Pré-Pago.',
      condicao:
        'Válido para compras acima de R$ 2.000,00 na Central de Vendas.',
      valor: 2000,
      observacao: '',
      moedasPermitidas: [],
    },
    OMAL44: {
      desconto: 44,
      descricao:
        'Cupom de R$ 44,00 na compra de moedas em espécie da América Latina.',
      condicao:
        'Válido para compras acima de R$ 2.000,00 na Central de Vendas.',
      valor: 2000,
      observacao: [
        'Peso Chileno, Argentino, Uruguaio, Novo Sol Peruano, etc.',
        'De acordo com disponibilidade da região.',
      ],
      moedasPermitidas: [
        'Peso Chileno',
        'Peso Argentino',
        'Peso Uruguaio',
        'Sol Peruano',
        'Boliviano',
        'Peso Colombiano',
        'Peso Mexicano',
      ],
    },
    OME10: {
      desconto: 10,
      descricao: 'Cupom de R$ 10,00 na compra de euro em espécie.',
      condicao:
        'Válido para compras acima de R$ 6.000,00 na Central de Vendas.',
      valor: 6000,
      observacao: 'Válido apenas para euro (EUR).',
      moedasPermitidas: ['Euro'],
    },
    OMRI33: {
      desconto: 33,
      descricao: 'Cupom de R$ 33,00 na primeira Remessa Internacional.',
      condicao: 'Cupom progressivo acima de R$ 5.500,00 na Central de Vendas.',
      valorMinimo: 5500,
      observacao: '',
      moedasPermitidas: [],
    },
    OMMG15: {
      desconto: 15,
      descricao:
        'Cupom de R$ 15,00 para MoneyGram (Transferência Internacional).',
      condicao:
        'Válido para compras acima de R$ 1.400,00 na Central de Vendas.',
      valorMinimo: 1400,
      observacao: '',
      moedasPermitidas: [],
    },
  };

  return cuponsValidos[cupom] || null;
}

export async function updatedCarrinhoQuantidade(id, novaQuantidade) {
  try {
    await db.carrinho.where({ id: String(id) }).modify((item) => {
      item.quantidade = novaQuantidade;
    });
  } catch (error) {
    console.log('Erro ao atualizar a quantidade do carrinho: ', error);
  }
}

export const db = new LocalDBDexie();
