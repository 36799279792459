import styled from "styled-components";

export const LinkClean = styled.a`
  text-decoration: none;
  color: unset;
  &:hover {
    color: #5f6368;
    cursor: pointer;
  }
`;
