import colaboradoraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-1g-frente.png";
import colaboradoraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-2g-frente.png";
import colaboradoraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-5g-frente.png";
import colaboradoraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-10g-frente.png";
import colaboradoraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-25g-frente.png";
import colaboradoraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-31g-frente.png";
import colaboradoraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-50g-frente.png";
import colaboradoraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-1g-verso.png";
import colaboradoraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-2g-verso.png";
import colaboradoraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/colaboradora/colaboradora-ouro-5g-verso.png";

export const  colaboradoraMockups = {
    nome: "Colaboradora",
    frente1g: colaboradoraFrente1g,
  
    frente2g: colaboradoraFrente2g,
  
    frente5g: colaboradoraFrente5g, 
    frente10g: colaboradoraFrente10g,
    frente25g:colaboradoraFrente25g,
    frente31g: colaboradoraFrente31g,
    frente50g: colaboradoraFrente50g, 
  
    verso1g: colaboradoraVerso1g,
    verso2g: colaboradoraVerso2g,
  
    verso5g: colaboradoraVerso5g
  };