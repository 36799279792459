import newYorkFrente1g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-1g-frente.png";
import newYorkFrente2g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-2g-frente.png";
import newYorkFrente5g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-5g-frente.png";
import newYorkFrente10g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-10g-frente.png";
import newYorkFrente25g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-25g-frente.png";
import newYorkFrente31g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-31g-frente.png";
import newYorkFrente50g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-50g-frente.png";
import newYorkVerso1g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-1g-verso.png";
import newYorkVerso2g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-2g-verso.png";
import newYorkVerso5g from "../../../assets/newImages/mockups/mundos/new-york/new-york-ouro-5g-verso.png";

export const  newYorkMockups = {
    nome: "New York",
    frente1g: newYorkFrente1g,
  
    frente2g: newYorkFrente2g,
  
    frente5g: newYorkFrente5g,
  
    frente10g: newYorkFrente10g,
  
    frente25g: newYorkFrente25g,
  
    frente31g: newYorkFrente31g,
  
    frente50g: newYorkFrente50g,
  
    verso1g: newYorkVerso1g,
    verso2g: newYorkVerso2g,
  
    verso5g: newYorkVerso5g,
    verso10g: newYorkVerso5g,
    verso25g: newYorkVerso5g,
    verso31g: newYorkVerso5g,
    verso50g: newYorkVerso5g,
  };

 