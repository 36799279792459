import React from 'react';
import './ourominasApp.css';
import banner700x400 from '../../../assets/images/banner/ourominasApp/mobile-700x400.png';
import banner700x520 from '../../../assets/images/banner/ourominasApp/tablet-700x520.png';
import banner600x1400 from '../../../assets/images/banner/ourominasApp/notebook-600x1400.png';
import banner600x2500 from '../../../assets/images/banner/ourominasApp/desktop-600x2500.png';

export default function OurominasApp() {
  const handleRedirect = () => {
    window.open('https://ourominasapp.com/', '_blank');
  };
  const handleRedirectDownload = () => {
    window.open('https://app.ourominasapp.com/baixar', '_blank');
  };

  return (
    <main>
      <div className="bannerApp">
        <picture>
          <source
            media="(min-width: 560px) and (max-width: 909px)"
            srcSet={banner700x520}
          />

          <source
            media="(min-width: 910px) and (max-width: 1400px)"
            srcSet={banner600x1400}
          />

          <source media="(min-width: 1401px)" srcSet={banner600x2500} />

          <img
            src={banner700x400}
            alt="Banner Responsivo"
            className="bannerImg"
          />
        </picture>

        <button className="saibaMaisBtnApp" onClick={handleRedirect}></button>
        <button className="baixarBtnApp" onClick={handleRedirectDownload}></button>
      </div>
    </main>
  );
}
