import cascavelFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-1g-frente.png";
import cascavelFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-2g-frente.png";
import cascavelFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-5g-frente.png";
import cascavelFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-10g-frente.png";
import cascavelFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-25g-frente.png";
import cascavelFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-31g-frente.png";
import cascavelFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-50g-frente.png";
import cascavelVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-1g-verso.png";
import cascavelVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-2g-verso.png";
import cascavelVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/cascavel/cascavel-ouro-5g-verso.png";

export const  cascavelMockups = {
    nome: "Cascavel",
    frente1g: cascavelFrente1g,
  
    frente2g: cascavelFrente2g,
  
    frente5g: cascavelFrente5g,
  
    frente10g: cascavelFrente10g,
  
    frente25g: cascavelFrente25g,
  
    frente31g: cascavelFrente31g,
  
    frente50g: cascavelFrente50g,
  
    verso1g: cascavelVerso1g,
    verso2g: cascavelVerso2g,
  
    verso5g: cascavelVerso5g,
  };

 