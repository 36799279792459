import professoraFrente1g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-1g-frente.png";
import professoraFrente2g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-2g-frente.png";
import professoraFrente5g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-5g-frente.png";
import professoraFrente10g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-10g-frente.png";
import professoraFrente25g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-25g-frente.png";
import professoraFrente31g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-31g-frente.png";
import professoraFrente50g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-50g-frente.png";
import professoraVerso1g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-1g-verso.png";
import professoraVerso2g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-2g-verso.png";
import professoraVerso5g from "../../../assets/newImages/mockups/profissoes/professora/professora-ouro-5g-verso.png";

export const  professoraMockups = {
    nome: "Professora de Ouro",
    frente1g: professoraFrente1g,
  
    frente2g: professoraFrente2g,
  
    frente5g: professoraFrente5g,
  
    frente10g: professoraFrente10g,
  
    frente25g: professoraFrente25g,
  
    frente31g: professoraFrente31g,
  
    frente50g: professoraFrente50g,
  
    verso1g: professoraVerso1g,
    verso2g: professoraVerso2g,
  
    verso5g: professoraVerso5g,
    verso10g: professoraVerso5g,
    verso25g: professoraVerso5g,
    verso31g: professoraVerso5g,
    verso50g: professoraVerso5g,
  };

 