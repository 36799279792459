export const LogModel = {
    usuarioPortalId: "",
    descricao: "",
    tipoAcao: "",
    data: Date,
  };
  
  function setLogModel(usuarioPortalId, descricao, tipoAcao) {
    this.usuarioPortalId = usuarioPortalId  ;
    this.descricao = descricao ;
    this.tipoAcao = tipoAcao ;
   this.data = new Date().toISOString();
  }

  
export { setLogModel };