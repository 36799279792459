import esmeraldaFrente1g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-1g-frente.png";
import esmeraldaFrente2g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-2g-frente.png";
import esmeraldaFrente5g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-5g-frente.png";
import esmeraldaFrente10g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-10g-frente.png";
import esmeraldaFrente25g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-25g-frente.png";
import esmeraldaFrente31g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-31g-frente.png";
import esmeraldaFrente50g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-50g-frente.png";
import esmeraldaVerso1g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-1g-verso.png";
import esmeraldaVerso2g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-2g-verso.png";
import esmeraldaVerso5g from "../../../assets/newImages/mockups/pedras-preciosas/esmeralda/esmeralda-ouro-5g-verso.png";

export const  esmeraldaMockups = {
    nome: "Esmeralda",
    frente1g: esmeraldaFrente1g,
  
    frente2g: esmeraldaFrente2g,
  
    frente5g: esmeraldaFrente5g,
  
    frente10g: esmeraldaFrente10g,
  
    frente25g: esmeraldaFrente25g,
  
    frente31g: esmeraldaFrente31g,
  
    frente50g: esmeraldaFrente50g,
  
    verso1g: esmeraldaVerso1g,
    verso2g: esmeraldaVerso2g,
  
    verso5g: esmeraldaVerso5g,
    verso10g: esmeraldaVerso5g,
    verso25g: esmeraldaVerso5g,
    verso31g: esmeraldaVerso5g,
    verso50g: esmeraldaVerso5g,
  };

 