import React from 'react';
import './politicas.css';
import img from '../../../assets/newImages/ouvidoria1.png';

export default function Ouvidoria() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              POLÍTICA DE OUVIDORIA
            </p>
            <p>
              A Política de Ouvidoria tem como missão estabelecer um canal de
              comunicação eficiente entre os cliente, parceiros, usuários de
              produtos e serviços da <strong>OM DTVM</strong>, buscando
              intermediar conflitos e trazendo soluções definitivas para as
              reclamações, de acordo com as normas legais e regulamentares
              aplicáveis e relativas ao direito do consumidor.
            </p>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                RESPONSABILIDADE E VALIDADE
              </li>
              <li className="list-unstyled">
                <p>
                  O conteúdo da Política de Ouvidoria é de responsabilidade do
                  diretor da <strong>OM DTVM</strong> registrado no UNICAD do
                  Banco Central do Brasil como o responsável pelo seu
                  cumprimento. A gestão desta Política (elaboração,
                  conformidade, guarda, divulgação, controle de atualizações e
                  adesão) é de responsabilidade da Estrutura de Compliance da{' '}
                  <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  A Política de Ouvidoria é reavaliada a cada 3 (três) anos, em
                  razão de requerimentos legais que justifiquem a sua
                  atualização ou quando da ocorrência de eventos considerados
                  relevantes pela <strong>OM DTVM</strong>, incluindo: I -
                  oferta de novos produtos ou serviços relevantes; II -
                  modificações relevantes nos produtos, nos serviços, nas
                  atividades ou nos processos da <strong>OM DTVM</strong>; III -
                  mudanças significativas no modelo de negócios da{' '}
                  <strong>OM DTVM</strong>; IV - reorganizações societárias
                  significativas; V - mudanças políticas, legais,
                  regulamentares, tecnológicas ou de mercado, incluindo
                  alterações significativas nas preferências de consumo, que
                  impactem de forma relevante os negócios da{' '}
                  <strong>OM DTVM</strong>, tanto positiva quanto negativamente;
                  e VI - alterações relevantes em relação ao escopo de aplicação
                  da Política de Ouvidoria.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PÚBLICO-ALVO
              </li>
              <li className="list-unstyled">
                <p>
                  A Política de Ouvidoria aplica-se a todas as partes
                  interessadas, ou seja, a) os clientes e usuários dos produtos
                  e serviços da instituição; b) a comunidade interna à
                  instituição (funcionários, colaboradores (pessoas contratadas
                  fora do regime CLT que executam atividades em nome da{' '}
                  <strong>OM DTVM</strong>), estagiários, jovens aprendizes,
                  correspondentes combiais e postos de compra de ouro); c) os
                  fornecedores e os prestadores de serviços tercerizados
                  relevantes da instituição; d) as demais pessoas impactadas
                  pelos produtos, serviços, atividades e processos da
                  instituição; e, e) todos aqueles que querem conhecer o
                  posicionamento ético e estratégico da <strong>OM DTVM</strong>
                  .
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO
              </li>
              <p>
                Estabelecer, por meio do serviço de Ouvidoria um canal de
                comunicação entre a <strong>OM DTVM</strong> e seus clientes,
                visando acompanhar as demandas, além de mediar conflitos e
                aperfeiçoar os procedimentos da <strong>OM DTVM</strong>.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOS PRINCÍPIOS
              </li>
              <li className="list-unstyled">
                <p>
                  A <strong>OM DTVM</strong> busca oferecer a todas as partes
                  interessadas condições de trabalho adequadas e uma cultura
                  organizacional que enfatize o papel de cada agente no processo
                  de ouvidoria tendo, como base, as definições estratégicas e
                  aos assumidos voluntariamente. A <strong>OM DTVM</strong>.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMPONENTE ORGANIZACIONAL
              </li>
              <li className="list-unstyled">
                <p>
                  A estrutura organizacional é compátivel com a natureza e a
                  complexidade dos serviços prestados pela{' '}
                  <strong>OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>O serviço de Ouvidoria é:</p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Amplamente divulgado pela <strong>OM DTVM</strong>,
                      informando sua finalidade e forma de utilização, inclusive
                      por meio dos canais de comunicação utilizados para
                      difundir os serviços da <strong>OM DTVM</strong>;
                    </li>
                    <li>
                      Disponibilizado por meio de um 0800 e por meio eletrônico,
                      visando acesso gratuito aos seus clientes;
                    </li>
                    <li>
                      O acesso telefônico gratuito é divulgado e mantido em
                      local e formato visível aos clientes nas dependências, no
                      site e demais canais de comunicação utilizados pela{' '}
                      <strong>OM DTVM</strong>;
                    </li>
                    <li>
                      Nos materiais de propaganda e publicidade e demais
                      documentos que sejam destinados aos clientes da{' '}
                      <strong>OM DTVM</strong>;
                    </li>
                    <li>
                      O serviço de Ouvidoria está diretamente ligado à Diretoria
                      da <strong>OM DTVM</strong>;
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ATRIBUIÇÕES DO SERVIÇO DE OUVIDORIA
              </li>
              <p>
                São atribuições do Serviço de Ouvidoria, porém, não se
                limitando:
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Receber as demandas dos clientes que não tenham sido
                      solucionados pelo atendimento;
                    </li>
                    <li>
                      Registrar gerando um número de protocolo de atendimento;
                    </li>
                    <li>
                      Instruir, analisar e dar tratamento formal e adequado às
                      reclamações dos clientes;
                    </li>
                    <li>
                      Esclarecer o que necessário for e dar ciência aos
                      reclamantes acerca do andamento das suas demandas, bem
                      como das providências adotadas;
                    </li>
                    <li>
                      Informar aos reclamantes no prazo previsto para resposta
                      final, o qual deverá ser contado da data de protocolização
                      da ocorrência;
                    </li>
                    <li>
                      Encaminhar resposta conclusiva para o reclamante no prazo
                      previsto;
                    </li>
                    <li>
                      Certificar-se que ao final de cada semestre o Diretor
                      responsável pela Ouvidoria elabore relatório contemplando
                      quantitativamente e qualitativamente a atuação do serviço
                      de Ouvidoria que deverá ser encaminhado à Diretoria da{' '}
                      <strong>OM DTVM</strong> e à auditor's interna, quando
                      aplicável.
                    </li>
                    <li>
                      Divulgar semestralmente no site da{' '}
                      <strong>OM DTVM</strong>, o relatório contendo informações
                      relativas às atividades desenvolvidas pela Ouvidoria,
                      inclusive os dados relativos à avaliação direta da
                      qualidade de atendimento.
                    </li>
                    <li>
                      Os relatórios previstos no item acima deverão permanecer à
                      disposição do Banco Central do Brasil, por no mínimo, 5
                      (cinco) anos.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                FLUXO DE TRATAMENTO
              </li>
              <p>
                As demandas dos clientes são recebidas via 0800, e-mail ou via
                site da <strong>OM DTVM</strong>.
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      O serviço de Ouvidoria registra a demanda recebida. Após o
                      serviço de Ouvidoria encaminha a demanda pra o setor
                      competente, devendo acompanhar o processo de resolução da
                      demanda e por fim responder diretamente ao cliente. O
                      atendimento é considerado finalizado somente após o
                      contato final com o cliente.
                    </li>
                    <li>
                      As manifestações recebidas deverão ser atendidas no prazo
                      de 10 (dez) dias úteis a contar da data de recebimento,
                      podendo ser prorrogado, excepcionalmente e de forma
                      justificada, uma única vez, por igual período, limitado o
                      número de prorrogações 10% (dez por cento) do total de
                      demandas no mês, devendo o demandante ser informado sobre
                      os motivos da prorrogação.
                    </li>
                    <li>
                      O Diretor responsável pela Ouvidoria deve encaminhar o
                      Relatório semestral à Diretoria da{' '}
                      <strong>OM DTVM </strong>e à auditoria interna, quando
                      aplicável.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DEVERES DO SERVIÇO DE OUVIDORIA
              </li>
              <p>São deveres do Serviço de Ouvidoria:</p>
              <ul>
                <li>
                  Examinar as demandas recebidas e encaminhar o setor
                  competente;
                </li>
                <li>
                  Acompanhar o andamento da demanda junto aos setores internos;
                </li>
                <li>Responder diretamente ao cliente de forma satisfatória;</li>
                <li>
                  Examinar o desempenho da <strong>OM DTVM</strong> frente às
                  demandas recebidas;
                </li>
                <li>Tratar com sigilo as demandas recepcionadas;</li>
                <li>
                  Certificar-se que o Relatório de Ouvidoria semestralmente foi
                  realizado;
                </li>
                <li>
                  Divulgar, por meio dos canais internos de comunicação, o
                  trabalho desenvolvido pelo serviço de Ouvidoria.
                </li>
              </ul>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DIRETOR RESPONSÁVEL
              </li>
              <p>
                Os dados do diretor responsável pela Ouvidoria deverão estar
                sempre atualizados em sistema de informações, na forma
                estabelecida pelo Banco Central do Brasil.
              </p>
              <p>
                O diretor responsável pela Ouvidoria deve elaborar relatório
                semestralmente, de acordo com a forma definida pelo Banco
                Central do Brasil, contemplando às atividades de Ouvidoria nas
                datas-bases de 30 (trinta) de junho e 31 (trinta e um) de
                dezembro. O referido Relatório de Ouvidoria deverá ser:
              </p>

              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Revisado pela auditoría externa, devendo a mesma
                      manifestar-se acerca da qualidade e adequação da estrutura
                      do serviço de Ouvidoria;
                    </li>
                    <li>Apreciado pela auditoria interna;</li>
                    <li>
                      Encaminhado ao Banco Central do Brasil no caso de
                      ocorrência de fato relevante; e
                    </li>
                    <li>
                      Arquivado na <strong>OM DTVM</strong>, à disposição do
                      Banco Central do Brasil, pelo prazo mínimo de 5 (cinco)
                      anos, acompanhado da revisão pela auditoria externa e da
                      apreciação pela auditoria interna.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                INTEGRANTES DA OUVIDORIA
              </li>
              <p>Sobre os integrantes da Ouvidoria tem-se:</p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Os integrantes da Ouvidoria deverão ser considerados aptos
                      em exame de certificação organizado por entidade de
                      reconhecida capacidade técnica.
                    </li>
                    <li>
                      O referido exame deverá abranger, no mínimo, temas
                      relativos à ética, aos direitos e defesas do consumidor e
                      à mediação de conflitos;
                    </li>
                    <li>
                      Caso o diretor responsável pela ouvidoria seja o ouvidor,
                      o mesmo deverá ser considerado apto em exame de
                      certificação organizado por entidade de reconhecida
                      capacidade técnica.
                    </li>
                    <li>
                      Os integrantes do serviço de Ouvidoria deverão agir com
                      ética, transparência, imparcialidade e comprometimento.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PRINCIPAIS REGULAMENTAÇÕES
              </li>
              <p>
                As regulamentações legais devem sempre ser consultadas, nos
                sítios oficiais, para obtenção da versão mais atualizada em cada
                momento;
              </p>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      <strong>
                        RESOLUÇÃO CMN № 4.860, DE 23 DE OUTUBRO DE 2020
                      </strong>{' '}
                      - Dispõe sobre a constituição e o funcionamento de
                      componente organizacional de ouvidoria pelas instituições
                      autorizadas a funcionar pelo Banco Central do Brasil.
                    </li>
                  </ul>
                </p>
              </li>
              <p>
                Em caso de conflito entre esta Politica de Ouvidoria e as
                legislações e regulamentações aplicáveis, prevalecerá o padrão
                mais rigoroso e conservador, desde que não infrinja a legislação
                pertinente.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SANÇÕES ADMINISTRATIVAS
              </li>
              <p>
                As partes interessadas que não cumprirem por negligência, culpa
                ou dolo os requerimentos da Politica de Ouvidoria da{' '}
                <strong>OM DTVM</strong>, dependendo da gravidade da falta,
                serão advertidos oficiosamente, Nadvertidos formalmente,
                suspensos temporariamente ou ter seu contrato denunciado.
              </p>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
