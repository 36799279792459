const getEnvironmentVariable = (environmentVariable) => {
    const unvalidatedEnvironmentVariable = process.env[environmentVariable];
    if (!unvalidatedEnvironmentVariable) {
      throw new Error(
        `Couldn't find environment variable: ${environmentVariable}`
      );
    } else {
      return unvalidatedEnvironmentVariable;
    }
  };

  export const env = {
    linkAPI: getEnvironmentVariable("REACT_APP_API_LINK"),
    userLogin: getEnvironmentVariable("REACT_APP_USER_LOGIN"),
    userPassword: getEnvironmentVariable("REACT_APP_USER_PASSWORD"),
    travelAPI: getEnvironmentVariable("REACT_APP_AUTH_API_TRAVEL_LINK"),
    travelLogin: getEnvironmentVariable("REACT_APP_AUTH_USER"),
    travelPassword: getEnvironmentVariable("REACT_APP_AUTH_PASSWORD"),
    viacep: getEnvironmentVariable("REACT_APP_AUTH_API_VIACEP"),
    isProd: getEnvironmentVariable("REACT_APP_IS_PROD").includes("true"),
  };