import primaveraFrente1g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-1g-frente.png";
import primaveraFrente2g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-2g-frente.png";
import primaveraFrente5g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-5g-frente.png";
import primaveraVerso1g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-1g-verso.png";
import primaveraVerso2g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-2g-verso.png";
import primaveraVerso5g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-5g-verso.png";
import primaveraVerso10g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-10g-verso.png";
import primaveraVerso25g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-25g-verso.png";
import primaveraVerso31g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-31g-verso.png";

import primaveraVerso50g from "../../../assets/newImages/mockups/estacoes-ano/primavera/primavera-50g-verso.png";

import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g-primavera.png";
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g-primavera.png";
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g-outono.png";
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g-primavera.png";
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g-primavera.png";

export const  primaveraMockups = {
    nome: "Primavera",
    frente1g: primaveraFrente1g,
  
    frente2g: primaveraFrente2g,
  
    frente5g: primaveraFrente5g,  
  
    verso1g: primaveraVerso1g,
    verso2g: primaveraVerso2g,
  
    verso5g: primaveraVerso5g,
    verso10g: primaveraVerso10g,
    verso25g: primaveraVerso25g,
    verso31g: primaveraVerso31g,
    verso50g: primaveraVerso50g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g
  };

  export const primavera = [
    {
      name: "Primavera",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]