import fotografaFrente1g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-1g-frente.png";
import fotografaFrente2g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-2g-frente.png";
import fotografaFrente5g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-5g-frente.png";
import fotografaFrente10g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-10g-frente.png";
import fotografaFrente25g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-25g-frente.png";
import fotografaFrente31g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-31g-frente.png";
import fotografaFrente50g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-50g-frente.png";
import fotografaVerso1g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-1g-verso.png";
import fotografaVerso2g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-2g-verso.png";
import fotografaVerso5g from "../../../assets/newImages/mockups/profissoes/fotografa/fotografa-ouro-5g-verso.png";

export const  fotografaMockups = {
    nome: "Fotógrafa de Ouro",
    frente1g: fotografaFrente1g,
  
    frente2g: fotografaFrente2g,
  
    frente5g: fotografaFrente5g,
  
    frente10g: fotografaFrente10g,
  
    frente25g: fotografaFrente25g,
  
    frente31g: fotografaFrente31g,
  
    frente50g: fotografaFrente50g,
  
    verso1g: fotografaVerso1g,
    verso2g: fotografaVerso2g,
  
    verso5g: fotografaVerso5g,
    verso10g: fotografaVerso5g,
    verso25g: fotografaVerso5g,
    verso31g: fotografaVerso5g,
    verso50g: fotografaVerso5g,
  };

 