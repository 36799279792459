import netoFrente1g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-1g-frente.png";
import netoFrente2g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-2g-frente.png";
import netoFrente5g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-5g-frente.png";
import netoFrente10g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-10g-frente.png";
import netoFrente25g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-25g-frente.png";
import netoFrente31g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-31g-frente.png";
import netoFrente50g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-50g-frente.png";
import netoVerso1g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-1g-verso.png";
import netoVerso2g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-2g-verso.png";
import netoVerso5g from "../../../assets/newImages/mockups/familia/neto/neto-ouro-5g-verso.png";

export const  netoMockups = {
    nome: "Neto de Ouro",
    frente1g: netoFrente1g,
  
    frente2g: netoFrente2g,
  
    frente5g: netoFrente5g, 
    frente10g: netoFrente10g,
    frente25g:netoFrente25g,
    frente31g: netoFrente31g,
    frente50g: netoFrente50g, 
  
    verso1g: netoVerso1g,
    verso2g: netoVerso2g,
  
    verso5g: netoVerso5g,
    verso10g: netoVerso5g,
    verso25g: netoVerso5g,
    verso31g: netoVerso5g,
    verso50g: netoVerso5g,
  };