import invernoFrente1g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-1g-frente.png";
import invernoFrente2g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-2g-frente.png";
import invernoFrente5g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-5g-frente.png";
import invernoVerso1g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-1g-verso.png";
import invernoVerso2g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-2g-verso.png";
import invernoVerso5g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-5g-verso.png";
import invernoVerso10g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-10g-verso.png";
import invernoVerso25g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-25g-verso.png";
import invernoVerso31g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-31g-verso.png";
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g-inverno.png";
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g-inverno.png";
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g-inverno.png";
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g-inverno.png";
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g-inverno.png";

import invernoVerso50g from "../../../assets/newImages/mockups/estacoes-ano/inverno/inverno-50g-verso.png";

export const  invernoMockups = {
    nome: "Inverno",
    frente1g: invernoFrente1g,
  
    frente2g: invernoFrente2g,
  
    frente5g: invernoFrente5g,  
  
    verso1g: invernoVerso1g,
    verso2g: invernoVerso2g,
  
    verso5g: invernoVerso5g,
    verso10g: invernoVerso10g,
    verso25g: invernoVerso25g,
    verso31g: invernoVerso31g,
    verso50g: invernoVerso50g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g


  };

  export const inverno = [
    {
      name: "Inverno",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]