import nutricionistaFrente1g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-1g-frente.png";
import nutricionistaFrente2g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-2g-frente.png";
import nutricionistaFrente5g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-5g-frente.png";
import nutricionistaFrente10g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-10g-frente.png";
import nutricionistaFrente25g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-25g-frente.png";
import nutricionistaFrente31g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-31g-frente.png";
import nutricionistaFrente50g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-50g-frente.png";
import nutricionistaVerso1g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-1g-verso.png";
import nutricionistaVerso2g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-2g-verso.png";
import nutricionistaVerso5g from "../../../assets/newImages/mockups/profissoes/nutricionista/nutricionista-ouro-5g-verso.png";

export const  nutricionistaMockups = {
    nome: "Nutricionista de Ouro",
    frente1g: nutricionistaFrente1g,
  
    frente2g: nutricionistaFrente2g,
  
    frente5g: nutricionistaFrente5g,
  
    frente10g: nutricionistaFrente10g,
  
    frente25g: nutricionistaFrente25g,
  
    frente31g: nutricionistaFrente31g,
  
    frente50g: nutricionistaFrente50g,
  
    verso1g: nutricionistaVerso1g,
    verso2g: nutricionistaVerso2g,
  
    verso5g: nutricionistaVerso5g,
    verso10g: nutricionistaVerso5g,
    verso25g: nutricionistaVerso5g,
    verso31g: nutricionistaVerso5g,
    verso50g: nutricionistaVerso5g,
  };

 