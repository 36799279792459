import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { postLancamento } from "../../../services/api";
import {
  maskOnlyLetters,
  maskPhoneWithDDD,
  validEmail,
} from "../../../utils/regex";

export default function FiquePorDentro() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [check, setCheck] = useState(false);
  const [formaContato, setFormaContato] = useState(0);
  const [errEmail, setErrEmail] = useState();
  const [errTelephone, setErrTelephone] = useState();
  const [errNome, setErrNome] = useState();
  const [errCheck, setErrCheck] = useState(false);
  const [errFormaContato, setErrFormaContato] = useState();

  function enviar() {
    let count = 0;
    setErrEmail("");
    setErrNome("");
    setErrTelephone("");
    setErrFormaContato("");
    setErrCheck("");
    if (nome === "") {
      count++;
      setErrNome("Digite o seu nome!");
    }
    if (formaContato === 1) {
      if (email === "") {
        count++;
        setErrEmail("Digite o seu email!");
      } else if (!validEmail.test(email)) {
        setErrEmail("Email inválido!");
      }
    } else if (formaContato === 2) {
      if (!telefone) {
        count++;
        setErrTelephone("Digite o telefone!");
      }
    } else {
      count++;
      setErrFormaContato("Selecione a forma de Contato");
    }
    if (check === false) {
      count++;
      setErrCheck("Marque o aceite!");
    }
    if (count === 0) {
      let props = {
        nome: nome,
        email: email,
        telefone: telefone,
      };
      postLancamento(props).then(()=>{
        setEmail("")
        setNome("")
        setTelefone("")
      });
    }
  }
  return (
    <Row className="footer-row">
      <Col xs={12} sm={12} md={6} className="footer1">
        <h1>FIQUE POR DENTRO</h1>
        <p>
          Inscreva-se ao lado e receba por e-mail ou Whatsapp os lançamentos,
          novidades e promoções da Ourominas.
        </p>
      </Col>
      <Col xs={12} sm={12} md={6} className="footer1">
        <div>
          <input
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(maskOnlyLetters(e.target.value))}
          />
          <span className="text-danger">{errNome}</span>
          <select
            className="selectFooter"
            onChange={(e) => {
              setFormaContato(parseInt(e.target.value));
            }}
          >
            <option value={0}>Selecione a Forma de Contato</option>
            <option value={1}>Email</option>
            <option value={2}>Whatsapp</option>
          </select>
          <span className="text-danger">{errFormaContato}</span>

          {formaContato === 1 ? (
            <>
              <input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="text-danger">{errEmail}</span>
            </>
          ) : formaContato === 2 ? (
            <>
              <input
                placeholder="Whatsapp"
                value={telefone}
                onChange={(e) => setTelefone(maskPhoneWithDDD(e.target.value))}
              />
              <span className="text-danger">{errTelephone}</span>
            </>
          ) : null}
        </div>
        <div className="divCheck col-12  flex-row">
          <input
            className="form-check-input checkInput checkFooter ps-1"
            type="checkbox"
            value={check}
            id="check3"
            onChange={(e) => setCheck(!check)}
          />
          <label className="form-check-label lblCheck">
            Autorizo que a OM me envie conteúdos.
          </label>
        </div>
        <div>
          <span className="text-danger">{errCheck}</span>
        </div>

        <div>
          <button className="send-footer" onClick={enviar}>
          Enviar
          </button>
        </div>

      </Col>
    </Row>
  );
}
