import secretariaFrente1g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-1g-frente.png";
import secretariaFrente2g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-2g-frente.png";
import secretariaFrente5g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-5g-frente.png";
import secretariaFrente10g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-10g-frente.png";
import secretariaFrente25g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-25g-frente.png";
import secretariaFrente31g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-31g-frente.png";
import secretariaFrente50g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-50g-frente.png";
import secretariaVerso1g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-1g-verso.png";
import secretariaVerso2g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-2g-verso.png";
import secretariaVerso5g from "../../../assets/newImages/mockups/empresa-ouro/secretaria/secretaria-ouro-5g-verso.png";

export const secretariaMockups = {
  nome: "Secretária de Ouro",
  frente1g: secretariaFrente1g,

  frente2g: secretariaFrente2g,

  frente5g: secretariaFrente5g,

  frente10g: secretariaFrente10g,

  frente25g: secretariaFrente25g,

  frente31g: secretariaFrente31g,

  frente50g: secretariaFrente50g,

  verso1g: secretariaVerso1g,
  verso2g: secretariaVerso2g,

  verso5g: secretariaVerso5g,
  verso10g: secretariaVerso5g,
  verso25g: secretariaVerso5g,
  verso31g: secretariaVerso5g,
  verso50g: secretariaVerso5g,
};