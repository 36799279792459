import oncaFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-1g-frente.png";
import oncaFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-2g-frente.png";
import oncaFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-5g-frente.png";
import oncaFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-10g-frente.png";
import oncaFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-25g-frente.png";
import oncaFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-31g-frente.png";
import oncaFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-50g-frente.png";
import oncaVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-1g-verso.png";
import oncaVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-2g-verso.png";
import oncaVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/onca/onca-ouro-5g-verso.png";

export const  oncaPintadaMockups = {
    nome: "Onça Pintada",
    frente1g: oncaFrente1g,
  
    frente2g: oncaFrente2g,
  
    frente5g: oncaFrente5g,
  
    frente10g: oncaFrente10g,
  
    frente25g: oncaFrente25g,
  
    frente31g: oncaFrente31g,
  
    frente50g: oncaFrente50g,
  
    verso1g: oncaVerso1g,
    verso2g: oncaVerso2g,
  
    verso5g: oncaVerso5g,
  };

 