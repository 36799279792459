import axios from "axios";
import { env } from "../env";
import {
  getAuthTravelStorage,
  setAuthTravelStorage,
} from "../../utils/localStorage";

export let apiTravel = axios.create({
  baseURL: `${env.travelAPI}/v1`,
});

apiTravel.interceptors.request.use(
  async (config) => {
    let token = "";
    const auth = getAuthTravelStorage();

    if (auth && new Date(auth.expiresIn) > Date.now()) {
      token = auth.token;
    } else {
      token = await tokenTravel().then((resp) => resp);
    }

    config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function tokenTravel() {
  try {
    const response = await fetch(`${env.travelAPI}/v1/auth`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nomeUsuario: env.travelLogin,
        senha: env.travelPassword,
      }),
    });

    if (response.ok) {
      const data = await response.json();

      const token = data.data.idToken;
      const expiresIn = data.data.expiresIn;

      setAuthTravelStorage(expiresIn, token);
      return token;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.Message || "Login failed");
    }
  } catch (error) {
    console.error("Login Error:", error);
  }
}
