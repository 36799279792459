import React from "react";
import img from "../../../assets/newImages/export-ouro.png";
import img2 from "../../../assets/om-mapa-ingles.jpg";
import "../../pages.css";
export default function Export() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 mt-5">
        <div className="col-6  d-flex flex-column p-div sect">
        <p className="fw-bolder text-decoration-underline">EXPORT</p>
          <p>
          Ourominas (OM) focuses on the Foreign Trade segment and excels in the export of precious metals. As it is a Financial Institution (FI), OM is authorized by the Federal Revenue Service and the Central Bank to export gold.
          </p>
          <p>
          Through planning and vision, it has the structure to organize international and national operations, also including road and air logistics. Aiming at good prices and tax planning, the company offers its suppliers all the tax benefits related to export activity and access in several regions of the world, in particular Asia, Europe and North America.
          </p>
          <p>
          Brazil is an exporting country, with Mato Grosso (MT), Pará (PA), Minas Geras (MG), Goiás (GO) and Bahia (BA) the most representative producing states where there are important gold reserves, and it is in the states of MT and PA where the OM gold extractions are legally carried out where they have the highest concentration of gold ore in Brazil.
          </p>
          <p>
          OM has partnerships with the largest mining companies, gold mines owned by PF (Individuals) and cooperatives authorized and regulated by the competent bodies in Brazil. OM also has its own smelter with a highly technological structure, where they are constantly monitored in order to guarantee the full efficiency of their services, which also works for refining precious metals, recovering precious metals and analyzing precious metals with a high standard of ethics, quality and trust.
          </p>
        </div>
        <div className="col-6 div-image">
          <img src={img} className="image " alt="grupo-om"></img>
        </div>
      </section>

      <section className="w-100 mt-5 d-flex justify-content-center">
        <div className="">
        <h1 className="fw-bold mb-3 text-center">The Types of Path of Gold</h1>
        <img src={img2} className="image caminho-ouro" alt="grupo-om"></img>
        </div>
      </section>
    </article>
  );
}
