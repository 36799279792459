import cairoFrente1g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-1g-frente.png";
import cairoFrente2g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-2g-frente.png";
import cairoFrente5g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-5g-frente.png";
import cairoFrente10g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-10g-frente.png";
import cairoFrente25g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-25g-frente.png";
import cairoFrente31g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-31g-frente.png";
import cairoFrente50g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-50g-frente.png";
import cairoVerso1g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-1g-verso.png";
import cairoVerso2g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-2g-verso.png";
import cairoVerso5g from "../../../assets/newImages/mockups/mundos/cairo/cairo-ouro-5g-verso.png";

export const  cairoMockups = {
    nome: "Cairo",
    frente1g: cairoFrente1g,
  
    frente2g: cairoFrente2g,
  
    frente5g: cairoFrente5g,
  
    frente10g: cairoFrente10g,
  
    frente25g: cairoFrente25g,
  
    frente31g: cairoFrente31g,
  
    frente50g: cairoFrente50g,
  
    verso1g: cairoVerso1g,
    verso2g: cairoVerso2g,
  
    verso5g: cairoVerso5g,
    verso10g: cairoVerso5g,
    verso25g: cairoVerso5g,
    verso31g: cairoVerso5g,
    verso50g: cairoVerso5g,
  };

 