import destaqueFrente1g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-1g-frente.png";
import destaqueFrente2g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-2g-frente.png";
import destaqueFrente5g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-5g-frente.png";
import destaqueFrente10g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-10g-frente.png";
import destaqueFrente25g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-25g-frente.png";
import destaqueFrente31g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-31g-frente.png";
import destaqueFrente50g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-50g-frente.png";
import destaqueVerso1g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-1g-verso.png";
import destaqueVerso2g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-2g-verso.png";
import destaqueVerso5g from "../../../assets/newImages/mockups/empresa-ouro/destaque/destaque-ouro-5g-verso.png";

export const  destaqueAnoMockups = {
    nome: "Destaque do Ano",
    frente1g: destaqueFrente1g,
  
    frente2g: destaqueFrente2g,
  
    frente5g: destaqueFrente5g, 
    frente10g: destaqueFrente10g,
    frente25g:destaqueFrente25g,
    frente31g: destaqueFrente31g,
    frente50g: destaqueFrente50g, 
  
    verso1g: destaqueVerso1g,
    verso2g: destaqueVerso2g,
  
    verso5g: destaqueVerso5g
  };