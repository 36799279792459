import consultoraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-1g-frente.png";
import consultoraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-2g-frente.png";
import consultoraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-5g-frente.png";
import consultoraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-10g-frente.png";
import consultoraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-25g-frente.png";
import consultoraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-31g-frente.png";
import consultoraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-50g-frente.png";
import consultoraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-1g-verso.png";
import consultoraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-2g-verso.png";
import consultoraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/consultora/consultora-ouro-5g-verso.png";

export const  consultoraMockups = {
    nome: "Consultora",
    frente1g: consultoraFrente1g,
  
    frente2g: consultoraFrente2g,
  
    frente5g: consultoraFrente5g, 
    frente10g: consultoraFrente10g,
    frente25g:consultoraFrente25g,
    frente31g: consultoraFrente31g,
    frente50g: consultoraFrente50g, 
  
    verso1g: consultoraVerso1g,
    verso2g: consultoraVerso2g,
  
    verso5g: consultoraVerso5g
  };