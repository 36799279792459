import React, { useEffect, useState } from "react";
import img from "../../../assets/newImages/premiacao-barra-ouro.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";
import TextButton from "../../../components/buttons/textButton";
import {
  premiacoes2020,
  premiacoes2021,
  premiacoes2022,
  premiacoes2023,
  premiacoes2024,
  premiacoes2025,
  ReturnText,
  ViewCarouselPremmium,
} from "./utils";

export default function PremiacaoBarraOuro() {
  const [premiacao, setPremiacao] = useState();

  useEffect(() => {
    const details = document.querySelectorAll("details");

    // Add the onclick listeners.
    details.forEach((detail) => {
      detail.addEventListener("toggle", () => {
        if (detail.open) setTargetDetail(detail);
      });
    });

    // Close all the details that are not targetDetail.
    function setTargetDetail(targetDetail) {
      setPremiacao("");
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.open = false;
        }
      });
    }
  }, []);

  const handleClickScroll = async (name) => {
    if (premiacao === name) {
      await setPremiacao("");
    } else {
      await setPremiacao(name);
      const element = document.getElementById(name);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              PREMIAÇÃO COM CERTIFICADO EM BARRA DE OURO
            </p>
            <p>
              Ouro, o nobre, cobiçado e o mais antigo lastro de valor da
              história está conquistando as agências de marketing promocional
              como mecanismo para assegurar o sucesso das promoções.
            </p>
            <p>
              A Ourominas (OM) é uma das líderes na emissão de certificados em
              barras de ouro para o mercado corporativo. Com larga experiência
              no segmento de premiações a OM tem participado das maiores ações
              promocionais do mercado nacional nos últimos anos. Tem como
              clientes as principais agências de publicidade e promoção,
              empresas varejistas, empresas de telefonia, emissoras de rádio e
              tv, shopping centers, supermercados, redes de fast-food e bancos
              do país.
            </p>
            <p>
              Se sua empresa está procurando uma forma diferenciada para
              premiações, aqui está a solução, premiação em certificado de barra
              de ouro, um produto inovador que vai motivar seu funcionário,
              parceiro ou cliente.
            </p>
            <p>
              Ao realizar uma operação com a OM, você pode ter a certeza de que
              todos os procedimentos relativos a cadastro, compliance e boas
              práticas corporativas estão sendo adotados. Todas as operações são
              pautadas pelo respeito à legislação e às normas do Banco Central
              do Brasil.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <section />

        <section>
          <p>
            <details>
              <summary
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Premiações 2020
              </summary>
              <ul>
                {premiacoes2020.map((item, i) => {
                  return (
                    <li key={i++}>
                      <TextButton
                        onClick={() => {
                          handleClickScroll(item.name);
                        }}
                      >
                        <b>{item.title}</b>
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </details>
          </p>
          <p>
            <details>
              <summary
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Premiações 2021
              </summary>
              <ul>
                {premiacoes2021.map((item, i) => {
                  return (
                    <li key={i++}>
                      <TextButton
                        onClick={() => {
                          handleClickScroll(item.name);
                        }}
                      >
                        <b>{item.title}</b>
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </details>
          </p>
          <p>
            <details>
              <summary
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Premiações 2022
              </summary>
              <ul>
                {premiacoes2022.map((item, i) => {
                  return (
                    <li key={i++}>
                      <TextButton
                        onClick={() => {
                          handleClickScroll(item.name);
                        }}
                      >
                        <b>{item.title}</b>
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </details>
          </p>
          <p>
            <details>
              <summary
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Premiações 2023
              </summary>
              <ul>
                {premiacoes2023.map((item, i) => {
                  return (
                    <li key={i++}>
                      <TextButton
                        onClick={() => {
                          handleClickScroll(item.name);
                        }}
                      >
                        <b>{item.title}</b>
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </details>
          </p>
          <p>
            <details>
              <summary
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Premiações 2024
              </summary>
              <ul>
                {premiacoes2024.map((item, i) => {
                  return (
                    <li key={i++}>
                      <TextButton
                        onClick={() => {
                          handleClickScroll(item.name);
                        }}
                      >
                        <b>{item.title}</b>
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </details>
          </p>
          <p>
            <details>
              <summary
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Premiações 2025
              </summary>
              <ul>
                {premiacoes2025.map((item, i) => {
                  return (
                    <li key={i++}>
                      <TextButton
                        onClick={() => {
                          handleClickScroll(item.name);
                        }}
                      >
                        <b>{item.title}</b>
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </details>
          </p>
        </section>
        {premiacao && (
          <section id={premiacao}>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
            <ViewCarouselPremmium name={premiacao} />
            <ReturnText name={premiacao} />
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </section>
        )}

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O QUE É CERTIFICADO EM BARRAS DE OURO?</summary>
            <p className="mt-3">
              Certificado em Barras de Ouro é uma forma fácil, ágil e segura de
              empresas premiarem seus clientes, sua equipe e seus fornecedores,
              sejam eles pessoas físicas ou jurídicas.
            </p>
            <p>
              Quando o lastro do prêmio é ouro, as empresas não entregam o
              metal, mas o dinheiro equivalente ao número de barras de ouro
              correspondentes ao prêmio.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>
              POR QUE PREMIAR COM CERTIFICADOS EM BARRAS DE OURO?{" "}
            </summary>
            <p className="mt-3">
              Campanhas de marketing promocional, com lastro em ouro, cresceram
              70%.
            </p>
            <p>
              É cada vez maior o número de agências promocionais que percebem as
              vantagens da premiação em certificados de ouro. A principal
              vantagem é poder trocar o prêmio por dinheiro, sem perder valor.
            </p>
            <p>
              O ouro exerce um poder sobre as pessoas. Independente da idade,
              sexo ou condição social, a expressão “valer ouro” significa o que
              de melhor existe em comparação com outras alternativas de
              premiação. Ouro não desgasta, não enferruja, não precisa ficar
              estocado, tem baixíssimo custo de logística, não necessita de
              seguros e não tem incidência de impostos. Que outro tipo de prêmio
              conta com essas características?
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>
              QUANDO PREMIAR COM CERTIFICADOS EM BARRAS DE OURO?
            </summary>
            <p className="mt-3">
              As promoções são utilizadas pelo varejo para fomentarem as vendas.
              Geralmente, os supermercados premiam no aniversário da loja; os
              shoppings centers nas datas comemorativas; e, as lojas de
              eletrodomésticos quando precisam estimular o consumo.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO FUNCIONA A VENDA DE CERTIFICADO DE OURO? </summary>
            <p className="mt-3">
              A estruturação para pagamento de ações promocionais proposta pela
              OM é simples e inovadora.
            </p>
            <ol>
              <li>
                <p>
                  A OM emite a NNO (Nota de Negociação de Ouro), que é o
                  documento comprobatório da aquisição junto aos órgãos
                  reguladores. A Caixa Econômica Federal deixa de ser o órgão
                  responsável pela autorização e fiscalização de promoções
                  comerciais e sorteios, transferindo essa obrigação para a
                  Secretaria de Acompanhamento Fiscal, Energia e Loteria
                  (SEFEL), a liquidação (acordado entre as partes) pode ser
                  estabelecida para data futura.
                </p>
              </li>
              <li>
                <p>
                  <p>
                    O PROMOTOR compra uma quantidade de ouro suficiente para dar
                    lastro à operação de pagamento, de acordo com o regulamento
                    de sua ação promocional. A OM irá orientar em relação a
                    preços e quantidades para tal.
                  </p>
                </p>
              </li>
              <li>
                <p>
                  O PROMOTOR efetua o pagamento, que permita transferir a
                  titularidade do ouro para o contemplado. Para isso, será
                  necessário o preenchimento, assinatura e carimbo de um TERMO
                  DE TRANSFERÊNCIA.
                </p>
              </li>
              <li>
                <p>
                  O PROMOTOR fornece a documentação pessoal necessária dos
                  contemplados (dependendo do valor do prêmio) e assina a
                  SOLICITAÇÃO DE COMPRA DE OURO, indicando a conta corrente ou
                  poupança de sua titularidade para crédito.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  A OM efetua o pagamento por transferência bancária (TED) para
                  a conta fornecida pelo promotor.
                </p>
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>
              PODEM SER UTILIZADAS OUTRAS FORMAS DE PAGAMENTO DO PRÊMIO COM
              OURO?{" "}
            </summary>
            <p className="mt-3">Sim. As emissões simples de Certificado:</p>
            <p>
              A empresa promotora compra o ouro físico ou no cartão
              OurominasCard em valor equivalente ao do disposto no regulamento
              da ação promocional e solicita à OM a emissão de um certificado
              representativo da quantidade de ouro à disposição do contemplado.
            </p>
            <p>
              Compra de barras: o promotor compra as barras e entrega
              diretamente ao contemplado, transferindo sua posse e propriedade.
              O contemplado poderá negociar essa barra livremente a preços de
              mercado posteriormente. Nessa operação há risco de sinistro
              (extravio, furto ou roubo da barra).
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>OSCILAÇÃO DO PREÇO DO METAL </summary>
            <p className="mt-3">
              Assumimos os riscos de oscilação de preço do metal, garantindo a
              seus contemplados o recebimento do valor exatamente igual ao
              proposto pelo regulamento da sua ação promocional na modalidade de
              venda, já com a recompra garantida.
            </p>
            <ol>
              <li>
                <p>
                  Cadastro Simplificado: consiste no preenchimento da Ficha
                  Cadastral com os dados básicos necessários.
                </p>
              </li>
              <li>
                <p>
                  Cadastro Completo: é o procedimento padrão de cadastro da
                  instituição. Consiste no preenchimento da Ficha Cadastral e
                  documentação complementar.
                </p>
              </li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>HORÁRIO DE NEGOCIAÇÃO. </summary>
            <p className="mt-3">
              Nosso horário de negociação: Segunda à Sexta, das 9:00 às 18:00h
              (Horário de Brasília).
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
