import empresarioFrente1g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-1g-frente.png";
import empresarioFrente2g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-2g-frente.png";
import empresarioFrente5g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-5g-frente.png";
import empresarioFrente10g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-10g-frente.png";
import empresarioFrente25g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-25g-frente.png";
import empresarioFrente31g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-31g-frente.png";
import empresarioFrente50g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-50g-frente.png";
import empresarioVerso1g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-1g-verso.png";
import empresarioVerso2g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-2g-verso.png";
import empresarioVerso5g from "../../../assets/newImages/mockups/profissoes/empresario/empresario-ouro-5g-verso.png";

export const  empresarioMockups = {
    nome: "Empresário de Ouro",
    frente1g: empresarioFrente1g,
  
    frente2g: empresarioFrente2g,
  
    frente5g: empresarioFrente5g,
  
    frente10g: empresarioFrente10g,
  
    frente25g: empresarioFrente25g,
  
    frente31g: empresarioFrente31g,
  
    frente50g: empresarioFrente50g,
  
    verso1g: empresarioVerso1g,
    verso2g: empresarioVerso2g,
  
    verso5g: empresarioVerso5g,
    verso10g: empresarioVerso5g,
    verso25g: empresarioVerso5g,
    verso31g: empresarioVerso5g,
    verso50g: empresarioVerso5g,
  };

 