import espiritaFrente1g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-1g-frente.png";
import espiritaFrente2g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-2g-frente.png";
import espiritaFrente5g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-5g-frente.png";
import espiritaFrente10g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-10g-frente.png";
import espiritaFrente25g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-25g-frente.png";
import espiritaFrente31g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-31g-frente.png";
import espiritaFrente50g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-50g-frente.png";
import espiritaVerso1g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-1g-verso.png";
import espiritaVerso2g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-2g-verso.png";
import espiritaVerso5g from "../../../assets/newImages/mockups/religiao/espirita/espirita-ouro-5g-verso.png";

export const  espiritaMockups = {
    nome: "Espirita",
    frente1g: espiritaFrente1g,
  
    frente2g: espiritaFrente2g,
  
    frente5g: espiritaFrente5g, 
    frente10g: espiritaFrente10g,
    frente25g:espiritaFrente25g,
    frente31g: espiritaFrente31g,
    frente50g: espiritaFrente50g, 
  
    verso1g: espiritaVerso1g,
    verso2g: espiritaVerso2g,
  
    verso5g: espiritaVerso5g,
    verso10g: espiritaVerso5g,
    verso25g: espiritaVerso5g,
    verso31g: espiritaVerso5g,
    verso50g: espiritaVerso5g,
  };