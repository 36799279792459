import React from "react";
import "./notFound.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-large.png"
export default function NotFound() {
  return (
    <div className=" page d-flex mt-5 ">
      <div className="col-12 text-center">
        
        <img src={logo} height="250px" className="not-found__image mb-4" alt="logo da Ourominas"/>
        <p className="bold fw-bolder">OPS! NÃO ENCONTRAMOS ESSA PÁGINA</p>
        <Link to="/">
          <button className="btn btn-success mb-5 ">
            Vá para a Página Inicial
          </button>
        </Link>
      </div>
    </div>
  );
}
