import React from "react";
// eslint-disable-next-line
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import PageRoutes from "./routes";
import CartProvider from "./components/context/shopp/cartShoppService";
import CotacoesProvider from "./components/context/cotacoes/cotacoesService";
import { ReactNotifications } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CotacoesProvider>
        <CartProvider>
          <React.StrictMode>
            <ReactNotifications />
            <PageRoutes />
          </React.StrictMode>
        </CartProvider>
      </CotacoesProvider>
    </ThemeProvider>
  );
}

export default App;
