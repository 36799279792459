export const faunaBrasileira = [
  {
    name: "Arara",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Cascavel",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Coruja",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Jacaré",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Mico Leão Dourado",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Onça Pintada",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Papagaio",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Preguiça",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Tartaruga",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  },{
    name: "Tucano",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ],
  }
  
];