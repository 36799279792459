export const signos = [
  {
    name: "Aquário",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Áries",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Câncer",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Capricórnio",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Escorpião",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Gêmeos",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Leão",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Libra",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Peixes",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Sagitário",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Touro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  },{
    name: "Virgem",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }
    
    
  ];