import ObrigadoFrente1g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-1g-frente.png";
import ObrigadoFrente2g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-2g-frente.png";
import ObrigadoFrente5g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-5g-frente.png";
import ObrigadoFrente10g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-10g-frente.png";
import ObrigadoFrente25g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-25g-frente.png";
import ObrigadoFrente31g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-31g-frente.png";
import ObrigadoFrente50g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-50g-frente.png";
import ObrigadoVerso1g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-1g-verso.png";
import ObrigadoVerso2g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-2g-verso.png";
import ObrigadoVerso5g from "../../../assets/newImages/mockups/sentimentos/obrigado/obrigado-ouro-5g-verso.png";

export const  ObrigadoMockups = {
    nome: "Obrigado",
    frente1g: ObrigadoFrente1g,
  
    frente2g: ObrigadoFrente2g,
  
    frente5g: ObrigadoFrente5g, 
    frente10g: ObrigadoFrente10g,
    frente25g:ObrigadoFrente25g,
    frente31g: ObrigadoFrente31g,
    frente50g: ObrigadoFrente50g, 
  
    verso1g: ObrigadoVerso1g,
    verso2g: ObrigadoVerso2g,
  
    verso5g: ObrigadoVerso5g,
    verso10g: ObrigadoVerso5g,
    verso25g: ObrigadoVerso5g,
    verso31g: ObrigadoVerso5g,
    verso50g: ObrigadoVerso5g,
  };