import React, { useState } from 'react';
import './banner.css';
import { Carousel } from 'react-bootstrap';
import Home from './home';
import Global from './global/global';
import CambioComercial from './cambioComercial/CambioComercial';
import OurominasApp from './ourominasApp/ourominasApp';

export default function Banner() {
  const [index, setIndex] = useState(0);
  const totalItems = 4;

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel
        className={`carouselBanner ${index === 0 ? 'disable-prev' : ''} ${
          index === totalItems - 1 ? 'disable-next' : ''
        }`}
        activeIndex={index}
        onSelect={handleSelect}
        controls={true}
      >
        <Carousel.Item interval={10000}>
          <section className="divBannerGlobal">
            <CambioComercial />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={10000}>
          <section className="divBannerApp">
            <OurominasApp />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={10000}>
          <section className="divBannerGlobal">
            <Global />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={10000}>
          <section className="divBanner">
            <Home />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
