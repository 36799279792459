import React from 'react';
import img from '../../../assets/newImages/LOGO_MORE_LARANJA.png';
import '../../pages.css';
import linha from '../../../assets/linha.png';

export default function MorePayment() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">MORE PAYMENT</p>
            <p>A Ourominas (OM) é parceira e representante oficial da More Payment Evolution</p>
            <p>
              Fundada em 2003, a More Payment Evolution surgiu da necessidade de
              alternativas mais eficazes no mercado de pagamentos. Com décadas
              de experiência e reconhecimento no setor financeiro, nossos
              fundadores decidiram explorar novas oportunidades para atender
              melhor às necessidades de pagamento global. Desde então, a More
              tem evoluído continuamente, focando em oferecer soluções de
              pagamento rápidas e eficientes para uma rede crescente de mais de
              120 países e uma sólida conexão bancária.
            </p>
            <p>
              Escolha a More Payment Evolution e seja parte da transformação no
              mundo dos pagamentos. Com nossa experiência e soluções inovadoras,
              facilitamos o caminho para suas transações financeiras
              internacionais e garantimos que sua experiência seja a mais fluida
              possível.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              TRANSFORME A FORMA COMO VOCÊ FAZ PAGAMENTOS INTERNACIONAIS COM A
              MORE PAYMENT EVOLUTION
            </summary>
            <p className="my-4">
              A More Payment Evolution é sua parceira ideal para simplificar e
              aprimorar suas transações financeiras, tanto locais quanto
              globais. Com nossa expertise em pagamentos, oferecemos soluções
              que tornam seus processos mais rápidos, seguros e sem fronteiras.
              Escolha a More e experimente a evolução no modo como você
              movimenta seu dinheiro.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              COBERTURA MUNDIAL E PAGAMENTOS EM TEMPO REAL
            </summary>
            <p className="mt-4">
              Com uma cobertura que abrange mais de 120 países e uma rede direta
              com mais de 90 bancos ao redor do mundo, a More redefine a
              experiência de transferências internacionais. Nossa solução
              oferece pagamentos em tempo real de maneira simples e eficiente. A
              integração através de uma única API permite que empresas e
              indivíduos façam transações sem a necessidade de se preocupar com
              as complexidades do mercado global.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              REMITTANCE: TRANSFERÊNCIAS INTERNACIONAISSEM FRONTEIRAS
            </summary>
            <p className="mt-4">
              A More é a escolha preferida por milhões de pessoas ao redor do
              globo quando se trata de enviar dinheiro com rapidez, segurança e
              as melhores taxas do mercado. Nossa plataforma conecta você a mais
              de 120 países, permitindo enviar dinheiro para diversas
              modalidades, como:
            </p>
            <ol>
              <li>Retirada de Espécie</li>
              <li>Crédito em Conta Corrente </li>
              <li>Crédito em Mobile Wallet (Carteira Móvel)</li>
            </ol>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              PAYINS: COBRANÇAS LOCAIS FACILITADAS
            </summary>
            <p className="mt-4">
              Remova as barreiras geográficas com a More. Nossa solução de
              Payins permite que você ofereça métodos de pagamento relevantes
              localmente na América Latina, África, Ásia e outros mercados. Com
              uma única integração de API, você amplia seu alcance e elimina as
              lacunas das vendas internacionais, levando seu negócio para novos
              horizontes.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary className="fw-bold">
              PAYOUTS: PAGAMENTOS IMEDIATOS E EFICIENTES
            </summary>
            <p className="mt-4">
              A More proporciona uma rede robusta e abrangente de conexões
              bancárias diretas, pontos de coleta de dinheiro e carteiras móveis
              (mobile wallets) globalmente. Isso possibilita que comerciantes
              realizem pagamentos imediatos para clientes, fornecedores e muito
              mais, independentemente da localização. Com nossa extensa
              cobertura e rede bancária, você tem a flexibilidade de enviar
              pagamentos para onde quer que seus clientes estejam.
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
