import ariesFrente1g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-1g-frente.png";
import ariesFrente2g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-2g-frente.png";
import ariesFrente5g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-5g-frente.png";
import ariesFrente10g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-10g-frente.png";
import ariesFrente25g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-25g-frente.png";
import ariesFrente31g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-31g-frente.png";
import ariesFrente50g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-50g-frente.png";
import ariesVerso1g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-1g-verso.png";
import ariesVerso2g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-2g-verso.png";
import ariesVerso5g from "../../../assets/newImages/mockups/signos/aries/aries-ouro-5g-verso.png";

export const  ariesMockups = {
    nome: "Áries",
    frente1g: ariesFrente1g,
  
    frente2g: ariesFrente2g,
  
    frente5g: ariesFrente5g, 
    frente10g: ariesFrente10g,
    frente25g:ariesFrente25g,
    frente31g: ariesFrente31g,
    frente50g: ariesFrente50g, 
  
    verso1g: ariesVerso1g,
    verso2g: ariesVerso2g,
  
    verso5g: ariesVerso5g,
    verso10g: ariesVerso5g,
    verso25g: ariesVerso5g,
    verso31g: ariesVerso5g,
    verso50g: ariesVerso5g,
  };