import cancerFrente1g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-1g-frente.png";
import cancerFrente2g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-2g-frente.png";
import cancerFrente5g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-5g-frente.png";
import cancerFrente10g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-10g-frente.png";
import cancerFrente25g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-25g-frente.png";
import cancerFrente31g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-31g-frente.png";
//import cancerFrente50g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-50g-frente.png";
import cancerVerso1g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-1g-verso.png";
import cancerVerso2g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-2g-verso.png";
import cancerVerso5g from "../../../assets/newImages/mockups/signos/cancer/cancer-ouro-5g-verso.png";

export const  cancerMockups = {
    nome: "Câncer",
    frente1g: cancerFrente1g,
  
    frente2g: cancerFrente2g,
  
    frente5g: cancerFrente5g, 
    frente10g: cancerFrente10g,
    frente25g:cancerFrente25g,
    frente31g: cancerFrente31g,
    frente50g: cancerFrente5g, 
  
    verso1g: cancerVerso1g,
    verso2g: cancerVerso2g,
  
    verso5g: cancerVerso5g,
    verso10g: cancerVerso5g,
    verso25g: cancerVerso5g,
    verso31g: cancerVerso5g,
    verso50g: cancerVerso5g,
  };