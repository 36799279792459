import irmaoFrente1g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-1g-frente.png";
import irmaoFrente2g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-2g-frente.png";
import irmaoFrente5g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-5g-frente.png";
import irmaoFrente10g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-10g-frente.png";
import irmaoFrente25g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-25g-frente.png";
import irmaoFrente31g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-31g-frente.png";
import irmaoFrente50g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-50g-frente.png";
import irmaoVerso1g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-1g-verso.png";
import irmaoVerso2g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-2g-verso.png";
import irmaoVerso5g from "../../../assets/newImages/mockups/familia/irmao/irmao-ouro-5g-verso.png";

export const  irmaoMockups = {
    nome: "Irmão de Ouro",
    frente1g: irmaoFrente1g,
  
    frente2g: irmaoFrente2g,
  
    frente5g: irmaoFrente5g, 
    frente10g: irmaoFrente10g,
    frente25g:irmaoFrente25g,
    frente31g: irmaoFrente31g,
    frente50g: irmaoFrente50g, 
  
    verso1g: irmaoVerso1g,
    verso2g: irmaoVerso2g,
  
    verso5g: irmaoVerso5g,
    verso10g: irmaoVerso5g,
    verso25g: irmaoVerso5g,
    verso31g: irmaoVerso5g,
    verso50g: irmaoVerso5g,
  };