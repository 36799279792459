import { useNavigate } from "react-router-dom";
import "../../../styles/ouroninas.css";
import styled from "styled-components";

export default function BoxButtons() {
    const navigate = useNavigate();
  const linkClick = (link) => {
    window.scrollTo(0, 0);
    navigate(link);
  };
  return (
    <Container>
      <div className="Alert-cont-h p-0">
        <h3>COTAÇÃO, SIMULAÇÃO E COMPRA</h3>
        <p className="m-0">
          
        </p>
      </div>
      <Box
      > <ButtonBox  onClick={() => linkClick("/om/servicos-ouro")}>OURO</ButtonBox>
      <ButtonBox onClick={() => linkClick("/om/servicos-cambio")}>CÂMBIO</ButtonBox><ButtonBox onClick={() => linkClick("/om/outros-servicos")}>OUTROS SERVIÇOS</ButtonBox></Box>
    </Container>
  );
}
const Box = styled.div`
border: 1px dotted grey;
background-color: #f7f7f7;
display: flex;
justify-content-center;
width: 100%;
flex-wrap: wrap;
`
const ButtonBox = styled.button`

padding: 10px 0;
border : 0;
width: 33.3%;
color: #004133;
font-size: 0.875rem;
font-weight: 500;
&&:hover{
    background-color: rgba(62, 62, 62, 0.201)
}

`

const Container = styled.section`
margin: 20px 0 30px 0;
`
