import rioJaneiroFrente1g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-1g-frente.png";
import rioJaneiroFrente2g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-2g-frente.png";
import rioJaneiroFrente5g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-5g-frente.png";
import rioJaneiroFrente10g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-10g-frente.png";
import rioJaneiroFrente25g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-25g-frente.png";
import rioJaneiroFrente31g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-31g-frente.png";
import rioJaneiroFrente50g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-50g-frente.png";
import rioJaneiroVerso1g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-1g-verso.png";
import rioJaneiroVerso2g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-2g-verso.png";
import rioJaneiroVerso5g from "../../../assets/newImages/mockups/mundos/rio-janeiro/rio-janeiro-ouro-5g-verso.png";

export const  rioJaneiroMundoMockups = {
    nome: "Rio de Janeiro",
    frente1g: rioJaneiroFrente1g,
  
    frente2g: rioJaneiroFrente2g,
  
    frente5g: rioJaneiroFrente5g,
  
    frente10g: rioJaneiroFrente10g,
  
    frente25g: rioJaneiroFrente25g,
  
    frente31g: rioJaneiroFrente31g,
  
    frente50g: rioJaneiroFrente50g,
  
    verso1g: rioJaneiroVerso1g,
    verso2g: rioJaneiroVerso2g,
  
    verso5g: rioJaneiroVerso5g,
    verso10g: rioJaneiroVerso5g,
    verso25g: rioJaneiroVerso5g,
    verso31g: rioJaneiroVerso5g,
    verso50g: rioJaneiroVerso5g,
  };

 