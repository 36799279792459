export  function PoliticaEntrega() {
  return (
    <details className="mb-2 pb-2 border-bottom">
      <summary>POLÍTICA DE ENTREGA.</summary>
      <h5 className="mt-4"> Região de Entrega:</h5>
      <p>
        <b>Correios: Enviamos para todo o Brasil.</b>
      </p>
      <p>
        Áreas com restrição de entrega domiciliar: Os Correios não efetuam a
        entrega domiciliar em algumas cidades, em área rural, logradouros de
        difícil acesso ou de risco. Caso o objeto seja destinado a uma área com
        restrições de entrega domiciliar, os Correios avaliarão qual a
        providência a ser tomada para o CEP indicado, podendo alongar o prazo de
        entrega do objeto em mais 7 dias ou enviar o objeto para uma das
        unidades dos Correios, de modo que o destinatário possa retirá-lo. Os
        Correios deixarão um aviso de chegada no endereço do destinatário, que
        deverá se dirigir à unidade dos Correios indicada, portando documentos
        que permitam a sua identificação, para fazer a retirada do objeto. O
        prazo de guarda são de 7 dias corridos para SEDEX.
      </p>
      <p>
        As entregas via Correios limitam-se ao valor de R$ 35.571,17 ( Trinta e cinco mil, trezentos e setenta e um reais e dezessete centavos) por envio. Os custos dessa postagem são adicionados ao pedido.
        Todas as postagens são realizadas com seguro (valor declarado do pedido)
        que constará a Nota Fiscal de Remessa com Ouro fixada por fora a caixa
        de seu pedido.
      </p>
      <p>
        Portador de Valores: Regiões que abrangem na modalidade são: São Paulo
        (Capital) e Grande São Paulo*
      </p>
      <p>
        *Regiões atendidas: Osasco, Diadema (centro), São Bernardo do Campo,
        Santo André, Guarulhos, Barueri, Santana de Parnaíba, São Caetano do Sul
        e Alphaville.
      </p>
      <p>
        Regiões não atendidas: Caieiras, Itapevi, Carapicuíba, Itapecerica da
        Serra, Taboão da Serra, Franco da Rocha, Francisco Morato,
        Itaquaquecetuba, Mogi das Cruzes, Mauá, Ribeirão Pires e Suzano.
      </p>
      <p>
        Demais regiões consultar nossa Central de vendas sobre a taxa de entrega
        e / ou áreas restritas.
      </p>
      <p>
        Algumas regiões estarão sujeitas ao prazo de entrega diferenciada devido
        a localização restrita, e substituídas a modalidade para Correios e
        custos repassados ao cliente.
      </p>
      <p>
        O valor mínimo, são equivalente a R$ 2.000,00 (dois mil reais).
        Verifique com o operador os pontos de entrega disponíveis. Para locais
        fora da área de cobertura será cobrado uma taxa adicional.
      </p>

      <h5>Prazo de entrega: </h5>
      <p className="text-decoration-underline">
        <b>Correios</b>
      </p>
      <p>Nossas entregas são feitas pelos Correios através de Sedex.</p>
      <p>O prazo será determinado na emissão do pedido, sendo:</p>

      <p>
        Sedex: O prazo de entrega são de até 3 a 7 dias úteis, após a
        confirmação de pagamento.
      </p>
      <p>
        Entrega domiciliar, em dias úteis, de segunda-feira até sexta-feira
        (algumas regiões atendem entregas via Correios no sábado), em todos os
        municípios do Brasil.
      </p>
      <p>
        Haverá 3 (três) tentativas de entrega em dias úteis seguidos, sendo a
        primeira na mesma data de chegada na unidade de distribuição.
      </p>
      <p>
        Sendo impossível a entrega devido a casa fechada ou ausência de pessoa
        civilmente capaz, a encomenda ficará disponível para entrega na agência
        mais próxima do endereço do destinatário, durante sete (7) dias
        corridos, após será devolvida ao remetente.
      </p>
      <p>
        A entrega no balcão da unidade postal é feita apenas ao próprio
        destinatário ou à pessoa por ele autorizada, no campo apropriado do
        aviso de chegada deixado pelo carteiro. Se a entrega não for possível
        por outros motivos, a encomenda será devolvida de imediato.
      </p>
      <p>
        A entrega da encomenda em área rural ou de risco é realizada
        exclusivamente na agência dos Correios mais próxima do endereço do
        destinatário.
      </p>
      <p>
        No caso, do pedido retornar para a Ourominas por quaisquer motivos acima
        informado, o frete de reenvio deverá ser pago pelo cliente.
      </p>

      <h5 className="mt-2">Importante: </h5>
      <ol>
        <ul>
          Em alguns casos, os Correios não enviam o aviso de chegada para o
          endereço do destinatário, por isso é muito importante rastrear seu
          pedido periodicamente (o código de rastreamento é enviado via
          WhatsApp, sempre 1 dia útil posterior a postagem).
        </ul>
        <ul>
          É muito importante verificar o prazo de guarda do objeto na unidade
          dos Correios, pois após o prazo mencionado, o objeto será devolvido
          para a Ourominas e um novo frete será cobrado para reenvio.
        </ul>
        <ul>
          As postagens são realizadas através de Sedex sem Mãos Próprias, caso
          queira adicionar essa opção, por gentileza, informe a modalidade ao
          atendente de imediato após a finalização de seu pedido.
        </ul>
        <ul>
          Ao serviço de Sedex, no caso de adicionado o serviço Mão Próprias,
          pelo qual o remetente recebe a garantia de que o objeto, por ele
          postado sob Registro, será entregue somente ao próprio destinatário,
          através da confirmação de sua identidade.
        </ul>
      </ol>

      <h5 className="mt-2">Portador de Valores:</h5>
      <p>
        Nossas entregas via portadores de valores realizadas em horário
        comercial (9h até as 18h) de segunda-feira a sexta-feira.
      </p>
      <p>O prazo será determinado na emissão do pedido.</p>
      <p>
        A instituição poderá se negar a realizar a entrega em locais que se
        encontram fora de sua área de cobertura e no caso de aceitar, poderá
        cobrar custos adicionais, que serão informados ao cliente, quando for o
        caso.
      </p>
      <p>As entregas são realizadas diretamente ao comprador.</p>
      <p>Em caso de recebimento por terceiros:</p>
      <p>
        Deverá informar os dados, preencher e assinar digitalmente o termo de
        Autorização para entregas a terceiros.
      </p>
      <p>
        OBS.: Se os dados do recebedor não baterem com os informados, não haverá
        a entrega do seu pedido.
      </p>
      <p>Havendo reagendamento será cobrado custos de reenvio do cliente.</p>
      <h5 className="mt-2"> Importante: </h5>
      <ol>
        <li>
          Em alguns casos, os portadores entram em contato com o cliente para
          informar que está no local.
        </li>
        <li>
          É muito importante verificar o prazo de espera do portador. Prazo de
          10 minutos de espera.
        </li>
        <li>
          O portador de valores vai até o local, realiza a ligação, aguarda pelo
          prazo informado, caso não seja atendido, ele seguirá o roteiro, e sua
          entrega / pedido será reagendada a entrega para próximo dia útil após
          o pagamento dos custos de reenvio.
        </li>
        <li>
          As entregas são realizadas diretamente ao comprador, através da
          confirmação de sua identidade. Caso não esteja disponível para receber
          e queira autorizar um terceiro, por gentileza, informe a modalidade ao
          atendente de imediato após a finalização de seu pedido.
        </li>
      </ol>
    </details>
  );
}

export function PoliticaEntregaCambio() {
  return (
    <details className="mb-2 pb-2 border-bottom">
      <summary>POLÍTICA DE ENTREGA.</summary>
      <h5 className="mt-4"> Região de Entrega:</h5>
      <p>
        <b>Correios: Enviamos para todo o Brasil.</b>
      </p>
      <p>
        Áreas com restrição de entrega domiciliar: Os Correios não efetuam a
        entrega domiciliar em algumas cidades, em área rural, logradouros de
        difícil acesso ou de risco. Caso o objeto seja destinado a uma área com
        restrições de entrega domiciliar, os Correios avaliarão qual a
        providência a ser tomada para o CEP indicado, podendo alongar o prazo de
        entrega do objeto em mais 7 dias ou enviar o objeto para uma das
        unidades dos Correios, de modo que o destinatário possa retirá-lo. Os
        Correios deixarão um aviso de chegada no endereço do destinatário, que
        deverá se dirigir à unidade dos Correios indicada, portando documentos
        que permitam a sua identificação, para fazer a retirada do objeto. O
        prazo de guarda são de 7 dias corridos para SEDEX.
      </p>
      <p>
        As entregas via Correios limitam-se ao valor de R$ 10.000,00 (dez mil
        reais) por envio. Os custos dessa postagem são adicionados ao pedido.
        Todas as postagens são realizadas com seguro (valor declarado do pedido)
        que constará o boleto de cambio fixado por fora a caixa de seu pedido.
      </p>
      <p>
        Portador de Valores: Regiões que abrangem na modalidade são: São Paulo
        (Capital) e Grande São Paulo*
      </p>
      <p>
        *Regiões atendidas: Osasco, Diadema (centro), São Bernardo do Campo,
        Santo André, Guarulhos, Barueri, Santana de Parnaíba, São Caetano do Sul
        e Alphaville.
      </p>
      <p>
        Regiões não atendidas: Caieiras, Itapevi, Carapicuíba, Itapecerica da
        Serra, Taboão da Serra, Franco da Rocha, Francisco Morato,
        Itaquaquecetuba, Mogi das Cruzes, Mauá, Ribeirão Pires e Suzano.
      </p>
      <p>
        Demais regiões consultar nossa Central de vendas sobre a taxa de entrega
        e / ou áreas restritas.
      </p>
      <p>
        Algumas regiões estarão sujeitas ao prazo de entrega diferenciada devido
        a localização restrita, e substituídas a modalidade para Correios e
        custos repassados ao cliente.
      </p>
      <p>
        O valor mínimo, são equivalente a R$ 2.000,00 (dois mil reais).
        Verifique com o operador os pontos de entrega disponíveis. Para locais
        fora da área de cobertura será cobrado uma taxa adicional.
      </p>

      <h5>Prazo de entrega: </h5>
      <p className="text-decoration-underline">
        <b>Correios</b>
      </p>
      <p>Nossas entregas são feitas pelos Correios através de Sedex.</p>
      <p>O prazo será determinado na emissão do pedido, sendo:</p>

      <p>
        Sedex: O prazo de entrega são de até 3 a 7 dias úteis, após a
        confirmação de pagamento.
      </p>
      <p>
        Entrega domiciliar, em dias úteis, de segunda-feira até sexta-feira
        (algumas regiões atendem entregas via Correios no sábado), em todos os
        municípios do Brasil.
      </p>
      <p>
        Haverá 3 (três) tentativas de entrega em dias úteis seguidos, sendo a
        primeira na mesma data de chegada na unidade de distribuição.
      </p>
      <p>
        Sendo impossível a entrega devido a casa fechada ou ausência de pessoa
        civilmente capaz, a encomenda ficará disponível para entrega na agência
        mais próxima do endereço do destinatário, durante sete (7) dias
        corridos, após será devolvida ao remetente.
      </p>
      <p>
        A entrega no balcão da unidade postal é feita apenas ao próprio
        destinatário ou à pessoa por ele autorizada, no campo apropriado do
        aviso de chegada deixado pelo carteiro. Se a entrega não for possível
        por outros motivos, a encomenda será devolvida de imediato.
      </p>
      <p>
        A entrega da encomenda em área rural ou de risco é realizada
        exclusivamente na agência dos Correios mais próxima do endereço do
        destinatário.
      </p>
      <p>
        No caso, do pedido retornar para a Ourominas por quaisquer motivos acima
        informado, o frete de reenvio deverá ser pago pelo cliente.
      </p>

      <h5 className="mt-2">Importante: </h5>
      <ol>
        <ul>
          Em alguns casos, os Correios não enviam o aviso de chegada para o
          endereço do destinatário, por isso é muito importante rastrear seu
          pedido periodicamente (o código de rastreamento é enviado via
          WhatsApp, sempre 1 dia útil posterior a postagem).
        </ul>
        <ul>
          É muito importante verificar o prazo de guarda do objeto na unidade
          dos Correios, pois após o prazo mencionado, o objeto será devolvido
          para a Ourominas e um novo frete será cobrado para reenvio.
        </ul>
        <ul>
          As postagens são realizadas através de Sedex sem Mãos Próprias, caso
          queira adicionar essa opção, por gentileza, informe a modalidade ao
          atendente de imediato após a finalização de seu pedido.
        </ul>
        <ul>
          Ao serviço de Sedex, no caso de adicionado o serviço Mão Próprias,
          pelo qual o remetente recebe a garantia de que o objeto, por ele
          postado sob Registro, será entregue somente ao próprio destinatário,
          através da confirmação de sua identidade.
        </ul>
      </ol>

      <h5 className="mt-2">Portador de Valores:</h5>
      <p>
        Nossas entregas via portadores de valores realizadas em horário
        comercial (9h até as 18h) de segunda-feira a sexta-feira.
      </p>
      <p>O prazo será determinado na emissão do pedido.</p>
      <p>
        A instituição poderá se negar a realizar a entrega em locais que se
        encontram fora de sua área de cobertura e no caso de aceitar, poderá
        cobrar custos adicionais, que serão informados ao cliente, quando for o
        caso.
      </p>
      <p>As entregas são realizadas diretamente ao comprador.</p>
      <p>Em caso de recebimento por terceiros:</p>
      <p>
        Deverá informar os dados, preencher e assinar digitalmente o termo de
        Autorização para entregas a terceiros.
      </p>
      <p>
        OBS.: Se os dados do recebedor não baterem com os informados, não haverá
        a entrega do seu pedido.
      </p>
      <p>Havendo reagendamento será cobrado custos de reenvio do cliente.</p>
      <h5 className="mt-2"> Importante: </h5>
      <ol>
        <li>
          Em alguns casos, os portadores entram em contato com o cliente para
          informar que está no local.
        </li>
        <li>
          É muito importante verificar o prazo de espera do portador. Prazo de
          10 minutos de espera.
        </li>
        <li>
          O portador de valores vai até o local, realiza a ligação, aguarda pelo
          prazo informado, caso não seja atendido, ele seguirá o roteiro, e sua
          entrega / pedido será reagendada a entrega para próximo dia útil após
          o pagamento dos custos de reenvio.
        </li>
        <li>
          As entregas são realizadas diretamente ao comprador, através da
          confirmação de sua identidade. Caso não esteja disponível para receber
          e queira autorizar um terceiro, por gentileza, informe a modalidade ao
          atendente de imediato após a finalização de seu pedido.
        </li>
      </ol>
    </details>
  );
}
