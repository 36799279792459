import goianiaFrente1g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-1g-frente.png";
import goianiaFrente2g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-2g-frente.png";
import goianiaFrente5g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-5g-frente.png";
import goianiaFrente10g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-10g-frente.png";
import goianiaFrente25g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-25g-frente.png";
import goianiaFrente31g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-31g-frente.png";
import goianiaFrente50g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-50g-frente.png";
import goianiaVerso1g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-1g-verso.png";
import goianiaVerso2g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-2g-verso.png";
import goianiaVerso5g from "../../../assets/newImages/mockups/brasil-turistico/goiania/goiania-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  goianiaMockups = {
    nome: "Goiânia",
    frente1g: goianiaFrente1g,

    frente2g: goianiaFrente2g,

    frente5g: goianiaFrente5g,

    frente10g: goianiaFrente10g,

    frente25g: goianiaFrente25g,

    frente31g: goianiaFrente31g,

    frente50g: goianiaFrente50g,

    verso1g: goianiaVerso1g,
    verso2g: goianiaVerso2g,

    verso5g: goianiaVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const goianiaBR = [
    {
      name: "Goiânia",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]