import euaFrente1g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-1g-frente.png";
import euaFrente2g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-2g-frente.png";
import euaFrente5g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-5g-frente.png";

import euaVerso1g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-1g-verso.png";
import euaVerso2g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-2g-verso.png";
import euaVerso5g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-5g-verso.png";
import euaVerso10g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-10g-verso.png";
import euaVerso25g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-25g-verso.png";
import euaVerso31g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-31g-verso.png";
import euaVerso50g from "../../../assets/newImages/mockups/golden-cup/eua/eua-ouro-50g-verso.png";

export const  euaMockups = {
    nome: "EUA",
    frente1g: euaFrente1g,
  
    frente2g: euaFrente2g,
  
    frente5g: euaFrente5g,  

    frente10g: euaFrente5g,
    frente25g:euaFrente5g,
    frente31g: euaFrente5g,
    frente50g: euaFrente5g,
  
    verso1g: euaVerso1g,
    verso2g: euaVerso2g,
  
    verso5g: euaVerso5g,

    verso10g: euaVerso10g,
    verso25g: euaVerso25g,
    verso31g: euaVerso31g,
    verso50g: euaVerso50g
  };

 