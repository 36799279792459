import curitibaFrente1g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-1g-frente.png";
import curitibaFrente2g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-2g-frente.png";
import curitibaFrente5g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-5g-frente.png";
import curitibaFrente10g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-10g-frente.png";
import curitibaFrente25g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-25g-frente.png";
import curitibaFrente31g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-31g-frente.png";
import curitibaFrente50g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-50g-frente.png";
import curitibaVerso1g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-1g-verso.png";
import curitibaVerso2g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-2g-verso.png";
import curitibaVerso5g from "../../../assets/newImages/mockups/brasil-turistico/curitiba/curitiba-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  curitibaMockups = {
    nome: "Curitiba",
    frente1g: curitibaFrente1g,

    frente2g: curitibaFrente2g,

    frente5g: curitibaFrente5g,

    frente10g: curitibaFrente10g,

    frente25g: curitibaFrente25g,

    frente31g: curitibaFrente31g,

    frente50g: curitibaFrente50g,

    verso1g: curitibaVerso1g,
    verso2g: curitibaVerso2g,

    verso5g: curitibaVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const curitibaBR = [
    {
      name: "Curitiba",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]