export const profissoes = [
  {
    name: "Advogada de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Advogado de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Arquiteta de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Arquiteto de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Artista de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Ator de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Atriz de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Dentista de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Economista de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Empresária de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Empresário de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Enfermeira de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Enfermeiro de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Engenheira de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Engenheiro de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Farmacêutica de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Farmacêutico de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Fotógrafa de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Fotógrafo de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Médica de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Médico de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Nutricionista de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Professor de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Professora de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Psicóloga de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Psicólogo de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Publicitária de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, {
    name: "Publicitário de Ouro",
    value: [
      "OURO BARRA (1g)",
      "OURO BARRA (2g)",
      "OURO BARRA (5g)",
      "OURO BARRA (10g)",
      "OURO BARRA (25g)",
      "OURO BARRA (31,104g)",
      "OURO BARRA (50g)",
    ]
  }, 
    
    
  ];