import React, { useEffect, useState } from "react";
import styled from "styled-components";
import spinner from "../../assets/spinner.gif";
import "./cancellation.css";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  maskDDD,
  maskPhoneNotDDD,
  validDDD,
  validEmail,
  validTel,
} from "../../utils/regex";
import { SendButton } from "../buttons/send";
import { putNewsLetter } from "../../services/cotacoes";

export default function Cancellation() {
  const [contato, setContato] = useState("");
  const [errContato, setErrContato] = useState();
  const [email, setEmail] = useState("");
  const [errEmail, setErrEmail] = useState();
  const [tel, setTel] = useState("");
  const [errTel, setErrTel] = useState();
  const [ddd, setDDD] = useState("");
  const [errDDD, setErrDDD] = useState();
  const [load, setLoad] = useState(false);
  const [errText, setErrText] = useState("");
  const [success, setSuccess] = useState("");
  let cont = 0;

  function limpar() {
    setErrDDD("");
    setErrTel("");
    setErrText("");
    setSuccess("");

    validate();
    if (cont === 0) {
      setLoad(true);

      if (contato === 3) {
        postEmailPhone();
      } else {
        const endpoint =
          contato === 1
            ? `/email/${email}`
            : contato === 2
              ? `/telefone/${ddd + tel}`
              : null;

        putNewsLetter(endpoint)
          .then(() => {
            setContato("");
            setSuccess("Cadastro cancelado com sucesso!");
          })
          .catch((err) => {
            if (err.response.status === 404) {
              setErrText("Email ou Telefone não encontrado!");
            }
          })
          .finally(() => setLoad(false));
      }
    }

  }

  useEffect(() => {
    setErrDDD("");
    setEmail("");
    setTel("");
    setDDD("")
    setErrEmail("");
    setErrTel("");
    setErrText("");
  }, [contato]);

  function validate() {
    if (contato === !1 || contato === !2 || contato === !3) {
      setErrContato("Informe o contato!");
      cont++;
    }
    if ((contato === 1 && email === "") || (contato === 3 && email === "")) {
      setErrEmail("Informe seu email!");
      cont++;
    } else if ((contato === 1 && !validEmail.test(email)) || (contato === 3 && !validEmail.test(email))) {
      setErrEmail("Informe um email válido!");
      cont++;
    }

    if ((contato === 2 && tel === "") || (contato === 3 && tel === "")) {
      setErrTel("Informe seu Telefone");
      cont++;
    } else if ((contato === 2 && !validTel.test(tel)) || (contato === 3 && !validTel.test(tel))) {
      setErrTel("Informe um telefone válido!");
      cont++;
    }

    if ((contato === 2 && ddd === "") || (contato === 3 && ddd === "")) {
      setErrTel("Informe seu DDD");
      cont++;
    } else if ((contato === 2 && !validDDD.test(ddd)) || (contato === 3 && !validDDD.test(ddd))) {
      setErrTel("Informe um DDD válido!");
      cont++;
    }
  }

  async function postEmailPhone() {
    setSuccess("");

    setErrText("")
    let finaly = true;
    await putNewsLetter(`/email/${email}`)
      .then(() => {
        setSuccess(succ => succ +"Email cancelado com sucesso!");
      })
      .catch((err) => {
        if (err.response.status === 404) {
          finaly = false;
          setErrText(err => err + "\nEmail não encontrado!");
        }
      })
      .finally(() => setLoad(false));

    await putNewsLetter(`/telefone/${ddd + tel}`)
      .then(() => {
        setSuccess(succ => succ + "\nWhatsapp cancelado com sucesso!");
      })
      .catch((err) => {
        if (err.response.status === 404) {
          finaly = false;
          setErrText(err => err + "\nWhatsapp não encontrado!");
        }
      })
      .finally(() => setLoad(false));

    if(finaly){
      setContato("");
    }
  }
  return (
    <Container id="cancellation">
      <Title>Cancelamento de
        de Cotações</Title>

      <BoxInput>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            Forma de contato
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={contato}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            label="Receber Cotação"
            placeholder="Receber Cotação"
            onChange={(e) => setContato(e.target.value)}
          >
            <MenuItem value={1}>Email</MenuItem>
            <MenuItem value={2}>WhatsApp</MenuItem>
            <MenuItem value={3}>Email e WhatsApp</MenuItem>
          </Select>
          <FormHelperText>{errContato}</FormHelperText>
        </FormControl>
      </BoxInput>
      {contato === 1 ? (
        <BoxInput>
          <TextField
            style={{ backgroundColor: "white" }}
            helperText={errEmail}
            className="bg-white"
            placeholder="EMAIL"
            label="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </BoxInput>
      ) : contato === 2 ? (
        <BoxInputTel>
          <TextField
            style={{ width: "100px", marginRight: "10px" }}
            className="bg-white"
            placeholder="DDD"
            label="DDD"
            value={ddd}
            helperText={errDDD}
            onChange={(e) => setDDD(maskDDD(e.target.value))}
          />
          <TextField
            helperText={errTel}
            className="bg-white"
            placeholder="TELEFONE"
            label="Telefone"
            type="text"
            value={tel}
            onChange={(e) => setTel(maskPhoneNotDDD(e.target.value))}
          />
        </BoxInputTel>
      ) : contato === 3 ? (
        <>
          <BoxInput>
            <TextField
              style={{ backgroundColor: "white" }}
              helperText={errEmail}
              className="bg-white"
              placeholder="EMAIL"
              label="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </BoxInput>
          <BoxInputTel>
            <TextField
              style={{ width: "100px", marginRight: "10px" }}
              className="bg-white"
              placeholder="DDD"
              label="DDD"
              value={ddd}
              onChange={(e) => setDDD(maskDDD(e.target.value))}
            />
            <TextField
              helperText={errTel}
              className="bg-white"
              placeholder="TELEFONE"
              label="Telefone"
              type="text"
              value={tel}
              onChange={(e) => setTel(maskPhoneNotDDD(e.target.value))}
            />
          </BoxInputTel>
        </>
      ) : null}

      {contato && (
        <>
          <SendButton onClick={limpar}>
            <b>CANCELAR CADASTRO</b>
          </SendButton>
        </>
      )}

      {load && <Loading src={spinner} />}
      <Error>{errText}</Error>
      <Success>{success}</Success>
    </Container>
  );
}

const Container = styled.div`
  height: 85vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const Title = styled.p`
  font-weight: 600;
  margin: 20px 0;
  color: #004133;
  text-align: center;
  @media (min-width: 320px) {
    font-size: 20px;
  }
  @media (min-width: 460px) {
    font-size: 35px;
  }

`;
const BoxInput = styled.div`
  width: 250px;
  margin: 15px;
  && > * {
    width: 100%;
    background-color: #fff;
  }
`;

const BoxInputTel = styled.div`
  width: 250px;
  margin: 15px;
  display: flex;
  background-color: #fff;
  && > * {
    background-color: #fff;
  }
`;

const Loading = styled.img`
  margin: 10px 0;
  width: 35px;
`;
const Error = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: red;
  white-space: pre-line;
  text-align: center;
`;
const Success = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: green;
  white-space: pre-line;
  text-align: center;
`;
