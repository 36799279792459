import React, { useEffect, useRef, useState } from "react";
import "../../../styles/ouroninas.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { postAlert } from "../../../services/api";
import "./alert.css";
import { FormHelperText, InputLabel } from "@mui/material";
import {
  maskDDD,
  maskFloat,
  maskNumber,
  maskOnlyLetters,
  maskPhoneNotDDD,
  validEmail,
} from "../../../utils/regex";
import { AlertModel } from "../../../models/Alert";
import CountryFlags from "../../../utils/countryFlags";
import { TextSuccess } from "../../styled/text";

export default function Alert({ ouro, moedas }) {
  const [currency, setCurrency] = useState(0);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [telephone, setTelephone] = useState("");
  const [ddd, setDdd] = useState("");
  const [maxDate, setmaxDate] = useState("2023-12-05");
  const [newCot, setNewCot] = useState([0]);
  const [cotDesejada, setCotDesejada] = useState();
  const [errCotDesejada, setErrCotDesejada] = useState();
  const [check, setCheck] = useState(false);
  const [errCheck, setErrCheck] = useState();
  const [dolar, setDolar] = useState([]);
  const [libra, setLibra] = useState([]);
  const [euro, setEuro] = useState([]);
  const [errName, setErrName] = useState();
  const [errEmail, setErrEmail] = useState();
  const [errTelephone, setErrTelephone] = useState();
  const [errCurrency, setErrCurrency] = useState();
  const [cod, setCod] = useState();
  const [contato, setContato] = useState('');
  const [errContato, setErrContato] = useState();
  const [coinName, setCoinName] = useState('');
  const [success, setSuccess] = useState('');
  const ref = useRef(null);
  let count = 0;
  const alert = AlertModel;
  /*useEffect(() => {
    setInterval(() => {
      filterMoedas(moedas);
    }, 100000);
  }, []);*/
  useEffect(() => {
    if(moedas && Object.keys(moedas).length > 0){
      filterMoedas(moedas);

    }
  }, [moedas]);
  async function filterMoedas(coin) {
    setDolar(() => coin.filter((moeda) => moeda.codIso.includes("EUSCOM")));
    setEuro(() => coin.filter((moeda) => moeda.codIso.includes("EUR")));
    setLibra(() => coin.filter((moeda) => moeda.codIso.includes("GBP")));
  }
  const selectMoedas = async (event) => {
    let moeda = event.target.value;
    setCurrency(moeda);
    setCoinName(moeda);
    switch (moeda) {
      case 1:
        await setNewCot(ouro[0]?.calculoGramaturaVenda);
        setCod(ouro[0]?.codIso);
        break;
      case 2:
        await setNewCot(ouro[2]?.calculoVenda);
        setCod(ouro[2]?.codIso);
        break;
      case 3:
        setNewCot(dolar[0]?.calculoIOFVenda);
        setCod(dolar[0]?.codIso);
        break;
      case 4:
        setNewCot(libra[0]?.calculoIOFVenda);
        setCod(libra[0]?.codIso);
        break;
      case 5:
        setNewCot(euro[0]?.calculoIOFVenda);
        setCod(euro[0]?.codIso);
        break;
      default:
        break;
    }
  };
  function validationInputs() {
    setSuccess("")
    setErrCheck("");
    setErrCotDesejada("");
    setErrCurrency("");
    setErrName("");
    setErrEmail("");
    setErrContato("");
    setErrTelephone("");
    setErrContato("");
    if (currency === 0) {
      count++;
      setErrCurrency("Selecione a moeda!");
    }
    if (fullName === "" || fullName == null) {
      count++;
      setErrName("Digite o seu nome!");
    }
    if (
      contato === 0 ||
      contato === undefined ||
      contato === "" ||
      contato === null
    ) {
      count++;
      setErrContato("Selecione o contato");
    }
    if (contato === 1) {
      setTelephone("");
      setDdd("");
      if (email === "") {
        count++;
        setErrEmail("Digite o seu email!");
      } else if (!validEmail.test(email)) {
        setErrEmail("Email inválido!");
        count++;
      }
    } else if (contato === 2) {
      setEmail("");
      if (!ddd || !telephone) {
        count++;
        setErrTelephone("Digite o telefone!");
      }
    }
    if (!cotDesejada) {
      count++;
      setErrCotDesejada("Digite a cotação!");
    } else if (parseFloat(cotDesejada) > newCot) {
      count++;
      setErrCotDesejada("O valor deve ser menor que a cotação atual!");
    }
    if (!check) {
      count++;
      setErrCheck("Marque o aceite!");
    }
  }
  function createPost() {
    validationInputs();
    if (count === 0) {
      alert.nome = fullName;
      alert.email = contato === 1 ? email : "";
      alert.telefone = contato === 2 ? ddd + telephone : "";
      alert.valor = parseFloat(maskFloat(cotDesejada));
      alert.codIso = cod;
      alert.dataLimite = maxDate;
      postAlert(alert).then(() => {
        setSuccess("Alerta criado com sucesso!")
        setCurrency(0);
        setCotDesejada("");
        setFullName("");
        setDdd("");
        setTelephone("");
        setEmail("");
        setContato('')
        setCoinName('')
      });
    }   
  }

  return (
    <section className="mb-5">
      <div className="Alert-cont-h p-0">
        <h3>CRIAR ALERTA</h3>
        <p className="m-0">
          Enviamos um alerta quando a cotação atingir o preço que deseja.
        </p>
      </div>
      <Box
        sx={{
          border: "1px dotted grey",
          background: "#f7f7f7",
        }}
      >
        <div className="sectAlert">
          <div className="formaContato">
            <div className="eachAlert my-3">
              <FormControl className="w-100 bg-white">
                <InputLabel id="demo-simple-select-label">
                  Selecione a moeda
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="coinAlert"
                  ref={ref}
                  onChange={selectMoedas}
                  value={coinName}
                  defaultValue={0}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label="Selecione a moeda"
                  placeholder="COTAÇÃO"
                >
                  <MenuItem value={1}> <CountryFlags cod="OURO" /> Ouro (grama)</MenuItem>
                  <MenuItem value={3}><CountryFlags cod="EUSCOM"/>   Dólar Americano (em espécie)</MenuItem>
                  <MenuItem value={4}><CountryFlags cod="GBP"/>  Libra Esterlina (em espécie)</MenuItem>
                  <MenuItem value={5}><CountryFlags cod="EUR"/>  Euro (em espécie)</MenuItem>
                </Select>
                <FormHelperText>{errCurrency}</FormHelperText>
              </FormControl>
            </div>
            <div className="divCot eachAlert my-3 ">
              <div className="d-flex">
                <div>
                  <TextField
                    className="bg-white me-2"
                    type="text"
                    placeholder="COTAÇÃO"
                    label="Cotação Atual"
                    value={parseFloat(newCot).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    disabled
                  />
                </div>

                <TextField
                  className="cotDesejada bg-white col-6"
                  value={cotDesejada}
                  helperText={errCotDesejada}
                  placeholder="COTAÇÃO"
                  label="Cotação desejada"
                  onChange={(e) => setCotDesejada(maskNumber(e.target.value))}
                />
              </div>
            </div>
            <div className=" eachAlert my-3 divDateAlert">
              <TextField
                className="bg-white w-100"
                name="Data máxima"
                label="Data máxima"
                InputLabelProps={{ shrink: true }}
                type="date"
                value={maxDate}
                onChange={(e) => {
                  setmaxDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="formaContato">
            <div className="eachAlert my-3 ">
              <FormControl className="col-12 bg-white formCotacao">
                <InputLabel id="demo-simple-select-label">
                  Forma de Contato
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={contato}
                  defaultValue={0}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label="Forma de contato"
                  placeholder="Forma de Contato"
                  onChange={(e) => setContato(e.target.value)}
                >
                  <MenuItem value={1}>Email</MenuItem>
                  <MenuItem value={2}>Whatsapp</MenuItem> 
                </Select>
                <FormHelperText>{errContato}</FormHelperText>
              </FormControl>
            </div>
            <div className="eachAlert my-3 d-flex flex-column">
              <TextField
                helperText={errName}
                className="bg-white w-100"
                placeholder="NOME"
                label="Nome"
                value={fullName}
                onChange={(e) => setFullName(maskOnlyLetters(e.target.value))}
              />
            </div>
            {contato === 1 ? (
              <div className="eachAlert my-3 d-flex flex-column">
                <TextField
                  helperText={errEmail}
                  className="bg-white"
                  placeholder="EMAIL"
                  value={email}
                  label="Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            ) : contato === 2 ? (
              <div className="eachAlert my-3 d-flex divCelDD">
                <div className="divDDD">
                  <TextField
                    className="bg-white"
                    placeholder="DDD"
                    label="DDD"
                    value={ddd}
                    onChange={(e) => setDdd(maskDDD(e.target.value))}
                  />
                </div>
                <div className="ms-2 col-9 divCel">
                  <TextField
                    helperText={errTelephone}
                    className="bg-white w-100"
                    placeholder="Celular"
                    label="Celular"
                    value={telephone}
                    onChange={(e) =>
                      setTelephone(maskPhoneNotDDD(e.target.value))
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="divCheck col-12">
            <input
              className="form-check-input checkInput"
              type="checkbox"
              value=""
              id="check"
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
            />
            <label className="form-check-label lblCheck">
            Autorizo que a OM entre em contato comigo quando atingir a cotação
              desejada.
            </label>
          </div>
          <div className="col-12">
            <span className="text-danger fs-6" value={errCheck}>
              {errCheck}
            </span>
          </div>
          <div className="py-2 mb-2 divButton btnAlertCont">
            <button
              type="submit"
              className="send-to btn-green-hover"
              onClick={createPost}
            >
              CRIAR ALERTA
            </button>
          </div>
          
          <div className="divSuccess">
            <TextSuccess>{success}</TextSuccess>
          </div>
          
        </div>
          
      </Box>
    </section>
  );
}
