import React from "react";
import img from "../../../assets/newImages/export-ouro.png";
import img2 from "../../../assets/om-mapa.jpg";
import "../../pages.css";

export default function Exportacao() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 mt-5">
        <div className="col-6  d-flex flex-column p-div sect">
        <p className="fw-bolder text-decoration-underline">EXPORTAÇÃO</p>
          <p>
          A Ourominas (OM) concentra-se no segmento de Comércio Exterior e possui excelência na exportação de metais preciosos. Por se tratar de uma Instituição Financeira (IF), a OM é autorizada pela Receita Federal e Banco Central a exportar ouro.
          </p>
          <p>
          Por meio de planejamento e visão, possui estrutura para organizar operações internacionais e nacionais, incluindo também logística rodoviárias e aéreas. Objetivando bons preços e planejamento tributário, a empresa oferece aos seus fornecedores todos os benefícios fiscais relacionados à atividade exportadora e acesso em diversas regiões do mundo, em destaque Ásia, Europa e América do Norte.
          </p>
          <p>
          O Brasil é um país exportador, tendo Mato Grosso (MT), Pará (PA), Minas Geras (MG), Goiás (GO) e Bahia (BA) os estados produtores de maior representatividade onde há importantes reservas de ouro, e são nos estados de MT e PA que são realizadas legalmente as extrações de ouro da OM onde possuem a maior concentração de minério de ouro no Brasil.
          </p>
          <p>
          A OM possui parcerias com as maiores mineradoras, garimpos de titularidade PF (Pessoa Física) e cooperativas autorizadas e regulamentadas pelos órgãos competentes do Brasil. A OM também possui a sua própria fundidora com uma estrutura altamente tecnológica, onde são constantemente monitoradas a fim de garantir a plena eficiência dos seus serviços que funciona também para refino de metais preciosos, recuperação de metais preciosos e análise de metais preciosos com alto padrão de ética, qualidade e confiança.
          </p>
        </div>
        <div className="col-6 div-image">
          <img src={img} className="image " alt="grupo-om"></img>
        </div>
      </section>

      <section className="w-100 mt-4 d-flex justify-content-center">
        <div className="">
        <h1 className="fw-bold mb-3 text-center">Os Tipos de Caminho do Ouro</h1>
        <img src={img2} className="image caminho-ouro" alt="grupo-om"></img>
        </div>
      </section>
    </article>
  );
}
