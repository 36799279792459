
import Carousel from "react-bootstrap/Carousel";
import "./carousel.css";

export function CarrouselBootstrap({ props }) {
  return (
    <section>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img1}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img2}
            alt="Second slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img3}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  );
}

export function CarrouselBootstrapOuro({ props }) {
  return (
    <section>
      <Carousel className="CarrouselGold">
        <Carousel.Item interval={200000}>
          <img className="d-block w-100" src={props?.img1} alt="First slide" />
          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <img className="d-block w-100" src={props?.img2} alt="Second slide" />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <img className="d-block w-100" src={props?.img3} alt="Third slide" />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={200000}>
          <img className="d-block w-100" src={props?.img4} alt="Third slide" />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  );
}

export function CarrouselBootstrap7({ props }) {
  return (
    <section>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img1}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img2}
            alt="Second slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img3}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img4}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img5}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img6}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={props[0]?.img7}
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3></h3> */}
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  );
}

export function CarrouselBootstrapFinal({ props }) {
  return (
    <section>
      <Carousel>
        {props.map((item, i) => {
          return (
            <Carousel.Item key={i++}>
              <img className="d-block w-100" src={item.img} alt=" slide" />
              <Carousel.Caption>
                {/* <h3></h3> */}
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
}
export function CarrouselDiv({ props }) {
  return (
    <section>
      <Carousel>
        {props.map((item, i) => {
          return (
            <Carousel.Item key={i++}>
              {item}
              <Carousel.Caption>
                {/* <h3></h3> */}
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
}
