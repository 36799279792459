import clienteFrente1g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-1g-frente.png";
import clienteFrente2g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-2g-frente.png";
import clienteFrente5g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-5g-frente.png";
import clienteFrente10g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-10g-frente.png";
import clienteFrente25g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-25g-frente.png";
import clienteFrente31g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-31g-frente.png";
import clienteFrente50g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-50g-frente.png";
import clienteVerso1g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-1g-verso.png";
import clienteVerso2g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-2g-verso.png";
import clienteVerso5g from "../../../assets/newImages/mockups/empresa-ouro/cliente/cliente-ouro-5g-verso.png";

export const  clienteMockups = {
    nome: "Cliente",
    frente1g: clienteFrente1g,
  
    frente2g: clienteFrente2g,
  
    frente5g: clienteFrente5g, 
    frente10g: clienteFrente10g,
    frente25g:clienteFrente25g,
    frente31g: clienteFrente31g,
    frente50g: clienteFrente50g, 
  
    verso1g: clienteVerso1g,
    verso2g: clienteVerso2g,
  
    verso5g: clienteVerso5g
  };