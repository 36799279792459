import economistaFrente1g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-1g-frente.png";
import economistaFrente2g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-2g-frente.png";
import economistaFrente5g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-5g-frente.png";
import economistaFrente10g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-10g-frente.png";
import economistaFrente25g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-25g-frente.png";
import economistaFrente31g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-31g-frente.png";
import economistaFrente50g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-50g-frente.png";
import economistaVerso1g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-1g-verso.png";
import economistaVerso2g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-2g-verso.png";
import economistaVerso5g from "../../../assets/newImages/mockups/profissoes/economista/economista-ouro-5g-verso.png";

export const  economistaMockups = {
    nome: "Economista de Ouro",
    frente1g: economistaFrente1g,
  
    frente2g: economistaFrente2g,
  
    frente5g: economistaFrente5g,
  
    frente10g: economistaFrente10g,
  
    frente25g: economistaFrente25g,
  
    frente31g: economistaFrente31g,
  
    frente50g: economistaFrente50g,
  
    verso1g: economistaVerso1g,
    verso2g: economistaVerso2g,
  
    verso5g: economistaVerso5g,
    verso10g: economistaVerso5g,
    verso25g: economistaVerso5g,
    verso31g: economistaVerso5g,
    verso50g: economistaVerso5g,
  };

 