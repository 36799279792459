import sshdFrente1g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-1g-frente.png";
import sshdFrente2g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-2g-frente.png";
import sshdFrente5g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-5g-frente.png";
import sshdFrente10g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-10g-frente.png";
import sshdFrente25g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-25g-frente.png";
import sshdFrente31g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-31g-frente.png";
import sshdFrente50g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-50g-frente.png";
import sshdVerso1g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-1g-verso.png";
import sshdVerso2g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-2g-verso.png";
import sshdVerso5g from "../../../assets/newImages/mockups/sentimentos/sshd-escrito/sshd-escrito-5g-verso.png";

export const  sshdMockups = {
    nome: "S.S.H.D",
    frente1g: sshdFrente1g,
  
    frente2g: sshdFrente2g,
  
    frente5g: sshdFrente5g, 
    frente10g: sshdFrente10g,
    frente25g:sshdFrente25g,
    frente31g: sshdFrente31g,
    frente50g: sshdFrente50g, 
  
    verso1g: sshdVerso1g,
    verso2g: sshdVerso2g,
  
    verso5g: sshdVerso5g,
    verso10g: sshdVerso5g,
    verso25g: sshdVerso5g,
    verso31g: sshdVerso5g,
    verso50g: sshdVerso5g,
  };