import React from 'react';
import './cards.css';
import { NavLink } from 'react-router-dom';

export function CardContaGlobal({ href, src, productName }) {
  return (
    <NavLink className="aCard card text-center" to={href}>
      <div className="card-body">
        <img
          src={src}
          style={{ height: 'auto', width: 100 }}
          alt={`ícone do ${productName}`}
        />
        <h6 className="title-decorative mt-2 text-muted text-transform-uppercase">
          {productName}
        </h6>
      </div>
    </NavLink>
  );
}
