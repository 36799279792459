import publicitarioFrente1g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-1g-frente.png";
import publicitarioFrente2g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-2g-frente.png";
import publicitarioFrente5g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-5g-frente.png";
import publicitarioFrente10g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-10g-frente.png";
import publicitarioFrente25g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-25g-frente.png";
import publicitarioFrente31g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-31g-frente.png";
import publicitarioFrente50g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-50g-frente.png";
import publicitarioVerso1g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-1g-verso.png";
import publicitarioVerso2g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-2g-verso.png";
import publicitarioVerso5g from "../../../assets/newImages/mockups/profissoes/publicitario/publicitario-ouro-5g-verso.png";

export const  publicitarioMockups = {
    nome: "Publicitário de Ouro",
    frente1g: publicitarioFrente1g,
  
    frente2g: publicitarioFrente2g,
  
    frente5g: publicitarioFrente5g,
  
    frente10g: publicitarioFrente10g,
  
    frente25g: publicitarioFrente25g,
  
    frente31g: publicitarioFrente31g,
  
    frente50g: publicitarioFrente50g,
  
    verso1g: publicitarioVerso1g,
    verso2g: publicitarioVerso2g,
  
    verso5g: publicitarioVerso5g,
    verso10g: publicitarioVerso5g,
    verso25g: publicitarioVerso5g,
    verso31g: publicitarioVerso5g,
    verso50g: publicitarioVerso5g,
  };

 