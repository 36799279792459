import spFrente1g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-1g-frente.png";
import spFrente2g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-2g-frente.png";
import spFrente5g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-5g-frente.png";
import spFrente10g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-10g-frente.png";
import spFrente25g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-25g-frente.png";
import spFrente31g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-31g-frente.png";
import spFrente50g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-50g-frente.png";
import spVerso1g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-1g-verso.png";
import spVerso2g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-2g-verso.png";
import spVerso5g from "../../../assets/newImages/mockups/brasil-turistico/sao-paulo/sao-paulo-5g-verso.png";
import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  SaoPauloMockups = {
    nome: "São Paulo",
    frente1g: spFrente1g,

    frente2g: spFrente2g,

    frente5g: spFrente5g,

    frente10g: spFrente10g,

    frente25g: spFrente25g,

    frente31g: spFrente31g,

    frente50g: spFrente50g,

    verso1g: spVerso1g,
    verso2g: spVerso2g,

    verso5g: spVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const saoPauloBR = [
    {
      name: "São Paulo",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]