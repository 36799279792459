import diretoraFrente1g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-1g-frente.png";
import diretoraFrente2g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-2g-frente.png";
import diretoraFrente5g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-5g-frente.png";
import diretoraFrente10g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-10g-frente.png";
import diretoraFrente25g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-25g-frente.png";
import diretoraFrente31g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-31g-frente.png";
import diretoraFrente50g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-50g-frente.png";
import diretoraVerso1g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-1g-verso.png";
import diretoraVerso2g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-2g-verso.png";
import diretoraVerso5g from "../../../assets/newImages/mockups/empresa-ouro/diretora/diretora-ouro-5g-verso.png";

export const  diretoraMockups = {
    nome: "Diretora",
    frente1g: diretoraFrente1g,
  
    frente2g: diretoraFrente2g,
  
    frente5g: diretoraFrente5g, 
    frente10g: diretoraFrente10g,
    frente25g:diretoraFrente25g,
    frente31g: diretoraFrente31g,
    frente50g: diretoraFrente50g, 
  
    verso1g: diretoraVerso1g,
    verso2g: diretoraVerso2g,
  
    verso5g: diretoraVerso5g
  };