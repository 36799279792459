import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./tabLojas.css";
import {
  Alagoas,
  Bahia,
  Fortaleza,
  GOIAS,
  GrandeSP,
  InteriorSP,
  ItemsLojasAutorizadas,
  Maranhao,
  MatoGrosso,
  MinasGerais,
  Para,
  Parana,
  Recife,
  // RioGrandeSul,
  RioJaneiro,
  SaoPaulo,
  Sergipe,
} from "./itemsCorrespondentes";
import styled from "styled-components";
import { SaoPauloPropria, ItemsLojasProprias, GrandeSaoPaulo, InteriorSaoPaulo } from "./itemLojasProprias";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabLojasAutorizadas() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <BoxTab id="lojas">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="AL" {...a11yProps(0)} className="tabItems" />
          <Tab label="BA" {...a11yProps(1)} className="tabItems" />
          {/* <Tab label="CE" {...a11yProps(2)} className="tabItems" /> */}
          <Tab label="GO" {...a11yProps(3)} className="tabItems" />
          <Tab label="MA" {...a11yProps(4)} className="tabItems" />
          <Tab label="MT" {...a11yProps(5)} className="tabItems" />
          <Tab label="MG" {...a11yProps(6)} className="tabItems" />
          <Tab label="PA" {...a11yProps(7)} className="tabItems" />
          <Tab label="PE" {...a11yProps(8)} className="tabItems" />
          <Tab label="PR" {...a11yProps(9)} className="tabItems" />
          <Tab label="RJ" {...a11yProps(10)} className="tabItems" />
          {/* <Tab label="RS" {...a11yProps(10)} className="tabItems" /> */}
          <Tab label="SE" {...a11yProps(11)} className="tabItems" />
          <Tab label="SP (CIDADE)" {...a11yProps(12)} className="tabItems" />
          <Tab label="SP (GRANDE SP)" {...a11yProps(13)} className="tabItems" />
          <Tab label="SP (INTERIOR)" {...a11yProps(14)} className="tabItems" />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Alagoas}
          name={"ALAGOAS"}
        ></ItemsLojasAutorizadas>
      </TabPanel>

      <TabPanel value={value} index={1} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Bahia}
          name={"BAHIA"}
        ></ItemsLojasAutorizadas>
      </TabPanel>

      {/* <TabPanel value={value} index={2} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Fortaleza}
          name={"CEARÁ"}
        ></ItemsLojasAutorizadas>
      </TabPanel> */}
      <TabPanel value={value} index={2} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={GOIAS}
          name={"GOIÁS"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={3} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Maranhao}
          name={"MARANHÃO"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={4} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={MatoGrosso}
          name={"MATO GROSSO"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={5} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={MinasGerais}
          name={"MINAS GERAIS"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={6} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Para}
          name={"PARÁ"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={7} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Recife}
          name={"PERNAMBUCO"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={8} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Parana}
          name={"PARANÁ"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={9} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={RioJaneiro}
          name={"RIO DE JANEIRO"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      {/* <TabPanel value={value} index={10} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={RioGrandeSul}
          name={"RIO GRANDE DO SUL"}
        ></ItemsLojasAutorizadas>
      </TabPanel> */}
      <TabPanel value={value} index={10} className="tabPanelLoja">
        <ItemsLojasAutorizadas
          items={Sergipe}
          name={"SERGIPE"}
        ></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={11} className="tabPanelLoja">
        <ItemsLojasProprias
          items={SaoPauloPropria}
          name={"CIDADE DE SÃO PAULO"}
          ></ItemsLojasProprias>
        <hr></hr>
        <ItemsLojasAutorizadas items={SaoPaulo}></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={12} className="tabPanelLoja">
        {/* <h4 className="fw-bold my-4">GRANDE SÃO PAULO</h4> */}
        <ItemsLojasProprias
          items={GrandeSaoPaulo}
          name={"GRANDE SÃO PAULO"}
          ></ItemsLojasProprias>
        {/* <Guarulhos />
        <hr></hr>
        <Suzano />*/}
        <hr></hr>
        <ItemsLojasAutorizadas items={GrandeSP}></ItemsLojasAutorizadas>
      </TabPanel>
      <TabPanel value={value} index={13} className="tabPanelLoja">
        <ItemsLojasProprias
          items={InteriorSaoPaulo}
          name={"INTERIOR DE SÃO PAULO"}
        ></ItemsLojasProprias>
        <hr></hr>
        <ItemsLojasAutorizadas items={InteriorSP}></ItemsLojasAutorizadas>
      </TabPanel>
    </BoxTab>
  );
}

const BoxTab = styled.div`
@media(min-width: 320px){
  width: 100%;
}
@media(min-width: 1200px){
  width: 70%;
  margin: 0 auto;
}

@media(min-width: 2000px){
  width: 70%;
  margin: 0 auto;
}
`;
