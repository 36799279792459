import libraFrente1g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-1g-frente.png";
import libraFrente2g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-2g-frente.png";
import libraFrente5g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-5g-frente.png";
import libraFrente10g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-10g-frente.png";
import libraFrente25g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-25g-frente.png";
import libraFrente31g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-31g-frente.png";
import libraFrente50g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-50g-frente.png";
import libraVerso1g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-1g-verso.png";
import libraVerso2g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-2g-verso.png";
import libraVerso5g from "../../../assets/newImages/mockups/signos/libra/libra-ouro-5g-verso.png";

export const  libraMockups = {
    nome: "Libra",
    frente1g: libraFrente1g,
  
    frente2g: libraFrente2g,
  
    frente5g: libraFrente5g, 
    frente10g: libraFrente10g,
    frente25g:libraFrente25g,
    frente31g: libraFrente31g,
    frente50g: libraFrente50g, 
  
    verso1g: libraVerso1g,
    verso2g: libraVerso2g,
  
    verso5g: libraVerso5g,
    verso10g: libraVerso5g,
    verso25g: libraVerso5g,
    verso31g: libraVerso5g,
    verso50g: libraVerso5g,
  };