import netaFrente1g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-1g-frente.png";
import netaFrente2g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-2g-frente.png";
import netaFrente5g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-5g-frente.png";
import netaFrente10g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-10g-frente.png";
import netaFrente25g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-25g-frente.png";
import netaFrente31g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-31g-frente.png";
import netaFrente50g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-50g-frente.png";
import netaVerso1g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-1g-verso.png";
import netaVerso2g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-2g-verso.png";
import netaVerso5g from "../../../assets/newImages/mockups/familia/neta/neta-ouro-5g-verso.png";

export const  netaMockups = {
    nome: "Neta de Ouro",
    frente1g: netaFrente1g,
  
    frente2g: netaFrente2g,
  
    frente5g: netaFrente5g, 
    frente10g: netaFrente10g,
    frente25g:netaFrente25g,
    frente31g: netaFrente31g,
    frente50g: netaFrente50g, 
  
    verso1g: netaVerso1g,
    verso2g: netaVerso2g,
  
    verso5g: netaVerso5g,
    verso10g: netaVerso5g,
    verso25g: netaVerso5g,
    verso31g: netaVerso5g,
    verso50g: netaVerso5g,
  };