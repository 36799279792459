import React from 'react';
import styled from 'styled-components';

export default function InputsOM({
  label,
  type="text",
  value,
  placeholder="",
  onChange=null,
  disabled=false,
  error=""
}) {
  return (
    <Container>
      <Label>{label}</Label>
      <div>
        <Inputs
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
        <span>{error}</span>
      </div>
    </Container>
  );
}

const Container = styled.div `
display: flex;
flex-direction: column;
  > div {
    display: flex;
    flex-direction: column;
    > span {
      color: red;
      font-size: 12px;
    }
  }
`

const Label = styled.label `
  font-weight: 500;
  color: var(--light);
`;

const Inputs = styled.input `
  border: 1px solid var(--light);
  border-radius: 8px;
  padding: 15px;
  height: 45px;


`
