import React, { useEffect } from "react";
import { Modal } from "@mui/material";
import { MouseEvent, useState } from "react";
import { PageLoading } from "../../../components/Loading/Loading";
import styled from "@emotion/styled";
import InputsOM from "../../components/inputs";
import { ButtonMain } from "../../components/buttons";
import { maskNumberInt, validSenha } from "../../../utils/regex";
import { postTokenEmail, putTokenSenha } from "../../../services/modules/cliente/senhaService";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getClienteByCPF } from "../../../services/modules/cliente/clienteService";

export default function RecuperarSenha(props: { cpf: string; title?: string }) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [codigo, setCodigo] = useState(null);
  const [errCodigo, setErrCodigo] = useState("");
  const [errSenha, setErrSenha] = useState("");
  const [errConfSenha, setErrConfSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [reenviarAtivo, setReenviarAtivo] = useState(false);
  const [tempoRestante, setTempoRestante] = useState(30);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfPassword = () => setShowConfPassword(!showConfPassword);

  const senhaMinLength = senha.length >= 8;
  const hasUpperCase = /[A-Z]/.test(senha);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(senha);

  const navigate = useNavigate();
  const handleClose = (
    event: MouseEvent<Element, MouseEvent>,
    reason: string
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  function isValidSenha() {
    return (
      senhaMinLength && hasUpperCase && hasSpecialChar && senha === confSenha
    );
  }
  function save() {
    setErrConfSenha("");
    setErrSenha("");
    let count = 0;
    if (!codigo || codigo.length < 4) {
      setErrCodigo("Informe o código");
      count++;
    }
    if (!senha || senha.length < 8) {
      setErrSenha("A senha deve ter pelo menos 8 caracteres!");
      count++;
    } else if (!validSenha.test(senha)) {
      setErrSenha(
        "A senha deve conter pelo menos uma letra maiúscula e um caractere especial!"
      );
      count++;
    } else if (senha !== confSenha) {
      setErrConfSenha("As senhas não conferem!");
      count++;
    }

    if (count !== 0) return;
    setLoading(true);
    putTokenSenha({
      cpfCnpj: props.cpf,
      password: senha,
      token: codigo as number,
    })
      .then(() => {
        AlertNotify({ message: "Senha atualizada com sucesso!" });
        navigate(
          "/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/dados-pessoais"
        );
        setOpen(false);
      })
      .catch((e: AxiosError) => {
        if (e.response.status === 400) {
          console.log(e.response.data);
          const data = e.response.data as any;
          AlertNotify({ message: data.message, type: "warning" });
        }
      })
      .finally(() => setLoading(false));
  }

  function reenviarCodigo() {
    if (!reenviarAtivo) return;

    setLoading(true);
    getClienteByCPF(props.cpf)
      .then(async (resp) => {
        await postTokenEmail(resp.codigoCliente)
      .then(() => {
        AlertNotify({
              message: "Código reenviado para o seu e-mail!"
        });
        setReenviarAtivo(false);
        setTempoRestante(30);
      })
      .catch(() => {
        AlertNotify({
          message: "Erro ao reenviar o código. Tente novamente mais tarde.",
              type: "warning"
            })
          })
          .finally(() => setLoading(false))
      })
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (tempoRestante > 0) {
      timer = setTimeout(() => {
        setTempoRestante(tempoRestante - 1);
      }, 1000);
    } else {
      setReenviarAtivo(true);
    }

    return () => clearTimeout(timer);
  }, [tempoRestante]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Container>
        {loading && <PageLoading />}
        <h1>{props.title ?? "Alterar senha"}</h1>
        <p style={{ textAlign: "center" }}>
          Para continuar, informe abaixo o código que enviamos para o seu
          e-mail. Em seguida, crie sua senha.
        </p>
        <br />
        <InputsOM
          label="Informe o código recebido por e-mail"
          value={codigo}
          onChange={(e) => setCodigo(maskNumberInt(e.target.value))}
          error={errCodigo}
        />
        <span
          onClick={reenviarCodigo}
          style={{
            cursor: reenviarAtivo ? "pointer" : "not-allowed",
            color: reenviarAtivo ? "var(--green)" : "gray",
          }}
        >
          {reenviarAtivo
            ? "Reenviar código"
            : `Reenviar código (${tempoRestante}s)`}
        </span>
        <InputsContainer>
          <InputsOM
            label="Senha"
            type={showPassword ? "text" : "password"}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <VisibilityToggle onClick={toggleShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </VisibilityToggle>
        </InputsContainer>

        <InputsContainer>
          <InputsOM
            label="Confirme sua senha"
            type={showConfPassword ? "text" : "password"}
            value={confSenha}
            onChange={(e) => setConfSenha(e.target.value)}
          />
          <VisibilityToggle onClick={toggleShowConfPassword}>
            {showConfPassword ? <VisibilityOff /> : <Visibility />}
          </VisibilityToggle>
        </InputsContainer>

        <PasswordChecklist>
          <li>{senhaMinLength ? "✓" : "✗"} Mínimo 8 caracteres</li>
          <li>{hasUpperCase ? "✓" : "✗"} Pelo menos uma letra maiúscula</li>
          <li>{hasSpecialChar ? "✓" : "✗"} Pelo menos um caractere especial</li>
          <li>{confSenha === senha ? "✓" : "✗"} Confirm ar senha</li>
        </PasswordChecklist>
        <ButtonMain disabled={!isValidSenha} onClick={save}>
          {" "}
          Alterar senha
        </ButtonMain>

        <span>
          Se não tiver mais acesso ao seu email entre em contato conosco.
          <a
            href="https://web.whatsapp.com/send?phone=551120501646"
            rel="noopene noreferrer"
            target="_blank"
            className="alterSenha"
          >
            {" "}
            Clicando aqui!
          </a>
        </span>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  width: 350px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
   z-index: 5000;

  span {
    font-size: 12px;
    margin-top: 5px;
    cursor: pointer;
  }

  .alterSenha {
    text-decoration: none;
    color: var(--green);
  }
`;

const InputsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 275px;
  }
`;

const VisibilityToggle = styled.div`
  position: absolute;
  right: 10px;
  top: 43%;
  cursor: pointer;
`;

const PasswordChecklist = styled.ul`
  font-size: 12px;
  color: var(--light);
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
  align-self: flex-start;
  li {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    &::before {
      content: " ";
      margin-right: 8px;
    }
  }
`;
