import React from "react";
import "../../pages.css";
import img from "../../../assets/newImages/curiosidades-ouro.png";
import linha from "../../../assets/linha.png";

export default function CuriosidadesOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              CURIOSIDADES SOBRE O OURO
            </p>
            <p>
              O ouro é um elemento químico de número atómico 79 que está situado
              no grupo 11 da tabela periódica, e de massa atómica 197 u. Na
              natureza, o ouro é produzido a partir da colisão de duas estrelas
              de nêutrons.
            </p>
            <p>
              O ouro é utilizado de forma generalizada em joalharia, indústria e
              eletrônica, bem como reserva de valor.
            </p>
            <p><b>Símbolo:</b> Au</p>
            <p><b>Ponto de fusão:</b> 1 064 °C</p>
            <p><b>Massa atômica:</b> 196,96657 u</p>
            <p><b>Número atômico:</b> 79</p>
            <p>
              <b>Série química:</b> Metal, Metal de transição, Metal pesado tóxico,
              Elementos do Grupo 11, Elemento do 6º Período
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO SURGIU O OURO NO BRASIL?</summary>
            <p className="mt-4">
              A descoberta do ouro, no final do século XVII, nas regiões das
              Minas Gerais foi importante para a expansão territorial e para uma
              nova organização administrativa da colônia. Em 1693, no tempo em
              que se descobriu ouro nas regiões das minas, foram criadas as
              capitanias do Rio de Janeiro, São Paulo e Minas.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUANDO O OURO FOI DESCOBERTO NO BRASIL?</summary>
            <p className="my-4">
              Os bandeirantes, no final do século XVII, começaram a encontrar
              minas de ouro em Minas Gerais, Goiás e Mato Grosso. Portugal viu
              nesta atividade uma nova fonte de renda. A descoberta de ouro no
              Brasil provocou uma verdadeira “corrida do ouro”, durante todo
              século XVIII (auge do ciclo do ouro).
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUEM FOI QUE DESCOBRIU O OURO?</summary>
            <p className="my-4">
              Foi Rodrigues de Arzão um destemido bandeirante, e o primeiro que
              descobriu ouro em Minas Gerais; faleceu em 1696, deixando o
              roteiro de suas descobertas a seu concunhado Bartolomeu Bueno de
              Siqueira, que, nesse mesmo ano, se embrenhou nos sertões em busca
              desse metal.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUANTO DE OURO TEM NA TERRA?</summary>
            <p className="mt-4">
              Seriam 171,3 mil toneladas de ouro no mundo – ou seja, caberiam em
              uma sala quadrada com paredes de 20,7 metros de comprimento e 9,8
              metros de altura. Mas as estimativas variam bastante, vão de 155
              mil toneladas a 2,5 milhões de toneladas.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUAL FOI A MAIOR MINA DE OURO DO MUNDO?</summary>
            <p className="mt-4">
              As dez maiores minas produtoras de ouro do mundo estão na Oceania,
              Ásia e Américas. A brasileira mina Morro do Ouro, localizada na
              cidade de Paracatu, em Minas Gerais, é operada pela Kinross Gold e
              é a maior produtora nacional de ouro, com 477.862 onças de ouro,
              ou 14,8 toneladas produzidas em 2015.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>
              QUAIS OS ESTADOS BRASILEIROS QUE MAIS PRODUZEM OURO?
            </summary>
            <p className="mt-4">
              No Brasil os principais estados produtores de ouro são:
            </p>
            <p className="mb-4">
              <ol>
                <li>Minas Gerais (39,7%)</li>
                <li>Goiás (23,9%)</li>
                <li>Pará (10,1%)</li>
                <li>Bahia (9,8%)</li>
              </ol>
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO O OURO SE FORMA NA TERRA?</summary>
            <p className="mt-4">
              Na Terra, formada há 4,5 bilhões de anos, o ouro apareceu na forma
              de átomos alojados na estrutura de outros minerais. Mas a
              quantidade é muito pequena. Para se ter uma ideia, na crosta
              terrestre – a camada mais superficial do planeta – em cada bilhão
              de átomos, apenas cinco são de ouro.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUAL É O MELHOR OURO?</summary>
            <p className="my-4">
              Para definir a quantidade de ouro numa liga, surgiu a expressão
              quilates (K), sendo que 24K equivalem a 100% ouro, ouro puro. Se
              falarmos que uma joia é de ouro 18K, isso significa que ela tem 18
              partes de ouro e 6 partes de outros metais, ou seja, tem 75% de
              ouro.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO SABER SE É OURO OU FOLHEADO?</summary>
            <p className="mt-4">
              A primeira coisa a fazer para verificar se o ouro é
              &quot;verdadeiro&quot; é observá-lo. Procure por sinais
              particulares que possam indicar se o material é verdadeiro.
              Procure marcas oficiais na peça. Uma contrastaria indicará a
              pureza dele (10K, 14K, 18K, 22K ou 24K).
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUANTO OURO AINDA EXISTE NO BRASIL?</summary>
            <p className="mt-4">
              Na outra ponta de um ranking de 100 países, com a reserva mais
              modesta, está o Chade, país africano que tem 300 quilos de ouro em
              suas reservas. O Brasil tem a 47ª maior reserva de ouro do mundo.
              Nos cofres do Banco Central há 33,6 toneladas do metal, o que
              corresponde a 1,4 bilhão de dólares.
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
