import farmaceuticoFrente1g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-1g-frente.png";
import farmaceuticoFrente2g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-2g-frente.png";
import farmaceuticoFrente5g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-5g-frente.png";
import farmaceuticoFrente10g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-10g-frente.png";
import farmaceuticoFrente25g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-25g-frente.png";
import farmaceuticoFrente31g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-31g-frente.png";
import farmaceuticoFrente50g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-50g-frente.png";
import farmaceuticoVerso1g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-1g-verso.png";
import farmaceuticoVerso2g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-2g-verso.png";
import farmaceuticoVerso5g from "../../../assets/newImages/mockups/profissoes/farmaceutico/farmaceutico-ouro-5g-verso.png";

export const  farmaceuticoMockups = {
    nome: "Farmacêutico de Ouro",
    frente1g: farmaceuticoFrente1g,
  
    frente2g: farmaceuticoFrente2g,
  
    frente5g: farmaceuticoFrente5g,
  
    frente10g: farmaceuticoFrente10g,
  
    frente25g: farmaceuticoFrente25g,
  
    frente31g: farmaceuticoFrente31g,
  
    frente50g: farmaceuticoFrente50g,
  
    verso1g: farmaceuticoVerso1g,
    verso2g: farmaceuticoVerso2g,
  
    verso5g: farmaceuticoVerso5g,
    verso10g: farmaceuticoVerso5g,
    verso25g: farmaceuticoVerso5g,
    verso31g: farmaceuticoVerso5g,
    verso50g: farmaceuticoVerso5g,
  };

 