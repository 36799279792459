import advogadaFrente1g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-1g-frente.png";
import advogadaFrente2g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-2g-frente.png";
import advogadaFrente5g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-5g-frente.png";
import advogadaFrente10g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-10g-frente.png";
import advogadaFrente25g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-25g-frente.png";
import advogadaFrente31g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-31g-frente.png";
import advogadaFrente50g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-50g-frente.png";
import advogadaVerso1g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-1g-verso.png";
import advogadaVerso2g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-2g-verso.png";
import advogadaVerso5g from "../../../assets/newImages/mockups/profissoes/advogada/advogada-ouro-5g-verso.png";

export const  advogadaMockups = {
    nome: "Advogada de Ouro",
    frente1g: advogadaFrente1g,
  
    frente2g: advogadaFrente2g,
  
    frente5g: advogadaFrente5g,
  
    frente10g: advogadaFrente10g,
  
    frente25g: advogadaFrente25g,
  
    frente31g: advogadaFrente31g,
  
    frente50g: advogadaFrente50g,
  
    verso1g: advogadaVerso1g,
    verso2g: advogadaVerso2g,
  
    verso5g: advogadaVerso5g,
    verso10g: advogadaVerso5g,
    verso25g: advogadaVerso5g,
    verso31g: advogadaVerso5g,
    verso50g: advogadaVerso5g,
  };

 