import React from "react";
import styled from "styled-components";
import coroaSueca from "../../../../assets/images/moedas/coroa-sueca.png";
import coroaDinamarquesa from "../../../../assets/images/moedas/coroa-dinamarquesa.png";
import dirham from "../../../../assets/images/moedas/dirham-emirados.png";
import dolarAustraliano from "../../../../assets/images/moedas/dolar-australiano.png";
import dolaCanadense from "../../../../assets/images/moedas/dolar-canadense.png";
import euro from "../../../../assets/images/moedas/euro.png";
import francoSuico from "../../../../assets/images/moedas/franco-suico.png";
import iene from "../../../../assets/images/moedas/iene.png";
import libra from "../../../../assets/images/moedas/libra-esterlina.png";
import novaZelandia from "../../../../assets/images/moedas/nova-zelandia.png";
import pesoArgentino from "../../../../assets/images/moedas/peso-argentino.png";
import coroaNorueguesa from "../../../../assets/images/moedas/coroa-norueguesa.png";
import pesoChileno from "../../../../assets/images/moedas/peso-chileno.png";
import pesoColombiano from "../../../../assets/images/moedas/peso-colombiano.png";
import pesoMexicano from "../../../../assets/images/moedas/peso-mexicano.png";
import pesoUruguaio from "../../../../assets/images/moedas/peso-uruguaio.png";
import rand from "../../../../assets/images/moedas/rand.png";
import rubloRusso from "../../../../assets/images/moedas/rublo-russo.png";
import shekel from "../../../../assets/images/moedas/shekel.png";
import solPeruano from "../../../../assets/images/moedas/sol-peruano.png";
import yuan from "../../../../assets/images/moedas/yuan.png";
import dolaComercial from "../../../../assets/newImages/moneys.png";

export default function ImageCoin({ cod }) {
  return (
    <>
      {cod === "DKK" ? (
        <ImageCartao src={coroaDinamarquesa} />
      ) : cod === "NOK" ? (
        <ImageCartao src={coroaNorueguesa} />
      ) : cod === "CHF" ? (
        <ImageCartao src={francoSuico} />
      ) : cod === "GBP" ? (
        <ImageCartao src={libra} />
      ) : cod === "AUD" ? (
        <ImageCartao src={dolarAustraliano} />
      ) : cod === "EUR" ? (
        <ImageCartao src={euro} />
      ) : cod === "NZD" ? (
        <ImageCartao src={novaZelandia} />
      ) : cod === "JPY" ? (
        <ImageCartao src={iene} />
      ) : cod === "EUSCOM" ? (
        <ImageCartao src={dolaComercial} /> // confirmar USD
      ) : cod === "ARS" ? (
        <ImageCartao src={pesoArgentino} />
      ) : cod === "CAD" ? (
        <ImageCartao src={dolaCanadense} />
      ) : cod === "CLP" ? (
        <ImageCartao src={pesoChileno} />
      ) : cod === "COP" ? (
        <ImageCartao src={pesoColombiano} />
      ) : cod === "MXN" ? (
        <ImageCartao src={pesoMexicano} />
      ) : cod === "UYU" ? (
        <ImageCartao src={pesoUruguaio} />
      ) : cod === "ZAR" ? (
        <ImageCartao src={rand} />
      ) : cod === "RUB" ? (
        <ImageCartao src={rubloRusso} />
      ) : cod === "ILS" ? (
        <ImageCartao src={shekel} />
      ) : cod === "PEN" ? (
        <ImageCartao src={solPeruano} />
      ) : cod === "CNY" ? (
        <ImageCartao src={yuan} />
      ) : cod === "SEK" ? (
        <ImageCartao src={coroaSueca} />
      ) : cod === "AED" ? (
        <ImageCartao src={dirham} />
      ) : null}
    </>
  );
}

const ImageCartao = styled.img`
  @media (min-width: 320px) {
    margin: 0 auto;
    width: 200px;
    padding-top: 10px;
  }
  @media (min-width: 768px) {
    width: 288px;
    height: 488px;
    margin: 0 auto;
  }
`;
