import React, { useContext, useEffect } from "react";
import "../styles/ouroninas.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ofert from "./homepage/ofert/ofert";
import AlertCot from "./homepage/alertCot/alertCot";
import Rentabilitys from "./homepage/tables/rentabTable/rentability";
import Alert from "./homepage/alert/alert";
import { OuroContext } from "./context/cotacoes/cotacoesService";
import { useForeignCoinStore } from "../services/useStore/useCoinStore";

function Section4() {
  const { productsOuro, addOuro } = useContext(OuroContext);
  const { data, getData} = useForeignCoinStore();

  useEffect(async () => {
   await getData();

  }, [])


  useEffect(() => {
    if (productsOuro.length === 0) {
      addOuro();
    }
  }, [productsOuro, addOuro]);

  return (
    <section className="section4">
      <Container>
        <Row className="row3 pt-0 pb-4">
          <Col xs={12} sm={12} md={12} className="col-3">
            <Alert ouro={productsOuro} moedas={data} />
            <Ofert ouro={productsOuro} moedas={data} />
            <AlertCot />
            <Rentabilitys />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Section4;
