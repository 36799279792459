import tioFrente1g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-1g-frente.png";
import tioFrente2g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-2g-frente.png";
import tioFrente5g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-5g-frente.png";
import tioFrente10g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-10g-frente.png";
import tioFrente25g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-25g-frente.png";
import tioFrente31g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-31g-frente.png";
import tioFrente50g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-50g-frente.png";
import tioVerso1g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-1g-verso.png";
import tioVerso2g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-2g-verso.png";
import tioVerso5g from "../../../assets/newImages/mockups/familia/tio/tio-ouro-5g-verso.png";

export const  tioMockups = {
    nome: "Tio de Ouro",
    frente1g: tioFrente1g,
  
    frente2g: tioFrente2g,
  
    frente5g: tioFrente5g, 
    frente10g: tioFrente10g,
    frente25g:tioFrente25g,
    frente31g: tioFrente31g,
    frente50g: tioFrente50g, 
  
    verso1g: tioVerso1g,
    verso2g: tioVerso2g,
  
    verso5g: tioVerso5g,
    verso10g: tioVerso5g,
    verso25g: tioVerso5g,
    verso31g: tioVerso5g,
    verso50g: tioVerso5g,
  };