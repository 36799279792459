/* eslint-disable no-undef */
import { create } from "zustand";
import { getCartaoPrePago, getCartaoPrePagoLojas, getMoedasEstrangeira, getMoedasEstrangeiraGuarulhos, getMoedasEstrangeiraLojas, getMoneygram, getOuroLoja } from "../cotacoes";

export const useForeignCoinStore = create((set, get) => ({
    data: [],
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    countError: 0,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados

        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getMoedasEstrangeira();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();
        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();

            return get().data;
        }
        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));

export const useCardCoinStore = create((set, get) => ({
    data: {},
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados


        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getCartaoPrePago();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();

        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();
            return get().data;
        }

        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));

function filter(coins) {
    let filter = coins.sort((a, b) => {
        return a["nomeMoeda"] < b["nomeMoeda"] ? -1 : 1;
    });

    return filter;
}

export const useStoresCoinStore = create((set, get) => ({
    data: {},
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados

        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getMoedasEstrangeiraLojas();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();

        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();
            return get().data;
        }

        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));


export const useStoresCoinGuarulhosStore = create((set, get) => ({
    data: {},
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados

        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getMoedasEstrangeiraGuarulhos();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();

        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();
            return get().data;
        }

        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));

export const useGoldStore = create((set, get) => ({
    data: {},
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados

        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getOuroLoja();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();

        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();
            return get().data;
        }

        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));


export const useCardStoresCoinStore = create((set, get) => ({
    data: {},
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados

        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getCartaoPrePagoLojas();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();

        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();
            return get().data;
        }

        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));



export const useStoresMoneygram = create((set, get) => ({
    data: {},
    isError: false,
    isLoading: false,
    isFirstLoading: true,
    isFetchingData: false,
    intervalId: null,
    fetchData: async () => {
        if (get().isFetchingData) {
            return;
        }
        set({ isFetchingData: true }); // Marque que está buscando os dados

        // Inicia o intervalo apenas quando os dados são buscados pela primeira vez
        if (!get().intervalId) {
            const intervalId = setInterval(async () => {
                await get().fetchData(); // Chama fetchData a cada 1 minuto para atualizar os dados automaticamente
            }, 60000);
            set({ intervalId });
        }
        try {
            set({ isLoading: true });
            const resp = await getMoneygram();
            set({ data: filter(resp), isError: false, isLoading: false, isFetchingData: false, isFirstLoading: false });
        }
        catch (error) {
            set({ isLoading: false, isError: true, isFetchingData: false });
        }
    },
    getData: async () => {
        const { data } = await get();

        if (!data || Object.keys(data).length === 0) {
            await get().fetchData();
            return get().data;
        }

        return data;
    },
    clearInterval: () => {
        const intervalId = get().intervalId;
        if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
        }
    },

    destroy: () => {
        get().clearInterval();
    },



}));
