import touroFrente1g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-1g-frente.png";
import touroFrente2g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-2g-frente.png";
import touroFrente5g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-5g-frente.png";
import touroFrente10g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-10g-frente.png";
import touroFrente25g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-25g-frente.png";
import touroFrente31g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-31g-frente.png";
import touroFrente50g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-50g-frente.png";
import touroVerso1g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-1g-verso.png";
import touroVerso2g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-2g-verso.png";
import touroVerso5g from "../../../assets/newImages/mockups/signos/touro/touro-ouro-5g-verso.png";

export const  touroMockups = {
    nome: "Touro",
    frente1g: touroFrente1g,
  
    frente2g: touroFrente2g,
  
    frente5g: touroFrente5g, 
    frente10g: touroFrente10g,
    frente25g:touroFrente25g,
    frente31g: touroFrente31g,
    frente50g: touroFrente50g, 
  
    verso1g: touroVerso1g,
    verso2g: touroVerso2g,
  
    verso5g: touroVerso5g,
    verso10g: touroVerso5g,
    verso25g: touroVerso5g,
    verso31g: touroVerso5g,
    verso50g: touroVerso5g,
  };