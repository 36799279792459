import React from "react";
import "../../pages.css";
import TabLojasAutorizadas from "../tabLojasAutorizadas/tabLojasAutorizadas";
import "./lojas.css";
export default function Lojas() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="sectLoja">
        <h1 className="fw-bold text-center my-4">Nossas Lojas</h1>
        <hr className="w-10"></hr>

        <TabLojasAutorizadas />
      </section>
    </article>
  );
}
