import React from "react";
import styled from "styled-components";
import icoCertification from "../../assets/images/professional/ico-certification.png";
import icoGoldBar from "../../assets/images/professional/ico-gold-bar.png";
import icoGoldCard from "../../assets/images/professional/ico-gold-card.png";
import icoGoldFoil from "../../assets/images/professional/ico-gold-foil.png";
import icoGoldPyramid from "../../assets//images/professional/ico-gold-pyramid.png";
import icoSafeBox from "../../assets/images/professional/ico-safe-box.png";
import icoSearchGoldBar from "../../assets/images/professional/ico-search-gold-bar.png";
import icoCel from "../../assets/images/professional/ico-cel.png";
import icoWorld from "../../assets/images/professional/ico-global.png";
import { Card } from "../../components/section/cards/cards";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export default function SectionOuro() {
  const name = useParams()

  useEffect(()=>{
    window.scrollTo(0,0)
  },[name])
  const itemsDescription = [
    {
      productName: "Barra de Ouro (1g à 1kg)",
      href: "1g-1kg",
      src: icoGoldBar,
    },
    {
      productName: "Lâmina de Ouro",
      href: "encaminhamento/lamina",
      src: icoGoldFoil,
    },
    {
      productName: "Pirâmide de Ouro",
      href: "piramide-ouro",
      src: icoGoldPyramid,
    },
    {
      productName: "Cartão OuroFácil",
      href: "cartao-ouro",
      src: icoGoldCard,
    },
    {
      productName: "Consórcio em Ouro",
      href: "encaminhamento/consorcio",
      src: icoSafeBox,
    },
    {
      productName: "Premiação com Certificado em Barra de Ouro",
      href: "encaminhamento/premiacao-certificado-ouro",
      src: icoCertification,
    },
    {
      productName: "Exportação de Ouro",
      href: "encaminhamento/exportacao",
      src: icoWorld,
    },
    {
      productName: "Ouro Para Joalheiros",
      href: "encaminhamento/joalheiros",
      src: icoSearchGoldBar,
    },
  ];
  return (
    <SectionContainer>
      <SectionIntroContainer>
        <h2 className="display-4">O QUE VOCÊ PRECISA HOJE?</h2>
      </SectionIntroContainer>

      <ul className="feature-list feature-list-sm row d-flex flex-wrap">
        <li className="card-li col-6 col-md-4 mb-4">
          <a
            href="https://www.ourominasapp.com/"
            className="aCard card text-center "
            rel="noopene noreferrer"
            target="_blank"
          >
            <div className="card-body">
              <img src={icoCel} style={{ height: "auto", width: "60px", paddingTop: "10px" }}  alt="ícone celular"/>
              <h6 className=" text-muted title-decorative mt-2">
                Ouro Digital
              </h6>
            </div>
          </a>
        </li>
        {itemsDescription.map(({ href, src, productName }) =>
          Card(href, src, productName)
        )}
      </ul>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-color: #f8f9fa;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 50px;
`;
