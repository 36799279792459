import londresFrente1g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-1g-frente.png";
import londresFrente2g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-2g-frente.png";
import londresFrente5g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-5g-frente.png";
import londresFrente10g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-10g-frente.png";
import londresFrente25g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-25g-frente.png";
import londresFrente31g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-31g-frente.png";
import londresFrente50g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-50g-frente.png";
import londresVerso1g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-1g-verso.png";
import londresVerso2g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-2g-verso.png";
import londresVerso5g from "../../../assets/newImages/mockups/mundos/londres/londres-ouro-5g-verso.png";

export const  londresMockups = {
    nome: "Londres",
    frente1g: londresFrente1g,
  
    frente2g: londresFrente2g,
  
    frente5g: londresFrente5g,
  
    frente10g: londresFrente10g,
  
    frente25g: londresFrente25g,
  
    frente31g: londresFrente31g,
  
    frente50g: londresFrente50g,
  
    verso1g: londresVerso1g,
    verso2g: londresVerso2g,
  
    verso5g: londresVerso5g,
    verso10g: londresVerso5g,
    verso25g: londresVerso5g,
    verso31g: londresVerso5g,
    verso50g: londresVerso5g,
  };

 