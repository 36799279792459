import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function GoBack({ text }) {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(-1)}>{text ? text : " < Voltar"}</Button>
  );
}
const Button = styled.button`
  background: none;
  border: none;
  color: #014b3e;
`;
