import professorFrente1g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-1g-frente.png";
import professorFrente2g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-2g-frente.png";
import professorFrente5g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-5g-frente.png";
import professorFrente10g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-10g-frente.png";
import professorFrente25g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-25g-frente.png";
import professorFrente31g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-31g-frente.png";
import professorFrente50g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-50g-frente.png";
import professorVerso1g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-1g-verso.png";
import professorVerso2g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-2g-verso.png";
import professorVerso5g from "../../../assets/newImages/mockups/profissoes/professor/professor-ouro-5g-verso.png";

export const  professorMockups = {
    nome: "Professor de Ouro",
    frente1g: professorFrente1g,
  
    frente2g: professorFrente2g,
  
    frente5g: professorFrente5g,
  
    frente10g: professorFrente10g,
  
    frente25g: professorFrente25g,
  
    frente31g: professorFrente31g,
  
    frente50g: professorFrente50g,
  
    verso1g: professorVerso1g,
    verso2g: professorVerso2g,
  
    verso5g: professorVerso5g,
    verso10g: professorVerso5g,
    verso25g: professorVerso5g,
    verso31g: professorVerso5g,
    verso50g: professorVerso5g,
  };

 