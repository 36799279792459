import { BehaviorSubject } from 'rxjs';
import { getClienteCPF } from '../../../utils/localStorage';

const cpfSubject = new BehaviorSubject(getClienteCPF());

// Function to set a new value in localStorage and update the observable
function setClienteCPF(newCpf) {
  localStorage.setItem('cpf', newCpf);
  cpfSubject.next(newCpf); // Notify all subscribers with the new value
}

// Observable to expose the cpfSubject as an observable
const cpfObservable = cpfSubject.asObservable();

export { cpfObservable, setClienteCPF };