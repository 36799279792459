import React from 'react';
import {
  RedirectTel,
  RedirectWhatsapp,
} from '../../../components/whatsapp/whatsapp';
import './tabLojas.css';

export function ItemsLojasAutorizadas({ items, name }) {
  function clickOpen(id) {
    document.getElementById(id).classList.toggle('d-none');
  }

  const textoPadrao =
    `
    <p>Esta loja é um correspondente cambial autorizado pela Ourominas (OM DTVM LTDA). </p>
    <p>É obrigatório o correspondente cambial informar ao cliente os dados da conta bancária da Ourominas (OM DTVM LTDA) caso a opção de pagamento seja transferência bancária, independentemente do valor.</p>
    `;
  const textoEspecifico =
    `
    <p>O Posto de Compra de Ouro (“PCO”) constitui-se como uma extensão da OM DTVM em regiões auríferas, especialmente para atendimento da legislação e resoluções incidentes nas operações de aquisição de ouro bruto.</p>
    <p>Com relação às operações de compra realizadas pelos PCO's, a contraprestação deverá advir apenas da OM DTVM, sendo o Portador da Permissão de Lavra Garimpeira (“PLG”), o exclusivo beneficiário desta.</p>
    `;
  const textoEspecificoDemaisLojas =
    `
    <p>A Loja constitui-se por um Correspondente Cambial autorizado a operar pela OM DTVM (“Ourominas”), estando este sujeito à todas as normativas instituídas tanto pela OM DTVM, quanto pelos Órgãos Reguladores de sua atividade.</p>
    <p>No evento de o pagamento da transação ser feito mediante transferência bancária ou PIX, é obrigatório que esta Loja informe ao Cliente os dados bancários da OM DTVM, independentemente do valor total da transação.</p>
    `;

  return (
    <div>
      <h4 className="fw-bold my-4">{name}</h4>
      {items?.map((item, i) => {
        return (
          <div key={i} className="mb-4">
            {i > 0 ? <hr></hr> : null}
            <p>
              <b>Loja Autorizada: </b>
              <span className="lojaAutorizada">{item?.LojaAutorizada}</span>
            </p>
            <p>
              <span className="fw-bold">Cidade:</span> {item?.Cidade}
            </p>
            <p className="LineTel">
              <span className="LineSpan">
                <b>Telefone: </b>
                {item?.Fixo === 'Não possui' ? (
                  <span>{item.Fixo}</span>
                ) : (
                  <RedirectTel number={item?.Fixo} />
                )}

                <RedirectTel number={item?.Fixo2} />
                <RedirectTel number={item?.Fixo3} />
              </span>
              <span className="LineSpan">
                <b>WhatsApp: </b>
                {item?.WhatsApp === 'Não possui' ? (
                  <span>Não possui</span>
                ) : (
                  <RedirectWhatsapp number={item?.WhatsApp}></RedirectWhatsapp>
                )}
                <RedirectWhatsapp number={item?.WhatsApp2}></RedirectWhatsapp>
                <RedirectWhatsapp number={item?.WhatsApp3}></RedirectWhatsapp>
              </span>
            </p>
            <p className="LineTel">
              <span className="LineSpan">
                <b>Email: </b>
                {item?.Email === 'Não possui' ? (
                  <span>Não possui</span>
                ) : (
                  <a href={`mailto:${item?.Email}`}>{item?.Email}</a>
                )}

                <a href={`mailto:${item?.Email2}`}>{item?.Email2}</a>
                <a href={`mailto:${item?.Email3}`}>{item?.Email3}</a>
              </span>
            </p>
            <p>
              <b>Endereço:</b> {item?.Endereço}
            </p>
            <p>
              <b>Horário de Atendimento:</b> {item?.Horario}
            </p>

            <p>
              <button className="btn text-start p-0">
                <b onClick={() => clickOpen(item?.CNPJ + item?.LojaAutorizada)}>
                  Leia Mais
                </b>
              </button>
            </p>

            <div id={item?.CNPJ + item?.LojaAutorizada} className="d-none">
              <h4 className="mt-4">
                <b>Produtos oferecidos na loja:</b>
              </h4>

              {item?.Ouro && (
                <details>
                  <summary>Ouro</summary>
                  <ul>
                    {item.Ouro.map((ouro, i) => {
                      return <li key={i + ouro}>{ouro}</li>;
                    })}
                  </ul>
                </details>
              )}

              {item?.Produtos && (
                <details>
                  <summary>Câmbio</summary>
                  <ul>
                    {item?.Produtos?.p1 && <li>{item?.Produtos?.p1}</li>}
                    {item?.Produtos?.p2 && <li>{item?.Produtos?.p2}</li>}
                    {item?.Produtos?.p3 && <li>{item?.Produtos?.p3}</li>}
                    {item?.Produtos?.p4 && <li>{item?.Produtos?.p4}</li>}
                    {item?.Produtos?.p5 && <li>{item?.Produtos?.p5}</li>}
                    {item?.Produtos?.p6 && <li>{item?.Produtos?.p6}</li>}
                    {item?.Produtos?.p7 && <li>{item?.Produtos?.p7}</li>}
                    {item?.Produtos?.p8 && <li>{item?.Produtos?.p8}</li>}
                  </ul>
                </details>
              )}

              {item?.Outros && (
                <details>
                  <summary>Outros Serviços</summary>
                  <ul>
                    {item.Outros.map((outro, i) => {
                      return <li key={i + outro}>{outro}</li>;
                    })}
                  </ul>
                </details>
              )}

              <h4 className="mt-4">
                <b>Informações Importantes:</b>
              </h4>
              <p>
                <b>Nome Fantasia: </b>
                {item.NomeFantasia}
              </p>
              <p>
                <b>Razão Social: </b> {item.RazaoSocial}
              </p>
              <p>
                <b>CNPJ: </b>
                {item.CNPJ}
              </p>

              {["FATOR METAIS", "JAC COMÉRCIO E REPRESENTAÇÕES"].includes(item?.LojaAutorizada.trim()) ? (
              <div dangerouslySetInnerHTML={{ __html: textoEspecifico }} />
            ) : (
              ["Brás", "Suzano", "Matriz (Cidade Jardim)"].includes(item?.Cidade) ? (
                <div dangerouslySetInnerHTML={{ __html: textoPadrao }} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: textoEspecificoDemaisLojas }} />
              )
            )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export const Alagoas = [
  {
    Cidade: 'Maceió',
    LojaAutorizada: 'CONVERSÃO CÂMBIO E TURISMO LTDA',
    WhatsApp: '(82) 99917-5682',
    Fixo: '(82) 3221-0793',
    Email: 'nataliatenorio12@hotmail.com',
    Endereço:
      'Rua Barão de Penedo, Nº 61, Sala 12, Centro, Maceió/AL. CEP: 57.020-340',
    Localizacao: 'ALAGOAS',
    Horario:
      'Segunda à Quinta-feira - 09:00 às 12:00 e 14:00 às 16:00. Sexta-feira - 09:00 às 13:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p4: 'Pagamento Internacional (PI)',
    },
    RazaoSocial: 'CONVERSAO CÂMBIO E TURISMO LTDA',
    NomeFantasia: 'CONVERSÃO CÂMBIO',
    CNPJ: '26.925.221/0001-64\r\n',
  },
];

export const Amazonas = [
  {
    Cidade: 'Manaus',
    LojaAutorizada: 'MUNDI CÂMBIO',
    WhatsApp: '(92) 99114-0181',
    Fixo: '(92) 4141-5178',
    Email: 'Não possui',
    Endereço:
      'Rua Rio Itannana, Nº 708, sala 13 - Nossa Sra. das Graças - Manaus/AM. CEP: 69.053-040',
    Localizacao: 'AMAZONAS',
    Horario: 'Segunda à Sexta-feira - 10:00 às 17:00.',
    Produtos: {
      p3: 'Conta Global Internacional',
      p2: 'Moedas estrangeiras (Compra e venda)',
      p4: 'Pagamento Internacional (PI)',
      p5: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'R. J. CHAMMA NETO & CIA. LTDA.',
    NomeFantasia: 'MUNDI CÂMBIO\r\n',
    CNPJ: '12.566.523/0001-89\r\n',
  },
];

export const Bahia = [
  {
    Cidade: 'Teixeira de Freitas',
    LojaAutorizada: 'GAVATUR',
    WhatsApp: '(73) 98816-4466',
    Fixo: '(73) 2011-0405',
    Email: 'financeiro@gavatur.com.br',
    Endereço:
      'Avenida Mal. Castelo Branco, Nº 235a - Centro, Teixeira de Freitas/BA. CEP: 45.985-160',
    Localizacao: 'BAHIA',
    Horario:
      'Segunda à Sexta-feira - 08:00 às 18:00. Sábados - 08:00 às 12:00.',
    Produtos: {
      p2: 'Moedas Estrangeiras (Compra e Venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'GAVA TURISMO LTDA',
    NomeFantasia: 'GAVATUR',
    CNPJ: '32.615.130/0001-25',
  },
];

export const SaoPaulo = [
  {
    Cidade: 'São Paulo',
    LojaAutorizada: 'PROMOTUR VIAGENS E TURISMO\r\n',
    WhatsApp: '(11) 99560-8223',
    Fixo: '(11) 3151-4049',
    Email: 'atendimento@promoturcambio.com.br',
    Endereço:
      'Avenida São Luis, Nº 187, Loja 43, Bairro República, Galeria Metrópoles - São Paulo/SP. CEP: 01.046-001',
    Localizacao: 'CIDADE DE SÃO PAULO',
    Horario: 'Segunda à Sexta-feira - 10:00 às 17:00.',
    Ouro: ['Cartão OuroFácil'],
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'PROMOTUR VIAGENS E TURISMO LTDA',
    NomeFantasia: 'PROMOTUR VIAGENS E TURISMO\r\n',
    CNPJ: '07.357.518/0001-26\r\n',
  },
];
export const DF = [];
// export const Fortaleza = [
//   {
//     Cidade: 'Fortaleza',
//     LojaAutorizada: 'RRC REPRESENTAÇÕES',
//     WhatsApp: '(85) 98892-8892',
//     Fixo: '(85) 3264-9830',
//     Email: 'om_ceara@hotmail.com',
//     Endereço:
//       'Rua Osvaldo Cruz, Nº 1 - Loja 08 - Meireles, Fortaleza/CE. CEP: 60.125-150',
//     Localizacao: 'FORTALEZA',
//     Horario:  'Segunda à Sexta-feira - 09:00 às 18:00. Sábados - 09:00 às 14:00.',
//     Ouro: [
//       'Barras de Ouro',
//       'Cartão OuroFácil',
//       'Compra de Ouro',
//       'Pirâmide de Ouro',
//     ],
//     Produtos: {
//       p3: 'Conta Global Internacional',
//       p2: 'Moedas estrangeiras (Compra e venda)',
//       p4: 'Pagamento Internacional (PI)',
//       p5: 'Remessa Internacional (MoneyGram)',
//     },
//     RazaoSocial: 'RRC REPRESENTAÇÕES COMERCIAIS LTDA - ME',
//     NomeFantasia: 'RRC REPRESENTAÇÕES\r\n',
//     CNPJ: '08.979.291/0001-13\r\n',
//   },
// ];

export const GOIAS = [
  {
    Cidade: 'Goiânia',
    LojaAutorizada: 'PRATA CÂMBIO',
    WhatsApp: '(62) 99374-5476',
    Fixo: '(62) 3252-2801',
    Email: 'pratacambio@gmail.com',
    Endereço:
      'Avenida Independência, Nº 3392, Shopping Gallo Loja 249, Setor Central - Goiânia/GO. CEP: 74.055-055',
    Localizacao: 'GOIÁS',
    Horario:
      'Segunda à Sexta-feira - 08:00 às 18:00. Sábados - 08:00 às 12:00.',
    Ouro: ['Barras de Ouro', 'Cartão OuroFácil'],
    Produtos: {
      p2: 'Moedas Estrangeiras (compra e venda)',
      p4: 'Pagamento Internacional (PI)',
      p5: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'AG MOREIRA LTDA',
    NomeFantasia: 'PRATA CÂMBIO',
    CNPJ: '21.900.785/0001-29',
  },
];
export const GrandeSP = [
  {
    Cidade: 'Barueri',
    LojaAutorizada: 'INVESTING GOLD\r\n',
    WhatsApp: '(11) 96331-0291',
    Fixo: '(11) 4191-4486',
    Email: 'contato@investinggold.com.br',
    Endereço:
      'Praça das Orquídeas, Nº 116 - Loja 01 - Condomínio Centro Comercial Alphaville, Barueri/SP. CEP: 06.453-002',
    Localizacao: 'INTERIOR DE SÃO PAULO',
    Horario: 'Segunda à Sexta-feira - 09:00 às 18:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
    ],
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p4: 'Pagamento Internacional (PI)',
      p5: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['Chip Internacional (MySimTravel)'],
    RazaoSocial: 'MARCIO GONÇALVES DA SILVA EIRELI',
    NomeFantasia: 'INVESTING GOLD\r\n',
    CNPJ: '19.756.756/0001-93\r\n',
  },
  {
    Cidade: 'Guarulhos',
    LojaAutorizada: 'ACAPULCO TURISMO',
    WhatsApp: 'Não Possui',
    Fixo: '(11) 2813-8555',
    Email: 'acapulco.turismo@ourominas.com',
    Endereço: 'Rua Oswaldo Cruz, Nº 88, Guarulhos/SP',
    Localizacao: 'GRANDE SÃO PAULO',
    Horario: 'Segunda à Sexta-feira - 09:00 às 17:00.',
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p4: 'Pagamento Internacional (PI)',
    },
    Outros: ['Passagens Aéreas', 'Seguro Viagem (GTA)'],
    RazaoSocial: 'ACAPULCO TURISMO',
    NomeFantasia: 'ACAPULCO TURISMO',
    CNPJ: '58.194.697/0001-69',
  },
  {
    Cidade: 'Santo André',
    LojaAutorizada: 'ABC CÂMBIO\r\n',
    WhatsApp: '(11) 94470-3880',
    Fixo: '(11) 98321-5201',
    Email: 'abc@cambioabc.com.br',
    Endereço:
      'Avenida Pereira Barreto, Nº 42, Loja 75 Shopping ABC - Vila Gilda, Santo André/SP. CEP: 09.190-210',
    Localizacao: 'GRANDE SÃO PAULO',
    Horario: 'Segunda à Sexta-feira - 10:00 às 20:00. Sábado - 10:00 às 18:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Remessa Internacional (MoneyGram)',
      p4: 'Pagamento Internacional (PI)',
    },
    RazaoSocial: 'QUIMERA AGÊNCIA DE VIAGENS E TURISMOS LTDA',
    NomeFantasia: 'ABC CÂMBIO\r\n',
    CNPJ: '10.449.567/0001-01\r\n',
  },
];

export const InteriorSP = [
  // {
  //   Cidade: 'Araras',
  //   LojaAutorizada: 'ASPEN CÂMBIO E OURO ',
  //   WhatsApp: '(19) 99739-1808',
  //   Fixo: '(19) 3541-0255',
  //   Email: 'atendimento@aspencambio.com.br',
  //   Endereço:
  //     'Rua Domingos Graziano, Nº 53, Sala 47 - Centro, Araras/SP. CEP: 13.600-718\r\n',
  //   Localizacao: 'INTERIOR DE SÃO PAULO',
  //   Horario: 'Segunda à Sexta-feira - 10:00 às 17:00.',
  //   Produtos: {
  //     p3: 'Conta Global Internacional',
  //     p2: 'Moedas estrangeiras (Compra e venda)',
  //     p4: 'Pagamento Internacional (PI)',
  //     p5: 'Remessa Internacional (MoneyGram)',
  //   },
  //   Outros: ['DHL'],
  //   RazaoSocial: 'GFP GRIGOLETO SERVIÇOS ADMINISTRATIVOS EIRELI',
  //   NomeFantasia: 'ASPEN CÂMBIO E OURO',
  //   CNPJ: '29.698.861/0001-77\r\n',
  // },

  {
    Cidade: 'Campinas',
    LojaAutorizada: 'PAX CÂMBIO\r\n',
    WhatsApp: 'Não Possui',
    Fixo: '(19) 97121-0033',
    Email: 'paxcambio.bandeiras@gmail.com',
    Endereço:
      'Avenida John Boyd Dunlop, Nº 3900 - Loja 2003 - Shooping Parque Bandeira 2º piso - Jardim Ipaussurama - Campinas/SP. CEP: 13.060-905',
    Localizacao: 'INTERIOR DE SÃO PAULO',
    Horario: 'Segunda à Sexta-feira - 10:00 às 17:30.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
    },
    RazaoSocial: 'PAX SOLUÇÕES DIGITAIS LTDA',
    NomeFantasia: 'PAX CÂMBIO\r\n',
    CNPJ: '38.366.912/0001-54\r\n',
  },

  {
    Cidade: 'Itu',
    LojaAutorizada: 'M & A CÂMBIO\r\n',
    WhatsApp: '(11) 4813-2406',
    Fixo: 'Não possui',
    Email: 'contato@macambio.com',
    Endereço:
      'Rua Santa Rita, Nº 412 - Sala 2 - Centro, Itu/SP. CEP: 13.300-070\r\n',
    Localizacao: 'INTERIOR DE SÃO PAULO',
    Horario:
      'Segunda à Sexta-feira - 08:30 às 17:30. Sábados - 09:00 às 13:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['Chip Internacional (MySimTravel)', 'Seguro Viagem (GTA)'],
    RazaoSocial: 'M & A CÂMBIO E CORRESPONDENTE BANCARIO LTDA',
    NomeFantasia: 'M & A CÂMBIO\r\n',
    CNPJ: '32.917.926/0001-32\r\n',
  },
];

export const Maranhao = [
  {
    Cidade: 'São Luis',
    LojaAutorizada: 'NACIONAL CÂMBIO\r\n',
    WhatsApp: '(98) 98352-3938',
    Fixo: '(98) 3212-8635',
    Email: 'nacionalcambio.filial@gmail.com',
    Endereço:
      'Avenida Professor Carlos Cunha, Nº 1000, Loja 255a, São Luis/MA. CEP: 65.076-907',
    Localizacao: 'MARANHÃO',
    Horario:
      'Segunda à Sexta-feira - 10:00 às 21:00.  Sábados - 10:00 às 18:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'J L AGÊNCIA DE VIAGENS E TURISMO LTDA',
    NomeFantasia: 'NACIONAL CÂMBIO\r\n',
    CNPJ: '17.644.908/0001-30\r\n',
  },
];

export const MatoGrosso = [
  {
    Cidade: 'Cuiabá',
    LojaAutorizada: 'JAC COMÉRCIO E REPRESENTAÇÕES\r\n',
    WhatsApp: 'Não Possui',
    Fixo: '(65) 2127-7242',
    Email: 'pcomjac@gmail.com',
    Endereço:
      'Rua Custodio de Mello, Nº 121, Quadra 01 Lote 12 - Cidade Alta, Cuiabá/MT. CEP:  78.030-435',
    Localizacao: 'MATO GROSSO',
    Horario:  'Segunda à Sexta-feira - 08:00 às 17:00.',
    Ouro: ['Posto de Compra e Venda de Ouro'],
    RazaoSocial: 'GABRIEL E FRANZ LTDA',
    NomeFantasia: 'JAC COMÉRCIO E REPRESENTAÇÕES\r\n',
    CNPJ: '36.121.396/0001-72\r\n',
  },
  {
    Cidade: 'Cuiabá',
    LojaAutorizada: 'MVI AGÊNCIA DE VIAGENS E TURISMO \r\n',
    WhatsApp: '(65) 99998-2298',
    Fixo: '(65) 3028-3498',
    Email: 'atendimentocgb06@mviturismo.com.br',
    Endereço:
      'Avenida das Flores, Nº 945, sala 1305 - Jardim Cuiaba, Cuiabá/MT. CEP:  78.043-172',
    Localizacao: 'MATO GROSSO',
    Horario: 'Segunda à Sexta-feira - 08:00 às 18:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Consórcio de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
      'Premiação Certificado em Barra de Ouro',
    ],
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p4: 'Pagamento Internacional (PI)',
      p5: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'MVI AGÊNCIA DE VIAGENS E TURISMO LTDA',
    NomeFantasia: 'MVI AGÊNCIA DE VIAGENS E TURISMO\r\n',
    CNPJ: '04.749.227/0001-95\r\n',
  },
  {
    Cidade: 'Cuiabá',
    LojaAutorizada: 'FRATUR CÂMBIO',
    WhatsApp: '(65) 3624-9400',
    Fixo: '(65) 3624-9400',
    Email: 'rosangelaouro@hotmail.com',
    Endereço:
      'Rua Cândido Mariano, Nº 401 - Centro Norte, Cuiabá/MT. CEP: 78.005-150',
    Localizacao: 'MATO GROSSO',
    Horario: 'Segunda à Sexta-feira - 08:00 às 16:30.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
      'Premiação Certificado em Barra de Ouro',
    ],
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'ROSANGELA APARECIDA FRATUCHELI',
    NomeFantasia: 'FRATUR CÂMBIO\r\n',
    CNPJ: '02.762.018/0001-56\r\n',
  },
  {
    Cidade: 'Peixoto de Azevedo',
    LojaAutorizada: 'FATOR METAIS\r\n',
    WhatsApp: 'Não Possui',
    Fixo: '(66) 9999-3377',
    Email: 'fatormetal@hotmail.com',
    Endereço: 'Avenidas Brasil, Nº 849 - Centro - Peixoto de Azevedo/MT. CEP: 78.530-000',
    Localizacao: 'MATO GROSSO',
    Horario: 'Segunda à Sexta-feira - 07:00 às 17:30.',
    Ouro: ['Posto de Compra e Venda de Ouro'],
    RazaoSocial: 'MATHEUS P. B. LIMA LTDA',
    NomeFantasia: 'FATOR METAIS\r\n',
    CNPJ: '42.597.589/0001-05\r\n',
  },
  // {
  //   Cidade: 'Sinop',
  //   LojaAutorizada: 'VERTICAL',
  //   WhatsApp: '(66) 99217-0747',
  //   Fixo: '(66) 99996-0021',
  //   Email: 'atendimento@verticalcambio.com.br',
  //   Endereço:
  //     'Avenidas das Embaúbas, Nº 1578 - Sala 02, Térreo - Edifício Sinop Center - Setor Comercial - Sinop/MT. CEP: 78.550-206',
  //   Localizacao: 'MATO GROSSO',
  //   Horario: 'Segunda à Sexta-feira - 08:00 às 17:00.',
  //   Produtos: {
  //     p2: 'Conta Global Internacional',
  //     p1: 'Moedas estrangeiras (Compra e venda)',
  //     p3: 'Pagamento Internacional (PI)',
  //   },
  //   RazaoSocial: 'VERTICAL TURISMO LTDA',
  //   NomeFantasia: 'VERTICAL',
  //   CNPJ: '20.016.488/0001-52',
  // },
];

export const MinasGerais = [
  {
    Cidade: 'Patos de Minas',
    LojaAutorizada: 'BEST CÂMBIO',
    WhatsApp: '(34) 99777-0101',
    Fixo: '(34) 3815-0048',
    Email: 'bestcambio@gmail.com',
    Endereço:
      'Praça Alexina Cândida Conceição, Nº 05, Loja 56 - Centro, Patos de Minas/MG. CEP: 38.700-022',
    Localizacao: 'MINAS GERAIS',
    Horario: 'Segunda à Sexta-feira - 10:00 às 18:00.',
    Produtos: {
      p2: 'Moedas Estrangeiras (Compra e Venda)',
      p3: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['DHL'],
    RazaoSocial: 'JUNIOR GERALDO GUEDES CUNHA & CIA LTDA',
    NomeFantasia: 'BEST CÂMBIO\r\n',
    CNPJ: '11.077.304/0002-54\r\n',
  },
];

export const Parana = [
  {
    Cidade: 'Curitiba',
    LojaAutorizada: 'ALIANÇA CÂMBIO\r\n',
    WhatsApp: '(41) 99927-0173',
    Fixo: '(41) 3093-5307',
    Email: 'marcelo@aliancacambio.com.br',
    Endereço:
      'Avenida Luiz Xavier, Nº 68, Loja 06 - Centro, Curitiba/PR. CEP: 80.020-020',
    Localizacao: 'PARANÁ',
    Horario:
      'Segunda à Sexta-feira - 09:00 às 17:30. Sábados - 09:00 às 12.30.',
    Produtos: {
      p1: 'Remessa Internacional (Moneygram)',
    },
    RazaoSocial: 'ALC SERVIÇOS E TURISMO LTDA',
    NomeFantasia: 'ALIANÇA CÂMBIO\r\n',
    CNPJ: '29.917.659/0001-99\r\n',
  },

  {
    Cidade: 'Apucarana',
    LojaAutorizada: 'FORLIN CORRETORA DE SEGUROS\r\n',
    WhatsApp: '(43) 98800-2367',
    Fixo: '(43) 3422-2879',
    Email: 'administrativo@forlincambio.com.br',
    Endereço:
      'Avenida Dr. Munhoz da Rocha, Nº 965 - Centro, Apucarana/PR. CEP: 86.800-010\r\n',
    Localizacao: 'PARANÁ',
    Horario: 'Segunda à Sexta-feira - 09:00 às 17:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'V. FORLIN CORRETORA DE SEGUROS LTDA',
    NomeFantasia: 'FORLIN CORRETORA DE SEGUROS\r\n',
    CNPJ: '03.322.945/0001-18\r\n',
  },
];

export const Para = [
  {
    Cidade: 'Santarem',
    LojaAutorizada: 'TAPAJOS CÂMBIO',
    WhatsApp: '(93) 99211-6979',
    Fixo: '(93) 99123-1362',
    Email: 'gil.stm30@gmail.com',
    Endereço:
      'Travessa Dos Mártires, Nº 198a - Centro, Santarém/PA. CEP: 68.005-540',
    Localizacao: 'PARÁ',
    Horario: 'Segunda à Sábado - 09:00 às 18:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
    },
    RazaoSocial: 'J ANTUNES DA SILVA EIRELI',
    NomeFantasia: 'TAPAJOS CÂMBIO',
    CNPJ: '30.632.585/0001-23',
  },
  {
    Cidade: 'Belém',
    LojaAutorizada: 'TURVICAM DOCAS',
    WhatsApp: '(91) 3201-5454',
    Fixo: '(91) 3201-5491',
    Email: 'atendimento@turvicam.com.br',
    Endereço:
      'Avenida Boulevard Castilhos França, s/n, Estação das Docas, Loja 03v - Campina, Belém/PA. CEP: 66.010-020',
    Localizacao: 'PARÁ',
    Horario: 'Segunda à Sábado - 10:00 às 19:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
    ],
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['Chip Internacional (MySimTravel)', 'DHL', 'Seguro Viagem (GTA)'],
    RazaoSocial: 'TURVICAM ESTAÇÃO DOCAS',
    NomeFantasia: 'TURVIDOCAS',
    CNPJ: '34.623.710/0007-85',
  },
  {
    Cidade: 'Belém',
    LojaAutorizada: 'TURVICAM MATRIZ',
    WhatsApp: '(91) 3201-5454',
    Fixo: '(91) 3201-5454',
    Email: 'atendimento@turvicam.com.br',
    Endereço:
      'Avenida Presidente Vargas, Nº 640 - Campina, Belém/PA CEP: 66.017-000',
    Localizacao: 'PARÁ',
    Horario: 'Segunda à Sexta-feira - 08:00 às 18:00. Sábado - 08:00 às 14:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
    ],
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['Chip Internacional (MySimTravel)', 'DHL', 'Seguro Viagem (GTA)'],
    RazaoSocial: 'TURVICAM TURISMO VIAGENS E CÂMBIO LTDA',
    NomeFantasia: 'TURVICAM',
    CNPJ: '34.623.710/0001-90',
  },
  {
    Cidade: 'Belém',
    LojaAutorizada: 'TURVICAM AEROPORTO',
    WhatsApp: '(91) 3201-5454',
    Fixo: '(91) 3201-5455',
    Email: 'atendimento@turvicam.com.br',
    Endereço:
      'Avenida Júlio César, s/n, Loja 26 - Val de Cães - Térreo Aeroporto, Belém/PA. CEP: 66.115-970',
    Localizacao: 'PARÁ',
    Horario: 'Todos os dias - 06:00 às 00:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
    ],
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['Chip Internacional (MySimTravel)', 'DHL', 'Seguro Viagem (GTA)'],
    RazaoSocial: 'TURVICAM TURISMO VIAGENS E CÂMBIO LTDA (Aeroporto)',
    NomeFantasia: 'TURVAERO',
    CNPJ: '34.623.710/0005-13',
  },
];

export const Recife = [
  {
    Cidade: 'Recife',
    LojaAutorizada: 'RRC RECIFE',
    WhatsApp: '(81) 98204-3054',
    Fixo: '(81) 98124-4250',
    Email: 'rrcrecife@gmail.com',
    Endereço:
      'Rua Padre Carapuceiro, Nº 968, SALA 1701, Edificio Emp. Janete Costa - Boa Viagem, Recife/PE. CEP: 51.020-280',
    Localizacao: 'RECIFE',
    Horario: 'Segunda à Sexta-feira - 09:00 às 17:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
    ],
    Produtos: {
      p2: 'Moedas estrangeiras (Compra e venda)',
      p5: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['Chip Internacional (MySimTravel)'],
    RazaoSocial: 'RRC REPRESENTAÇÕES COMERCIAIS LTDA',
    NomeFantasia: 'OUROCAMBIO\r\n',
    CNPJ: '08.979.291/0001-13',
  },
];

export const RioJaneiro = [
  {
    Cidade: 'Rio de Janeiro',
    LojaAutorizada: 'DANTUR CATETE',
    WhatsApp: '(21) 98566-5128',
    Fixo: '(21) 3489-8440',
    Email: 'dantur@dantur.com.br',
    Endereço:
      'Rua do Catete, Nº 228,  Loja 122, Catete - Rio de Janeiro/RJ. CEP: 22.220-001',
    Localizacao: 'RIO DE JANEIRO',
    Horario:
      'Segunda à Sexta-feira - 09:30 às 18:00. Sábados - 10:00 às 14:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
      'Premiação Certificado em Barra de Ouro',
    ],
    Produtos: {
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'DANTUR PASSAGENS E TURISMO LTDA (CATETE )',
    NomeFantasia: 'DANTUR PASSAGENS E TURISMO LTDA',
    CNPJ: '31.899.354/0003-05',
  },
  {
    Cidade: 'Rio de Janeiro',
    LojaAutorizada: 'DANTUR CENTRO',
    WhatsApp: '(21) 98566-5128',
    Fixo: '(21) 2262-3424',
    Email: 'danturcentro1@dantur.com.br',
    Endereço:
      'Avenida Rio Branco, Nº 156 - Subsolo, Loja 134, Centro - Rio de Janeiro/RJ. CEP: 20.040-901\r\n',
    Localizacao: 'RIO DE JANEIRO',
    Horario: 'Segunda à Sexta-feira - 09:30 às 17:30.',
    Produtos: {
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'DANTUR PASSAGENS E TURISMO LTDA (CENTRO)',
    NomeFantasia: 'DANTUR TURISMO',
    CNPJ: '31.899.354/0002-24',
  },
  {
    Cidade: 'Flamengo',
    LojaAutorizada: 'DANTUR FLAMENGO',
    WhatsApp: 'Não Possui',
    Fixo: '(21) 98566-5128',
    Email: 'expresschangetur@gmail.com',
    Endereço:
      'Largo do Machado, Nº 29, Loja 47 - Catete - Rio de Janeiro/RJ. CEP: 22.221-020 \r\n',
    Localizacao: 'RIO DE JANEIRO',
    Horario:
      'Segunda à Sexta-feira - 09:30 às 18:00. Sábados - 10:00 às 14:00.',
    Produtos: {
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'DANTUR PASSAGENS E TURISMO LTDA',
    NomeFantasia: 'DANTUR TURISMO',
    CNPJ: '31.899.354/0001-43',
  },
  {
    Cidade: 'Rio de Janeiro',
    LojaAutorizada: 'EXPRESS CHANGE TUR',
    WhatsApp: '(21) 98566-5128',
    Fixo: '(21) 4042-5187',
    Email: 'dantur@dantur.com.br',
    Endereço:
      'Avenida Francisco Bicalho, Nº 01 - Loja L04SA - Santo Cristo, Rio de Janeiro/RJ - CEP: 20.220-310',
    Localizacao: 'RIO DE JANEIRO',
    Horario: 'Segunda à Domingo - 07:00 às 21:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
    ],
    Produtos: {
      p4: 'Pagamento Internacional (PI)',
      p5: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'EXPRESS CHANGE TURISMO EIRELI',
    NomeFantasia: 'EXPRESS CHANGE TUR',
    CNPJ: '14.298.933/0001-11',
  },
];

// export const RioGrandeSul = [
//   {
//     Cidade: 'Porto Alegre',
//     LojaAutorizada: 'MONEYWAY',
//     WhatsApp: '(51) 99964-9824',
//     Fixo: '(51) 3212-1500',
//     Email: 'moneywaycambioturismo@hotmail.com',
//     Endereço:
//       'Avenida Borges, Nº 453, Sala 132, Centro Histórico - Porto Alegre/RS. CEP: 90.020-905',
//     Localizacao: 'RIO GRANDE DO SUL',
//     Horario:
//       'Segunda à Sexta-feira - 09:00 às 17:00. Sábados - 09:00 às 14:00.',
//     Produtos: {
//       p2: 'Conta Global Internacional',
//       p1: 'Moedas estrangeiras (Compra e venda)',
//       p4: 'Remessa Internacional (MoneyGram)',
//     },
//     RazaoSocial: 'MONEY WAY TURISMO E CÂMBIO LTDA',
//     NomeFantasia: 'MONEY WAY TURISMO E CÂMBIO LTDA\r\n',
//     CNPJ: '32.778.428/0001-56\r\n',
//   },
// ];

export const SantaCatarina = [
  {
    Cidade: 'Itajaí',
    LojaAutorizada: 'TURISMO BRASIL',
    WhatsApp: '(47) 99901-4844',
    Fixo: '(47) 3046-1444',
    Email: 'daronchluciano@gmail.com',
    Endereço:
      'R. Dr. Pedro Ferreira, Nº 84, sala 01 - Centro, Itajaí/SC. CEP: 88.301-030',
    Localizacao: 'SANTA CATARINA',
    Horario: 'Segunda à Sexta-feira - 08:00 às 18:00.',
    Produtos: {
      p1: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'TURISMO BRASIL ITAJAI LTDA',
    NomeFantasia: 'TURISMO BRASIL\r\n',
    CNPJ: '23.785.184/0001-48\r\n',
  },
];

export const Sergipe = [
  {
    Cidade: 'Aracaju',
    LojaAutorizada: 'ITAPOAN CÂMBIO E TURISMO\r\n',
    WhatsApp: '(79) 98858-1887',
    Fixo: '(79) 3213-0090',
    Email: 'filial@itapoancambioeturismo.com.br',
    Endereço:
      'Avenida Luiz Lua Gonzaga, Nº 215, Loja 12, Bairro Jardins, Aracaju/SE. CEP:  49.035-500',
    Localizacao: 'SERGIPE',
    Horario: 'Segunda à Sábado - 10:00 às 21:00. Domingos - 14:00 às 19:00.',
    Ouro: [
      'Barras de Ouro',
      'Cartão OuroFácil',
      'Compra de Ouro',
      'Consórcio de Ouro',
      'Lâmina de Ouro',
      'Pirâmide de Ouro',
      'Premiação Certificado em Barra de Ouro',
    ],
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    RazaoSocial: 'ITAPOAN VIAGENS E TURISMO LTDA (FILIAL)',
    NomeFantasia: 'ITAPOAN CÂMBIO ITAPE TURISMO\r\n',
    CNPJ: '25.196.017/0002-78',
  },
  {
    Cidade: 'Aracaju',
    LojaAutorizada: 'ITAPOAN CÂMBIO E TURISMO\r\n',
    WhatsApp: '(79) 98834-8627',
    Fixo: '(79) 3231-2881',
    Email: 'matriz@itapoancambioeturismo.com.br',
    Endereço:
      'Avenida Delmiro Gouveia , Nº 400, Shopping Riomar, Bairro Coroado Meio, Aracaju/SE. CEP: 49.035-500',
    Localizacao: 'SERGIPE',
    Horario: 'Segunda à Sábado - 10:00 às 21:00. Domingos - 14:00 às 19:00.',
    Produtos: {
      p1: 'Moedas estrangeiras (Compra e venda)',
      p3: 'Pagamento Internacional (PI)',
      p4: 'Remessa Internacional (MoneyGram)',
    },
    Outros: ['DHL'],
    RazaoSocial: 'ITAPOAN VIAGENS E TURISMO LTDA (MATRIZ)',
    NomeFantasia: 'ITAPOAN CÂMBIO E TURISMO\r\n',
    CNPJ: '25.196.017/0001-97\r\n',
  },
];
