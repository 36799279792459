import consultorFrente1g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-1g-frente.png";
import consultorFrente2g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-2g-frente.png";
import consultorFrente5g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-5g-frente.png";
import consultorFrente10g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-10g-frente.png";
import consultorFrente25g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-25g-frente.png";
import consultorFrente31g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-31g-frente.png";
import consultorFrente50g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-50g-frente.png";
import consultorVerso1g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-1g-verso.png";
import consultorVerso2g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-2g-verso.png";
import consultorVerso5g from "../../../assets/newImages/mockups/empresa-ouro/consultor/consultor-ouro-5g-verso.png";

export const  consultorMockups = {
    nome: "Consultor",
    frente1g: consultorFrente1g,
  
    frente2g: consultorFrente2g,
  
    frente5g: consultorFrente5g, 
    frente10g: consultorFrente10g,
    frente25g:consultorFrente25g,
    frente31g: consultorFrente31g,
    frente50g: consultorFrente50g, 
  
    verso1g: consultorVerso1g,
    verso2g: consultorVerso2g,
  
    verso5g: consultorVerso5g
  };