import sydneyFrente1g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-1g-frente.png";
import sydneyFrente2g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-2g-frente.png";
import sydneyFrente5g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-5g-frente.png";
import sydneyFrente10g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-10g-frente.png";
import sydneyFrente25g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-25g-frente.png";
import sydneyFrente31g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-31g-frente.png";
import sydneyFrente50g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-50g-frente.png";
import sydneyVerso1g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-1g-verso.png";
import sydneyVerso2g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-2g-verso.png";
import sydneyVerso5g from "../../../assets/newImages/mockups/mundos/sydney/sydney-ouro-5g-verso.png";

export const  sydneyMockups = {
    nome: "Sydney",
    frente1g: sydneyFrente1g,
  
    frente2g: sydneyFrente2g,
  
    frente5g: sydneyFrente5g,
  
    frente10g: sydneyFrente10g,
  
    frente25g: sydneyFrente25g,
  
    frente31g: sydneyFrente31g,
  
    frente50g: sydneyFrente50g,
  
    verso1g: sydneyVerso1g,
    verso2g: sydneyVerso2g,
  
    verso5g: sydneyVerso5g,
    verso10g: sydneyVerso5g,
    verso25g: sydneyVerso5g,
    verso31g: sydneyVerso5g,
    verso50g: sydneyVerso5g,
  };

 