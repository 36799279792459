/** @format */

export default class NumberUtility {
  static displayCurrencyValue(value) {
    if (value || value === 0) {
      if (value === 0) return "0,00";

      return Number(value).toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
      });
    }
  }

  static fromCurrencyToFloat(value) {
    let normalizedValue = value.toString().replace(".", "").replace(",", ".");

    return parseFloat(normalizedValue);
  }
}
