import venezaFrente1g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-1g-frente.png";
import venezaFrente2g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-2g-frente.png";
import venezaFrente5g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-5g-frente.png";
import venezaFrente10g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-10g-frente.png";
import venezaFrente25g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-25g-frente.png";
import venezaFrente31g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-31g-frente.png";
import venezaFrente50g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-50g-frente.png";
import venezaVerso1g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-1g-verso.png";
import venezaVerso2g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-2g-verso.png";
import venezaVerso5g from "../../../assets/newImages/mockups/mundos/veneza/veneza-ouro-5g-verso.png";

export const  venezaMockups = {
    nome: "Veneza",
    frente1g: venezaFrente1g,
  
    frente2g: venezaFrente2g,
  
    frente5g: venezaFrente5g,
  
    frente10g: venezaFrente10g,
  
    frente25g: venezaFrente25g,
  
    frente31g: venezaFrente31g,
  
    frente50g: venezaFrente50g,
  
    verso1g: venezaVerso1g,
    verso2g: venezaVerso2g,
  
    verso5g: venezaVerso5g,
    verso10g: venezaVerso5g,
    verso25g: venezaVerso5g,
    verso31g: venezaVerso5g,
    verso50g: venezaVerso5g,
  };

 