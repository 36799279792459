import araraAzulFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-1g-frente.png";
import araraAzulFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-2g-frente.png";
import araraAzulFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-5g-frente.png";
import araraAzulFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-10g-frente.png";
import araraAzulFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-25g-frente.png";
import araraAzulFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-31g-frente.png";
import araraAzulFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-50g-frente.png";
import araraAzulVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-1g-verso.png";
import araraAzulVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-2g-verso.png";
import araraAzulVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-azul-5g-verso.png";
import araraVermelhaFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-1g-frente.png";
import araraVermelhaFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-2g-frente.png";
import araraVermelhaFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-5g-frente.png";
import araraVermelhaFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-10g-frente.png";
import araraVermelhaFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-25g-frente.png";
import araraVermelhaFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-31g-frente.png";
import araraVermelhaFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-50g-frente.png";
import araraVermelhaVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-1g-verso.png";
import araraVermelhaVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-2g-verso.png";
import araraVermelhaVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/arara/arara-vermelha-5g-verso.png";

export const  araraAzulMockups = {
    nome: "Arara Azul",
    frente1g: araraAzulFrente1g,
  
    frente2g: araraAzulFrente2g,
  
    frente5g: araraAzulFrente5g,
  
    frente10g: araraAzulFrente10g,
  
    frente25g: araraAzulFrente25g,
  
    frente31g: araraAzulFrente31g,
  
    frente50g: araraAzulFrente50g,
  
    verso1g: araraAzulVerso1g,
    verso2g: araraAzulVerso2g,
  
    verso5g: araraAzulVerso5g,
  };

 export const  araraVermelhaMockups = {
    nome: "Arara",
    frente1g: araraVermelhaFrente1g,
  
    frente2g: araraVermelhaFrente2g,
  
    frente5g: araraVermelhaFrente5g,
  
    frente10g: araraVermelhaFrente10g,
  
    frente25g: araraVermelhaFrente25g,
  
    frente31g: araraVermelhaFrente31g,
  
    frente50g: araraVermelhaFrente50g,
  
    verso1g: araraVermelhaVerso1g,
    verso2g: araraVermelhaVerso2g,
  
    verso5g: araraVermelhaVerso5g,
  };
