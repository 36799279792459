import corujaFrente1g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-1g-frente.png";
import corujaFrente2g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-2g-frente.png";
import corujaFrente5g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-5g-frente.png";
import corujaFrente10g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-10g-frente.png";
import corujaFrente25g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-25g-frente.png";
import corujaFrente31g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-31g-frente.png";
import corujaFrente50g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-50g-frente.png";
import corujaVerso1g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-1g-verso.png";
import corujaVerso2g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-2g-verso.png";
import corujaVerso5g from "../../../assets/newImages/mockups/fauna-brasileira/coruja/coruja-ouro-5g-verso.png";

export const  corujaMockups = {
    nome: "Coruja",
    frente1g: corujaFrente1g,
  
    frente2g: corujaFrente2g,
  
    frente5g: corujaFrente5g,
  
    frente10g: corujaFrente10g,
  
    frente25g: corujaFrente25g,
  
    frente31g: corujaFrente31g,
  
    frente50g: corujaFrente50g,
  
    verso1g: corujaVerso1g,
    verso2g: corujaVerso2g,
  
    verso5g: corujaVerso5g,
  };

 