import React from 'react';
import './politicas.css';
import img from '../../../assets/newImages/politicaPrivacidade.png';

export default function PoliticaPrivacidade() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              POLÍTICA DE PRIVACIDADE
            </p>
            <p>Lei 13.709/2018 (Lei Geral de Proteção de Dados – LGPD) </p>
            <p>
              A Política de Privacidade e Cookies da OM está em conformidade com
              a Lei Geral de Proteção de Dados – LGPD Pessoais (LGPD) número
              13.709, sancionada em 14 de Agosto de 2018 e estabelece um canal
              de comunicação eficiente entre os titulares dos dados e seus
              parceiros, buscando manter as boas práticas no comprimento desta
              Lei, de acordo com as normas legais e regulamentares aplicáveis e
              relativas ao direito do consumidor.
            </p>

            <p>
              A Política de Privacidade e Cookies (LGPD), é reavaliada a cada 2
              (dois) anos, em razão de requerimentos legais que justifiquem a
              sua atualização ou quando da ocorrência de eventos considerados
              relevantes pela <strong>OM DTVM</strong>, incluindo: I – oferta de
              novos produtos ou serviços relevantes; II – modificações
              relevantes nos produtos, nos serviços, nas atividades ou nos
              processos da <strong>OM DTVM</strong>; III – mudanças
              significativas no modelo de negócios da <strong>OM DTVM</strong>;
              IV – reorganizações societárias significativas; V – mudanças
              políticas, legais, regulamentares, tecnológicas ou de mercado,
              incluindo alterações significativas nas preferências de consumo,
              que impactem de forma relevante os negócios da
              <strong>OM DTVM</strong>, tanto positiva quanto negativamente; e
              VI – alterações relevantes em relação ao escopo de aplicação da
              Política de Privacidade (LGPD).
            </p>
            <p>
              A Política de Privacidade aplica-se, de acordo com o Art. 1º desta
              Lei, sobre o tratamento de dados pessoais, inclusive nos meios
              digitais, por pessoa natural ou por pessoa jurídica de direito
              público ou privado, com o objetivo de proteger os direitos
              fundamentais de liberdade e de privacidade e o de livre
              desenvolvimento da personalidade da pessoa natural.
            </p>
            <p>
              Diante disso, nossa Política de Privacidade atende a todas as
              partes interessadas, ou seja,
            </p>
            <p>
              a) os clientes e usuários dos produtos e serviços da instituição;
            </p>
            <p>
              b) a comunidade interna à instituição (funcionários,
              colaboradores, pessoas contratadas fora do regime CLT que executam
              atividades em nome da <strong>OM DTVM</strong>, estagiários,
              jovens aprendizes, correspondentes cambiais e postos de compra de
              ouro);
            </p>
            <p>
              c) os fornecedores e os prestadores de serviços terceirizados
              relevantes da instituição;
            </p>
            <p>
              d) as demais pessoas impactadas pelos produtos, serviços,
              atividades e processos da instituição; e
            </p>
            <p>
              e) todos aqueles que querem conhecer o posicionamento ético e
              estratégico da <strong>OM DTVM</strong>.
            </p>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO
              </li>
              <li className="list-unstyled">
                <p>
                  Aplicar a Lei em sua íntegra, de acordo com o estabelecido no
                  Art. 3 que especifica a obrigação de atendê-la em toda e
                  qualquer operação de tratamento de dados, independentemente do
                  meio, do país de sua sede ou do país onde estejam localizados
                  os dados, desde que:
                </p>
                <p>I – As operações sejam realizadas em território nacional;</p>
                <p>
                  II – Tenham por objetivo a oferta ou fornecimento de bens ou
                  serviços ou tratamento de dados de indivíduos localizados no
                  território nacional;
                </p>
                <p>
                  Se aplicam às candidaturas para vagas na{' '}
                  <strong>OM DTVM</strong>, e em qualquer operação cambial, de
                  ouro ou outros serviços oferecidos ao mercado por esta
                  Instituição Financeira (IF) que haja a necessidade de
                  formalização contratual.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                QUAIS INFORMAÇÕES SÃO TRATADAS?
              </li>
              <li className="list-unstyled">
                <p>
                  A depender da relação que o cliente ou usuário possua com a{' '}
                  <strong>OM DTVM</strong>, podem ser realizadas a coleta de
                  diferentes categorias de Dados Pessoais não sensíveis, tais
                  quais:
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      Informações que identificam o cliente ou o tornam
                      identificável, incluindo, por exemplo, o seu nome, RG,
                      CPF, CNH, estado civil, gênero, profissão, nacionalidade,
                      data de nascimento, filiação, RNE, passaporte;
                    </li>
                    <li>
                      Informações que auxiliem a empresa a contatá-lo, como
                      endereço postal, CEP, telefone fixo ou celular, e-mail,
                      etc.;
                    </li>
                    <li>
                      Informações financeiras, como dados bancários, dados de
                      rendimentos etc.;
                    </li>
                    <li>
                      Informações comportamentais, como perfil de consumo ou
                      hábitos de navegação online;
                    </li>
                    <li>
                      Atributos associados aos dispositivos eletrônicos do
                      cliente, como endereço de IP, fontes instaladas, idioma,
                      configurações do navegador e fuso horário;
                    </li>
                    <li>
                      Informações proveniente de Terceiros, como identificadores
                      de contas bancárias, informações de rendimentos, certidões
                      de óbito etc.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMO SÃO COLETADOS OS DADOS PESSOAIS?
              </li>
              <p>
                Os Dados Pessoais que a <strong>OM DTVM</strong> possui sobre o
                cliente ou usuários, podem ter sido informados diretamente por
                vontade própria, por “terceiros” ou coletados de forma
                automática. A <strong>OM DTVM</strong> também pode ter coletado
                alguns dados disponíveis publicamente, dispensando a questão de
                “consentimento”. Para assegurar que o cliente tenha maior
                clareza, o detalhamento da correspondência entre as fontes e as
                informações coletadas, está abaixo:
              </p>
              <p>
                <ul>
                  <li>
                    Fornecidas diretamente pelo cliente ou usuário durante o
                    relacionamento do cliente com a <strong>OM DTVM</strong>,
                    desde um contato via website, através do link
                    www.ourominas.com/om e seus parceiros de negócio, da área de
                    relacionamento, marketing ou comercial, na contratação de
                    produtos e serviços oferecidos ou através de cadastro de
                    fornecedores, o cliente e/ou usuário compartilha inúmeros
                    dados pessoais não sensíveis, os quais são utilizados para
                    as finalidades que lhe são informadas;
                  </li>
                </ul>
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMO SÃO UTILIZADAS?
              </li>
              <p>
                <ul>
                  <li>
                    <strong>
                      ATENDER A FINALIDADE PARA A QUAL O DADO FOI FORNECIDO:
                    </strong>{' '}
                    Os dados fornecidos podem ser utilizados sem vício de
                    “consentimento” e com propósitos específicos, informados
                    pelo cliente no momento da coleta das informações e para
                    outras finalidades que sejam compatíveis. Por exemplo, para
                    identificação, responder ou manter contato com o cliente,
                    administrar o histórico de operações, atender às políticas
                    de segurança e privacidade, para atendimento de obrigações
                    do Banco Central, da Autoridade Nacional de Proteção de
                    Dados Pessoais, demandas judiciais, fiscais entre outras
                    finalidades.
                  </li>
                  <li>
                    <strong>
                      PERMITIR O EXERCÍCIO REGULAR DOS DIREITOS DA{' '}
                      <strong>OM DTVM</strong>:
                    </strong>{' '}
                    Mesmo após o término da relação contratual, a{' '}
                    <strong>OM DTVM</strong> identificada como{' '}
                    <strong>“CONTROLADOR”</strong>, poderá tratar alguns Dados
                    Pessoais dos clientes e/ou usuários para exercer seus
                    direitos garantidos em lei e/ou regulamentação dos órgãos
                    governamentais, inclusive como prova em processos judiciais,
                    fiscais, administrativos ou arbitrais.
                  </li>
                  <li>
                    <strong>
                      VIABILIZAR AS ATIVIDADES NECESSÁRIAS PARA AS OPERAÇÕES:
                    </strong>{' '}
                    A <strong>OM DTVM</strong> pode tratar os dados, também,
                    para finalidades legítimas envolvendo a continuidade de suas
                    operações, sempre observando os limites da expectativa e
                    nunca em prejuízo dos interesses de seus clientes, bem como,
                    direitos e liberdades fundamentais. Os Dados Pessoais podem
                    ser considerados, por exemplo, em estudos internos de forma
                    anoniminzada, sobre a utilização dos produtos e serviços ou
                    da área de relacionamento, para permitir uma melhor
                    distribuição e alocação de recursos internos, ou, ainda,
                    para mensurar a qualidade e o nível de satisfação com a
                    prestação de produtos e serviços fornecidos pela empresa ao
                    público em geral.
                  </li>
                  <li>
                    <strong>
                      PROMOVER ATIVIDADES E AMPLIAR AS OFERTAS DE
                      COMERCIALIZAÇÃO DE PRODUTOS E SERVIÇOS:
                    </strong>{' '}
                    Adicionalmente, a <strong>OM DTVM</strong> pode utilizar as
                    informações de contato dos clientes para envio de
                    comunicações publicitárias, notícias, ofertas e promoções
                    realizadas pelo Grupo <strong>OM</strong> ou por intermédio
                    de seus parceiros e que sejam do interesse do cliente. Caso
                    o cliente se sinta incomodado e não deseje mais receber
                    quaisquer informativos publicitários, ele pode, a qualquer
                    momento, contatar a <strong>OM DTVM</strong>, através do
                    nosso canal <strong>DPO –</strong>{' '}
                    <a href="mailto:dpo@ourominas.com">dpo@ourominas.com</a>,{' '}
                    manifestando sua vontade para que sejam encerrados.
                  </li>
                  <li>
                    <strong>
                      EVITAR FRAUDES E ZELAR PELA SEGURANÇA DO CLIENTE:
                    </strong>{' '}
                    Por fim, a <strong>OM DTVM</strong> também trata os dados
                    para comprovar que o cliente é realmente quem diz ser e,
                    assim, evitar fraudes que comprometam operações não
                    autorizadas pelos clientes.
                  </li>
                </ul>
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                O QUE SÃO COOKIES?
              </li>
              <p>
                Cookies são arquivos que podem ser armazenados nos dispositivos
                (pc´s, notebook´s, iPad´s, celulares e tablets) através dos
                browsers (navegadores de internet), e possuem como finalidade, o
                armazenamento de informações de visitas a websites. Essa é uma
                prática comum de uso de internet, dado que ela ajuda os
                desenvolvedores dos websites a criarem sistemas funcionais, além
                de otimizar a experiência de navegação do cliente e/ou usuário.
              </p>
              <p>
                A <strong>OM DTVM</strong> utiliza cookies para diferentes
                finalidades, para coleta de estatísticas e, atividades de
                navegação em diferentes páginas para, por exemplo, personalizar
                anúncios etc.
              </p>
              <p>
                Destacamos que os cookies são essenciais para o normal
                funcionamento dos websites, sendo que oposição à utilização
                desta ferramenta poderá implicar na inutilização dos serviços
                disponíveis, causar mal funcionamento no acesso. Consulte a Guia
                do Google Chrome para saber mais sobre como configurar os
                cookies em um dos principais navegadores do mundo.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COM QUEM COMPARTILHA OS DADOS?
              </li>
              <p>
                A <strong>OM DTVM</strong> poderá utilizar o compartilhamento
                dos Dados Pessoais com pessoas jurídicas do mesmo grupo
                econômico ou com Terceiros, como órgãos governamentais, o Poder
                Judiciário, instituições financeiras, parceiros de negócio,
                fornecedores de serviço e/ou infraestrutura, dentre outros.
              </p>
              <p>
                Ainda, compartilhamentos podem acontecer em caso de operações
                societárias, como parte das negociações, ou qualquer etapa de
                uma compra, fusão ou aquisição parcial ou total em que a{' '}
                <strong>OM DTVM</strong> ou empresas do grupo façam parte.
              </p>
              <p>
                A <strong>OM DTVM</strong> e empresas do grupo se utiliza de
                instrumentos contratuais, políticas e auditorias para assegurar
                que qualquer Terceiro que receba os Dados Pessoais, garanta a
                eles a proteção adequada. É possível que, eventualmente, estes
                Terceiros se encontrem fora do país.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMO OS DADOS PESSOAIS SÃO MANTIDOS SEGUROS?
              </li>
              <p>
                A segurança dos Dados Pessoais dos clientes e/ou usuários, é uma
                prioridade nossa. Por isso, a <strong>OM DTVM</strong> dispõe de
                políticas e procedimentos internos que determinam como os Dados
                Pessoais devem ser tratados pela empresa.
              </p>
              <p>
                A <strong>OM DTVM</strong> adota medidas técnicas aptas a manter
                os Dados Pessoais seguros e protegidos de acessos não
                autorizados e de situações acidentais ou ilícitas de destruição,
                perda, alteração, comunicação ou qualquer outra forma de
                “Tratamento” inadequado ou ilícito, sempre à luz das regras
                aplicáveis de proteção de dados e segurança da informação.
              </p>
              <p>
                Como exemplo de algumas medidas que são tomadas: controle
                restrito do tratamento de Dados Pessoais, incluindo limitação de
                acesso; mecanismos de autenticação de acesso, incluindo senhas e
                sistemas de dupla autenticação (MFA) que asseguram a
                individualização dos registros; inventário detalhado dos
                registros de conexão (IP), incluindo o momento, a duração, a
                identidade do responsável e o arquivo acessado.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DIREITOS DO CLIENTE, ENQUANTO TITULAR DE DADOS
              </li>
              <p>
                De acordo com o Art. 17. da Lei Geral de Proteção de Dados
                Pessoais (LGPD), o cliente tem os seguintes direitos relativos
                aos seus Dados Pessoais (informação relacionada a pessoa natural
                identificada ou identificável):
              </p>
              <ul>
                <li>
                  Saber se a <strong>OM DTVM</strong> trata algum Dado Pessoal
                  dele e, se afirmativo, quais;
                </li>
                <li>
                  Corrigir dados incompletos, inexatos ou desatualizados, pelos
                  meios exigidos pela regulamentação específica, quando
                  necessário;
                </li>
                <li>
                  Solicitar a anonimização, bloqueio ou eliminação de dados
                  desnecessários, excessivos ou que, porventura, tenham sido
                  tratados em desconformidade com a lei;
                </li>
                <li>
                  Solicitar a anonimização, bloqueio ou eliminação de dados
                  desnecessários, excessivos ou que, porventura, tenham sido
                  tratados em desconformidade com a lei; Solicitar alteração,
                  inclusão, atualização, exclusão ou portabilidade dos dados a
                  outro fornecedor de serviço ou produto, caso isso seja feito
                  expressamente, através de nosso canal DPO (
                  <a href="mailto:dpo@ourominas.com">dpo@ourominas.com</a>).
                </li>
              </ul>
              <p>
                Quando a atividade de Tratamento necessitar do consentimento do
                cliente, ele pode se negar a consentir.
              </p>
              <p>
                Neste caso, a <strong>OM DTVM</strong> informará sobre as
                consequências da não realização de tal atividade. Caso seja
                consentido, a qualquer momento o cliente poderá revogá-lo.
              </p>
              <p>
                É importante informar que no Art. 52. desta Lei, a Autoridade
                Nacional de Proteção de Dados estimulará a adoção de padrões
                técnicos que facilitem o controle pelos titulares dos Dados
                Pessoais.
              </p>
              <p>
                Desta forma, a <strong>OM DTVM</strong> atende de forma
                irrestrita a vontade de nossos clientes e/ou usuários, zelando
                pelas boas práticas e não sofrendo sansões administrativas
                aplicáveis, iniciadas por advertência, multas, bloqueios e
                eliminação dos dados pessoais a que se refere a infração.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SANÇÕES ADMINISTRATIVAS INTERNAS
              </li>
              <p>
                As partes interessadas que não cumprirem por negligência, por
                culpa ou dolo os requerimentos da Política de Privacidade e
                Cookie da <strong>OM DTVM</strong>, dependendo da gravidade da
                falta, serão advertidos oficiosamente, advertidos formalmente,
                suspensos temporariamente ou ter seu contrato denunciado.
              </p>

              <p>
                De acordo com o Art. 52 (Das Sanções Administrativas), são
                levados em consideração uma série de elementos como boa-fé do
                infrator, a vantagem auferida ou pretendida pelo infrator, a
                reincidência, o grau do dano, a condição financeira do infrator
                e a cooperação do infrator.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DA RESPONSABILIDADE
              </li>
              <p>
                Art. 31. Quando houver infração em decorrência do tratamento de
                Dados Pessoais por órgãos públicos, a Autoridade Nacional de
                Proteção de Dados poderá enviar informe com medidas cabíveis
                para fazer cessar a violação.
              </p>

              <p>
                Art. 32. A Autoridade Nacional de Proteção de Dados poderá
                solicitar a agentes do Poder Público a publicação de relatórios
                de impacto à proteção de dados pessoais e sugerir a adoção de
                padrões e de boas práticas para os tratamentos de dados pessoais
                pelo Poder Público.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOS AGENTES DE TRATAMENTO PESSOAIS
              </li>
              <p>
                Art. 37. O CONTROLADOR e o OPERADOR devem manter seguros, os
                registros das operações de tratamento de dados pessoais que
                realizarem, especialmente quando baseado no legítimo interesse.
              </p>
              <p>
                Cabe à <strong>OM DTVM</strong> como “CONTROLADOR”, adotar todos
                os procedimentos necessários que garantam a proteção,
                anonimização e acesso, direitos assegurados aos clientes e/ou
                usuários.
              </p>
              <p>
                Art. 39. O Operador (colaboradores e terceiros contratados pela{' '}
                <strong>OM DTVM</strong>) deverá realizar o tratamento segundo
                as instruções fornecidas pelo CONTROLADOR, que verificará a
                observância das próprias instruções e das normas sobre a
                matéria.
              </p>
              <p>
                Art. 40. A Autoridade Nacional de Proteção de Dados poderá
                dispor sobre padrões de interoperabilidade (capacidade de um
                sistema de se comunicar de forma transparente com outro sistema,
                através de API´s ou outros mecanismos), livre acesso aos Dados
                Pessoais e segurança, assim como sobre o tempo de guarda dos
                registros, tendo em vista especialmente a necessidade e a
                transparência.
              </p>
              <p>
                Ao Encarregado (DPO – Data Protection Officer), segundo o Art.
                41, após ter sido nomeado, realizará as seguintes obrigações:
              </p>
              <ul>
                <li>
                  § 1º A identidade e as informações de contato do encarregado
                  deverão ser divulgadas publicamente, de forma clara e
                  objetiva, preferencialmente no sítio eletrônico do
                  controlador.
                </li>
                <li>§ 2º As atividades do encarregado consistem em:</li>
                <li>
                  I – aceitar reclamações e comunicações dos titulares, prestar
                  esclarecimentos e adotar providências;
                </li>
                <li>
                  II – receber comunicações da autoridade nacional e adotar
                  providências;
                </li>
                <li>
                  III – orientar os funcionários e os contratados da entidade a
                  respeito das práticas a serem tomadas em relação à proteção de
                  dados pessoais; e
                </li>
                <li>
                  IV – executar as demais atribuições determinadas pelo
                  controlador ou estabelecidas em normas complementares.
                </li>
                <li>
                  § 3º A Autoridade Nacional de Proteção de Dados poderá
                  estabelecer normas complementares sobre a definição e as
                  atribuições do encarregado, inclusive hipóteses de dispensa da
                  necessidade de sua indicação, conforme a natureza e o porte da
                  entidade ou o volume de operações de tratamento de dados.
                </li>
              </ul>
              <br />
              <p>
                A <strong>OM DTVM</strong> possui um canal denominado DPO, sendo
                o Sr. Adriano Nascimento Felipe responsável pelas atribuições,
                nomeado conforme art. 5º desta lei, respeitando rigorosamente o
                exposto na Seção II - Do Encarregado pelo Tratamento de Dados
                Pessoais.
              </p>
              <p>
                Para dirimir sobre o tema, a <strong>OM DTVM</strong> utiliza o
                seguinte endereço eletrônico{' '}
                <a href="mailto:dpo@ourominas.com">dpo@ourominas.com</a>
              </p>
              <p>
                Fica à cargo da Autoridade Nacional de Proteção de Dados (ANPD),
                autarquia de natureza especial, dotada de autonomia técnica e
                decisória, deliberar na esfera administrativa, em caráter
                terminativo, sobre a interpretação desta Lei, as suas
                competências e os casos omissos.
              </p>
              <p>
                Segundo o § 1º ao impor condicionantes administrativas ao
                tratamento de dados pessoais por agente de tratamento privado,
                sejam eles limites, encargos ou sujeições, a ANPD deve observar
                a exigência de mínima intervenção, assegurando os fundamentos,
                os princípios e os direitos dos titulares previstos no art. 170
                da Constituição Federal e nesta Lei (incluído pela Lei nº 13.853
                de 2019), medida provisória nº 869 de 2018.
              </p>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
