export const UserAthStorageTravelModel = {
  token: "",
  expiresIn: "",
};

export function setUserAuthStorageTravel(token, expiresIn) {
  this.token = token;
  this.expiresIn = expiresIn;
}

