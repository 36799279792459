import belemFrente1g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-1g-frente.png";
import belemFrente2g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-2g-frente.png";
import belemFrente5g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-5g-frente.png";
import belemFrente10g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-10g-frente.png";
import belemFrente25g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-25g-frente.png";
import belemFrente31g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-31g-frente.png";
import belemFrente50g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-50g-frente.png";

import belemVerso1g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-1g-verso.png";
import belemVerso2g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-2g-verso.png";
import belemVerso5g from "../../../assets/newImages/mockups/brasil-turistico/belem/belem-5g-verso.png";

import especificacao1g from "../../../assets/images/especificacoesOuro/ouro-1g.png";
import especificacao2g from "../../../assets/images/especificacoesOuro/ouro-2g.png"
import especificacao5g from "../../../assets/images/especificacoesOuro/ouro-5g.png"
import especificacao10g from "../../../assets/images/especificacoesOuro/ouro-10g.png"
import especificacao25g from "../../../assets/images/especificacoesOuro/ouro-25g.jpg"
import especificacao31g from "../../../assets/images/especificacoesOuro/ouro-31g.png"
import especificacao50g from "../../../assets/images/especificacoesOuro/ouro-50g.png"

export const  belemMockups = {
    nome: "Belém",
    frente1g: belemFrente1g,

    frente2g: belemFrente2g,

    frente5g: belemFrente5g,

    frente10g: belemFrente10g,

    frente25g: belemFrente25g,

    frente31g: belemFrente31g,

    frente50g: belemFrente50g,

    verso1g: belemVerso1g,
    verso2g: belemVerso2g,

    verso5g: belemVerso5g,

    especificacao1g: especificacao1g,
    especificacao2g: especificacao2g,
    especificacao5g: especificacao5g,
    especificacao10g: especificacao10g,
    especificacao25g: especificacao25g,
    especificacao31g: especificacao31g,
    especificacao50g: especificacao50g,
  };

  export const belemBR = [
    {
      name: "Belém",
      value: [
        "OURO BARRA (1g)",
        "OURO BARRA (2g)",
        "OURO BARRA (5g)",
        "OURO BARRA (10g)",
        "OURO BARRA (25g)",
        "OURO BARRA (31,104g)",
        "OURO BARRA (50g)",
      ],
    }]